<template>
  <div class="container">
    <div class="about-banner-mobile">
      <pic-mobile :src="options.info.pic" />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "../../common/Pic";

export default {
  name: 'AboutBannerMobile',
  components: {PicMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.about-banner-mobile {
  width:100%;
  height: 280px;
  margin-bottom: 40px;
  margin-top: 20px;
  border-radius: 12px;
  overflow: hidden;
}
</style>
