<template>
  <div class="video-index-default">
    <video-index-mobile v-if="browser===2" />
  </div>
</template>

<script>

import {mapState} from "vuex";
import VideoIndexMobile from "../../Mobile/Video/mobile";

export default {
  name: 'VideoIndexDefault',
  components: {VideoIndexMobile},
  data() {
    return {
      options: {
        list: []
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    this.init()
    this.setShare()
  },
  methods: {
    init() {
    },
    setShare(){
      this.getWxConfig('', '')
    }
  }
}
</script>

<style scoped>
.video-index-default{
}
</style>
