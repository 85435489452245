<template>
  <div class="topic-list-pc">
    <template v-for="(res, index) in data.list">
      <topic-item-pc
        :key="index"
        :info="res"
        :keyword="data.key"
      />
    </template>
    <page-common-pc
      :change-page="changePage"
      :data="data"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopicItemPc from "./item";
import PageCommonPc from "../../common/PageCommon";

export default {
  name: 'TopicListPc',
  components: {PageCommonPc, TopicItemPc},
  props: {
    data: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.topic-list-pc {

}
</style>
