<template>
  <div
    ref="tabsRef"
    class="activity-tabs-list-mobile"
  >
    <template v-for="(res, index) in options.childType">
      <activity-tabs-list-item-mobile
        :key="index"
        :info="res"
        :on="options.childTypeId === res.id"
        @click="e => tabsOnclick(e, res)"
      />
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import ActivityTabsListItemMobile from "./item";
import $ from "jquery";

export default {
  name: 'ActivityTabsListMobile',
  components: {ActivityTabsListItemMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeTypeId: {
      type: Function,
      default: () => () => {}
    },
  },
  computed: {
    ...mapState(['browser', 'lang', 'windowWidth']),
  },
  methods: {
    tabsOnclick(e, res){
      this.tabsClickScroll('tabsRef', e, res)
    },
    tabsClickScroll(refDom, e, a) {
      this.changeTypeId(a.id)
      this.$nextTick(() => {
        this.setTabView()
      })
    },
    setTabView(){
      this.$nextTick(() => {
        let father = $('.activity-tabs-list-mobile');
        let scrollTo = $('.activity-tabs-list-item-mobile.on');
        if(scrollTo.offset().left && father.offset().left){
          father.animate({
            scrollLeft: (scrollTo.offset().left - father.offset().left + father.scrollLeft()) - (this.windowWidth / 2) + $(scrollTo).width()
          }, 0);
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.activity-tabs-list-mobile {
  white-space: nowrap;
  overflow-x: scroll;
}
</style>
