<template>
  <div class="live-tabs-mobile">
    <template v-for="(res, index) in list">
      <live-tabs-item-mobile
        :key="index"
        :change-type-id="changeTypeId"
        :info="res"
        :on="res.id === childTypeId"
      />
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import LiveTabsItemMobile from "./item";

export default {
  name: 'LiveTabsMobille',
  components: {LiveTabsItemMobile},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    childTypeId: {
      type: [Number, String],
      default: () => 0
    },
    liveid: {
      type: [Number, String],
      default: () => 0
    },
    liverichang: {
      type: Boolean,
      default: () => false
    },
    changeLiveId: {
      type: Function,
      default: () => () => {}
    },
    changeTypeId: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.live-tabs-mobile{
  text-align: center;
}
</style>
