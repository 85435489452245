<template>
  <div class="awards-list-mobile">
    <top-nav-mobile
      :backurl="backurl"
      :is-back="true"
    >
      <span class="nav-title">{{ $t('10005') }}</span>
    </top-nav-mobile>
    <div class="container">
      <div class="awards-list-header">
        <div v-if="parseInt(options.typeid) === 12">
          <div class="tags">
            <template v-for="(res, index) in options.category">
              <btn-big-awards-mobile
                :key="index"
                class="btn-item"
                :label="$t(res.title)"
                :on="res.timeid === options.timeid"
                @click="changeTimeid(res.timeid)"
              />
            </template>
          </div>
        </div>
      </div>
      <div
        v-if="$t(options.category[options.timeid].content) && parseInt(options.typeid) === 12"
        ref="desc"
        class="desc"
        :style="{height: lang === `en` && !showMore ? (descHeight - 20) + `px` : `auto`, marginBottom: lang === `en` && !showMore ? `20px` : `0`}"
      >
        <text-content-mobile
          :content="$t(options.category[options.timeid].content)"
          margin-bottom="20px"
          title=""
        />
        <div
          v-if="lang === `en` && showMore === false"
          class="more"
          @click="showMore=true"
        >
          {{ $t('50017') }}>>
        </div>
      </div>
      <div
        v-if="$t(options.category[options.timeid].content2) && parseInt(options.typeid) === 12"
        ref="desc1"
        class="desc"
        :style="{height: lang === `en` && !showMore1 ? (descHeight1 - 40) + `px` : `auto`, marginBottom: lang === `en` && !showMore1 ? `40px` : `0`}"
      >
        <text-content-mobile
          :content="$t(options.category[options.timeid].content2)"
          title=""
        />
        <div
          v-if="lang === `en` && showMore1 === false"
          class="more"
          @click="showMore1=true"
        >
          {{ $t('50017') }}>>
        </div>
      </div>
      <div class="awards-list">
        <template v-for="(res, index) in options.data.list">
          <awards-list-item-mobile
            :key="index"
            :info="res"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import AwardsListItemMobile from "../../../components/Mobile/Awards/AwardsListItem";
import TextContentMobile from "../../../components/Mobile/common/TextContent";
import BtnBigAwardsMobile from "../../../components/Mobile/common/BtnBigAwards";
import BackBtnMobile from "../../../components/Mobile/common/BackBtn";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import LangBtnMobile from "../../../components/Mobile/common/LangBtn";

export default {
  name: 'AwardsListMobile',
  components: {
    LangBtnMobile,
    TopNavMobile,
    BackBtnMobile, BtnBigAwardsMobile, TextContentMobile, AwardsListItemMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changePage: {
      type: Function,
      default: () => () =>{}
    },
    changeTimeid: {
      type: Function,
      default: () => () =>{}
    }
  },
  data(){
    return {
      backurl: '',
      descHeight: 200,
      showMore: false,
      descHeight1: 200,
      showMore1: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    this.backurl = this.$route.meta.fromUrl === '/' ? '/awards' : this.$route.meta.fromUrl
  },
  mounted() {
    this.backurl = this.$route.meta.fromUrl === '/' ? '/awards' : this.$route.meta.fromUrl
    this.$nextTick(() => {
      this.descHeight = this.$refs.desc.offsetHeight
      this.descHeight1 = this.$refs.desc1.offsetHeight
    })
  }
}
</script>

<style scoped lang="scss">
.awards-list-mobile {
  .tags{
    display: flex;
  }
  .awards-list-header{
    margin-bottom: 20px;
  }
  .desc{
    overflow: hidden;
    position: relative;
    .more{
      position: absolute;
      font-size: 22px;
      font-weight: 500;
      color: rgba(234, 76, 30, 0.7);
      right: 0;
      background: #F8F8FA;
      bottom: 0;
      line-height: 36px;
      &:before{
        content: ' ';
        width: 40px;
        height: 32px;
        background: linear-gradient(to right, rgba(250,250,250,0), rgba(250,250,250,0.6), #fafafa);
        position: absolute;
        left: -40px;
        top:0;
      }
    }
  }
}
</style>
