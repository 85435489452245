<template>
  <div class="case-index-box-pc">
    <div class="main-box types">
      <common-class-pc
        :change-type-id="changeTypeId"
        :type-id="data.childTypeId"
        :type-list="data.childType"
        :type-name="type===1 ? `专业分类` : `应用领域`"
      />
    </div>
    <split-line-pc
      bg-color="rgba(255, 255, 255, 0.1)"
      height="1px"
      margin-bottom="0"
      margin-top="0"
    />
    <div class="main-box min-height">
      <empty-pc
        v-if="!loading && parseInt(data.data.total) === 0"
        description="空空如也"
      />
      <case-list-pc
        :change-page="changePage"
        :data="data.data"
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import SplitLinePc from "@/components/Pc/common/SplitLine";
import CaseListPc from "@/components/Pc/Case/caseList";
import EmptyPc from "@/components/Pc/common/Empty";
import TabsCaseItemPc from "../../common/TabsCaseItem";
import CommonClassPc from "../../common/CommonClass";

export default {
  name: 'CaseIndexBoxPc',
  components: {CommonClassPc, EmptyPc, CaseListPc, SplitLinePc},
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    changeTypeId: {
      type: Function,
      default: () => () => {}
    },
    loading: {
      type: Boolean,
      default: () => true
    },
    type: {
      type: Number,
      default: () => 1
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.case-index-box-pc {
  margin-bottom: 20px;
  .types{
    margin-bottom: 0;
  }
  .min-height{
    min-height: 876px!important;
    position: relative;
  }
}
</style>
