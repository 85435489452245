<template>
  <div class="exhibition-index-pc">
    <div
      v-if="false"
      class="bg-video"
    >
      <video
        autoplay=""
        loop=""
        muted=""
        src="../../../assets/mp4/bgvideo.mp4"
        style="object-fit: fill;"
      ></video>
    </div>
    <div class="container">
      <div class="w">
        <div class="zl_pic">
          <template
            v-for="(res, index) in list"
          >
            <a
              v-show="index === current || index === currentLast"
              :key="index"
              class="db rel"
              :class="index===currentClass ? `on` : ``"
              href="javascript:void(0)"
              style="display: none"
              @click="navigateTo('/Exhibition/list?typeid='+res.id)"
            >
              <div
                class="picl"
              >
                <img
                  alt=""
                  class="db"
                  :src="res.largeimg1"
                >
              </div>
              <div
                class="picr"
              >
                <img
                  alt=""
                  class="db"
                  :src="res.largeimg4"
                >
              </div>
              <div
                class="picc"
              >
                <img
                  alt=""
                  class="db"
                  :src="res.largeimg2"
                >
              </div>
              <div
                class="picp"
                :style="{transform: index === currentClass ? `scale(1)` : `scale(0)`, opacity: index === currentClass ? 1 : 0}"
              >
                <img
                  alt=""
                  class="db"
                  :src="res.largeimg3"
                >
              </div>
            </a>
          </template>
        </div>

        <div class="zl_pic1 tc">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                v-for="(res, index) in list"
                :key="index"
                class="swiper-slide"
              >
                <div
                  class="item trans"
                  :class="index === current ? `on` : ``"
                  @click="showSwiper(index)"
                >
                  <img
                    alt=""
                    class="db"
                    :src="res.smallimg"
                  >
                  <div class="title ">
                    <span :class="index === current ? `` : `line-2`">
                      {{ $t2(lang, res.typename, res.typename_en) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'ExhibitionIndexPc',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return {
      current: 0,
      currentClass: 0,
      currentLast: 0,
      timer: null,
      timer1: null,
      timera: null,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  beforeDestroy() {
    this.timer && clearTimeout(this.timer)
    this.timer1 && clearTimeout(this.timer1)
    this.timera && clearTimeout(this.timera)
  },
  mounted() {
    this.startSwiper()
  },
  methods:{
    showSwiper(index)  {
      clearTimeout(this.timer)
      clearTimeout(this.timer1)
      clearTimeout(this.timera)
      this.currentLast = JSON.parse(JSON.stringify(this.current))
      this.timer = setTimeout(() => {
        this.currentLast = index
      }, 500)
      this.current = index
      this.timer1 = setTimeout(() => {
        this.currentClass = index
      }, 200)
      this.startSwiper()
    },
    startSwiper(){
      this.timera = setTimeout(() => {
        let curI = this.current + 1
        if(curI >= this.list.length){
          curI=0;
        }
        this.showSwiper(curI)
      },5000)
    }
  }
}
</script>

<style scoped lang="scss">
@media screen and (max-height: 850px) {
  .exhibition-index-pc {
    padding-top: 0 !important;
  }
}
@media (max-width: 1900px) {
  .exhibition-index-pc .w {
    -webkit-transform: scale(0.75)!important;
    transform: scale(0.75)!important;;
  }
}

.exhibition-index-pc{
  padding: 80px 0 36px;
  .bg-video{
    top: 60px;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    z-index: -1;
    video{
      height: 100%;
      width: 100%;
    }
  }
  .w {
    position: relative;
    z-index: 3;
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    -webkit-transform-origin: center top;
    transform-origin: center top;
    .zl_pic {
      width: 1057px;
      height: 563px;
      position: relative;
      margin: 0 auto;
      a {
        width: 1057px;
        height: 563px;
        position: absolute;
        top: 0;
        left: 0;
        .picl {
          position: absolute;
          -webkit-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
          top: 0;
          left: -100px;
          width: 347px;
          height: 563px;
          z-index: 3;
          opacity: 0;
          img {
            height: 563px;
          }
        }
        .picr {
          position: absolute;
          -webkit-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
          top: 0;
          right: -100px;
          opacity: 0;
          width: 451px;
          box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.1);
          height: 563px;
          img {
            height: 563px;
          }
        }
        .picc {
          position: absolute;
          -webkit-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
          top: 0;
          left: 198px;
          width: 408px;
          height: 563px;
          box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.1);
          opacity: 0;
          img {
            height: 563px;
          }
        }
        .picp {
          position: absolute;
          -webkit-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
          bottom: -60px;
          left: 0px;
          -webkit-transform: scale(0);
          transform: scale(0);
          opacity: 0;
          -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
          z-index: 2;
        }
        &:nth-child(1) {
          display: block;
        }
        &.on .picl {
          left: 0;
          opacity: 1;
        }
        &.on .picr {
          right: 0;
          opacity: 1;
        }
        &.on .picc {
          opacity: 1;
          -webkit-transition-delay: 0.6s;
          transition-delay: 0.6s;
        }
      }
    }
    .zl_pic1 {
      width: 1100px;
      height: 186px;
      margin: 105px auto 30px auto;
      padding: 0 5px;
      .swiper-container{
        height: 300px;
      }
      .swiper-slide {
        width: 11.11%;
        float: left;
        &.swiper-slide-active .item {
          -webkit-transform: scale(1);
          transform: scale(1);
        }
        .item {
          width: 124px;
          -webkit-transform: scale(0.7);
          transform: scale(0.7);
          cursor: pointer;
          background: #fff;
          .title{
            text-align: center!important;
            font-size: 16px;
            color: #333333;
            line-height: 20px;
            padding: 15px 10px 15px;
            width: 100%;
            height: 54px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &.on {
            -webkit-transform: scale(1);
            transform: scale(1);
            height: auto;
            .title{
              height: auto;
            }
           }
          img {
            width: 124px;
            height: 124px;
          }
        }
      }
    }
  }
}
</style>
