<template>
  <div class="activity-index-pc">
    <div class="container">
      <div class="main-box types">
        <common-class-pc
          :change-type-id="changeTypeId"
          :type-id="options.childTypeId"
          :type-list="options.childType"
          type-name="活动版块"
        />
      </div>
      <split-line-pc
        bg-color="rgba(255, 255, 255, 0.1)"
        height="1px"
        margin-bottom="0"
        margin-top="0"
      />
      <div class="main-box">
        <empty-pc
          v-if="!options.loading && parseInt(options.data.total) === 0"
          description="空空如也"
        />
        <activity-list-pc
          :change-page="changePage"
          :data="options.data"
          :show-reserve-modal="showReserveModal"
        />
      </div>
    </div>
    <activity-reserve-modal-pc
      v-if="reserveShow"
      :hide-reserve-modal="hideReserveModal"
      :info="reserveInfo"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import SplitLinePc from "@/components/Pc/common/SplitLine";
import ActivityListPc from "@/components/Pc/Activity/ActivityList";
import ActivityReserveModalPc from "@/components/Pc/Activity/ActivityReserveModal";
import EmptyPc from "@/components/Pc/common/Empty";
import CommonClassPc from "../../../components/Pc/common/CommonClass";

export default {
  name: 'ActivityIndexPc',
  components: {CommonClassPc, EmptyPc, ActivityReserveModalPc, ActivityListPc, SplitLinePc},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeTypeId: {
      type: Function,
      default: () => () => {}
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
  },
  data(){
    return {
      reserveShow: false,
      reserveInfo: {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods:{
    showReserveModal(info){
      this.reserveInfo = info
      this.reserveShow = true
    },
    hideReserveModal(){
      this.reserveShow = false
    }
  }
}
</script>

<style scoped lang="scss">
.activity-index-pc {
  .types{
    margin-bottom: 0;
  }
}
</style>
