<template>
  <a
    class="exhibition-vr-btn-fixed"
    :href="link"
    target="_blank"
  >
  </a>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'ExhibitionVrBtnFixed',
  props: {
    link: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.exhibition-vr-btn-fixed{
  width: 80px;
  height: 72px;
  display: block;
  background: url("../../../../assets/images/vr_jiao.png") no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: absolute;
  right: 0;
  top:0
}
</style>
