<template>
  <div
    class="icon-text-location-mobile"
    :class="from"
  >
    <div class="icon"></div>
    <div
      class="label"
      :style="{color: color, textShadow: textShadow}"
    >
      {{ label }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextLocationMobile',
  props: {
    label: {
      type: String,
      default: () => ''
    },
    color: {
      type: String,
      default: () => "#FF835F"
    },
    from: {
      type: String,
      default: () => ''
    },
    textShadow: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.icon-text-location-mobile{
  line-height: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  white-space: normal;
  overflow: auto;
  &.collect{
    .icon{
      background: url("../../../../assets/images/location_white.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .icon{
    width: 22px;
    height: 22px;
    background: url("../../../../assets/images/icon_position.png") no-repeat;
    background-size: 100% 100%;
    margin-top: 2px;

  }
  .label{
    flex: 1;
    color: #F39478;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    margin-left: 8px;
    margin-right: 13px;
  }
}
</style>
