<template>
  <div
    v-if="zhuangtaiStr"
    class="liveing-mobile"
    :class="zhuangtaiCss"
    :style="{height: height, lineHeight: height, marginTop: marginTop}"
  >
    {{ $t(zhuangtaiStr) }}
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'LiveingMobile',
  props: {
    zhuangtai: {
      type: [Number, String],
      default: () => 0
    },
    height: {
      type: String,
      default: () => '36px'
    },
    marginTop: {
      type: String,
      default: () => '2px'
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    zhuangtaiStr(){
      switch(parseInt(this.zhuangtai)){
        case 1:
          return '21013'
        case 2:
          return '21012'
        case 3:
          return '21016'
        case 4:
          return '21014'
      }
      return ''
    },
    zhuangtaiCss(){
      switch(parseInt(this.zhuangtai)){
        case 1:
          return 'off'
        case 2:
          return '直播中'
        case 3:
          return 'off'
        case 4:
          return '回放中'
      }
      return ''
    }
  },
}
</script>

<style scoped lang="scss">
.liveing-mobile {
  height: 36px;
  background: linear-gradient(223deg, #FF1A2C, #FF9B61);
  font-size: 20px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 36px;
  text-align: center;
  padding: 0 12px;
  border-radius: 4px;
  margin-right: 8px;
  position: relative;
  top: -4px;
  &.off{
    background: rgba(255, 132, 97, .6);
    opacity: 0.4;
  }
}
</style>
