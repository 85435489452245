<template>
  <div class="user-supply-my-list-item-mobile">
    <div class="title-line clearfix">
      <div
        class="title line-1"
        @click="show(info)"
      >
        {{ info.title || info.title_en }}
      </div>
    </div>
    <div class="summary line-2">
      <div v-html="info.content||info.content_en"></div>
    </div>
    <div class="tags">
      <div class="tags-list">
        <template v-for="(res, index) in info.catename">
          <tags-item-mobile
            :key="index"
            class="item"
            :dark="true"
            :info="res"
          />
        </template>
      </div>
      <div class="btns">
        <div
          v-if="parseInt(info.is_status) !== 3 && parseInt(info.is_status) !== 4"
          class="edit-btn"
          @click="navigateTo('/user/post_supply?id='+info.id)"
        >
        </div>
        <div
          class="del-btn"
          @click="del(info.id)"
        >
        </div>
      </div>
    </div>
    <div class="status-view">
      <div
        v-if="parseInt(info.is_status) === 3"
        class="info"
      >
        <span
          :class="`status_`+info.is_status"
          @click="navigateTo('/user/supply_product?id='+info.id)"
        >{{ status }}<span class="spanred">{{ info.productCount }}</span>个</span>
        <img
          height="32"
          src="../../../../assets/images/supply_more_btn.png"
          width="32"
          @click="navigateTo('/user/supply_product?id='+info.id)"
        />
      </div>
      <div
        v-else
        class="info"
      >
        <span></span>
        <span
          :class="`status_`+info.is_status"
        >
          {{ status }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TagsItemMobile from "../../common/TagsItem";

export default {
  name: 'UserSupplyMyListItemPc',
  components: {TagsItemMobile},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    refresh: {
      type: Function,
      default: () => () =>  {}
    },
    showDemandModalInfo: {
      type: Function,
      default: () => () =>  {}
    },
    showDemandContactModalInfo:{
      type: Function,
      default: () => () => {}
    },
    hideDemandContactModal:{
      type: Function,
      default: () => () => {}
    },
    hideDemandModal:{
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    status(){
      switch(parseInt(this.info.is_status)){
        case 0:
          return '已通过'
        case 1:
          return '待审核'
        case 2:
          return '审核不通过'
        case 3:
          return '已匹配的供应产品'
        case 4:
          return '已下架'
      }
      return 0
    }
  },
  methods: {
    show(info){
      this.$u.api.users.getSupplyOne({id: info.id}).then(res => {
        if(res.status === 1){
          this.showDemandModalInfo(res.data)
        }
      })
    },
    del(id){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'mobile-message'
      }).then(() => {
        const params = {
          id: id
        }
        this.$u.api.users.actSupplyDelete(params).then(res => {
            if(res.status === 1){
              this.$message.success('删除成功')
              this.refresh()
            }
        })
      }).catch(() => {

      })
    }
  }
}
</script>

<style scoped lang="scss">
.user-supply-my-list-item-mobile {
  margin-top: 20px;
  padding: 18px 24px;
  background: rgba(255,255,255,0.1);
  border-radius: 12px;
  .title-line{
    .title{
      height: 44px;
      font-size: 32px;
      font-weight: 600;
      color: rgba(255,255,255, 0.8);
      line-height: 44px;
    }
  }
  .summary{
    height: 64px;
    font-size: 22px;
    font-weight: 400;
    color: rgba(255,255,255, 0.6);
    line-height: 32px;
    margin-top: 16px;
  }
  .tags{
    margin-top: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tags-list{
      width: 320px;
      white-space: normal;
      overflow: hidden;
      height: 32px;
      .item{
        margin-right: 10px;
      }
    }
    .btns{
      width: 240px;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &:after{
        content: ' ';
        width: 0;
        height: 0;
        clear: both;
      }
      .edit-btn{
        width: 32px;
        height: 32px;
        display: inline-block;
        margin-right: 28px;
        background: url("../../../../assets/images/edit.png");
        background-repeat: no-repeat;
        background-size: 100%;
      }
      .del-btn{
        width: 32px;
        height: 32px;
        display: inline-block;
        background: url("../../../../assets/images/del.png");
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }
  }
  .status-view{
    height: 55px;
    line-height: 55px;
    border-top: 1px solid rgba(255,255,255,0.1);
    margin-top: 25px;
    padding-top: 10px;
    .info{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .status{
      margin-right: 28px;
      color: #666;
      cursor: pointer;
    }
    span{
      font-size: 24px;
      padding: 0 12px;
      height: 44px;
      line-height: 44px;
      display: inline-block;
      text-align: center;
    }
    .status_2, .status_4{
      background: rgba(229, 1, 19, 0.1000);
      color: #E50113;
    }
    .status_1{
      background: rgba(0, 212, 0, 0.1000);
      color: rgba(0, 207, 31, 1);
    }
    .status_3{
      color: rgba(255,255,255,0.8);
    }
    .spanred{
      color:#EA4C1E;
    }
  }
}
</style>
