<template>
  <div class="exhibition-info-pc">
    <exhibition-info-pc-default
      v-if="options.info.template_id === '0'"
      :jiaohuanmingpian="jiaohuanmingpian"
      :options="options"
      :set-collect="setCollect"
      :set-zan="setZan"
    />
    <exhibition-info-pc-theme1
      v-else-if="options.info.template_id === '1'"
      :jiaohuanmingpian="jiaohuanmingpian"
      :options="options"
      :set-collect="setCollect"
      :set-zan="setZan"
    />
    <exhibition-info-pc-theme2
      v-else-if="options.info.template_id === '2'"
      :jiaohuanmingpian="jiaohuanmingpian"
      :options="options"
      :set-collect="setCollect"
      :set-zan="setZan"
    />
    <exhibition-info-pc-theme3
      v-else
      :jiaohuanmingpian="jiaohuanmingpian"
      :options="options"
      :set-collect="setCollect"
      :set-zan="setZan"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import ExhibitionInfoPcDefault from "./components/infoDefault";
import ExhibitionInfoPcTheme1 from "./components/infoTheme1";
import ExhibitionInfoPcTheme2 from "./components/infoTheme2";
import ExhibitionInfoPcTheme3 from "./components/infoTheme3";
export default {
  name: 'ExhibitionInfoPc',
  components: {ExhibitionInfoPcTheme3, ExhibitionInfoPcTheme2, ExhibitionInfoPcTheme1, ExhibitionInfoPcDefault},
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    setZan: {
      type: Function,
      default: () => () => {}
    },
    jiaohuanmingpian: {
      type:Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    showPlayer(videoUrl) {
      this.$bus.$emit('showPlayer', videoUrl)
    },
  }

}
</script>

<style scoped lang="scss">
.exhibition-info-pc{

}
</style>
