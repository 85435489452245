<template>
  <div
    class="news-item-pc"
    @click="navigateTo('/news/info?id='+info.id)"
  >
    <div class="pic">
      <pic-pc :src="info.pic" />
    </div>
    <div class="info">
      <div class="title">
        <div v-html="title"></div>
        <div
            v-if="from !== 'schedule'"
            class="right"
        >
          <icon-text-collect
              :id="info.id"
              class="item"
              :label="this.$t('50024')"
              :set-collect="setCollect"
              :shoucang="parseInt(info.is_cang) === 1"
              :type="6"
          />
        </div>
      </div>
      <div class="summary line-3">
        <div v-html="description"></div>
      </div>
      <div class="desc">
        <icon-text-from :label="$t2(lang, info.cn_source, info.en_source)" />
        <icon-text-time :label="timeFormat(info.addtime, 'yyyy/mm/dd')" />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicPc from "@/components/Pc/common/Pic";
import IconTextFrom from "@/components/Pc/common/IconText/from";
import IconTextTime from "@/components/Pc/common/IconText/time";
import IconTextCollect from "../../common/IconText/collect";

export default {
  name: 'NewsItemPc',
  components: {IconTextTime, IconTextFrom,  PicPc, IconTextCollect},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    keyword: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    title(){
      return this.replaceSearch(this.lang, this.keyword, this.info.title, this.info.en_title)
    },
    description(){
      return this.replaceSearch(this.lang, this.keyword, this.info.description, this.info.description_en)
    },
  },
  methods: {
    setCollect(){
      this.info.is_cang = 0
    }
  }
}
</script>

<style scoped lang="scss">
.news-item-pc {
  width: 100%;
  cursor: pointer;
  height: 180px;
  background: rgba(255,255,255,0.1);
  margin-bottom: 16px;
  transition: all 0.4s ease-in-out;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  &:hover{
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-3px);
    .pic{
      div{
        transition: all 0.4s ease-in-out;
        transform: scale(1.1);
      }
    }
  }
  .pic{
    width: 270px;
    height: 180px;
    overflow: hidden;
    float: left;
  }
  .info{
    margin-left: 290px;
    padding: 20px;
    height: 180px;
    position: relative;
    .title{
      font-size: 20px;
      font-weight: 600;
      color: #fff;
      line-height: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div:nth-child(1){
        width: calc(100%)
      }
    }
    .summary{
      margin-top: 8px;
      font-size: 14px;
      font-weight: 500;
      color: #999999;
      line-height: 20px;
    }
    .desc{
      position: absolute;
      left: 20px;
      bottom: 20px;
    }
  }
}
</style>
