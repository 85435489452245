<template>
  <div class="exhibition-info-pc-default">
    <div class="container">
      <div class="main-box relative min-height-770">
        <back-btn-pc backurl="/Exhibition" />
        <div class="info-box clearfix">
          <div class="pic">
            <pic-pc :src="options.info.logoimageurl" />
            <exhibition-vr-btn-fixed
              v-if="options.info.vr"
              :link="options.info.vr"
            />
          </div>
          <div class="desc">
            <div class="title-box">
              <div class="bar"></div>
              <div class="title">
                <div class="title-cn">
                  {{ $t2(lang, options.info.companyname, options.info.companynameen) }}
                </div>
                <div class="title-en">
                  {{ $t2(lang,options.info.companynameen, options.info.companyname) }}
                </div>
              </div>
              <div class="type">
                <div class="type-name">
                  {{ $t2(lang, options.info.projectteamname, options.info.projectteamname_en) }}
                </div>
                <div class="child-type-name">
                  {{ $t2(lang, options.info.applicationsarea, options.info.applicationsarea_en) }}
                </div>
              </div>
              <div class="btn-view">
                <icon-text-collect
                  :id="options.info.id"
                  class="btn-item"
                  color="rgba(255,255,255,0.8)"
                  :label="$t('50024')"
                  :set-collect="setCollect"
                  :shoucang="parseInt(options.info.is_cang) === 1"
                  :type="1"
                />
                <icon-text-zan
                  :id="options.info.id"
                  class="btn-item"
                  color="rgba(255,255,255,0.8)"
                  :label="options.info.zan"
                  :set-zan="setZan"
                  :type="1"
                  :zan="parseInt(options.info.is_zan) === 1"
                />
                <icon-text-share
                  :id="options.info.id"
                  class="btn-item"
                  color="rgba(255,255,255,0.8)"
                  :icon="options.info.logoimageurl"
                  :label="$t('50016')"
                />
                <btn
                  v-if="options.info.im"
                  :label="$t('20107')"
                  :link="options.info.im"
                />
              </div>
              <div class="contact">
                <div class="lianxipic"></div>
                <div class="jiaohuan">
                  <div
                    class="jiaohuan-btn"
                    @click="jiaohuanmingpian"
                  >
                    {{ !options.info.is_card ? $t('20102') : $t('20115') }}
                  </div>
                </div>
                <div class="lianxi-info">
                  <icon-text-line
                      v-if="false"
                    class="lianxi-item"
                    color="#fff"
                    :hover="false"
                    icon="icon_location.png"
                    :text="options.info.boothno"
                  />
                  <a
                    :href="`http://api.map.baidu.com/geocoder?address=`+options.info.address+`&output=html&src=webapp.baidu.openAPIdemo`"
                    target="_blank"
                  >
                    <icon-text-line
                      class="lianxi-item"
                      color="#fff"
                      icon="icon_map.png"
                      :text="$t2(lang, options.info.address, options.info.address_en)"
                    />
                  </a>
                  <icon-text-line
                    class="lianxi-item"
                    color="#fff"
                    :hover="false"
                    icon="icon_tel.png"
                    :text="options.info.phone"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <text-content-pc
          :content="$t2(lang, options.info.content, options.info.content_en)"
          :title="$t('20105')"
          title-size="24px"
        />
        <list-box-pc
          v-if="options.info.productList && options.info.productList.length > 0"
          :field="{img: 'imageurl', title: 'productname', title_en: 'productnameen'}"
          :item-click="(info) => navigateTo('/exhibition/productinfo?companyid='+options.id+'&id='+info.id )"
          :list="options.info.productList"
          :title="$t('20106')"
          type="product"
        />
        <template v-if="lang !== 'en'">
          <list-box-pc
            v-if="options.info.activitylist && options.info.activitylist.length > 0"
            :field="{img: 'pic', title: 'title', title_en: 'en_title', catename: 'catename'}"
            :item-click="(info) => navigateTo('/activity/info?id='+info.id)"
            :list="options.info.activitylist"
            :title="$t('50022')"
            type="activity"
          />
          <list-box2-pc
            v-if="options.info.informationList && options.info.informationList.length > 0"
            :field="{img: 'pic', title: 'title', title_en: 'en_title', description: 'description', description_en: 'en_description', time: 'addtime'}"
            :item-click="(info) => navigateTo('/news/info?id='+info.id)"
            :list="options.info.informationList"
            :title="$t('50028')"
            type="news"
          />
          <list-box-pc
            v-if="options.info.caselist && options.info.caselist.length > 0"
            :field="{img: 'pic', title: 'title', title_en: 'en_title', catename: 'catename'}"
            :item-click="(info) => navigateTo('/case/info?id='+info.id)"
            :list="options.info.caselist"
            :title="$t('27012')"
            type="case"
          />
        </template>
        <div
          v-if="options.info.liveList.length > 0"
          class="list-box-pc"
        >
          <div class="title title-before-line">
            直播列表
          </div>
          <live-history-list-pc
            :change-live-id="changeLiveId"
            :options="options.info"
          />
        </div>
        <video-list-pc
          v-if="options.info.videoList && options.info.videoList.length > 0"
          :data="{list: options.info.videoList}"
          :show-player="showPlayer"
          :title="$t('50018')"
          type="videos"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import BackBtnPc from "@/components/Pc/common/BackBtn";
import IconTextCollect from "@/components/Pc/common/IconText/collect";
import IconTextZan from "@/components/Pc/common/IconText/zan";
import IconTextShare from "@/components/Pc/common/IconText/share";
import Btn from "@/components/Pc/common/Btn";
import IconTextLine from "@/components/Pc/common/IconTextLine";
import TextContentPc from "@/components/Pc/common/TextContent";
import ListBoxPc from "@/components/Pc/common/ListBox";
import ListBox2Pc from "@/components/Pc/common/ListBox2";
import VideoListPc from "@/components/Pc/common/VideoList";
import ExhibitionVrBtnFixed from "@/components/Pc/Exhibition/ExhibitionVrBtnFixed";
import PicPc from "@/components/Pc/common/Pic";

import LiveHistoryListPc from "@/components/Pc/Live/LiveHistoryList";
export default {
  name: 'ExhibitionInfoPcDefault',
  components: {
    PicPc,
    ExhibitionVrBtnFixed,
    VideoListPc,
    ListBox2Pc,
    LiveHistoryListPc,
    ListBoxPc, TextContentPc, IconTextLine, Btn, IconTextShare, IconTextZan, IconTextCollect, BackBtnPc},
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    setZan: {
      type: Function,
      default: () => () => {}
    },
    jiaohuanmingpian: {
      type:Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    showPlayer(videoUrl) {
      this.$bus.$emit('showPlayer', videoUrl)
    },
    changeLiveId(id){
      window.location.href = '/Live?typeCurrent=1&liveid=' + id
    }
  }

}
</script>

<style scoped lang="scss">
.exhibition-info-pc-default{
  .relative{
    position: relative;
  }
  .info-box{
    margin-top: 20px;
    margin-bottom: 48px;
    .pic{
      position: relative;
      width: 280px;
      height: 280px;
      overflow: hidden;
      background: #F6F6F6;
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1), 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
      float: left;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .desc{
      margin-left: 336px;
      .title-box{
        .bar{
          height: 56px;
          width: 6px;
          background: linear-gradient(223deg, #FC501E, #FA8958);
          border-radius: 3px;
          float: left;
        }
        .title{
          margin-left: 22px;
          .title-cn{
            font-size: 20px;
            font-weight: 600;
            color: rgba(255,255,255,1);
            line-height: 24px;
          }
          .title-en{
            font-size: 16px;
            font-weight: 600;
            color: rgba(255,255,255,1);
            line-height: 20px;
            margin-top: 8px;
          }
        }
        .type{
          margin-left: 22px;
          margin-top: 16px;
          .type-name{
            font-size: 12px;
            font-weight: 500;
            color: rgba(255,255,255,0.6);
            line-height: 16px;
          }
          .child-type-name{
            margin-top: 2px;
            font-size: 12px;
            font-weight: 500;
            color: rgba(255,255,255,0.8);
            line-height: 16px;
          }
        }
        .btn-view{
          margin-top: 26px;
          margin-left: 22px;
          .btn-item{
            margin-right: 24px;
          }
        }
        .contact{
          background: rgba(255,255,255,0.1);
          padding: 12px 22px;
          margin-top: 18px;
          height: 102px;
          .lianxipic{
            top: 16px;
            position: relative;
            width: 42px;
            height: 48px;
            background: url("../../../../assets/images/lxfs.png");
            background-size: 100% 100%;
            float: left;
          }
          .lianxi-info{
            margin-left: 86px;
            width: calc( 100% - 250px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            .lianxi-item{
              margin-top: 5px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            margin-right: 150px;
          }
        }
        .jiaohuan{
          float: right;
          margin-top: 25px;
          .jiaohuan-btn{
            padding: 0 26px;
            display: inline-block;
            border-radius: 36px;
            border: 1px solid rgba(234, 76, 30, 0.2);
            color: #FF1A2C;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            &:hover{
              color: #ea4c1e;
              background: linear-gradient(45deg, rgba(255,155,97,0.1), rgba(255,26,44,0.1));
              border-radius: 36px;
              border: 1px solid rgba(234,76,30,0.6);
            }
          }
        }
      }
    }
  }

  .list-box-pc {
    margin-bottom: 48px;

    .title {
      font-size: 24px;
      font-weight: 600;
      color: #fff;
      line-height: 32px;
      margin-bottom: 12px;
    }
  }
}
</style>
