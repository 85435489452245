<template>
  <div
    class="logout-btn-mobile"
    @click="logout"
  >
    <img
      src="../../../../assets/images/mobile/logout_hei.png"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import loadLanguageAsync from "@/common/i18n/i18n";
import locale from 'element-ui/lib/locale'
import en from 'element-ui/lib/locale/lang/en'
import zh from 'element-ui/lib/locale/lang/zh-CN'

export default {
  name: 'LogoutBtnMobile',
  props: {

  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods:{
    changeLang() {
      locale.use(this.lang === 'en' ? 'zh' : 'en')
      console.log(this.lang)
      loadLanguageAsync(this.lang === 'en' ? 'zh' : 'en')
    },
    logout(){
      this.$confirm('是否要退出当前账号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'mobile-message'
      }).then(() => {
        this.$store.commit('setUser', {})
        this.navigateTo('/exhibition')
      })
    }
  }
}
</script>

<style scoped lang="scss">
.logout-btn-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 48px;
    height: 48px;
  }
}
</style>
