<template>
  <div class="bbs-index-default">
    <bbs-index-pc
      v-if="browser === 1"
      :change-child-type-id="changeChildTypeId"
      :change-date="changeDate"
      :change-key="changeKey"
      :change-page="changePage"
      :changeYear="changeYear"
      :year="options.year"
      :change-type-id="changeTypeId"
      :options="options"
      :search="search"
    />
    <bbs-index-mobile
      v-if="browser === 2"
      :change-child-type-id="changeChildTypeId"
      :change-date="changeDate"
      :change-key="changeKey"
      :change-page="changePage"
      :changeYear="changeYear"
      :year="options.year"
      :change-type-id="changeTypeId"
      :options="options"
      :search="search"
    />
  </div>
</template>

<script>

import BbsIndexPc from "@/views/Pc/Bbs";
import {mapState} from "vuex";
import BbsIndexMobile from "../../Mobile/Bbs";
import axios from 'axios'
import router from "@/router";
export default {
  name: 'BbsIndexDefault',
  components: {BbsIndexMobile, BbsIndexPc},
  data(){
    return {
      options: {
        typeList: [],
        childTypeList: [],
        typeId: 0,
        childTypeId: 0,
        loaded: false,
        year: '',
        data: {
          list: [],
          key: '',
          dateid: 0,
          page: 1,
          pagesize: 10,
          total: 0
        },
        date: {}
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if(this.$route.meta.needRefresh){
      if(!this.isFirst){
        this.options = this.defaultOptions;
        if(this.$route.params.year){
          this.options.year = this.$route.params.year
        }
        this.init()
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    console.log(this.$route.params.year)
    console.log(' this.options',  this.options)
    this.init()
    this.setShare()
  },
  methods: {
    init(){
      if(this.browser === 1){
        this.options.data.pagesize = 10
      }else{
        this.options.data.pagesize = 10
      }
      this.getType()
      this.$u.api.commons.getLiveDate().then(res => {
        if(res.status === 1){
          let date = {
            0: {
              id: 0,
              zh: '全部',
              en: 'all'
            }
          }
          for( var i in res.data){
            date[res.data[i].id] = res.data[i]
          }
          this.options.date = date
        }
      })
    },
    getType(){
      const params = {
        typeId: 2
      }
      this.$u.api.commons.getChildArchivesType(params).then(res => {
        if(res.status === 1){
          res.data.unshift({
            id: 0,
            name: '全部',
            ename: 'All'
          })
          this.options.typeList = res.data
          this.options.typeId = res.data[0].id
          this.getZhuanyeType()
        }
      })
    },
    getZhuanyeType(){
      const params = {
        typeId: 88
      }
      this.$u.api.commons.getChildArchivesType(params).then(res => {
        if(res.status === 1){
          res.data.unshift({
            id: 0,
            name: '全部',
            ename: 'All'
          })
          this.options.childTypeList = res.data
          this.options.childTypeId = 0
          this.getList(true)
        }
      })
    },
    getList(gotop = false){
      console.log(' this.options1',  this.options)
      this.options.loading = true;
      const params = {
        typesid: this.options.typeId,
        typeChildId: this.options.childTypeId,
        page: this.options.data.page,
        year: this.options.year,
        pagesize: this.options.data.pagesize,
        key: this.options.data.key,
        dateid: this.options.data.dateid || ''
      }
      this.options.loading = false;
      this.$u.api.bbss.getLists(params).then(res => {
        this.options.data.list = res.data.lists
        this.options.data.total = res.data.totalNum
        gotop && this.goTop()
      })
    },
    changeTypeId (id) {
      this.options.typeId = id
      this.options.childTypeId = 0
      this.getList()
    },
    changeChildTypeId (id) {
      this.options.childTypeId = id
      this.getList()
    },
    changeYear (year) {
      this.options.year = year
      router.replace('/Forum/' + year)
      this.getList()
    },
    changePage (page) {
      this.options.data.page = page
      this.getList(true)
    },
    changeKey (val) {
      this.options.data.key = val
    },
    changeDate (val) {
      this.options.data.page = 1
      this.options.data.dateid = val
      this.getList()
    },
    search () {
      this.getList()
    },
    setShare(){
      this.getWxConfig(this.$t('10003') + this.bankuai(), '产业升级、新经济、新赛道等热议话题，共话行业发展新趋势。', 'https://virtualobs.ciif-expo.com/shareimg/1.png')
    }
  }
}
</script>

<style scoped lang="scss">
.bbs-index-default{

}
</style>
