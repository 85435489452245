<template>
  <div class="user-index-default">
    <user-index-pc v-if="browser===1" />
    <user-index-mobile v-if="browser===2" />
  </div>
</template>

<script>

import {mapState} from "vuex";
import UserIndexPc from "../../Pc/User";
import UserIndexMobile from "../../Mobile/User";

export default {
  name: 'UserIndexDefault',
  components: {UserIndexMobile, UserIndexPc},
  data() {
    return {
      options: {
        list: []
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  beforeDestroy() {

  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    this.init()
    this.setShare()
  },
  methods: {
    init() {
      localStorage.removeItem('backUrl')
      localStorage.removeItem('backQuery')
    },
    setShare(){
      this.getWxConfig('', '')
    }
  }

}
</script>

<style scoped></style>
