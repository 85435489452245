<template>
  <div
    class="custom-list-mobile"
    :style="{marginTop: marginTop}"
  >
    <div class="title">
      {{ title }}
    </div>
    <div
      class="info-list"
    >
      <topic-product-list-mobile
        v-if="type==='product'"
        :companyid="companyid"
        :list="list"
        :more="more"
      />
      <video-list-mobile
        v-if="type==='video'"
        :list="list"
      />
      <topic-activity-list-mobile
        v-if="type==='activity'"
        :custom-list="list"
        :more="more"
      />
      <topic-news-list-mobile
        v-if="type==='news'"
        :custom-list="list"
        :more="more"
        :options="options"
      />
      <topic-case-list-mobile
        v-if="type==='case'"
        :custom-list="list"
        :more="more"
      />
      <live-history-list-mobile
        v-if="type==='live'"
        :change-live-id="changeLiveId"
        :options="list"
      />
    </div>
    <div
      v-if="!more && showMore"
      class="look-more"
      @click="more=true"
    >
      {{ $t('50008') }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import VideoListMobile from "@/components/Mobile/common/VideoList";
import TopicProductListMobile from "../TopicProductList";
import TopicActivityListMobile from "../TopicActivityList";
import TopicNewsListMobile from "../TopicNewsList";
import TopicCaseListMobile from "../TopicCaseList";
import LiveHistoryListMobile from "@/components/Mobile/Live/LiveHistoryList";

export default {
  name: 'CustomListMobile',
  components: {
    TopicCaseListMobile,
    TopicNewsListMobile,
    TopicActivityListMobile,
    LiveHistoryListMobile,
    TopicProductListMobile, VideoListMobile},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    changeLiveId: {
      type: Function,
      default: () => () => {}
    },
    title: {
      type: String,
      default: () => ''
    },
    type: {
      type: String,
      default: () => ''
    },
    companyid: {
      type: [Number, String],
      default: () => ''
    },
    marginTop: {
      type: String,
      default: () => '0px'
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return {
      more: false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    showMore(){
      switch(this.type){
        case 'product':
          if(this.list.length > 4){
            return true
          }
          break;
        case 'activity':
          if(this.list.length > 2){
            return true
          }
          break;
        case 'news':
          if(this.list.length > 3){
            return true
          }
          break;
        case 'case':
          if(this.list.length > 6){
            return true
          }
          break;
      }
      return false
    }
  },
}
</script>

<style scoped lang="scss">
.custom-list-mobile {
  &>.title{
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    line-height: 40px;
  }
  .info-list{
    margin-top: 28px;
  }
  .look-more{
    margin-top: 20px;
    height: 72px;
    background: rgba(255, 255, 255, 0.1000);
    border-radius: 12px;
    text-align: center;
    line-height: 72px;
    font-size: 20px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.8000);
  }
}
</style>
