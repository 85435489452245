<template>
  <div
    class="bbs-item-pc clearfix"
    @click="navigateTo('/forum/info?id='+info.id)"
  >
    <div class="pic">
      <pic-pc :src="info.pic" />
      <div
        v-if="parseInt(info.zhuangtai) === 2 || parseInt(info.zhuangtai) === 4"
        class="play-btn"
      ></div>
    </div>
    <div class="info">
      <div class="title-line">
        <div class="left">
          <div class="title">
            <liveing-pc :zhuangtai="info.zhuangtai" />
            <div
              class="line-1"
              v-html="title"
            ></div>
          </div>
          <div class="title-en">
            <div
              class="line-1"
              v-html="title_en"
            ></div>
          </div>
        </div>
        <div
          v-if="from !== 'schedule'"
          class="right"
        >
          <icon-text-collect
            :id="info.id"
            class="item"
            :label="this.$t('50024')"
            :set-collect="setCollect"
            :shoucang="parseInt(info.is_cang) === 1"
            :type="4"
          />
        </div>
      </div>

      <div class="tags line-1">
        <template v-for="(res, index) in cateName">
          <tag-item
            :key="index"
            class="item"
            :dark="true"
            :label="$t2(lang, res.name, res.ename)"
          />
        </template>
      </div>
      <div class="other">
        <icon-time-line
          class="item"
          icon="icon_clock_hei.png"
          :text="info.tag"
        />
        <div class="time-line">
          <icon-time-line
            class="item"
            icon="icon_position.png"
            :text="$t(info.shorttitle.toString())"
          />
          <div
            v-if="from === 'schedule' && false"
            class="right"
          >
            <icon-text-del
              :id="info.id"
              class="item"
              label=""
              :set-collect="setCollect"
              :shoucang="parseInt(info.is_cang) === 1"
              :type="4"
            />
          </div>
          <bbs-join-btn-pc
            v-if="info.id > 0 && !info.isSubmitEndTime && info.id != 336"
            :id="info.id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import TagItem from "@/components/Pc/common/TagItem";
import IconTimeLine from "@/components/Pc/common/IconTimeLine";
import {mapState} from "vuex";
import PicPc from "@/components/Pc/common/Pic";
import IconTextCollect from "@/components/Pc/common/IconText/collect";
import LiveingPc from "../../common/Liveing";
import IconTextDel from "../../common/IconText/del";
import BbsJoinBtnPc from "../BbsJoinBtnPc";
export default {
  name: 'BbsItemPc',
  components: {BbsJoinBtnPc, IconTextDel, LiveingPc, IconTextCollect, PicPc, IconTimeLine, TagItem},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    keyword: {
      type: String,
      default: () => ''
    },
    from: {
      type: String,
      default: () => ''
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    title(){
      return this.replaceSearch(this.lang, this.keyword, this.info.title, this.info.title_en)
    },
    title_en(){
      return this.replaceSearch(this.lang, this.keyword, this.info.title_en, this.info.title)
    },
    cateName(){
      let cat = this.info.catename
      if(cat && cat.length > 0){
        cat.sort((a, b) => a.name.length - b.name.length)
      }
      return cat
    }
  },
  methods: {
    setCollect(v){
      this.info.is_cang = v
      this.refresh()
    }
  }
}
</script>

<style scoped lang="scss">
.bbs-item-pc{
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1000);
  margin-bottom: 20px;
  transition: all 0.4s ease-in-out;
  position: relative;
  &:hover{
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  }
  .pic{
    width: 320px;
    height: 180px;
    overflow: hidden;
    float: left;
    position: relative;
    .play-btn{
      width: 40px;
      height: 40px;
      background: url("../../../../assets/images/play-btn.png");
      background-size: 100% 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -20px;
      margin-top: -20px;
    }
  }
  .info{
    margin-left: 352px;
    padding-top: 16px;
    .title-line{
      position: relative;
      .left{
        margin-right: 50px;
        .title{
          font-size: 20px;
          font-weight: 600;
          color: #fff;
          line-height: 24px;
          height: 24px;
          vertical-align: middle;
        }
        .title-en{
          font-size: 16px;
          font-weight: 600;
          color: #fff;
          line-height: 20px;
          height: 20px;
          margin-top: 4px;
        }
      }
      .right{
        position: absolute;
        right: 20px;
        top: 0;
      }
    }
    .tags{
      margin-top: 16px;
      height: 24PX;
      .item{
        margin-right: 4px
      }
    }
    .other{
      margin-top: 14px;
      .time-line{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .right{
          margin-right: 20px;
        }
      }
      .item{
        margin-top: 2px;
      }
    }
  }
}
</style>
