<template>
  <div
    class="icon-text-line-exhibition"
    :class="className + ` template_`+templateId"
    @click="$emit('click')"
  >
    <div class="icon"></div>
    <div
      class="text"
      :style="{color: color}"
      v-html="(text || '')"
    >
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextLineExhibition',
  props: {
    icon: {
      type: String,
      default: () => ''
    },
    text: {
      type: String,
      default: () => ''
    },
    className: {
      type: String,
      default: () => ''
    },
    color: {
      type: String,
      default: () => '#666666'
    },
    hover: {
      type: Boolean,
      default: () => true
    },
    templateId: {
      type: [Number, String],
      default: () => 1
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }
}
</script>

<style scoped lang="scss">
.icon-text-line-exhibition{
  &.position{
    .icon{
      width: 20px;
      height: 20px;
      margin-right: 6px;
      display: inline-block;
      cursor: pointer;
      float: left;
      background: url("../../../../assets/images/exhibition_theme_1/icon_location@2x.png");
      background-size: 100% 100%;
    }
    &.template_2 {
      .icon {
        background: url("../../../../assets/images/exhibition_theme_2/icon_location@2x.png");
        background-size: 100% 100%;
      }
    }
  }
  &.address{
    .icon{
      width: 20px;
      height: 20px;
      margin-right: 6px;
      display: inline-block;
      cursor: pointer;
      float: left;
      background: url("../../../../assets/images/exhibition_theme_1/icon_address@2x.png");
      background-size: 100% 100%;
    }
    &.template_2 {
      .icon {
        background: url("../../../../assets/images/exhibition_theme_2/icon_address@2x.png");
        background-size: 100% 100%;
      }
    }
  }
  &.tel{
    .icon{
      width: 20px;
      height: 20px;
      margin-right: 6px;
      display: inline-block;
      cursor: pointer;
      float: left;
      background: url("../../../../assets/images/exhibition_theme_1/icon_tel@2x.png");
      background-size: 100% 100%;
    }
    &.template_2 {
      .icon {
        background: url("../../../../assets/images/exhibition_theme_2/icon_tel@2x.png");
        background-size: 100% 100%;
      }
    }
  }
  .text{
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
    cursor: pointer;
    margin-left: 18px;
    white-space: nowrap;
  }
}
</style>
