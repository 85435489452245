<template>
  <div
    class="product-item-mobile"
    @click="navigateTo('/exhibition/productinfo?companyid='+(info.cid || companyid) + '&id='+info.id)"
  >
    <div class="pic">
      <pic-mobile :src="info.imageurl" />
    </div>
    <div class="title line-1">
      {{ $t2(lang, info.productname, info.productnameen) }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "@/components/Mobile/common/Pic";

export default {
  name: 'ProductItemMobile',
  components: {PicMobile},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    companyid: {
      type: [Number, String],
      default: () => 0
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.topic-list-swiper{
  .product-item-mobile {
    margin-bottom: 0!important;
  }
}
.product-item-mobile {
  width: 328px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0,0,0,0.1);
  background: rgba(255,255,255,0.1);
  overflow: hidden;
  .pic{
    width: 328px;
    height: 224px;
  }
  .title{
    font-size: 28px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 38px;
    margin-top: 8px;
    padding: 0 16px;
    padding-bottom: 30px;
  }
}
</style>
