<template>
  <div>
    <div

      v-show="opacity===1"
      class="slider"
      :style="{opacity: opacity}"
    >
      <circle-load
        v-if="time > 0"
        class="time"
        :origin-count-down="5"
      />
      <div
        class="pic"
        v-if="false"
      >
        <img src="../../../assets/images/mobile_home_logo.png" />
      </div>
      <swiper
        :options="swiperOptionsTop"
      >
        <swiper-slide
          v-for="(res, index) in swiper"
          :key="index"
        >
          <a
            class="swiper-img"
            :href="res.linkurl"
            :style="{background: `url(`+res.pic+`)`}"
          >
          </a>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
// import swiper module styles
import '@/assets/swiper.css'
import CircleLoad from "../../../components/Mobile/common/Daojishi";
import HlsJsPlayer from "xgplayer-hls.js";
export default {
  name: 'SliderMobile',
  components: {CircleLoad, Swiper, SwiperSlide},
  directives: {
    swiper: directive
  },
  props:{
    stopplay: {
      type: Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      swiper: [],
      swiperOptionsTop: {
        pagination: {
          el: ".swiper-pagination2",
          clickable: true,
        },
      },
      time: -1,
      myVideo: null,
      opacity: 1,
      startTime: new Date().getTime()
    }
  },
  beforeMount() {
  },
  mounted() {
    // this.init()
    // this.navigateTo('/index')
    this.$u.api.commons.getNewAds({typeId: 1}).then(res => {
      if(res.status === 1){
        var localDate = localStorage.getItem('sliderDateMobile3');
        if(localDate === null || (localDate !== this.timeFormat(null, 'yymmdd'))) {
          if(res.data.length > 0){
            this.swiper = this.getExtract(res.data);
            this.time = 5
            this.setTime();
          }else{
            this.navigateTo('/index')
          }
        }else{
          localStorage.setItem('sliderDateMobile3', this.timeFormat(null, 'yymmdd'))
          this.navigateTo('/index')
        }
      }
    })
  },
  methods: {
    play(){
      this.myVideo.play()
      this.opacity = 0;
    },
    tiaoguo(){
      // this.stopplay()
      this.navigateTo('/index')
    },
    init(){
      const _t = this;
    },
    getExtract(array) {
      const random = (min, max) => Math.floor(Math.random() * (max - min) + min); //随机数方法
      let index=random(0, array.length); //生成一个从最小值为0 最大值为数组长度的随机数
      let data = array.splice(index, 1);
      return data;
    },
    setTime(){
      var localDate = localStorage.getItem('sliderDateMobile3');
      if(this.time > 0){
          setTimeout(()=> {
            this.time--;
            this.setTime()
          }, 1000)
      }else{
        const params = {
          is_value: this.swiper[0].id,
          title: this.swiper[0].title,
          thumb: this.swiper[0].pic,
          time: 5
        }
        this.$u.api.ajaxs.actCNZZAds(params)
        if(localDate === null || (localDate !== this.timeFormat(null, 'yymmdd'))){
          localStorage.setItem('sliderDateMobile3', this.timeFormat(null, 'yymmdd'))
          this.navigateTo('/index')
        }else{
          localStorage.setItem('sliderDateMobile3', this.timeFormat(null, 'yymmdd'))
          this.navigateTo('/index')
        }
        localStorage.setItem('sliderDateMobile3', this.timeFormat(null, 'yymmdd'))
      }
    }
  }
}
</script>

<style scoped lang="scss">
.slider{
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  left: 0;
  .time{
    position: absolute;
    right: 40px;
    top: 40px;
    z-index: 2;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
  }
  .pic{
    width: 508px;
    height: 97px;
    position: absolute;
    z-index: 2;
    top: 48px;
    left: 50%;
    margin-left: -254px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .swiper-container{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    .swiper-slide {
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      .swiper-img{
        width: 100%;
        height: 100%;
        display: block;
        background-size: 100% auto!important;
        background-repeat: no-repeat!important;
      }
    }
  }
  .swiper-page-top{
    position: absolute;
    bottom: 20px;
    z-index: 99;
  }

  .slider-btn{
    position: absolute;
    top: 40px;
    z-index: 99;
    width: 56px;
    height: 56px;
    background: url("../../../assets/images/mobile/slider_close.png");
    background-size: 100% 100%;
    border-radius: 8px;
    filter: blur(0px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 32px;
    color: #FFFFFF;
    right: 40px;
  }
}
#welcomeVideo{
  width: 100%;
  height: 100%;
  video{
    width: 100%;
    height: 100%;
    object-fit: fill
  }
}
.video-index-mobile {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  .tiaoguo{
    width: 176px;
    height: 68px;
    position: absolute;
    right: 20px;
    top: 20px;
    line-height: 68px;
    font-size: 32px;
    background: rgba(0,0,0,0.25);
    color: rgba(255,255,255,0.8);
    border-radius: 34px;
    text-align: center;
    border: 1px solid rgba(255,255,255,0.25)
  }
}
</style>
