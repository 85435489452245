<template>
  <div class="case-index-default">
    <case-index-pc
      v-if="browser===1"
      :change-key="changeKey"
      :change-page="changePage"
      :change-type-id="changeTypeId"
      :change-sub-type-id="changeSubTypeId"
      :changeNewsPage="changeNewsPage"
      :options="options"
      :search="search"
    />
    <case-index-mobile
      v-if="browser===2"
      :change-child-type-id="changeChildTypeId"
      :change-key="changeKeyMobile"
      :change-page="changePageMobile"
      :change-type-id="changeTypeIdMobile"
      :change-sub-type-id="changeSubTypeId"
      :changeNewsPage="changeNewsPage"
      :options="options.mobileData"
      :search="searchMobile"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import CaseIndexPc from "@/views/Pc/Case";
import CaseIndexMobile from "../../Mobile/Case";

export default {
  name: 'CaseIndexDefault',
  components: {CaseIndexMobile, CaseIndexPc},
  data() {
    return {
      options: {
        loading: true,
        childTypeId: 0,
        childSubTypeId: 0,
        data: {
          list: [],
          page: 1,
          pagesize: 10,
          total: 0
        },
        pcData: [
          {
            typeId: 47,
            childType: [],
            childTypeId: 0,
            data: {
              list: [],
              key: '',
              page: 1,
              pagesize: 6,
              total: 0
            }
          },
          {
            typeId: 48,
            childType: [],
            childTypeId: 0,
            data: {
              list: [],
              key: '',
              page: 1,
              pagesize: 6,
              total: 0
            }
          }
        ],
        mobileData: {
          childSubTypeId: 0,
          typeList: [
            {
              id: 47,
              name: '应用领域',
              langid: '50026'
            },
            {
              id: 48,
              name: '九大专业展',
            }
          ],
          childTypeList: [],
          typeId: 47,
          childTypeId: 0,
          loading: false,
          data: {
            list: [],
            key: '',
            page: 1,
            pagesize: this.browser===1 ? 6 : 6,
            total: 0
          }
        }
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        if(this.browser === 1){
          this.init()
        }else{
          this.initMobile()
        }
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    if(this.browser === 1){
      this.init()
    }else{
      this.initMobile()
    }
    this.setShare()
  },
  methods: {
    init() {
      this.options.pcData.map((res, key) => {
        this.getChildTypeList(res.typeId, key)
      })
    },
    getChildTypeList(typeid, key) {
      this.$u.api.commons.getChildArchivesType({typeId: typeid}).then(res => {
        if(res.status === 1){
          res.data.unshift({id: typeid, name: '全部', ename: 'All'})
          this.options.pcData[key].childType = res.data
          this.options.pcData[key].childTypeId = res.data[0].id
          this.getList(key, res.data[0].id)
        }
      })
    },
    getList(key){
      this.options.loading = true;
      const params = {
        typeid: this.options.pcData[key].childTypeId,
        pagesize: this.options.pcData[key].data.pagesize,
        page: this.options.pcData[key].data.page,
        key: this.options.pcData[key].data.key
      }
      this.$u.api.casess.getLists(params).then(res => {
        this.options.loading = false
        if(res.status === 1){
          this.options.pcData[key].data.list = res.data.lists
          this.options.pcData[key].data.total = res.data.totalNum
        }
      })
    },
    changePage(key, val){
      this.options.pcData[key].data.page = val
      this.getList(key)
    },
    changeTypeId(key, val){
      this.options.pcData[key].childTypeId = val
      this.options.pcData[key].data.page = 1
      this.getList(key)
    },
    search(){
      this.getList(0)
      this.getList(1)
    },
    changeKey(v){
      this.options.pcData[0].data.key = v
      this.options.pcData[1].data.key = v
    },
    initMobile(){
      if(this.options.mobileData.childSubTypeId == 39){
        this.getNewsList();
      }else{
        this.getTypeListMobile(47)
      }
    },
    getTypeListMobile(typeid){
      this.$u.api.commons.getChildArchivesType({typeId: typeid}).then(res => {
        if(res.status === 1){
          res.data.unshift({id: typeid, name: '全部', ename: 'All'})
          this.options.mobileData.childTypeList = res.data
          this.options.mobileData.childTypeId = res.data[0].id
          this.getListMobile()
        }
      })
    },
    getListMobile(){
      this.options.loading = true;
      this.options.mobileData.data.list = []
      this.options.mobileData.data.total = 0
      const params = {
        typeid: this.options.mobileData.childTypeId,
        pagesize: this.options.mobileData.data.pagesize,
        page: this.options.mobileData.data.page,
        key: this.options.mobileData.data.key
      }
      this.$u.api.casess.getLists(params).then(res => {
        this.options.loading = false
        if(res.status === 1){
          this.options.mobileData.data.list = res.data.lists
          this.options.mobileData.data.total = res.data.totalNum
        }
      })
    },
    changePageMobile(val){
      this.options.mobileData.data.page = val
      this.getListMobile()
    },
    changeTypeIdMobile(val){
      this.options.mobileData.typeId = val
      this.options.mobileData.data.page = 1
      this.getTypeListMobile(val)
    },
    changeChildTypeId(val){
      this.options.mobileData.childTypeId = val
      this.options.mobileData.data.page = 1
      this.getListMobile()
    },
    changeSubTypeId(val){
      this.options.childSubTypeId = val
      this.options.mobileData.childSubTypeId = val
      if(val === 39){
        this.getNewsList();
      }else{
        this.getListMobile()
      }
    },
    searchMobile(){
      this.options.mobileData.data.page = 1
      this.getListMobile()
    },
    changeKeyMobile(v){
      this.options.mobileData.data.key = v
    },
    setShare(){
      this.getWxConfig(this.$t('10008') + this.bankuai(), '行业应用案例大合集，为您提供行业解决方案的案例参考。','https://virtualobs.ciif-expo.com/shareimg/8.png')
    },
    getNewsList(){
      this.options.loading = true
      this.options.data.list = []
      this.options.data.total = 0
      this.options.mobileData.data.list = []
      this.options.mobileData.data.total = 0
      const params = {
        typeid: 39,
        pagesize: this.options.data.pagesize,
        page: this.options.data.page
      }
      this.$u.api.newss.getLists(params).then(res => {
        this.options.loading = false
        if(res.status === 1){
          const {data: {lists,  totalNum}} = res
          if(this.browser === 1) {
            this.options.data.list = lists
            this.options.data.total = totalNum
          }else{
            this.options.mobileData.data.list = lists
            this.options.mobileData.data.total = totalNum
          }
        }
      })
    },
    changeNewsPage(v){
      this.options.data.page = v;
      this.getList()
    },

  }

}
</script>

<style scoped></style>
