<template>
  <a
    class="btn-big-block-mobile"
    :class="on ? `on` : ``"
    :href="link ? link : `javascript: void(0)`"
    :style="{background: inactiveBg, color: inactiveColor, height: height, lineHeight: height}"
    :target="link ? `_blank` : ``"
    @click="disabled ? () => {} : $emit('click')"
  >
    {{ label }}
  </a>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'BtnBigBlockMobile',
  props:{
    label: {
      type:String,
      default: () => ''
    },
    link: {
      type:String,
      default: () => ''
    },
    height: {
      type:String,
      default: () => ''
    },
    on: {
      type:Boolean,
      default: () => false
    },
    inactiveBg: {
      type: String,
      default: () => 'linear-gradient(223deg, rgba(255, 26, 44, 0.9) 0%, rgba(255, 155, 97, 0.8) 100%)'
    },
    inactiveColor: {
      type: String,
      default: () => '#fff'
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.btn-big-block-mobile{
  cursor: pointer;
  display: inline-block;
  line-height: 80px;
  height: 80px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 28px;
  background: linear-gradient(223deg, rgba(255, 26, 44, 0.9) 0%, rgba(255, 155, 97, 0.8) 100%);
  padding: 0 32px;
  color: #fff;
  vertical-align: top;
  width: 100%;
  text-align: center;
  opacity: 1;
  &.on, &:hover{
    background: linear-gradient(223deg, rgba(255, 26, 44, 0.9) 0%, rgba(255, 155, 97, 0.8) 100%)!important;
    color: #fff!important;
    opacity: 0.5;
  }
}
</style>
