<template>
  <div
    class="news-item-video-pc"
    @click="showPlayer(info.video)"
  >
    <div class="pic">
      <pic-pc :src="info.pic" />
    </div>
    <div class="info">
      <div class="title line-2">
        {{ $t2(lang, info.title, info.title_en) }}
      </div>
      <div class="desc">
        <icon-text-from :label="$t2(lang, info.cn_source, info.en_source)" />
        <icon-text-time :label="timeFormat(info.addtime, 'yyyy/mm/dd')" />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicPc from "@/components/Pc/common/Pic";
import IconTextFrom from "@/components/Pc/common/IconText/from";
import IconTextTime from "@/components/Pc/common/IconText/time";

export default {
  name: 'NewsItemVideoPc',
  components: {IconTextTime, IconTextFrom,  PicPc},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    showPlayer(videoUrl) {
      this.$bus.$emit('showPlayer', videoUrl)
    },
  }
}
</script>

<style scoped lang="scss">
.news-item-video-pc {
  width: 550px;
  margin-right: 20px;
  cursor: pointer;
  height: 160px;
  background: rgba(255,255,255,0.1);
  margin-bottom: 16px;
  transition: all 0.4s ease-in-out;
  padding: 5px;
  &:nth-child(2n){
    margin-right: 0;
  }
  &:hover{
    .pic{
      div{
        transition: all 0.4s ease-in-out;
        transform: scale(1.1);
      }
    }
  }
  .pic{
    width: 226px;
    height: 150px;
    overflow: hidden;
    float: left;
  }
  .info{
    margin-left: 226px;
    padding: 20px;
    height: 160px;
    position: relative;
    .title{
      font-size: 20px;
      font-weight: 600;
      color: rgba(255,255,255,0.8);
      line-height: 24px;
    }
    .summary{
      margin-top: 8px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(255,255,255,0.6);
      line-height: 20px;
    }
    .desc{
      position: absolute;
      left: 20px;
      bottom: 20px;
    }
  }
}
</style>
