<template>
  <form
    class="search-form"
    :class="{focus: focus}"
    :style="{width: width, margin: '0 auto'}"
  >
    <div class="search-icon">
      <img src="../../../../assets/images/anhei/pc/top_search.png" />
    </div>
    <div class="input">
      <input
        v-model="keyword"
        :placeholder="$t('20011')"
        tabindex="1"
        type="text"
        @blur="dialaingout()"
        @change="() => {}"
        @focus="dialaing()"
      />
    </div>
    <div
      class="search-btn"
      @click="goSearch(keyword)"
    >
      搜索
    </div>
  </form>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'SearchFormPc',
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    goSearch: {
      type: Function,
      default: () => () =>{}
    },
    width: {
      type: String,
      default: () => '100%'
    }
  },
  data(){
    return {
      keyword: '',
      focus: false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  mounted() {
    this.keyword = this.$route.query.keyword
  },
  methods: {
    dialaing(){
      this.focus = true
    },
    dialaingout(){
      this.focus = false
    }
  }
}
</script>

<style scoped lang="scss">
  .search-form{
    margin: 0 40px;
    height: 44px;
    background: rgba(250, 250, 250, 0.1);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 27px;
    position: relative;
    border: 1px solid rgba(0,0,0,0)!important;
    border-right: 0!important;
    &.focus{
      box-shadow: 0px 2px 10px 0px rgba(255,255,255,0.2);
      border: 1px solid rgba(255,255,255,0.5)!important;
      border-right: 0!important;
      height: 44px;
      backdrop-filter: blur(1px);
    }
    .search-icon{
      margin-left: 16px;
      width: 20px;
      img{
        width: 20px;
        height: 20px;
      }
    }
    .input{
      flex-grow: 1;
      margin: 0 10px;
      input{
        width: 100%;
        height: 44px;
        line-height: 44px;
        border: 0;
        outline: none;
      }
    }
    .search-btn{
      width: 148px;
      height: 44px;
      background: #EA4C1E;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 22px;
      display: flex;
      justify-content: center;
      position: absolute;
      right: 0;
      top: -1px;
      align-items: center;
      cursor: pointer;
    }
  }
</style>
