<template>
  <div>
    <div class="user-schedule-activity-list-pc">
      <template v-for="(res, index) in data.list">
        <user-schedule-activity-list-item-pc
          :key="index"
          :from="from"
          :info="res"
          :refresh="refresh"
        />
      </template>
    </div>
    <page-common-pc
      :change-page="changePage"
      :data="data"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import PageCommonPc from "../../common/PageCommon";
import UserScheduleActivityListItemPc from "./item";

export default {
  name: 'UserScheduleActivityListPc',
  components: {UserScheduleActivityListItemPc, PageCommonPc},
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    from: {
      type: String,
      default: () => ''
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.user-schedule-activity-list-pc {
}
</style>
