<template>
  <div
    class="announcement-mobile"
    @click="hideAnnouncement"
  >
    <div class="box">
      <div class="tit">
        {{ $t('10027') }}
      </div>
      <div class="cont">
        <div class="tit1">
          {{
            $t2(lang, siteConfig.site_notice, siteConfig.site_notice_en)
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'AnnouncementMobile',
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    hideAnnouncement: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'siteConfig']),
  },
}
</script>

<style scoped lang="scss">
.announcement-mobile {
  background: rgba(0,0,0,0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999999;
  .box{
    width: 600px;
    height: 400px;
    background: linear-gradient(180deg, #FFF6F3, #FFE1D8);
    box-shadow: 0 5px 20px 0 rgba(30, 115, 234, .1);
    border-radius: 4px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 11;
    padding: 51px 32px 32px;
    text-align: center;
    font-weight: bold;
    .tit{
      width: 230px;
      height: 62px;
      background: url(../../../../assets/images/mobile/liubianxing.png) no-repeat center center;
      text-align: center;
      line-height: 62px;
      font-size: 30px;
      font-weight: 600;
      color: #FFFFFF;
      position: absolute;
      top: 20px;
      left: 50%;
      margin-left: -115px;
    }
    .cont {
      width: 536px;
      height: 317px;
      background: linear-gradient(223deg, rgba(252, 80, 30, 0.1) 0%, rgba(250, 137, 88, 0.1) 100%);
      border-radius: 4px;
      padding: 81px 28px 0;
    }
    .cont .tit1 {
      color: #330B00;
      font-size: 28px;
    }
    .cont .tit2 {
      color: #EA4C1E;
      font-size: 40px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .cont .tit3 {
      color: #330B00;
      font-size: 28px;
    }
  }
}
</style>
