<template>
  <div class="bbs-jiabin-default">
    <bbs-jiabin-pc
      v-if="browser===1"
      :options="options"
    />
    <bbs-jiabin-mobile
      v-if="browser===2"
      :options="options"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import ExhibitionProductinfoPc from "@/views/Pc/Exhibition/productinfo";
import BbsJiabinPc from "@/views/Pc/Bbs/jiabin";
import BbsJiabinMobile from "../../Mobile/Bbs/jiabin";

export default {
  name: 'BbsJiabinDefault',
  components: {BbsJiabinMobile, BbsJiabinPc},
  data(){
    return {
      options: {
        id: 0,
        companyid: 0,
        info: {}
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    this.scrollAuto()
  },
  mounted() {
    this.options.id = this.$route.query.id
    this.init()
  },
  methods: {
    init(){
      const params = {
        id: this.options.id,
      }
      this.$u.api.bbss.getJiabinDetail(params).then(res => {
        if(res.status === 1){
          this.options.info = res.data
        }
      })
    }
  }

}
</script>

<style scoped lang="scss">
.bbs-jiabin-default{

}
</style>
