<template>
  <div>
    <div class="zhanwei"></div>
    <div class="exhibition-footer-mobile">
      <div class="left">
        <icon-text-collect-mobile
          :id="options.info.id"
          :bbs="true"
          class="item"
          color="rgba(255,255,255,.7)"
          :dark="true"
          font-size="20px"
          :label="$t('50024')"
          :set-collect="setCollect"
          :shoucang="parseInt(options.info.is_cang) === 1"
          :type="1"
          width="40px"
        />
        <icon-text-zan-mobile
          :id="options.info.id"
          class="item"
          color="rgba(255,255,255,.7)"
          font-size="20px"
          :label="$t('50048')"
          :set-zan="setZan"
          :type="1"
          width="40px"
          :zan="parseInt(options.info.is_zan) === 1"
        />
        <icon-text-column-mobile
          class="item"
          color="rgba(255,255,255,.7)"
          font-size="20px"
          icon="share_hei.png"
          :text="$t('50016')"
          type="share"
          width="40px"
        />
      </div>
      <btn-big-mobile
        :label="!options.info.is_card ? $t('20102') : $t('20115')"
        @click="jiaohuanmingpian"
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import IconTextCollectMobile from "../../common/IconText/collect";
import IconTextZanMobile from "../../common/IconText/zan";
import BtnBigMobile from "../../common/BtnBig";
import IconTextColumnMobile from "../../common/IconTextColumn";

export default {
  name: 'ExhibitionFooterMobile',
  components: {IconTextColumnMobile, BtnBigMobile, IconTextZanMobile, IconTextCollectMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    setZan: {
      type: Function,
      default: () => () => {}
    },
    jiaohuanmingpian: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.zhanwei{
  height: 100px;
  width: 100%;
}
.exhibition-footer-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 36px;
  background: #000;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
  left: 0;
  .left{
    display: flex;
    justify-content: space-around;
    align-items: center;
    .item{
      margin: 0 20px;
    }
  }
}
</style>
