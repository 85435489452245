<template>
  <div class="live-richeng-list-pc">
    <template v-for="(res, index) in list">
      <div
        :key="index"
        class="richeng-list"
      >
        <div class="title">
          {{ date[index].pub }}
        </div>
        <div class="list">
          <template v-for="(item, i) in res">
            <live-richeng-item-pc
              :key="i"
              :info="res[i]"
            />
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import LiveRichengItemPc from "@/components/Pc/Live/LiveRichengList/item";

export default {
  name: 'LiveRichengListPc',
  components: {LiveRichengItemPc},
  props: {
     list: {
       type: Object,
       default: () => {}
     },
    date: {
       type: Object,
      default: () => {}
    }
  },
  data(){
    return {

    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },

}
</script>

<style scoped lang="scss">
.live-richeng-list-pc{
  .richeng-list{
    .title{
      margin-left: 32px;
      font-size: 16px;
      font-weight: 600;
      color: #192745;
      line-height: 22px;
      opacity: .6;

      font-family: 'SF Pro Display Regular',PingFang-SC-Medium, PingFang-SC, Arial, 'Microsoft YaHei', sans-serif;
    }
    .list{
      margin-top: 12px;
    }
  }
}
</style>
