<template>
  <div class="exhibition-productinfo-pc">
    <div class="container">
      <div
        v-if="options.info.productRow"
        class="main-box min-height-770"
      >
        <div class="company-line clearfix">
          <back-btn-pc />
          <div
            class="company-name"
            @click="navigateTo('/exhibition/info?id='+options.companyid)"
          >
            {{ $t2(lang, options.info.zhanRow.companyname, options.info.zhanRow.companynameen) }}
          </div>
        </div>
        <div class="info">
          <div class="pic">
            <div
              class="zoomin"
              v-if="options.info.productRow.imageurl"
              @click="show(options.info.productRow.imageurl)"
            ></div>
            <img
                v-if="options.info.productRow.imageurl"
              :src="options.info.productRow.imageurl"
              @click="show(options.info.productRow.imageurl)"
            />
            <img
                v-else
              src="../../../assets/images/newlogo/logo_chang1.png"
              @click="show(options.info.productRow.imageurl)"
            />
          </div>
          <div class="title">
            <div class="bar"></div>
            <div class="text">
              {{ $t2(lang, options.info.productRow.productname, options.info.productRow.productnameen) }} <span
                v-if="options.info.productRow.leixing"
                class="leixing"
              >{{ $t2(lang, options.info.productRow.leixing, options.info.productRow.leixing_en) }}</span>
            </div>
          </div>
          <div class="type">
            <div class="left">
              <div class="type-name">
                {{ $t2(lang,options.info.zhanRow.projectteamname,options.info.zhanRow.projectteamname_en) }}
              </div>
              <div class="child-type-name">
                {{ $t2(lang,options.info.zhanRow.applicationsarea,options.info.zhanRow.applicationsarea_en) }}
              </div>
            </div>
            <div class="right">
              <icon-text-viewnum
                class="btn-item"
                :label="options.info.productRow.click"
              />
              <icon-text-zan
                :id="options.info.productRow.id"
                class="btn-item"
                :label="options.info.productRow.zan"
                :set-zan="setZan"
                :type="6"
                :zan="parseInt(options.info.productRow.is_zan) >= 1"
              />
              <icon-text-collect
                :id="options.info.productRow.id"
                class="btn-item"
                :label="this.$t('50024')"
                :set-collect="setCollect"
                :shoucang="parseInt(options.info.productRow.is_cang) == 1"
                :type="7"
              />
              <icon-text-share
                class="btn-item"
                label="分享"
              />
            </div>
          </div>
        </div>
        <split-line-pc
          bg-color="rgba(255,255,255,0.1)"
          height="1px"
          margin-bottom="47px"
          margin-top="20px"
        />
        <text-content-pc
          :content="$t2(lang, options.info.productRow.explains, options.info.productRow.explains_en)"
          :title="$t('20111')"
        />
      </div>
    </div>
    <div
      v-if="images.length > 0"
      class="imgmodal"
      style="width: 100%; height: 100%; position: absolute; left: 0; top: 0; z-index: 1"
    >
      <div class="close-btn">
        <btn-close-pc @click="hide" />
      </div>
      <viewer
        ref="viewer"
        class="viewer"
        :images="images"
        :options="optionsViewer"
        style="width: 100%; height: 100%;  background-size: contain;background-repeat: no-repeat;
                background-position: center;background-color: rgba(0,0,0,0.1);"
      >
        <img
          v-for="(img, index) in images"
          :key="index"
          :src="img"
          style="display: none; height: 630px;"
        />
      </viewer>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import Vue from "vue";
import BackBtnPc from "@/components/Pc/common/BackBtn";
import SplitLinePc from "@/components/Pc/common/SplitLine";
import BtnClosePc from "@/components/Pc/common/BtnClosePc";
import TextContentPc from "@/components/Pc/common/TextContent";
import IconTextViewnum from "@/components/Pc/common/IconText/viewnum";
import IconTextZan from "@/components/Pc/common/IconText/zan";
import IconTextCollect from "@/components/Pc/common/IconText/collect";
import IconTextShare from "@/components/Pc/common/IconText/share";
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer)
export default {
  name: 'ExhibitionProductinfoPc',
  components: {BtnClosePc, TextContentPc, SplitLinePc, BackBtnPc, IconTextViewnum, IconTextZan, IconTextCollect, IconTextShare},
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    setZan: {
      type: Function,
      default: () => () => {}
    },
  },
  data(){
    return {
      images: [],
      optionsViewer: {
        zIndex: 99999,
        inline: true,
        minHeight: 640,
        button: false,
        navbar: false,
        title: false,
        toolbar: false,
        tooltip: false,
        movable: true,
        zoomable: true,
        rotatable: false,
        scalable: false,
        transition: false,
        fullscreen: false,
        keyboard: false,
      },
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods:{
    show (img) {
      this.images = [img]
      this.$nextTick(() => {
        this.$refs.viewer.$viewer.show()
      })
    },
    hide(){
      this.images = []
      this.$refs.viewer.$viewer.hide()
    }
  }

}
</script>

<style scoped lang="scss">
.exhibition-productinfo-pc{
  .imgmodal{
    .close-btn{
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 999999;
    }
  }
  .company-line{
    .back-btn-pc{
      display: inline-block;
      float: left;
    }
    .company-name{
      display: inline-block;
      float: left;
      height: 40px;
      line-height: 40px;
      margin-left: 20px;
      font-size: 20px;
      color: rgba(255,255,255,0.8);
      cursor: pointer;
    }
  }
  .info{
    margin-top: 20px;
    .pic{
      min-width: 200px;
      max-height: 400px;
      max-width: 1120px;
      position: relative;
      display: inline-block;
      .zoomin{
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: 50px;
        height: 50px;
        background: url("../../../assets/images/zoom-in.png");
        background-size: 100%;
      }
      img{
        max-height: 400px;
      }
    }
    .title{
      margin-top: 20px;
      height: 32px;
      line-height: 32px;
      display: flex;
      align-items: center;
      .bar{
        width: 6px;
        height: 32px;
        background: linear-gradient(223deg, #FC501E, #FA8958);
        border-radius: 3px;
        display: inline-block;
        vertical-align: top;
      }
      .text{
        margin-left: 18px;
        vertical-align: top;
        font-size: 20px;
        font-weight: 600;
        color: rgba(255,255,255,0.8);
        display: flex;
        align-items: center;
        .leixing{
          margin-left: 7px;
          height: 20px;
          background: url('../../../assets/images/tag_center@2x.png');
          background-size: 100% 100%;
          display: inline-block;
          font-size: 12.5px;
          color: #fff;
          line-height: 20px;
          text-align: center;
          position: relative;
          padding-left: 10px;
          padding-right: 6px;
          background-position: 5px 0!important;
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
          &::before{
            position: absolute;
            left: 0;
            top: 0;
            content: '';
            display: inline-block;
            width: 8px;
            height: 20px;
            background: url('../../../assets/images/tag_left@2x.png');
            background-size: 100% 100%;
          }
          &::after{
            position: absolute;
            right: -8px;
            top: 0;
            content: '';
            display: inline-block;
            width: 8px;
            height: 20px;
            background: url('../../../assets/images/tag_right@2x.png') repeat-x;
            background-size: 100% 100%;
          }
        }
      }
    }
    .type{
      margin-left: 22px;
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .type-name{
        font-size: 12px;
        font-weight: 500;
        color: rgba(255,255,255,0.6);
        line-height: 16px;
      }
      .child-type-name{
        margin-top: 2px;
        font-size: 12px;
        font-weight: 500;
        color: rgba(255,255,255,0.8);
        line-height: 16px;
      }
      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        .btn-item{
          margin-left: 30px;
        }
      }
    }
  }
}
</style>
