<template>
  <div
    class="back-btn-bai-mobile"
    @click="goBack"
  >
    <img src="../../../../assets/images/mobile/back_bai.png" />
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'BackBtnBaiMobile',
  props: {
    url: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    goBack(){
      if(this.url !== ''){
        this.navigateTo(this.url)
      }else{
        this.$store.commit('setIsPageBack', true);
        this.$router.back()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.back-btn-bai-mobile {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 48px;
    height: 48px;
  }
}
</style>
