<template>
  <div
    class="btn-close-pc"
    :style="{width: size+`px`, height: size+`px`}"
    @click="$emit('click')"
  ></div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'BtnClosePc',
  props: {
    size: {
      type: String,
      default: () => '40'
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.btn-close-pc{
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: inline-block;
  background: url("../../../../assets/images/player_close.png");
  background-position: center;
  background-size: 100% 100%;
}
</style>
