<template>
  <div
    class="rank-type-item-mobile"
    :class="on ? `on item_`+index : `item_`+index"
    @click="$emit('click')"
  >
    {{ $t(info.title) }}
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'RankTypeItemMobile',
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    on: {
      type: Boolean,
      default: () => false
    },
    index: {
      type: Number,
      default: () => 0
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.rank-type-item-mobile {
  height: 72px;
  line-height: 72px;
  padding: 0 50px;
  font-size: 28px;
  font-weight: 600;
  color: #666666;
  background: #fff;
  &.on{
    color: #fff;
  }
}
.item_0{
  &.on{
    background: linear-gradient(230deg, #FF6767, #FFAFA7);
  }
}
.item_1{
  &.on{
    background: linear-gradient(225deg, #FF7F5A, #FFB697);
  }
}
.item_2{
  &.on{
    background: linear-gradient(225deg, #5A89FF 0%, #81AFFF 100%);
  }
}
.item_3{
  &.on{
    background: linear-gradient(228deg, #7070FF, #A1A9FF);
  }
}
</style>
