<template>
  <div class="news-hezuo-pc">
    <h3>{{ $t('25007') }}<img src="../../../../assets/images/icon_friend.png" /></h3>
    <swiper
      :options="swiperOptions"
    >
      <swiper-slide
        v-for="(res, index) in hezuo"
        :key="index"
        class="swiper-item-pic"
      >
        <img
          :src="res.pic"
        />
      </swiper-slide>
    </swiper>
    <div class="swiper-page">
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import '@/assets/swiper.css'
export default {
  name: 'NewsHezuoPc',
  components: {  Swiper, SwiperSlide},
  directives: {
    swiper: directive
  },
  props: {
    hezuo: {
      type: Array,
      default(){
        return []
      }
    }
  },
  data(){
    return {
      timer: null,
      swiperOptions: {
        slidesPerView: 6,
        slidesPerColumn: 2,
        slidesPerGroup: 6,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
.news-hezuo-pc{
  margin-top: 50px;
  h3{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    margin-bottom: 20px;
    img{
      width: 18px;
      height: 18px;
      margin-left: 6px;
    }
  }
  .swiper-item-pic{
    margin-bottom: 8px;
  }
}
</style>
