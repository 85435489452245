<template>
  <a
    class="btn-pc"
    :href="link"
    :style="{background: background}"
    target="_blank"
  >
    {{ label }}
  </a>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'BtnPc',
  props:{
    label: {
      type:String,
      default: () => ''
    },
    link: {
      type:String,
      default: () => ''
    },
    background: {
      type:String,
      default: () => 'linear-gradient(223deg, #FC501E, #FA8958)'
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.btn-pc{
  cursor: pointer;
  display: inline-block;
  line-height: 32px;
  height: 32px;
  padding: 0 32px;
  color: #fff;
  vertical-align: top;
}
</style>
