<template>
  <div
    class="news-item-renwu-pc"
    @click="navigateTo('/news/info?id='+info.id)"
  >
    <div class="pic">
      <pic-pc :src="info.pic" />
    </div>
    <div class="info">
      <div class="title">
        {{ $t2(lang, info.title, info.title_en) }}
      </div>
      <div class="summary line-3">
        {{ $t2(lang, info.description, info.description_en) }}
      </div>
      <div class="desc">
        <icon-text-from :label="$t2(lang, info.cn_source, info.en_source)" />
        <icon-text-time :label="timeFormat(info.addtime, 'yyyy/mm/dd')" />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicPc from "@/components/Pc/common/Pic";
import IconTextFrom from "@/components/Pc/common/IconText/from";
import IconTextTime from "@/components/Pc/common/IconText/time";

export default {
  name: 'NewsItemRenwuPc',
  components: {IconTextTime, IconTextFrom,  PicPc},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.news-item-renwu-pc {
  width: 1120px;
  cursor: pointer;
  height: 180px;
  background: rgba(255,255,255,0.1);
  margin-bottom: 16px;
  transition: all 0.4s ease-in-out;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  &:hover{
    .pic{
      div{
        transition: all 0.4s ease-in-out;
        transform: scale(1.1);
      }
    }
  }
  .pic{
    width: 270px;
    height: 180px;
    overflow: hidden;
    float: left;
  }
  .info{
    margin-left: 290px;
    padding: 20px;
    height: 180px;
    position: relative;
    .title{
      font-size: 20px;
      font-weight: 600;
      color: rgba(255,255,255,0.8);
      line-height: 24px;
    }
    .summary{
      margin-top: 8px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(255,255,255,0.6);
      line-height: 20px;
    }
    .desc{
      position: absolute;
      left: 20px;
      bottom: 20px;
    }
  }
}
</style>
