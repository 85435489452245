<template>
  <div class="user-login-default">
    <user-login-pc
      v-if="browser===1"
      ref="userLoginPcRef"
      :close-modal="closeModal"
      :modal-show="modalShow"
      :options="options"
      :qrcodeurl="qrcodeurl"
      :send-sms="sendSms"
      :submit="submit"
    />
    <user-login-mobile
      v-if="browser===2"
      ref="userLoginMobileRef"
      :close-modal="closeModal"
      :modal-show="modalShow"
      :options="options"
      :qrcodeurl="qrcodeurl"
      :send-sms="sendSms"
      :submit="submit"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import UserLoginPc from "../../Pc/User/login";
import UserLoginMobile from "../../Mobile/User/login";
import router from "../../../router";
import Vue from "vue";

export default {
  name: 'UserLoginDefault',
  components: {UserLoginMobile, UserLoginPc},
  data() {
    return {
      userLogonPcRef: null,
      userLogonMobileRef: null,
      options: {
        list: [],
        smsStatus: 1,
        smsTime: 0,
        smsTimes: 60,
        timeOut: null
      },
      defaultOptions: {},
      isFirst: false,
      nowTime: new Date().getTime(),
      modalShow: false,
      qrcodeurl: '',
      code: ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    this.getCode()
    this.setShare()
  },
  methods: {
    getCode () { // 非静默授权，第一次有弹框
      this.code = ''
      this.code = this.getUrlCode().code // 截取code
      console.log('code', this.code)
      if (this.code == null || this.code === '') {
        this.init()
      } else {
        // 你自己的业务逻辑
        const localuser = JSON.parse(localStorage.getItem('tmpuser'));
        if(localuser.mid){
          this.$u.api.users.getUserInfoByCode({code: this.code, mid: localuser.mid}).then(res => {
            if(res.status == 1){
              this.checkBind(localuser.mid)
            }else{
              this.init();
            }
          })
        }
      }
    },
    getUrlCode() { // 截取url中的code方法
      var url = location.search
      var theRequest = new Object()
      if (url.indexOf("?") != -1) {
        var str = url.substr(1)
        var strs = str.split("&")
        for(var i = 0; i < strs.length; i ++) {
          theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1])
        }
      }
      return theRequest
    },
    init() {
      let data = {
        time: 0,
        path: '/user/login',
        model: 10011
      }
      this.$u.api.ajaxs.actCNZZ(data)
    },
    sendSms(mobile){
      if(this.options.smsTime !== 0){
        return ;
      }
      var patt = /^[1][3-9][\d]{9}/
      if (!patt.test(mobile)) {
        if(this.browser===1){
          this.$message.error(this.$t('10022') + '!')
        }else{
          this.toast(this.$t('10022') + '!')
        }
        return
      }
      const params = {
        mobile: mobile
      }
      this.$u.api.users.sendSms(params).then(res => {
        if(res.status === 1){
          this.options.smsTime = 60;
          this.options.timeOut = setInterval(() => {
            if(this.options.smsTime <= 0){
              this.options.timeOut && clearInterval(this.options.timeOut)
              this.options.smsTime = 0
            }else{
              this.options.smsTime--
            }
          }, 1000)
        }else{
          this.$message.error({
            message: this.$t(res.msg),
            customClass: this.browser === 1 ? 'el-pc' : 'el-mobile'
          });
        }
      })
    },
    checkBind(mid){
      this.$u.api.users.checkBind({mid: mid}).then(res => {
        if(res.status === 1){
          this.$store.commit('setUser', JSON.parse(localStorage.getItem('tmpuser')));
          localStorage.removeItem('tmpuser')
          if(localStorage.getItem('backUrl') && localStorage.getItem('backUrl') !== '' && localStorage.getItem('backUrl') !== '/user/login'){
            if (navigator.userAgent.indexOf('Mobile') > -1) {
              this.$router.push({
                path: localStorage.getItem('backUrl'),
                query: JSON.parse(localStorage.getItem('backQuery')),
              })
            }else{
              this.$router.replace({
                path: localStorage.getItem('backUrl'),
                query: JSON.parse(localStorage.getItem('backQuery')),
              })
            }
            localStorage.removeItem('backUrl')
            localStorage.removeItem('backQuery')
          }else{
            this.navigateTo('/user')
          }
        }else{
          setTimeout(() => {
            this.checkBind(mid)
          }, 1000)
        }
      })
    },
    submit(params){
      this.$u.api.users.actCheck(params).then(res => {
        console.log('res', res)
        if(res.status ===1){
          this.$store.commit('setUser', res.data)
          if(!res.data.openid){
            localStorage.setItem('tmpuser', JSON.stringify(res.data))
            this.qrcodeurl = res.data.qrcodeurl
            // if(navigator.userAgent.indexOf('MicroMessenger') > -1 || navigator.userAgent.indexOf('WeChat') > -1 || navigator.userAgent.indexOf('Weixin') > -1 || navigator.userAgent.indexOf('weixin') > -1){
            //   // window.location.href = res.data.weixinLoginUrl;
            // }else{
              this.modalShow = true;
              this.$nextTick(() => {
                if(this.browser===1){
                  this.$refs['userLoginPcRef'].creatQrCode();
                }else{
                  this.$refs['userLoginMobileRef'].creatQrCode();
                }
              })
              // this.checkBind(res.data.mid)
            // }
          }else{
            this.$store.commit('setUser', res.data)
            if(localStorage.getItem('backUrl') && localStorage.getItem('backUrl') !== '' && localStorage.getItem('backUrl') !== '/user/login'){
              if (navigator.userAgent.indexOf('Mobile') > -1) {
                this.$router.push({
                  path: localStorage.getItem('backUrl'),
                  query: JSON.parse(localStorage.getItem('backQuery')),
                })
              }else{
                this.$router.replace({
                  path: localStorage.getItem('backUrl'),
                  query: JSON.parse(localStorage.getItem('backQuery')),
                })
              }
              localStorage.removeItem('backUrl')
              localStorage.removeItem('backQuery')
            }else{
              this.navigateTo('/user')
            }
          }
        }else if(res.status === -99){
          let cnzzData = {
            path: '/register',
            model: 10016,
            startTime: this.nowTime,
            time: Math.ceil( (new Date().getTime() - this.nowTime) / 1000),
          }
          this.$u.api.ajaxs.actCNZZ(cnzzData).then(() => {
            window.location.href = 'https://online.ciif-expo.cn/RegMethod.html?pcurl=https://virtual.ciif-expo.com/user/login&phone='+params.mobile
          })
        }else{
          if(this.browser===1){
            this.$message.error(this.$t(res.msg))
          }else{
            this.toast(this.$t(res.msg))
          }
        }
      })
    },
    setShare(){
      this.getWxConfig('', '')
    },
    closeModal(){
      this.modalShow = false
      if(localStorage.getItem('backUrl') && localStorage.getItem('backUrl') !== '' && localStorage.getItem('backUrl') !== '/user/login'){
        if (navigator.userAgent.indexOf('Mobile') > -1) {
          this.$router.push({
            path: localStorage.getItem('backUrl'),
            query: JSON.parse(localStorage.getItem('backQuery')),
          })
        }else{
          this.$router.replace({
            path: localStorage.getItem('backUrl'),
            query: JSON.parse(localStorage.getItem('backQuery')),
          })
        }
        localStorage.removeItem('backUrl')
        localStorage.removeItem('backQuery')
      }else{
        this.navigateTo('/user')
      }
    }
  }

}
</script>

<style scoped></style>
