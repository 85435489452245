<template>
  <div class="news-info-mobile">
    <top-nav-mobile
      :backurl="backurl"
      :is-back="true"
    >
      <span class="nav-title">{{ $t('10006') }}</span>
    </top-nav-mobile>
    <div class="container">
      <div class="pic radius">
        <img :src="options.info.pic" />
      </div>
      <div class="title mt-20">
        {{ $t2(lang, options.info.title, options.info.en_title) }}
      </div>
      <div class="btn-view">
        <div class="other">
          <icon-text-from-mobile
            class="item"
            color="rgba(255,255,255,0.6)"
            :label="$t2(lang, options.info.cn_source, options.info.en_source)"
          />
          <icon-text-time-mobile
            class="item"
            color="rgba(255,255,255,0.6)"
            :dark="true"
            :label="timeFormat(options.info.addtime, 'yyyy/mm/dd hh:MM')"
          />
        </div>
        <div class="btns">
          <icon-text-column-mobile
            color="rgba(255,255,255,0.6)"
            icon="views.png"
            :text="options.info.click"
          />
          <icon-text-zan-mobile
            :id="options.info.id"
            class="item"
            color="rgba(255,255,255,0.6)"
            :dark="true"
            font-size="20px"
            :label="$t('50048')"
            :set-zan="setZan"
            :type="4"
            width="32px"
            :zan="parseInt(options.info.is_zan) === 1"
          />
          <icon-text-collect-mobile
            :id="options.info.id"
            class="item"
            color="rgba(255,255,255,0.6)"
            font-size="20px"
            :label="$t('50024')"
            :set-collect="setCollect"
            :shoucang="parseInt(options.info.is_cang) === 1"
            :type="6"
            width="32px"
          />
          <icon-text-column-mobile
            color="rgba(255,255,255,0.6)"
            icon="share.png"
            :text="$t('50016')"
            type="share"
          />
        </div>
      </div>
      <split-line-mobile
        bg-color="rgba(255,255,255,0.2)"
        height="1px"
        margin-bottom="30px"
        margin-top="30px"
      />
      <text-content-mobile
        :content="$t2(lang, options.info.content, options.info.en_content)"
        title=""
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import IconTextFromMobile from "../../../components/Mobile/common/IconText/from";
import IconTextTimeMobile from "../../../components/Mobile/common/IconText/time";
import SplitLineMobile from "../../../components/Mobile/common/SplitLine";
import TextContentMobile from "../../../components/Mobile/common/TextContent";
import IconTextColumnMobile from "../../../components/Mobile/common/IconTextColumn";
import IconTextZanMobile from "../../../components/Mobile/common/IconText/zan";
import IconTextCollectMobile from "../../../components/Mobile/common/IconText/collect";

export default {
  name: 'NewsInfoMobile',
  components: {
    IconTextCollectMobile,
    IconTextZanMobile,
    TextContentMobile,
    SplitLineMobile,
    IconTextTimeMobile, IconTextFromMobile, IconTextColumnMobile,  TopNavMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    setZan: {
      type: Function,
      default: () => () => {}
    },
  },
  data(){
    return {
      backurl: ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    this.backurl = this.$route.meta.fromUrl === '/' ? '/news' : this.$route.meta.fromUrl
  },
  mounted() {
    this.backurl = this.$route.meta.fromUrl === '/' ? '/news' : this.$route.meta.fromUrl
  },
}
</script>

<style scoped lang="scss">
.news-info-mobile {
  .title{
    font-size: 36px;
    font-weight: 500;
    color: #fff;
    line-height: 48px;
  }
  .pic{
    width: 100%;
    text-align: center;
    img{
      width: 100%;
    }
  }
  .btn-view{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .other{
      margin-top: 24px;
      width: 50%;
      flex: 1;
      .item{
        margin-top: 12px;
      }
    }
    .btns{
      width: 50%;
      flex: 1;
      margin-top: 32px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
}
</style>
