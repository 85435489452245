<template>
  <div
    class="icon-text-card-del-mobile"
    @click.stop="actCard"
  >
    <div
      class="icon"
      :class="(shoucang ? `on` : ``)"
      :style="{width: width, height: width}"
    ></div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextCardDelMobile',
  props: {
    label: {
      type: [String, Number],
      default: () => ''
    },
    color: {
      type: String,
      default: () => '#666'
    },
    width: {
      type: String,
      default: () => '40px'
    },
    fontSize: {
      type: String,
      default: () => '20px'
    },
    shoucang: {
      type: Boolean,
      default: () => false
    },
    type: {
      type: Number,
      default: () => 1
    },
    icontype: {
      type: String,
      default: () => ''
    },
    id: {
      type: [String, Number],
      default: () => 1
    },
    setCollect:{
      type: Function,
      default: () => () => {}
    },
    refresh:{
      type: Function,
      default: () => () => {}
    },
    click:{
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    actCard(){
      const params = {
        id: this.id
      }
      this.$u.api.ajaxs.actCard(params).then(res => {
          this.refresh()
      })
    },
  }

}
</script>

<style scoped lang="scss">
.icon-text-card-del-mobile{
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .icon{
    width: 40px;
    height: 40px;
    background: url("../../../../assets/images/del.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
