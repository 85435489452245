<template>
  <div class="bbs-index-pc container">
    <div
      v-show="searchBarFixed"
      class="searchBarZhanwei"
    ></div>
    <div style="height: 82px" v-if="searchBarFixed"></div>
    <div
      id="searchBar"
      class="fixed-search"
      :class="searchBarFixed ? 'fixed' : ''"
    >
      <div
        class="main-box"
        :style="{paddingBottom: '20px' }"
      >
        <search-view-pc
          :change-key="changeKey"
          :search="search"
          :show-back="false"
        />
        <bbs-class-pc
          v-if="!searchBarFixed"
          :change-child-type-id="changeChildTypeId"
          :change-type-id="changeTypeId"
          :changeYear="changeYear"
          :child-type-id="options.childTypeId"
          :child-type-list="options.childTypeList"
          :type-id="options.typeId"
          :year="options.year"
          :type-list="options.typeList"
        />
      </div>
    </div>
    <div
      v-if="false"
      class="main-box bbs-date"
    >
      <div class="time-line">
        <template v-for="(res, index) in options.date">
          <btn-big
            :key="index"
            class="item"
            :label="res.zh"
            :on="options.data.dateid === res.id"
            @click="() => changeDate(res.id)"
          />
        </template>
      </div>
    </div>
    <div class="main-box min-height">
      <empty-pc
        v-if="!options.loading && parseInt(options.data.total) === 0"
        description="空空如也"
      />
      <bbs-list-pc
        :change-page="changePage"
        :data="options.data"
      />
    </div>
  </div>
</template>

<script>

import SearchViewPc from "@/components/Pc/common/SearchView";
import BbsClassPc from "@/components/Pc/Bbs/BbsClass";
import BbsListPc from "@/components/Pc/Bbs/BbsList";
import BtnBig from "@/components/Pc/common/BtnBig";
import EmptyPc from "@/components/Pc/common/Empty";
export default {
  name: 'BbsIndexPc',
  components: {EmptyPc, BtnBig, BbsListPc, BbsClassPc, SearchViewPc},
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    changeChildTypeId: {
      type: Function,
      default: () => {return () => {}}
    },
    changeTypeId: {
      type: Function,
      default: () => {return () => {}}
    },
    changePage: {
      type: Function,
      default: () => {return () => {}}
    },
    changeKey: {
      type: Function,
      default: () => {return () => {}}
    },
    changeYear: {
      type: Function,
      default: () => {return () => {}}
    },
    search: {
      type: Function,
      default: () => {return () => {}}
    },
    changeDate: {
      type: Function,
      default: () => {return () => {}}
    },
  },
  data(){
    return {
      searchBarFixed: false
    }
  },
  activated() {
    window.addEventListener('scroll', this.handleScroll,true)
  },
  methods: {
    handleScroll(){
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      var page = document.querySelector('#app')
      if(page && document.querySelector('#searchBar')){
        scrollTop = page.scrollTop
        var offsetTop = document.querySelector('#searchBar').offsetTop
        if (scrollTop > offsetTop) {
          this.searchBarFixed = true
        } else {
          this.searchBarFixed = false
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.bbs-index-pc{
  .min-height{
    min-height: 800px;
  }
  .bbs-date{
    margin-bottom: 0;
    text-align: center;
    .time-line{
      .item{
        margin-right: 10px;
      }
    }
  }
  .searchBarZhanwei{
    height: 0;
  }
  .fixed-search{
    width: 100%;
    z-index: 99;
    &.fixed{
      position: fixed;
      top: 0;
      left: 0;
      .main-box{
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;
        box-shadow: 0 3px 3px 0 rgba(0,0,0,0.1);
      }
    }
  }
}
</style>
