<template>
  <div class="video-list-mobile topic-list-swiper">
    <swiper
      :options="swiperOptions"
    >
      <swiper-slide
        v-for="(res, index) in list"
        :key="index"
        class="swiper-item-pic"
      >
        <video-item-mobile
          :key="index"
          :info="res"
          @click="showPlayer(res.videourl, res.id)"
        />
      </swiper-slide>
    </swiper>
    <div
      class="swiper-page"
    >
      <div class="swiper-pagination-videos"></div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import VideoItemMobile from "./item";
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import '@/assets/swiper.css'

export default {
  name: 'VideoListMobile',
  components: {VideoItemMobile, Swiper, SwiperSlide},
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return {
      swiperOptions: {
        spaceBetween: 5,
        slidesPerView: 3,
        slidesPerColumn: 1,
        slidesPerGroup: 3,
        slidesPerColumnFill: 'row',
        pagination: {
          el: ".swiper-pagination-videos",
          clickable: true,
        },
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    showPlayer(videoUrl, id) {
      this.$u.api.ajaxs.actLive({t: 1, is_value: id});
      this.$bus.$emit('showPlayer', videoUrl)
    },
  }
}
</script>

<style scoped lang="scss">
.video-list-mobile {
  overflow-x: scroll;
  justify-content: flex-start;
  .list{
    display: flex;
    flex-wrap: nowrap;
  }
  .swiper-pagination-videos{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
}
</style>
