<template>
  <div class="exhibition-productinfo-default">
    <exhibition-productinfo-pc
      v-if="browser===1"
      :options="options"
      :set-collect="setCollect"
      :set-zan="setZan"
    />
    <exhibition-product-mobile
      v-if="browser===2"
      :options="options"
      :set-collect="setCollect"
      :set-zan="setZan"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import ExhibitionProductinfoPc from "@/views/Pc/Exhibition/productinfo";
import ExhibitionProductMobile from "@/views/Mobile/Exhibition/productinfo";

export default {
  name: 'ExhibitionProductinfoDefault',
  components: {ExhibitionProductMobile, ExhibitionProductinfoPc},
  data(){
    return {
      options: {
        id: 0,
        companyid: 0,
        info: {}
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  mounted() {
    this.options.id = this.$route.query.id
    this.options.companyid = this.$route.query.companyid
    this.init()
  },
  methods: {
    init(){
      const params = {
        id: this.options.id,
        companyid: this.options.companyid,
        share: this.$route.query.share
      }
      this.$u.api.exhibitions.getProductDetail(params).then(res => {
        if(res.status === 1){
          this.options.info = res.data
          this.setShare(res)
        }
      })
    },
    setShare(res){
      this.getWxConfig(this.$t2(this.lang, res.data.productRow.productname, res.data.productRow.productnameen), this.$t2(this.lang,  res.data.zhanRow.companyname, res.data.zhanRow.companynameen), res.data.pic)
    },
    setCollect(v) {
      this.options.info.productRow.is_cang = v
      this.$forceUpdate()
    },
    setZan(v, val) {
      this.options.info.productRow.is_zan = v
      this.options.info.productRow.zan = val
      this.$forceUpdate()
    },
  }

}
</script>

<style scoped lang="scss">
.exhibition-productinfo-default{

}
</style>
