<template>
  <a
    class="btn-big-mobile"
    :class="on ? `on` : ``"
    :href="link ? link : `javascript: void(0)`"
    :style="{background: inactiveBg, color: inactiveColor}"
    :target="link ? `_blank` : ``"
    @click="$emit('click')"
  >
    {{ label }}
    <p class="sub">{{ sub }}</p>
  </a>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'BtnBigMobile',
  props:{
    label: {
      type:String,
      default: () => ''
    },
    link: {
      type:String,
      default: () => ''
    },
    sub: {
      type:String,
      default: () => ''
    },
    on: {
      type:Boolean,
      default: () => false
    },
    inactiveBg: {
      type: String,
      default: () => 'linear-gradient(223deg, #fcede9, #fdf4f0)'
    },
    inactiveColor: {
      type: String,
      default: () => '#fff'
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.btn-big-mobile{
  flex: 1;
  margin-left: 40px;
  text-align: center;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  font-weight: 500;
  background: linear-gradient(223deg, #FF4F1A, #FF9B61)!important;
  padding: 0 32px;
  color: #fff;
  font-size: 28px;
  vertical-align: top;
  .sub{
    font-size: 20px;
    color: #fff;
  }
  &.on{
    background: linear-gradient(223deg, #FC501E, #FA8958)!important;
    color: #fff;
  }
  &.in{
    background: linear-gradient(223deg, #faeee9, #fae9e4)!important;
    color: #EA4C1E;
  }
  &.default{
    background: rgba(204, 204, 204, 0.4)!important;
    color: #989898;
  }
}
</style>
