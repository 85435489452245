<template>
  <div class="news-swiper-mobile container">
    <div class="swiper-view">
      <swiper
        :options="swiperOptionsTop"
      >
        <swiper-slide
          v-for="(res, index) in options.swiper"
          :key="index"
        >
          <div
            class="swiper-img"
            :style="{background: `url(`+res.pic+`)`}"
            @click="navigateTo('/news/info?id='+res.id)"
          >
            <p class="line-1">
              {{ res.title }}
            </p>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-page-top">
        <div class="swiper-pagination2"></div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
// import swiper module styles
import '@/assets/swiper.css'
export default {
  name: 'NewsSwiperMobile',
  components: {Swiper, SwiperSlide},
  directives: {
    swiper: directive
  },
  props: {
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  data(){
    return {
      swiperOptionsTop: {
        pagination: {
          el: ".swiper-pagination2",
              clickable: true,
        },
        autoplay: {
          delay: 5000,
        },
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.news-swiper-mobile {
  .swiper-view{
    position: relative;
    p{
      position: absolute;
      bottom: 0;
      left: 0;
      background: rgba(0,0,0,0.3);
      color: #fff;
      margin: 0;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 80px;
      line-height: 80px;
      font-size: 24px;
      padding: 0 10px;
    }
    .swiper-img{
      width: 100%;
      height: 500px;
      overflow: hidden;
      background-size: cover!important;
      background-position: center!important;
      border-radius: 12px;
    }
    .swiper-item-pic{
      .swiper-item-pic-div{
        padding: 2px;
        border: 1px solid #EFEFEF;
        margin: 2px;
        background: #fff;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .swiper-page-top{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
