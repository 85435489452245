<template>
  <div class="container">
    <div class="about-link-mobile">
      <div class="title">
        {{ $t('28010') }}
      </div>
      <div class="about-link-list">
        <template v-for="(res, index) in list">
          <about-link-item-mobile
            :key="index"
            :info="res"
            @click="showQrcodeModal(res.pic_ewm)"
          />
        </template>
      </div>
    </div>
    <qrcode-modal-mobile
      v-if="qrocodeMdalShow"
      :close-qrcode-modal="closeQrcodeModal"
      :src="src"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import AboutLinkItemMobile from "./item";
import QrcodeModalMobile from "../../common/QrcodeModal";

export default {
  name: 'AboutLinkMobile',
  components: {QrcodeModalMobile, AboutLinkItemMobile},
  data(){
    return {
      list: [],
      qrocodeMdalShow: false,
      src: ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      this.$u.api.commons.getNewAds({typeId: 2}).then(res => {
        if(res.status === 1){
          this.list = res.data
        }
      })
    },
    showQrcodeModal(src){
      this.src = src
      this.qrocodeMdalShow = true
    },
    closeQrcodeModal(){
      this.qrocodeMdalShow = false
    }
  },
}
</script>

<style scoped lang="scss">
.about-link-mobile {
  margin-bottom: 60px;
  .title{
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    line-height: 32px;
  }
  .about-link-list{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
