<template>
  <div class="supply-demand-demand-modal-mobile">
    <div class="cell">
      <div class="box">
        <div class="title-line clearfix">
          <div class="title">
            <div class="text">
              {{ $t2(lang, info.title, info.title_en) }}
            </div>
          </div>
        </div>
        <div
          v-if="(info.catename && info.catename.length > 0) || (!info.is_follow && from !== 'my')"
          class="tags-line"
        >
          <div class="tags">
            <template v-for="(res, index) in info.catename">
              <tags-item-mobile
                :key="index"
                class="item"
                :dark="true"
                :info="res"
              />
            </template>
          </div>
          <div
            v-if="!info.is_follow && from !== 'my'"
            class="info"
            @click="contactShow=true"
          >
            {{ $t('24003') }} >
          </div>
        </div>
        <split-line-pc
          bg-color="#f5f5f5"
          height="0"
          margin-top="0"
        />
        <div class="info-main">
          <div
            v-if="contactShow"
            class="contact-main"
          >
            <div class="cell-list">
              <div class="label">
                姓名：
              </div>
              <div class="text">
                {{ contactInfo.name }}
              </div>
            </div>
            <div class="cell-list">
              <div class="label">
                邮箱：
              </div>
              <div class="text">
                {{ contactInfo.email }}
              </div>
            </div>
            <div class="cell-list">
              <div class="label">
                公司：
              </div>
              <div class="text">
                {{ contactInfo.company }}
              </div>
            </div>
            <div
              class="btns"
            >
              <btn-custom-mobile
                :background="parseInt(info.is_follow) === 0 ? `linear-gradient(223deg, rgba(255, 26, 44, 0.9), rgba(255, 155, 97, 0.8))` : `#eee`"
                class="btn"
                :color="parseInt(info.is_follow) === 0 ? `#fff` : `#aaa`"
                :label="parseInt(info.is_follow) === 0 ? `添加到我的供需` : `已添加`"
                @click="guanzhu"
              />
            </div>
          </div>
          <text-content-mobile
            class="content"
            :content="$t2(lang, info.content, info.content_en)"
            margin-bottom="0"
            title=""
          />
        </div>
        <div class="close-btn">
          <btn-close-mobile
            size="68"
            @click="hideDemandModal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import SplitLinePc from "@/components/Pc/common/SplitLine";
import TextContentMobile from "../../common/TextContent";
import BtnCloseMobile from "../../common/BtnCloseMobile";
import TagsItemMobile from "../../common/TagsItem";
import BtnCustomMobile from "../../common/BtnCustom";

export default {
  name: 'SupplyDemandDemandModalMobile',
  components: {
    BtnCustomMobile,
    TagsItemMobile,
    BtnCloseMobile,
    TextContentMobile, SplitLinePc},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    hideDemandModal: {
      type: Function,
      default: () => () => {}
    },
    showDemandContactModalInfo: {
      type: Function,
      default: () => () => {}
    },
    from: {
      type: String,
      default: () => ''
    }
  },
  data(){
    return {
      contactShow: false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    contactInfo() {
      return JSON.parse(this.htmlspecialchars_decode(this.info.contact))
    }
  },
  mounted() {
    if(this.from === 'user' || this.info.is_follow){
      this.contactShow=true
    }
    if(this.from === 'my'){
      this.contactShow = false
    }
  },
  methods: {
    guanzhu() {
      const params = {
        sid: this.info.id,
      }
      this.$u.api.supplydemands.actfollow(params).then(res => {
        if(res.status === 1){
          this.info.is_follow = res.data.is_follow
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.supply-demand-demand-modal-mobile{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  background: rgba(0,0,0,0.7);
  display: table;
  .cell{
    display: table-cell;
    vertical-align: middle;
    .close-btn{
      width: 68px;
      height: 68px;
      position: absolute;
      left: 50%;
      margin-left: -34px;
      bottom: -100px;
    }
    .box{
      position: relative;
      width: 686px;
      background: rgba(26, 26, 26, 1);
      margin: 0 auto;
      border-radius: 12px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02);
      .title-line{
        padding: 24px 32px 16px;
        .title{
          float: left;
          display: inline-block;
          font-size: 32px;
          font-weight: 600;
          color: #fff;
          line-height: 40px;
          .text{
            display: inline-block;
            vertical-align: middle;
          }
          .pic{
            margin-left: 4px;
            display: inline-block;
            width: 28px;
            height: 28px;
            vertical-align: middle;
          }
        }
        .guanzhu-btn{
          float: right;
        }
      }
      .tags-line{
        padding: 10px 32px 16px;
        margin-bottom: 20px;
        height: 28px;
        line-height: 28px;
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tags{
          float: left;
          .item{
            margin-right: 4px;
          }
        }
        .info{
          font-size: 20px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.6);
          line-height: 28px;
        }
      }
      .info-main{
        padding: 0 32px 24px;
        .contact-main{
          margin-top: 10px;
          padding: 10px;
          position: relative;
          .cell-list{
            display: table-row;
            height: 36px;
            line-height: 36px;
            font-size: 24px;
            font-weight: 500;
            .label{
              display: table-cell;
              color: rgba(255,255,255,0.8);
              width: 90px;
            }
            .text{
              display: table-cell;
              color: rgba(255,255,255,0.8);
            }
          }
          .btns{
            width: 100%;
            text-align: right;
            margin-top: 10px;
          }
        }

        .company-line{
          height: 28px;
          line-height: 28px;
          margin-top: 12px;
          .company{
            float: left;
            .pic{
              display: inline-block;
              width: 28px;
              height: 28px;
              border-radius: 28px;
              overflow: hidden;
              vertical-align: middle;
            }
            .name{
              margin-left: 5px;
              font-size: 20px;
              color: #999;
              display: inline-block;
              vertical-align: middle;
            }
          }
          .info{
            float: right;
          }
        }
        .content{
          width: 100%;
          max-height: 700px;
          overflow-y: scroll;
        }
      }
    }
  }
}
</style>
