<template>
  <div class="filter-view">
    <div
      class="supply-demand-filter-mobile"
      @click="filterBoxShow=true"
    >
      <div class="icon-left">
        <img src="../../../../assets/images/mobile/filter.png" />
      </div>
      <div class="title">
        {{ $t('50026') }}
      </div>
      <div class="text">
        {{ typename[0] && $t2(lang, typename[0].name, typename[0].ename) }}
      </div>
      <div class="text">
        {{ options.data.key }}
      </div>
      <div class="sanjiao"></div>
    </div>
    <supply-demand-filter-box-mobile
      v-if="filterBoxShow"
      :change-key="changeKey"
      :change-type-id="changeTypeId"
      :close-filter-box="closeFilterBox"
      :options="options"
      :search="search"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import SupplyDemandFilterBoxMobile from "../SupplyDemandFilterBox";

export default {
  name: 'SupplyDemandFilterMobile',
  components: {SupplyDemandFilterBoxMobile},
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    changeTypeId: {
      type: Function,
      default: () => () => {}
    },
    changeKey: {
      type: Function,
      default: () => () => {}
    },
    search: {
      type: Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      filterBoxShow: false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    typename(){
      return this.options.typeList.filter(res => res.id === this.options.typeId)
    }
  },
  methods: {
    closeFilterBox(){
      this.filterBoxShow = false
    }
  }
}
</script>

<style scoped lang="scss">
.filter-view{
  flex: 1;
}
.supply-demand-filter-mobile {
  flex: 1;
  height: 80px;
  background: rgba(0,0,0,0);
  border-radius: 12px;
  box-shadow:  0 2px 10px 0 rgba(0,0,0,0.05);
  border: 1px solid rgba(255,255,255,0.4);
  display: flex;
  align-items: center;
  position: relative;
  .icon-left{
    width: 28px;
    height: 28px;
    margin-left: 20px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .title{
    margin-left: 20px;
    height: 36px;
    font-size: 28px;
    font-weight: 500;
    color: #fff;
    line-height: 36px;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
  }
  .text{
    margin-left: 8px;
    height: 36px;
    font-size: 24px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6000);
    line-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    margin-top: 2px;
  }
  .sanjiao{
    width: 14px;
    height: 14px;
    background: url("../../../../assets/images/mobile/filter_jiao.png");
    background-size: 100% 100%;
    position: absolute;
    right: 6px;
    bottom: 6px;
    display: block;
  }
}
</style>
