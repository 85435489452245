<template>
  <div
    class="icon-text-line-mobile clearfix"
    @click="$emit('click')"
  >
    <div class="icon">
      <img
        :src="require('../../../../assets/images/mobile/'+icon)"
        :style="{width:width, height:width}"
      />
    </div>
    <div
      class="text"
      :style="{color: color, fontSize: fontSize, textShadow: textShadow}"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextLineMobile',
  props: {
    icon: {
      type: String,
      default: () => ''
    },
    text: {
      type: [String,Number],
      default: () => ''
    },
    color: {
      type: String,
      default: () => '#fff'
    },
    width: {
      type: String,
      default: () => '22px'
    },
    fontSize:{
      type:String,
      default: ()=> '22px'
    },
    textShadow: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }
}
</script>

<style scoped lang="scss">
.icon-text-line-mobile{
  .icon{
    display: inline;
    margin-right: 6px;
    cursor: pointer;
    float: left;
    img{
      width: 22px;
      height: 22px;
      vertical-align: middle;
    }
  }
  .text{
    float: left;
    font-size: 22px;
    font-weight: 400;
    color: #666666;
    line-height: 32px;
    cursor: pointer;
    width:calc(100% - 41px)
  }
  &:hover{
    .text {
      color: #EA4C1E!important;
    }
  }
}
</style>
