<template>
  <div
    class="icon-text-from-mobile"
    :class="{dark}"
  >
    <div class="icon"></div>
    <div class="label line-1">
      {{ label }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextFromMobile',
  props: {
    label: {
      type: String,
      default: () => ''
    },
    dark: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.icon-text-from-mobile{
  height: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .icon{
    width: 22px;
    height: 22px;
    background: url("../../../../assets/images/mobile/from.png") no-repeat;
    background-size: 100% 100%;
    float: left;
  }
  &.dark{
    .icon{
      background: url("../../../../assets/images/mobile/location.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .label{
    flex: 1;
    height: 28px;
    color: rgba(255,255,255,0.6);
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    margin-left: 8px;
  }
}
</style>
