<template>
  <div
    class="topic-item-pc"
    @click="navigateTo('/topic?typeid='+info.id)"
  >
    <div class="info">
      <div class="title line-1">
        <div v-html="name"></div>
      </div>
      <div class="desc summary line-2">
        <div v-html="content">
        </div>
        <div class="more">
          {{ $t('50017') }}>>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'TopicItemPc',
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    keyword: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    name(){
      return this.replaceSearch(this.lang, this.keyword, this.info.name, this.info.ename)
    },
    content(){
      return this.replaceSearch(this.lang, this.keyword, this.info.ztjs_content_cn, this.info.ztjs_content_en)
    }
  },
}
</script>

<style scoped lang="scss">
.topic-item-pc {
  background: #fafafa;
  margin-bottom: 16px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  &:hover{
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-3px);
    .pic{
      div{
        transition: all 0.4s ease-in-out;
        transform: scale(1.1);
      }
    }
  }
  .info{
    position: relative;
    .title{
      font-size: 20px;
      line-height: 24px;
      margin-top: 4px;
    }
    .desc{
      font-size: 14px;
      font-weight: 500;
      color: #999999;
      line-height: 20px;
      height: 40px;
      width: 100%;
      margin-top: 12px;
      .more{
        position: absolute;
        font-size: 14px;
        font-weight: 500;
        color: rgba(234, 76, 30, 0.7);
        line-height: 20px;
        right: 0;
        background: #fafafa;
        bottom: 0;
        &:before{
          content: ' ';
          width: 40px;
          height: 20px;
          background: linear-gradient(to right, rgba(250,250,250,0), rgba(250,250,250,0.6), #fafafa);
          position: absolute;
          left: -40px;
          top:0;
        }
      }
    }
  }
}
</style>
