<template>
  <div
    class="live-history-item-mobile"
    :class="parseInt(options.liveid) === parseInt(info.id) ? `on` : ``"
    @click="changeLiveId(info.id)"
  >
    <div class="pic">
      <div
        v-if="parseInt(info.state) !== 2"
        class="status"
        :class="`status`+info.state"
      >
        {{ $t(videoStatus) }}
      </div>
      <div
        v-if="parseInt(info.state) === 1 || parseInt(info.state) === 3"
        class="play-btn"
      ></div>
      <img :src="info.pic" />
    </div>
    <div class="info">
      <div class="title line-2">
        {{ info.title }}
      </div>
      <icon-time-line-moblie
        class="item"
        font-size="22px"
        icon="icon_clock.png"
        size="22"
        :text="livetime"
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import IconTimeLineMoblie from "../../common/IconTimeLine";
import moment from 'moment'

export default {
  name: 'LiveHistoryItemMobile',
  components: {IconTimeLineMoblie},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    info: {
      type: Object,
      default: () => {
      }
    },
    changeLiveId: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    livetime(){
      return moment.unix(this.info.live_start_time).format('YYYY/MM/DD HH:mm') + ' ~ ' + moment.unix(this.info.live_end_time).format('HH:mm')
    },
    videoStatus(){
      switch(parseInt(this.info.state)){
        case 0:
          //未开始
          return '21013'
          break;
        case 1:
          //直播中
          return '21012'
          break;
        case 2:
          //已结束
          return '21016'
          break;
        case 3:
          //回放中
          return '21014'
          break;
      }
      return ''
    }
  },
}
</script>

<style scoped lang="scss">
.live-history-item-mobile {
  width: 100%;
  height: 153px;
  background: rgba(255,255,255,0.1);
  margin-right: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  display: inline-block;
  border-radius: 12px;

  &:nth-child(2n){
    margin-right: 0;
  }
  .pic{
    width: 283px;
    height: 153px;
    float: left;
    position: relative;
    .play-btn{
      width: 60px;
      height: 60px;
      background: url("../../../../assets/images/play-btn.png");
      background-size: 100% 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -30px;
      margin-top: -30px;
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
  .info{
    padding: 16px 16px 16px 303px;
    height: 160px;
    cursor: pointer;
    .title{
      font-size: 28px;
      color: #fff;
      line-height: 38px;
      height: 76px
    }
    .item{
      margin-top: 6px;
    }
  }
  &.on{
    .pic{
      .play-btn{
        display: none;
      }
    }
    .info{
      .title{
        font-weight: bold;
        color: #EA4C1E
      }
    }
  }
  .status{
    position: absolute;
    left: 8px;
    top: 8px;
    width: 76px;
    height: 36px;
    background: #1BCC71;
    border-radius: 2px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    line-height: 36px;
  }
  .status0{
    background: #1BCC71;
  }
  .status1{
    background: #EA4C1E;
  }
  .status2{
    background: #1BCC71;
  }
  .status3{
    background: #1E8FEA;
  }
}
</style>
