<template>
  <div class="news-list-exhibition-thumb1-pc">
    <div class="container">
      <div class="header-titile">
        资讯热点<span>Information Hotspot</span>
      </div>
      <div class="list">
        <template v-for="(res, index) in list">
          <news-item-exhibition-thumb1-pc
            :key="index"
            :info="res"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import NewsItemExhibitionThumb1Pc from "./NewsItemExhibitionThumb1";

export default {
  name: 'NewsListExhibitionThumb1Pc',
  components: {NewsItemExhibitionThumb1Pc},
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.news-list-exhibition-thumb1-pc {
  .header-titile{
    padding: 0 40px;
    display: inline-block;
    height: 56px;
    line-height: 56px;
    text-align: center;
    font-size: 24px;
    color: #fff;
    background: #000;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    span{
      margin-left: 20px;
    }
  }
  .list{
    background: #fff;
    padding: 40px 40px 0 40px;
    border-radius: 0 0 28px 28px;
    max-height: 914px;
    overflow-y: auto;
  }
}
</style>
