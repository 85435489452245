<template>
  <div class="case-index-mobile">
    <top-nav-mobile>
      <span class="nav-title">{{ $t('10008') }}</span>
    </top-nav-mobile>
    <div class="container">
      <div class="news-tabs-mobile">
        <news-tabs-item-mobile
            :info="{name: '展商动态', ename: '展商动态'}"
            :on="options.childSubTypeId === 0"
            @click="changeSubTypeId(0)"
        />
        <news-tabs-item-mobile
            :info="{name: '引例场', ename: '展商动态'}"
            :on="options.childSubTypeId === 39"
            @click="changeSubTypeId(39)"
        />
      </div>
      <template v-if="options.childSubTypeId === 0">
        <div class="filter-view">
            <case-filter-mobile
              :change-child-type-id="changeChildTypeId"
              :change-key="changeKey"
              :change-type-id="changeTypeId"
              :options="options"
              :search="search"
            />
        </div>
        <case-list-mobile
          :change-page="changePage"
          :data="options.data"
        />
      </template>
      <template v-if="options.childSubTypeId === 39">
        <news-list-mobile
            :change-page="changePage"
            :data="options.data"
            :options="options"
        />
      </template>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import NewsListMobile from "../../../components/Mobile/News/NewsList";
import CaseFilterMobile from "../../../components/Mobile/Case/CaseFilter";
import CaseListMobile from "../../../components/Mobile/Case/CaseList";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import SearchBtnMobile from "../../../components/Mobile/common/SearchBtn";
import NewsTabsItemMobile from "../../../components/Mobile/News/NewsTabs/item.vue";

export default {
  name: 'CaseIndexMobile',
  components: {SearchBtnMobile, TopNavMobile, CaseListMobile, CaseFilterMobile, NewsTabsItemMobile, NewsListMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeChildTypeId: {
      type: Function,
      default: () => () => {}
    },
    changeTypeId: {
      type: Function,
      default: () => () => {}
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    changeKey: {
      type: Function,
      default: () => () => {}
    },
    changeNewsPage: {
      type: Function,
      default: () => () => {}
    },
    changeSubTypeId: {
      type: Function,
      default: () => () => {}
    },
    search: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.case-index-mobile {
  .filter-view{
    //margin-top: 20px;
  }

  .news-tabs-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 30px;
  }
}
</style>
