<template>
  <div class="news-info-pc">
    <div class="container">
      <div class="main-box">
        <back-btn-pc backurl="/news" />
        <div class="title">
          {{ $t2(lang, options.info.title, options.info.en_title) }}
        </div>
        <div class="desc clearfix">
          <div class="left">
            <icon-text-from :label="$t2(lang, options.info.cn_source, options.info.en_source)" />
            <icon-text-time :label="timeFormat(options.info.addtime, 'yyyy/mm/dd hh:MM')" />
          </div>
          <div class="right">
            <icon-text-viewnum
              class="btn-item"
              :label="options.info.click"
            />
            <icon-text-zan
              :id="options.info.id"
              class="btn-item"
              :label="options.info.zan"
              :set-zan="setZan"
              :type="4"
              :zan="parseInt(options.info.is_zan) >= 1"
            />
            <icon-text-collect
              :id="options.info.id"
              class="btn-item"
              :label="this.$t('50024')"
              :set-collect="setCollect"
              :shoucang="parseInt(options.info.is_cang) === 1"
              :type="6"
            />
            <icon-text-share
              class="btn-item"
              label="分享"
            />
          </div>
        </div>
        <split-line-pc
          bg-color="rgba(255, 255, 255, 0.1)"
          height="1px"
          margin-bottom="20px"
          margin-top="20px"
        />
        <div class="pic">
          <img :src="options.info.pic" />
        </div>
        <text-content-pc :content="options.info.content" />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import BackBtnPc from "@/components/Pc/common/BackBtn";
import IconTextFrom from "@/components/Pc/common/IconText/from";
import IconTextTime from "@/components/Pc/common/IconText/time";
import TextContentPc from "@/components/Pc/common/TextContent";
import SplitLinePc from "@/components/Pc/common/SplitLine";
import IconTextViewnum from "../../../components/Pc/common/IconText/viewnum";
import IconTextZan from "../../../components/Pc/common/IconText/zan";
import IconTextCollect from "../../../components/Pc/common/IconText/collect";
import IconTextShare from "@/components/Pc/common/IconText/share";

export default {
  name: 'NewsInfoPc',
  components: {
    IconTextCollect,
    IconTextZan,
    IconTextViewnum, SplitLinePc, TextContentPc, IconTextShare, IconTextTime, IconTextFrom, BackBtnPc},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    setZan: {
      type: Function,
      default: () => () => {}
    },
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.news-info-pc {
  .title{
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    line-height: 28px;
  }
  .pic{
    width: 100%;
    text-align: center;
    img{
      max-width: 100%;
    }
  }
  .desc{
    margin-top: 20px;
    .left{
      float: left;
    }
    .right {
      float: right;
      .btn-item{
        margin-left: 30px;
      }
    }
  }
}
</style>
