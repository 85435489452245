<template>
  <div :class="isBg ? 'isbg' : 'isnobg'">
    <div
      class="tabs-case-item-pc"
      :class="on && `on`"
      :style="{fontSize: fontSize}"
      @click="click"
    >
      {{ label }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'TabsCaseItemPc',
  props: {
    label: {
      type: String,
      default: () => ''
    },
    fontSize: {
      type: String,
      default: () => '16px'
    },
    isBg: {
      type: Boolean,
      default: () => true
    },
    on: {
      type: Boolean,
      default: () => false
    },
    change: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    click(){
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
.isbg{
  display: inline-block;
  .tabs-case-item-pc{
    padding: 0 10px;
    font-size: 16px;
    color: rgba(0,0,0,0.5);
    font-weight: bold;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.04);
    margin-right: 12px;
    margin-bottom: 12px;
    height: 40px;
    line-height: 40px;
    min-width: 92px;
    text-align: center;

    &:hover, &.on{
      background: linear-gradient(223deg, #FF4F1A, #FF9B61);
      color: #fff;
    }
    &:hover, &.on{
      background: linear-gradient(223deg, #FF4F1A, #FF9B61);
      color: #fff;
    }
  }
}
.isnobg{
  display: inline-block;
  font-weight: 500;
  .tabs-case-item-pc{
    padding: 4px 10px;
    font-size: 16px;
    color: #666;
    cursor: pointer;
    &:hover, &.on{
      color: #EA4C1E;
    }
    &:hover, &.on{
      color: #EA4C1E;
    }
  }
}
</style>
