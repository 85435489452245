<template>
  <div
    class="topic-list-item-mobile"
    @click="navigateTo('/topic?typeid='+info.id)"
  >
    <div class="title line-1">
      <div v-html="name">
      </div>
    </div>
    <div class="summary line-2">
      <div v-html="content" />
      <div class="more">
        {{ $t('50017') }}>>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'TopicListItemMobile',
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    keyword: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    name(){
      return this.replaceSearch(this.lang, this.keyword, this.info.name, this.info.ename)
    },
    content(){
      return this.replaceSearch(this.lang, this.keyword, this.info.ztjs_content_cn, this.info.ztjs_content_en)
    }
  },
}
</script>

<style scoped lang="scss">
.topic-list-item-mobile {
  margin-bottom: 20px;
    .title {
      font-size: 32px;
      font-weight: 500;
      color: #fff;
      line-height: 44px;
      height: 44px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .summary {
      font-size: 22px;
      margin-top: 8px;
      font-weight: 500;
      color: #999999;
      position: relative;

      .more{
        position: absolute;
        font-size: 22px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.7);
        right: 0;
        bottom: 0;
        background: #333;
        &:before{
          content: ' ';
          width: 40px;
          height: 32px;
          background: linear-gradient(to right, rgba(0,0,0,0), #333);
          position: absolute;
          left: -40px;
          top:0;
        }
      }
  }
}
</style>
