<template>
  <div class="filter-box-mobile">
    <div class="filter-box">
      <div class="title-line">
        <div
          class="close-btn"
          @click="closeFilterBox"
        >
          <img src="../../../../assets/images/mobile/case_menu_close.png" />
        </div>
        <div class="title">
          筛选
        </div>
        <div> </div>
      </div>
      <div class="search-view">
        <search-view-mobile
          :close-search="closeSearch"
          :options="options"
          :search="goSearch"
          :search-view="searchView"
        />
        <div
          v-show="searchView"
          class="search-list"
        >
          <search-list-mobile
            :list="searchListData"
          />
        </div>
      </div>
      <div
        v-show="!searchView"
        class="menu-list"
      >
        <div class="menu-left">
          <ul>
            <template v-for="(res, index) in options.typeList">
              <li
                :key="index"
                class="line-1"
                :class="options.typeId === res.id ? `on` : ``"
                @click="changeTypeId(res.id); leftClick && closeFilterBox()"
              >
                {{ $t2(lang, res.typename, res.typename_en) }}
              </li>
            </template>
          </ul>
        </div>
        <div class="menu-right">
          <ul>
            <template v-for="(res, index) in options.childTypeList">
              <li
                :key="index"
                class="line-1"
                :class="options.childTypeId === res.id ? `on` : ``"
                @click="changeChildTypeId(res.id);closeFilterBox()"
              >
                {{ $t2(lang, res.typelistname, res.typelistname_en) }}
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import SearchViewMobile from "@/components/Mobile/common/SearchView";
import SearchListMobile from "../SearchView/searchList";

export default {
  name: 'FilterBoxMobile',
  components: {SearchListMobile, SearchViewMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeChildTypeId: {
      type: Function,
      default: () => () => {}
    },
    changeTypeId: {
      type: Function,
      default: () => () => {}
    },
    leftClick: {
      type: Boolean,
      default: () => true
    },
    closeFilterBox: {
      type: Function,
      default: () => () => {}
    },
    changeKey: {
      type: Function,
      default: () => () => {}
    },
    search: {
      type: Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      key: '',
      searchView: false,
      searchListData: []
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    goSearch(v){
        this.changeKey(v)
        this.search()
        this.closeFilterBox()
        // const params = {
        //   page: 1,
        //   key: v
        // }
        // this.$u.api.exhibitions.getLists(params).then(res => {
        //   if(res.status === 1){
        //     if(parseInt(res.data.totalNum) > 0){
        //       this.searchListData = res.data.lists
        //       this.searchView = true
        //     }else{
        //       this.toast('没有找到任何结果')
        //     }
        //   }
        // })
    },
    closeSearch(){
      this.searchView = false;
    }
  }
}
</script>

<style scoped lang="scss">
.filter-box-mobile {
  background: rgba(0,0,0,0.6);
  backdrop-filter: blur(1px);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100vh - 148px);
  z-index: 10;
  display: flex;
  align-items: flex-end;
  .filter-box{
    width: 100%;
    height: calc(100vh - 400px);
    background: #fff;
    display: flex;
    flex-direction: column;
    .title-line{
      padding: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .close-btn{
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 28px;
          height: 28px;
        }
      }
      .title{
        font-size: 32px;
        font-weight: 600;
        color: #000000;
        line-height: 36px;
      }
    }
    .search-view{
      margin-left: 36px;
      margin-right: 36px;
      margin-bottom: 40px;
    }
    .search-list{
      flex: 1;
      background: #fefefe;
      display: flex;
    }
    .menu-list{
      flex: 1;
      background: #fefefe;
      display: flex;
      height: calc(100vh - 610px);
      .menu-left{
        width: 50%;
        height: 100%;
        overflow-y: auto;
        flex: 1;
        ul{
          li{
            font-size: 28px;
            font-weight: bold;
            color: #333333;
            height: 88px;
            line-height: 88px;
            padding-left: 32px;
            &.on{
              color: #EA4C1E;
              border-left: 4px solid #EA4C1E;
              padding-left: 28px;
            }
          }
        }
      }
      .menu-right{
        width: 50%;
        background: #fafafa;
        height: 100%;
        overflow-y: auto;
        flex: 1;
        ul{
          li{
            font-size: 26px;
            font-weight: 400;
            color: #333333;
            height: 88px;
            line-height: 88px;
            padding-left: 32px;
            &.on{
              color: #EA4C1E;
            }
          }
        }
      }
    }
  }
}
</style>
