<template>
  <div class="topc-cover-mobile">
    <top-nav-mobile>
      <span class="nav-title">{{ $t('10009') }}</span>
    </top-nav-mobile>
    <div class="container">
      <div style="margin-top: 20px;">
        <div
          v-if="false"
          class="img-2"
          @click="navigateTo('/topic?typeid=15')"
        >
          <img src="../../../assets/images/mobile/zhuanti_index_1.png" />
        </div>
        <div
          class="img-2"
          @click="navigateTo('/topic?typeid=7')"
        >
          <img src="../../../assets/images/mobile/zhuanti_index_2.png" />
        </div>
        <div
          class="img-2"
          @click="navigateTo('/topic?typeid=9')"
        >
          <img src="../../../assets/images/mobile/zhuanti_index_3.png" />
        </div>
        <div
          v-if="false"
          class="img-2"
          @click="navigateTo('/topic?typeid=6')"
        >
          <img src="../../../assets/images/mobile/zhuanti_index_4.png" />
        </div>
        <div
          class="img-2"
          @click="navigateTo('/topic?typeid=3')"
        >
          <img src="../../../assets/images/mobile/zhuanti_index_5.png" />
        </div>
        <div
          class="img-2"
          @click="navigateTo('/topic?typeid=11')"
        >
          <img src="../../../assets/images/mobile/zhuanti_index_6.png" />
        </div>
        <div
          class="img-2"
          @click="navigateTo('/topic?typeid=13')"
        >
          <img src="../../../assets/images/mobile/zhuanti_index_7.png" />
        </div>
        <div
          v-if="false"
          class="img-2"
          @click="navigateTo('/topic?typeid=14')"
        >
          <img src="../../../assets/images/mobile/zhuanti_index_8.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopNavMobile from "../../../components/Mobile/common/TopNav";

export default {
  name: 'TopicCoverMobile',
  components: {TopNavMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
  },
  data(){
    return {

    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.topc-cover-mobile {
  .img-1{
    width: 100%;
    margin-bottom: 20px;
    img{
      width: 100%;
    }
  }
  .img-2{
    width: calc((100% - 20px ) / 2);
    margin-bottom: 20px;
    display: inline-block;
    margin-right: 20px;
    &:nth-child(2n){
      margin-right: 0;
    }
    img{
      width: 100%;
    }
  }
}
</style>
