<template>
  <div class="user-supply-my-list-pc">
    <div
      class="apply-btn"
      @click="navigateTo('/user/post_supply')"
    >
      +{{ $t('50012') }}
    </div>
    <div class="supply-list">
      <template v-for="(res, index) in options.data.list">
        <user-supply-my-list-item-pc
          :key="index"
          :hide-demand-modal="hideDemandModal"
          :info="res"
          :refresh="refresh"
          :show-demand-contact-modal-info="showDemandContactModalInfo"
          :show-demand-modal-info="showDemandModalInfo"
          :show-supply-product-lists="showSupplyProductLists"
        />
      </template>
      <page-common-pc
        :change-page="changePage"
        :data="options.data"
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import UserSupplyMyListItemPc from "./item";
import PageCommonPc from "../../common/PageCommon";

export default {
  name: 'UserSupplyMyListPc',
  components: { PageCommonPc, UserSupplyMyListItemPc},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changePage:{
      type: Function,
      default: () => () => {}
    },
    showSupplyProductLists:{
      type: Function,
      default: () => () => {}
    },
    refresh:{
      type: Function,
      default: () => () => {}
    },
    showDemandModalInfo:{
      type: Function,
      default: () => () => {}
    },
    showDemandContactModalInfo:{
      type: Function,
      default: () => () => {}
    },
    hideDemandContactModal:{
      type: Function,
      default: () => () => {}
    },
    hideDemandModal:{
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }
}
</script>

<style scoped lang="scss">
.user-supply-my-list-pc {
  .apply-btn{
    width: 100%;
    height: 40px;
    border: 1px dashed #CCCCCC;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #FC501E;
    cursor: pointer;
  }
}
</style>
