<template>
  <div class="search-list-index-mobile">
    <top-nav-mobile
      :is-back="true"
      :search="false"
      url="/search"
    >
      <span class="nav-title">{{ $t('50025') }}</span>
    </top-nav-mobile>
    <div class="container">
      <div class="search-form">
        <search-view-mobile
          class-name="search-index"
          :search="goSearch"
          :tab="true"
        />
      </div>
      <search-type-mobile
        :change-type="changeType"
        :options="options"
      />
      <div class="search-list">
        <exhibition-list-mobile
          v-if="options.typeCurrent==='展览'"
          :change-page="changePage"
          :data="options.data"
        />
        <exhibition-list-mobile
          v-if="options.typeCurrent==='产品'"
          :change-page="changePage"
          :data="options.data"
          :product="true"
        />
        <bbs-list-mobile
          v-if="options.typeCurrent==='论坛'"
          :change-page="changePage"
          :data="options.data"
        />
        <video-list-search-mobile
          v-if="options.typeCurrent==='视频'"
          :data="options.data"
        />
        <awards-list-mobile-list-mobile
          v-if="options.typeCurrent==='评奖'"
          :change-page="changePage"
          :data="options.data"
        />
        <news-list-mobile
          v-if="options.typeCurrent==='资讯'"
          :change-page="changePage"
          :data="options.data"
        />
        <activity-list-mobile
          v-if="options.typeCurrent==='活动'"
          :change-page="changePage"
          :data="options.data"
        />
        <case-list-mobile
          v-if="options.typeCurrent==='案例'"
          :change-page="changePage"
          :data="options.data"
        />
        <topic-list-mobile
          v-if="options.typeCurrent==='专题'"
          :change-page="changePage"
          :data="options.data"
        />
        <div
          v-if="options.empty"
          class="empty"
        >
          <img
            height="160"
            src="../../../assets/images/mobile/search_empty.png"
            width="160"
          />
          <h3>抱歉， 没有搜到您查询的内容</h3>
          <h5>请尝试搜索其他关键词搜索</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import BackBtnMobile from "../../../components/Mobile/common/BackBtn";
import LangBtnMobile from "../../../components/Mobile/common/LangBtn";
import SearchViewMobile from "../../../components/Mobile/common/SearchView";
import SearchTypeMobile from "../../../components/Mobile/SearchType/SearchType";
import ExhibitionListMobile from "../../../components/Mobile/Exhibition/ExhibitionList";
import BbsListMobile from "../../../components/Mobile/Bbs/BbsList";
import AwardsListMobileListMobile from "../../../components/Mobile/Awards/AwardsList";
import NewsListMobile from "../../../components/Mobile/News/NewsList";
import ActivityListMobile from "../../../components/Mobile/Activity/ActivityList";
import CaseListMobile from "../../../components/Mobile/Case/CaseList";
import VideoListSearchMobile from "../../../components/Mobile/common/VideoList/search";
import TopicListMobile from "../../../components/Mobile/Topic/TopicList";

export default {
  name: 'SearchListIndexMobile',
  components: {
    TopicListMobile,
    VideoListSearchMobile,
    CaseListMobile,
    ActivityListMobile,
    NewsListMobile,
    AwardsListMobileListMobile,
    BbsListMobile,
    ExhibitionListMobile, SearchTypeMobile, SearchViewMobile, LangBtnMobile, BackBtnMobile, TopNavMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    goSearch: {
      type: Function,
      default: () => () => {}
    },
    changeType: {
      type: Function,
      default: () => () => {}
    },
    changePage: {
      type: Function,
      default: () => () =>{}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.search-list-index-mobile {
  .search-list{
    margin-top: 48px;
    .empty{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      flex-direction: column;
      margin-top: 100px;
      h3{
        margin-top: 40px;
      }
      h5{
        margin-top: 20px;
        color: rgba(255,255,255,0.8)
      }
    }
  }
}
</style>
