<template>
  <div
    class="rank-index-mobile"
    :style="{background: 'url('+(lang === 'zh' ? type[current].pic : type[current].pic_en)+') no-repeat '+type[current].bg, backgroundSize: 'cover'}"
  >
    <div class="pic">
      <div class="menu">
        <top-nav-mobile
          :btns="false"
          :fixed="true"
        >
          <div
            class="nav-btn-left"
            style="text-align: left"
          >
            <back-btn-bai-mobile
              slot="left"
              url="/exhibition"
            />
          </div>
          <span class="nav-title white">{{ $t('10014') }}</span>
          <div
            class="nav-btn-right"
            style="text-align: right"
          >
            <lang-btn-bai-mobile
              class="nav-btn-left"
              style="text-align: right"
            />
            <search-btn-bai-mobile />
          </div>
        </top-nav-mobile>
      </div>
    </div>
    <div class="container">
      <div class="type">
        <div class="type-list">
          <template v-for="(res, index) in type">
            <template v-if="[2,3].indexOf(index) > -1 || ([2,3].indexOf(index) <= -1 && lang !== 'en')">
              <rank-type-item-mobile
                :key="index"
                :index="index"
                :info="res"
                :on="current===index"
                @click="current=index;$forceUpdate()"
              />
            </template>
          </template>
        </div>
      </div>
      <div class="rank-list">
        <rank-list-mobile
          :field="type[current].field"
          :list="options[type[current].listField]"
          :type="type[current].listField"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import BackBtnBaiMobile from "../../../components/Mobile/common/BackBtnBai";
import LangBtnBaiMobile from "../../../components/Mobile/common/LangBtnBai";
import RankTypeItemMobile from "../../../components/Mobile/Rank/RankTypeItem";
import RankListMobile from "../../../components/Mobile/Rank/RankList";
import SearchBtnBaiMobile from "../../../components/Mobile/common/SearchBaiBtn";

export default {
  name: 'RankIndexMobile',
  components: {SearchBtnBaiMobile, RankListMobile, RankTypeItemMobile, LangBtnBaiMobile, BackBtnBaiMobile, TopNavMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  data(){
    return {
      backurl: '',
      current: this.lang === 'en' ? 2 : 0,
      type: [
        {
          title: '50039',
          pic: require('../../../assets/images/mobile/rank_top_bg_1.png'),
          pic_en: require('../../../assets/images/mobile/rank_top_bg_1_en.png'),
          listField: 'hotList',
          field: {title: 'title', en_title: 'title_en', num: 'click'},
          bg: '#ff8a7f'
        },
        {
          title: '50040',
          pic: require('../../../assets/images/mobile/rank_top_bg_2.png'),
          pic_en: require('../../../assets/images/mobile/rank_top_bg_2_en.png'),
          listField: 'informationList',
          field: {title: 'title', en_title: 'en_title', num: 'click'},
          bg: '#ff8a7f'
        },
        {
          title: '50041',
          pic: require('../../../assets/images/mobile/rank_top_bg_3.png'),
          pic_en: require('../../../assets/images/mobile/rank_top_bg_3_en.png'),
          listField: 'bbsList',
          field: {title: 'title', en_title: 'title_en', num: 'zan'},
          bg: '#ff8a7f'
        },
        {
          title: '50042',
          pic: require('../../../assets/images/mobile/rank_top_bg_4.png'),
          pic_en: require('../../../assets/images/mobile/rank_top_bg_4_en.png'),
          listField: 'zhanList',
          field:{title: 'companyname', en_title: 'companynameen', num: 'zan'},
          bg: '#ff8a7f'
        }
      ],
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    this.backurl = this.$route.meta.fromUrl === '/' ? '/exhibition' : this.$route.meta.fromUrl
  },
  mounted() {
    this.backurl = this.$route.meta.fromUrl === '/' ? '/exhibition' : this.$route.meta.fromUrl
    if(this.lang === 'en'){
      this.current = 2
    }
  }
}
</script>

<style scoped lang="scss">
.rank-index-mobile {
  min-height: calc(100vh - 100px);
  background-size: cover!important;
  padding-bottom: 74px;
  .pic{
    width: 100%;
    height: 280px;
    position: relative;
    .menu{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
  .type{
    margin-top: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    .type-list{
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    }
  }
}
</style>
