<template>
  <div
    class="back-btn-pc"
    @click="goBack"
  ></div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'BackBtnPc',
  props: {
    url: {
      type: String,
      default: () => ''
    },
    backurl: {
      type: String,
      default: () => '/'
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    goBack(){
      if(this.url !== ''){
        this.navigateTo(url)
      }else{
        if (window.history.length > 2) {
          this.$store.commit('setIsPageBack', true);
          this.$router.back()
        }else{
          this.navigateTo(this.backurl)
        }
      }
    }
  }

}
</script>

<style scoped></style>
