<template>
  <div
    class="actvity-list-item-mobile"
    @click="navigateTo('/activity/info?id='+info.id)"
  >
    <div class="pic">
      <pic-mobile :src="info.pic" />
    </div>
    <div class="info">
      <div
        class="title line-2"
        v-html="title"
      >
      </div>
      <div class="tags">
        <tags-item-mobile
          :dark="true"
          :info="info.catename && (info.catename[0] || info.catename)"
        />
      </div>
      <div class="summary line-2">
        <icon-text-time-mobile
          class=" line-1"
          color="#CCCCCC"
          :label="timeFormat(info.done_start_time, 'mm/dd hh:MM') + ` - ` + timeFormat(info.done_end_time, 'mm/dd hh:MM')"
        />
        <a
          :href="`http://api.map.baidu.com/geocoder?address=`+($t2(lang,
                                                                   info.cn_address,
                                                                   info.en_address))+`&output=html&src=webapp.baidu.openAPIdemo`"
          target="_blank"
          @click.stop=""
        >
          <icon-text-location-mobile
            class="item"
            color="#CCCCCC"
            :label="$t2(lang, info.cn_address, info.en_address)"
          />
        </a>
      </div>
      <div class="btn-view">
        <activity-btn-mobile
          :from="from"
          :info="info"
          :people="info.people"
          :status="info.is_status"
          @click="yuyue"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "../../common/Pic";
import TagsItemMobile from "../../common/TagsItem";
import ActivityBtnMobile from "../ActivityBtn";
import IconTextTimeMobile from "../../common/IconText/time";
import IconTextLocationMobile from "../../common/IconText/location";
import SubscribeModalMobile from "@/components/Mobile/common/SubscribeModal/index.vue";

export default {
  name: 'ActivityListItemMobile',
  components: {
    SubscribeModalMobile,
    IconTextLocationMobile, IconTextTimeMobile, ActivityBtnMobile, TagsItemMobile, PicMobile},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    keyword: {
      type: String,
      default: () => ''
    },
    from: {
      type: String,
      default: () => ''
    },
    showModal: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    title(){
      return this.replaceSearch(this.lang, this.keyword, this.info.title, this.info.en_title)
    },
  },
  methods: {
    yuyue(){
      if(this.info.is_status == 0){
        this.showModal(this.info)
      }
      if(this.info.is_status === 2 && this.info.live_id > 0){
        this.navigateTo('/Live?typeCurrent=1&liveid=' + this.info.live_id)
      }
    },
    showSub(){
      this.$refs.sub.show()
    }
  }
}
</script>

<style scoped lang="scss">
.actvity-list-item-mobile {
  margin-bottom: 20px;
  border-radius: 12px;
  background: rgba(255,255,255,0.1);
  overflow: hidden;
  width: 330px;
  height: 572px;
  .pic{
    width: 330px;
    height: 220px;
  }
  .info{
    padding: 17px;
    .title{
      font-size: 28px;
      font-weight: 500;
      color: #fff;
      line-height: 40px;
      height: 80px;
    }
    .tags{
      margin-top: 12px;
    }
    .summary{
      margin-top: 21px;
      .item{
        margin-top: 10px;
      }
    }
  }
}
</style>
