<template>
  <div class="user-card-pc user">
    <div class="container">
      <user-left-menu-pc />
      <div class="user-right">
        <div class="title">
          {{ $t('20102') }}
        </div>
        <div class="collect-type">
          <template v-for="(res, index) in options.typeList">
            <template v-if="lang !== 'en' || (lang ==='en' && !res.enHide)">
              <btn-big
                :key="index"
                class="item"
                :label="$t(res.title)"
                :on="options.type === res.type"
                @click="changeType(res.type)"
              />
            </template>
          </template>
        </div>
        <div class="collect-list">
          <user-collect-exhibition-card-list-pc
            :change-page="changePage"
            :data="options.data"
            from="collect"
            :refresh="refresh"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import UserLeftMenuPc from "../../../components/Pc/User/UserLeftMenu";
import BtnBig from "../../../components/Pc/common/BtnBig";
import UserCollectExhibitionCardListPc from "../../../components/Pc/User/UserCollectExhibitionCardList";

export default {
  name: 'UserCardPc',
  components: {
    UserCollectExhibitionCardListPc, BtnBig, UserLeftMenuPc},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeType: {
      type: Function,
      default: () => () => {}
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.user-card-pc {
  .collect-type{
    display: flex;
    justify-content: center;
    align-items: center;
    .item{
      margin: 0 8px;
    }
  }
  .collect-list{
    margin-top: 20px;
  }
  .user-right{
    .title{
      height: 20px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      line-height: 20px;
      position: relative;
      padding-left: 12px;
      &:before{
        position: absolute;
        left: 0;
        top: 2px;
        content: ' ';
        width: 4px;
        height: 16px;
        background: linear-gradient(223deg, #FC501E 0%, #FA8958 100%);
      }
    }
  }
}
</style>
