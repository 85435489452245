<template>
  <div class="about-contract">
    <div class="box-big-title title-before-line">
      {{ $t('28003') }}
    </div>
    <div class="about-contract-list">
      <template v-for="(res, index) in aboutContractFormats">
        <about-contract-item
          :key="index"
          :info="res"
        />
      </template>
    </div>
  </div>
</template>

<script>

import AboutContractItem from "./aboutContract/aboutContractItem";
export default {
  name: 'AboutContract',
  components: {AboutContractItem},
  props: {
    info: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  computed: {
    aboutContractFormats(){
      let arr = [{},{},{}];
      let {json_text} = this.info
      arr.map(function(res, index){
        arr[index] = {
          contact_item_status: json_text['contact_item_status['+index+']'],
          contact_title_cn: json_text['contact_title_cn['+index+']'],
          contact_title_en: json_text['contact_title_en['+index+']'],
          contact_title_1_cn: json_text['contact_title_1_cn['+index+']'],
          contact_title_1_en: json_text['contact_title_1_en['+index+']'],
          contact_content_1_cn: json_text['contact_content_1_cn['+index+']'],
          contact_content_1_en: json_text['contact_content_1_en['+index+']'],
          contact_title_2_cn: json_text['contact_title_2_cn['+index+']'],
          contact_title_2_en: json_text['contact_title_2_en['+index+']'],
          contact_content_2_cn: json_text['contact_content_2_cn['+index+']'],
          contact_content_2_en: json_text['contact_content_2_en['+index+']'],
          contact_title_3_cn: json_text['contact_title_3_cn['+index+']'],
          contact_title_3_en: json_text['contact_title_3_en['+index+']'],
          contact_content_3_cn: json_text['contact_content_3_cn['+index+']'],
          contact_content_3_en: json_text['contact_content_3_en['+index+']'],
        }
      })
      arr = this.sortBykey(arr, 'text_orderlist')
      return arr
    }
  }
}
</script>

<style scoped lang="scss">
.about-contract{
  .about-contract-list{
    margin-bottom: 48px;
  }
}
</style>
