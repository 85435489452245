<template>
  <div class="user-supply-mobile">
    <top-nav-mobile
      backurl="/user"
      :is-back="true"
      :search="false"
    >
      <span class="nav-title">{{ $t('30004') }}</span>
    </top-nav-mobile>
    <div class="container">
      <div class="types-view">
        <user-types-mobile
          :change-type="changeType"
          :options="options"
        />
      </div>
      <div class="supply-list">
        <user-supply-my-list-mobile
          v-if="options.type === 'my'"
          :change-page="changePage"
          :hide-demand-contact-modal="hideDemandContactModal"
          :hide-demand-modal="hideDemandModal"
          :options="options"
          :refresh="refresh"
          :show-demand-contact-modal-info="showDemandContactModalInfo"
          :show-demand-modal-info="(info) => showDemandModalInfo('my', info)"
        />
        <user-supply-follow-list-mobile
          v-if="options.type === 'follow'"
          :options="options"
          :refresh="refresh"
          :show-supply-modal="showSupplyModal"
        />
        <user-supply-card-list-mobile
          v-if="options.type === 'card'"
          from="user"
          :options="options"
          :refresh="refresh"
          :show-demand-contact-modal-info="showDemandContactModalInfo"
          :show-demand-modal-info="(info) => showDemandModalInfo('user', info)"
        />
      </div>
    </div>
    <supply-demand-supply-modal-mobile
      v-if="supplyShow"
      from="user"
      :hide-supply-modal="hideSupplyModal"
      :info="supplyModalInfo"
    />
    <supply-demand-demand-modal-mobile
      v-if="demandShow"
      :from="from"
      :hide-demand-modal="hideDemandModal"
      :info="demandModalInfo"
      :show-demand-contact-modal-info="showDemandContactModalInfo"
    />
    <supply-demand-demand-contact-modal-mobile
      v-if="demandContactShow"
      :del-item="delItem"
      from="user"
      :hide-demand-contact-modal="hideDemandContactModal"
      :info="demandContactModalInfo"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import UserTypesMobile from "../../../components/Mobile/common/UserTypes";
import UserSupplyMyListMobile from "../../../components/Mobile/User/UserSupplyMyList";
import UserSupplyFollowListMobile from "../../../components/Mobile/User/UserSupplyFollowList";
import UserSupplyCardListMobile from "../../../components/Mobile/User/UserSupplyCardList";
import SupplyDemandDemandModalMobile from "../../../components/Mobile/SupplyDemand/SupplyDemandDemandModal";
import SupplyDemandDemandContactModalMobile
  from "../../../components/Mobile/SupplyDemand/SupplyDemandDemandContactModal";
import SupplyDemandSupplyModalMobile from "../../../components/Mobile/SupplyDemand/SupplyDemandSupplyModal";

export default {
  name: 'UserSupplyMobile',
  components: {
    SupplyDemandSupplyModalMobile,
    SupplyDemandDemandContactModalMobile,
    SupplyDemandDemandModalMobile,
    UserSupplyCardListMobile,
    UserSupplyFollowListMobile,
    UserSupplyMyListMobile, UserTypesMobile, TopNavMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeType: {
      type: Function,
      default: () => () => {}
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    refresh: {
      type: Function,
      default: () => () => {}
    },
    delItem: {
      type: Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      demandModalInfo: {},
      supplyModalInfo: {},
      demandContactModalInfo: {},
      supplyShow: false,
      demandShow: false,
      demandContactShow: false,
      from: 'user'
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods:{
    showSupplyModal(info){
      this.supplyShow = true
      this.supplyModalInfo = info
    },
    showDemandModalInfo(from, info){
      this.from = from
      this.demandShow = true
      this.demandModalInfo = info
    },
    showDemandContactModalInfo(info){
      this.demandShow = false
      this.demandContactShow = true
      this.demandContactModalInfo = JSON.parse(this.htmlspecialchars_decode(info.contact))
      this.demandContactModalInfo.id = info.id
    },
    hideSupplyModal(){
      this.supplyShow = false
    },
    hideDemandContactModal(){
      this.demandContactShow = false
    },
    hideDemandModal(){
      this.demandShow = false
    },
  }
}
</script>

<style scoped lang="scss">
.user-supply-mobile {
  .types-view{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .supply-list{
    margin-top: 20px;
  }
}
</style>
