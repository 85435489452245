<template>
  <div class="search-index-default">
    <search-index-pc
      v-if="browser === 1"
      :change-type="changeType"
      :go-search="goSearch"
      :history-search="historySearch"
      :options="options"
    />
    <search-index-mobile
      v-if="browser === 2"
      :change-type="changeType"
      :go-search="goSearch"
      :history-search="historySearch"
      :options="options"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import SearchIndexPc from "../../Pc/Search";
import SearchIndexMobile from "../../Mobile/Search";

export default {
  name: 'SearchIndexDefault',
  components: {SearchIndexMobile, SearchIndexPc},
  data() {
    return {
      options: {
        list: [],
        history: [],
        typeCurrent: '展览'
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'searchHistory']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    this.init()
    this.setShare()
  },
  methods: {
    init() {
      this.getTypes()
      this.getHistoryKeyword()
    },
    getTypes(){
      this.$u.api.exhibitions.getParentTypes().then((res) => {
        if (res.status === 1) {
          this.options.list = res.data
        }
      })
    },
    getHistoryKeyword(){
      this.options.history = this.searchHistory;
      return
      this.$u.api.searchs.getHistoryKeyword().then((res) => {
        if (res.status === 1) {
          this.options.history = res.data
        }
      })
    },
    changeType(r){
      this.options.typeCurrent = r
    },
    goSearch(keyword){
      if(!keyword || keyword === 'undefined'){
        if(this.browser === 1){
          this.$message.error('请输入要搜索的关键词')
        }else{
          this.toast('请输入要搜索的关键词')
        }
        return
      }
      this.navigateTo('/search/list?keyword='+keyword+'&type='+this.options.typeCurrent)
    },
    historySearch(res){
      this.navigateTo('/search/list?keyword='+res.keyword+'&type='+res.type)
    },
    setShare(){
      this.getWxConfig(this.$t('50025') + this.bankuai(), '超级搜索库，精准定位企业信息；大数据功能，直观呈现行业热点。', 'https://virtualobs.ciif-expo.com/shareimg/6.png')
    }
  }
}
</script>

<style scoped></style>
