<template>
  <div class="news-tabs-mobile">
    <template v-for="(res, index) in options.childType">
      <news-tabs-item-mobile
        :key="index"
        :info="res"
        :on="options.childTypeId === res.id"
        @click="changeTypeid(res.id)"
      />
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import NewsTabsItemMobile from "./item";

export default {
  name: 'NewsTabsMobile',
  components: {NewsTabsItemMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeTypeid: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.news-tabs-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
}
</style>
