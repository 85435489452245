<template>
  <div class="user-card-mobile">
    <top-nav-mobile
      backurl="/user"
      :is-back="true"
      :search="false"
    >
      <span class="nav-title">{{ $t('10020') }}</span>
    </top-nav-mobile>
    <div class="container">
      <div class="types-view">
        <user-types-mobile
          :change-type="changeType"
          :options="options"
        />
      </div>
      <div class="collect-list">
        <user-collect-exhibition-card-list-mobile
          :change-page="changePage"
          :data="options.data"
          :refresh="refresh"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import UserTypesMobile from "../../../components/Mobile/common/UserTypes";
import UserCollectExhibitionCardListMobile from "../../../components/Mobile/User/UserCollectExhibitionCardList";

export default {
  name: 'UserCardMobile',
  components: {
    UserCollectExhibitionCardListMobile,
    UserTypesMobile, TopNavMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeType: {
      type: Function,
      default: () => () => {}
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.user-card-mobile {
  .collect-list{
    margin-top: 32px;
  }
}
</style>
