<template>
  <div class="exhibition-list-index-mobile">
    <top-nav-mobile
      backurl="/exhibition"
      :is-back="true"
    >
      <span class="nav-title">{{ $t('10002') }}</span>
    </top-nav-mobile>
    <div class="container">
      <exhibition-filter-mobile
        :change-child-type-id="changeChildTypeId"
        :change-key="changeKey"
        :change-type-id="changeTypeId"
        :options="options"
        :search="search"
      />
    </div>
    <div class="container list">
      <div class="exhibition-ad-box" v-if="ads">
        <!-- 配置slider组件 -->
        <slider ref="slider" :options="slideOptions">
          <!-- 直接使用slideritem slot -->
          <slideritem v-for="(item,index) in options.ads" :key="index" :style="item.style">
            <a :href="item.linkurl" target="_blank"><img :src="item.pic_ewm" /></a>
          </slideritem>
          <!-- 设置loading,可自定义 -->
        </slider>
      </div>
      <exhibition-list-mobile
        :change-page="changePage"
        :data="options.data"
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopNavMobile from "@/components/Mobile/common/TopNav";
import BackBtnMobile from "@/components/Mobile/common/BackBtn";
import ExhibitionFilterMobile from "@/components/Mobile/Exhibition/ExhibitionFilter";
import LangBtnMobile from "@/components/Mobile/common/LangBtn";
import ExhibitionListMobile from "@/components/Mobile/Exhibition/ExhibitionList";

import { slider, slideritem } from 'vue-concise-slider'// import slider components
export default {
  name: 'ExhibitionListIndexMobile',
  components: {ExhibitionListMobile, ExhibitionFilterMobile, TopNavMobile, slider, slideritem},
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    changeChildTypeId: {
      type: Function,
      default: () => {return () => {}}
    },
    changeTypeId: {
      type: Function,
      default: () => {return () => {}}
    },
    changePage: {
      type: Function,
      default: () => {return () => {}}
    },
    changeKey: {
      type: Function,
      default: () => {return () => {}}
    },
    search: {
      type: Function,
      default: () => {return () => {}}
    },
  },
  data(){
    return {
      slideOptions: {
        currentPage: 0,
        thresholdDistance:500,
        thresholdTime:100,
        autoplay: 5000,
        loop:true,
        loopedSlides:1,
        slidesToScroll:1,
        timingFunction: 'ease',
        speed: 300
      }
    }
  },
  watch: {
    options: {
      //侦听器处理函数
      handler(newVal) {
        if(newVal.ads.length > 1){
          this.slideOptions.pagination = true
        }else{
          this.slideOptions.pagination = false
        }
      },
      //使用deep开启深度监听，只要info对象中任何一个属性变化，都会触发‘对象的侦听器’
      deep: true
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    ads(){
      if(this.options.ads.length > 0){
        let randomIndex = Math.floor(Math.random() * this.options.ads.length);
        return this.options.ads[randomIndex];
      }else{
        return null
      }
    }
  },
}
</script>

<style scoped lang="scss">
.exhibition-list-index-mobile {
  .list{
    margin-top: 32px;
  }
  .exhibition-ad-box{
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
  }
}
</style>
