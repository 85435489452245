<template>
  <div class="bbs-class-pc clearfix">
    <div class="type-item clearfix">
      <div class="left-text">
        {{ $t('21001') }}：
      </div>
      <div class="right-box">
        <div class="types">
          <template v-for="(res, key) in typeList">
            <tabs-item-pc
              :key="key"
              :bold="true"
              font-size="14px"
              :label="$t2(lang, res.name, res.ename)"
              :on="res.id === typeId"
              @click="changeTypeId(res.id)"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="type-item clearfix">
      <div class="left-text">
        {{ $t('21007') }}：
      </div>
      <div class="right-box">
        <div class="types">
          <template v-for="(res, key) in childTypeList">
            <tabs-item-pc
              :key="key"
              :bold="true"
              font-size="14px"
              :label="$t2(lang, res.name, res.ename)"
              :on="res.id === childTypeId"
              @click="changeChildTypeId(res.id)"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="type-item clearfix">
      <div class="left-text">
        年份：
      </div>
      <div class="right-box">
        <div class="types">
          <template v-for="(res, key) in years">
            <tabs-item-pc
                style=" height: 28px; line-height: 22px;"
              :key="key"
              :bold="true"
              font-size="14px"
              :label="$t2(lang, res.name, res.ename)"
              :on="year === res.value"
              @click="changeYear(res.value)"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TabsItemPc from "@/components/Pc/common/TabsItem";

export default {
  name: 'BbsClassPc',
  components: {TabsItemPc},
  props: {
    typeList: {
      type: Array,
      default: () => []
    },
    childTypeList: {
      type: Array,
      default: () => []
    },
    childTypeId: {
      type: [String, Number],
      default: () => 0
    },
    year: {
      type: [String, Number],
      default: () => ''
    },
    typeId: {
      type: [String, Number],
      default: () => 0
    },
    changeChildTypeId: {
      type: Function,
      default: () => {return () => {}}
    },
    changeYear: {
      type: Function,
      default: () => {return () => {}}
    },
    changeTypeId: {
      type: Function,
      default: () => {return () => {}}
    },
    years: {
      type: Array,
      default: () => [
        {
          name: '全部',
          ename: '全部',
          value: '',
        },
        {
          name: '2023',
          ename: '2023',
          value: '2023'
        },
        // {
        //   name: '2024',
        //   ename: '2024'
        // },
        // {
        //   name: '2025',
        //   ename: '2025'
        // },
      ]
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.bbs-class-pc{
  width: 100%;
  .type-item{
    width: 100%;
    margin-bottom: 8px;
    &:last-child{
      margin-bottom: 0;
    }
    .left-text{
      float: left;
      width: 80px;
      text-align: center;
      height: 50px;
      line-height: 50px;
      color: #333;
    }
    .right-box{
      padding: 12px 20px 10px;
      margin-left: 80px;
      background: #FAFAFA;
      .types{
        width: 100%;
        //white-space: nowrap;
        overflow-x: scroll;
        overflow-y: auto;
        //height: 24px;
      }
    }
  }
}
</style>
