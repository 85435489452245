<template>
  <div
    class="actvity-list-item-user-mobile"
    @click="navigateTo('/activity/info?id='+info.id)"
  >
    <div class="pic">
      <pic-mobile :src="info.pic" />
    </div>
    <div class="info">
      <div
        class="title line-2"
        v-html="title"
      >
      </div>
      <div class="tags">
        <tags-item-mobile
          :dark="true"
          :info="info.catename && (info.catename[0] || info.catename)"
        />
      </div>
      <div class="summary">
        <div>
          <icon-text-time-mobile
            class=" line-1"
            color="#999"
            :label="timeFormat(info.done_start_time, 'mm/dd') + ` - ` + timeFormat(info.done_end_time, 'mm/dd')"
          />
          <a
            :href="`http://api.map.baidu.com/geocoder?address=`+($t2(lang,
                                                                     info.cn_address,
                                                                     info.en_address))+`&output=html&src=webapp.baidu.openAPIdemo`"
            target="_blank"
            @click.stop=""
          >
            <icon-text-location-mobile
              class="item"
              color="#999"
              :label="$t2(lang, info.cn_address, info.en_address)"
            />
          </a>
        </div>
        <icon-text-schedule-del-mobile
          :id="info.id"
          class="item"
          color="#c2c2c2"
          font-size="20px"
          :refresh="refresh"
          :set-collect="setCollect"
          :type="4"
          width="32px"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "../../common/Pic";
import TagsItemMobile from "../../common/TagsItem";
import IconTextTimeMobile from "../../common/IconText/time";
import IconTextLocationMobile from "../../common/IconText/location";
import IconTextScheduleDelMobile from "../../common/IconText/scheduledel";

export default {
  name: 'ActivityListItemUserMobile',
  components: {
    IconTextScheduleDelMobile,
    IconTextLocationMobile, IconTextTimeMobile,  TagsItemMobile, PicMobile},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    keyword: {
      type: String,
      default: () => ''
    },
    from: {
      type: String,
      default: () => ''
    },
    showModal: {
      type: Function,
      default: () => () => {}
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    title(){
      return this.replaceSearch(this.lang, this.keyword, this.info.title, this.info.en_title)
    },
  },
  methods: {
    setCollect(v){
      this.info.is_cang = v
      this.refresh()
    }
  }
}
</script>

<style scoped lang="scss">
.actvity-list-item-user-mobile {
  margin-bottom: 20px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1000);
  overflow: hidden;
  width: 100%;
  height: 228px;
  display: flex;
  .pic{
    width: 228px;
    height: 228px;
  }
  .info{
    width: 440px;
    padding: 17px;
    .title{
      font-size: 28px;
      font-weight: bold;
      color: #fff;
      line-height: 40px;
      height: 80px;
    }
    .tags{
      margin-top: 12px;
    }
    .summary{
      margin-top: 21px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
