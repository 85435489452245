<template>
  <div
    class="text-content-pc"
    :style="{marginTop: marginTop, marginBottom: marginBottom}"
  >
    <div
      v-if="title"
      class="title"
      :style="{fontSize: titleSize}"
    >
      {{ title }}
      <a :href="sublink" class="subtitle" target="_blank" v-if="subtitle">{{subtitle}}</a>
    </div>
    <div class="content">
      <div v-html="content" />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'TextContentPc',
  props: {
    title: {
      type: String,
      default: () => ''
    },
    subtitle: {
      type: String,
      default: () => ''
    },
    sublink: {
      type: String,
      default: () => ''
    },
    titleSize: {
      type: String,
      default: () => '24px'
    },
    content: {
      type: String,
      default: () => ''
    },
    marginTop: {
      type: String,
      default: () => '0'
    },
    marginBottom: {
      type: String,
      default: () => '40px'
    },
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.text-content-pc{
  margin-bottom: 48px;
  .title{
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    line-height: 32px;
    position: relative;
    padding-left: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .subtitle{
      font-size: 12px;
      font-weight: 400;
      color: #EA4C1E;
      line-height: 12px;
      padding-right: 15px;
      position: relative;
      &:after{
        content: ' ';
        background: #666;
        width: 9px;
        height: 10px;
        border-radius: 6px;
        position: absolute;
        right: 0;
        top: 0;
        background: url("../../../../assets/images/zhengce_sanjiao.png");
        background-size: 100% 100%;
      }
    }
    &::before{
      content: '';
      width: 6px;
      height: 22px;
      background: linear-gradient(223deg, #FC501E 0%, #FA8958 100%);
      border-radius: 3px;
      position: absolute;
      left: 0;
      top: 5px;
    }
  }
  .content{
    font-size: 14px!important;
    font-weight: 500!important;
    color: rgba(255,255,255,0.8)!important;
    line-height: 24px!important;
    margin-top: 12px!important;
    p{
      font-size: 14px!important;
      font-weight: 500!important;
      color: #666666!important;
      line-height: 24px!important;
    }
  }
}
</style>
