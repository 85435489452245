<template>
  <div class="exhibition-info-mobile-default">
    <top-nav-mobile
      :backurl="backurl"
      :is-back="true"
      :vr="options.info.vr"
    >
      <span class="nav-title">{{ $t('10002') }}</span>
    </top-nav-mobile>
    <div class="container">
      <div class="info">
        <div class="pic">
          <pic-mobile :src="options.info.logoimageurl" />
        </div>
        <div class="title-line">
          <div class="line"></div>
          <div class="title">
            {{ $t2(lang, options.info.companyname, options.info.companynameen) }}
          </div>
        </div>
        <div class="posline">
          <div class="tag-line">
            <div class="line1">
              {{ $t2(lang,options.info.projectteamname,options.info.projectteamname_en) }}
            </div>
            <div class="line2">
              {{ $t2(lang,options.info.applicationsarea,options.info.applicationsarea_en) }}
            </div>
          </div>
          <btn-loukong-mobile
            v-if="false"
            margin-left="20px"
            :text="!options.info.is_card ? $t('20102') : $t('20115')"
            @click="jiaohuanmingpian"
          />
        </div>
        <div class="contact-box">
          <div class="img">
            <img
              v-if="lang==='zh'"
              src="../../../../assets/images/mobile/lxfs.png"
            />
            <img
              v-else
              src="../../../../assets/images/mobile/lxfs_en.png"
            />
          </div>
          <div class="list">
            <icon-text-location-mobile
              class="item"
              color="#fff"
              :label="options.info.boothno"
              v-if="false"
            />
            <a
              :href="`http://api.map.baidu.com/geocoder?address=`+options.info.address+`&output=html&src=webapp.baidu.openAPIdemo`"
              target="_blank"
            >
              <icon-text-line-mobile
                v-if="$t2(lang, options.info.address, options.info.address_en)"
                class="item"
                icon="address.png"
                :text="$t2(lang, options.info.address, options.info.address_en)"
              />
            </a>
            <a :href="`tel:`+options.info.phone">
              <icon-text-line-mobile
                class="item"
                icon="tel.png"
                :text="options.info.phone"
              />
            </a>
          </div>
        </div>
        <text-content-mobile
          :content="$t2(lang, options.info.content, options.info.content_en)"
          margin-top="48px"
          :title="$t('20105')"
        />
        <custom-list-mobile
          v-if="options.info.productList && options.info.productList.length > 0"
          :companyid="options.info.id"
          :list="options.info.productList"
          margin-top="60px"
          :title="$t('20106')"
          type="product"
        />
        <template v-if="lang !== 'en'">
          <custom-list-mobile
            v-if="options.info.activitylist && options.info.activitylist.length > 0"
            :companyid="options.info.id"
            :list="options.info.activitylist"
            margin-top="60px"
            :title="$t('50022')"
            type="activity"
          />
          <custom-list-mobile
            v-if="options.info.informationList && options.info.informationList.length > 0"
            :companyid="options.info.id"
            :list="options.info.informationList"
            margin-top="60px"
            :options="{ childTypeId:0}"
            :title="$t('50028')"
            type="news"
          />
          <custom-list-mobile
            v-if="options.info.caselist && options.info.caselist.length > 0"
            :companyid="options.info.id"
            :list="options.info.caselist"
            margin-top="60px"
            :options="{ childTypeId:0}"
            :title="$t('27012')"
            type="case"
          />
        </template>
        <custom-list-mobile
          v-if="options.info.videoList && options.info.videoList.length > 0"
          :list="options.info.videoList"
          margin-top="60px"
          :title="$t('50018')"
          type="video"
        />
      </div>
      <custom-list-mobile
        v-if="options.info.liveList && options.info.liveList.length > 0"
        :change-live-id="changeLiveId"
        :list="options.info"
        margin-top="60px"
        title="直播列表"
        type="live"
      />
    </div>
    <exhibition-footer-mobile
      :jiaohuanmingpian="jiaohuanmingpian"
      :options="options"
      :set-collect="setCollect"
      :set-zan="setZan"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "@/components/Mobile/common/Pic";
import BtnLoukongMobile from "@/components/Mobile/common/BtnLoukong";
import IconTextLocationMobile from "@/components/Mobile/common/IconText/location";
import IconTextLineMobile from "@/components/Mobile/common/IconTextLine";
import TextContentMobile from "@/components/Mobile/common/TextContent";
import CustomListMobile from "@/components/Mobile/common/CustomList";
import TopNavMobile from "../../../../components/Mobile/common/TopNav";
import ExhibitionFooterMobile from "../../../../components/Mobile/Exhibition/ExhibitionFooter";

export default {
  name: 'ExhibitionInfoMobileDefault',
  components: {
    ExhibitionFooterMobile,
    TopNavMobile,
    CustomListMobile,
    TextContentMobile,
    IconTextLineMobile, IconTextLocationMobile, BtnLoukongMobile, PicMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    setZan: {
      type: Function,
      default: () => () => {}
    },
    jiaohuanmingpian: {
      type:Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      backurl: ''
    }
  },
  activated() {
    if(this.$route.meta.fromUrl.indexOf('product') === -1 && this.$route.meta.fromUrl.indexOf('news') === -1){
      this.backurl = this.$route.meta.fromUrl === '/' ? '/exhibition' : this.$route.meta.fromUrl
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  mounted() {
    this.backurl = this.$route.meta.fromUrl === '/' ? '/exhibition' : this.$route.meta.fromUrl
  },
  methods: {
    changeLiveId(id){
      window.location.href = '/Live?typeCurrent=1&liveid=' + id
    }
  }
}
</script>

<style scoped lang="scss">
.exhibition-info-mobile-default {
  .info{
    margin-top: 18px;
    .pic{
      width: 400px;
      height: 400px;
      border-radius: 12px;
      box-shadow: 0 10px 30px 0 rgba(234, 76, 30, 0.1);
      position: relative;
      overflow: visible;
      background-color: #f5f7fa;
      .pic-bg{
        border-radius: 12px;
      }
      &:after{
        content: '';
        width: 13px;
        height: 400px;
        background: url("../../../../assets/images/mobile/mobile_pic_chi.png");
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        right: -46px;
      }
    }
    .title-line{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      margin-top: 40px;
      .line{
        height: 100%;
        width: 8px;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(223deg, #FC501E, #FA8958);
        border-radius: 4px;
      }
      .title{
        margin-left: 26px;
        flex: 1;
        font-size: 40px;
        font-weight: bold;
        color: #fff;
        line-height: 56px;
        min-height: 56px;
      }
    }
    .posline{
      margin-left: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .tag-line{
        flex: 1;
        margin-top: 18px;
        .line1{
          font-size: 20px;
          color: rgba(255,255,255,0.8);
          line-height: 28px;
        }
        .line2{
          margin-top: 8px;
          font-size: 20px;
          color: rgba(255,255,255,0.8);
          line-height: 28px;
        }
      }
    }
    .contact-box{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background: rgba(255,255,255,.1);
      border-radius: 12px;
      padding: 25px;
      margin-top: 24px;

      .img{
        width: 100px;
        margin-top: 18px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .list{
        width: 500px;
        .item{
          margin-top: 12px;
        }
      }
    }
  }
}
</style>
