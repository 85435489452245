<template>
  <div class="supply-demand-item-pc">
    <supply-demmand-item-supply-pc
      v-if="parseInt(info.is_type) === 0 || parseInt(info.is_type) === 2"
      :info="info"
      :refresh="refresh"
      @click="showSupplyModalInfo(info)"
    />
    <supply-demmand-item-demand-pc
      v-if="parseInt(info.is_type) === 1"
      :info="info"
      :refresh="refresh"
      :show-demand-contact-modal-info="showDemandContactModalInfo"
      @click="showDemandModalInfo(info)"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import SupplyDemmandItemSupplyPc from "./component/supply";
import SupplyDemmandItemDemandPc from "./component/demand";

export default {
  name: 'SupplyDemandItemPc',
  components: {SupplyDemmandItemDemandPc, SupplyDemmandItemSupplyPc},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    showSupplyModalInfo: {
      type: Function,
      default: () => () => {}
    },
    showDemandModalInfo: {
      type: Function,
      default: () => () => {}
    },
    showDemandContactModalInfo: {
      type: Function,
      default: () => () => {}
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.supply-demand-item-pc{
  width: 562px;
  height: 140px;
  overflow: hidden;
  margin-right: 16px;
  margin-bottom: 16px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1000);
  padding: 18px 20px;
  transition: all 0.4s ease-in-out;
  &:hover {
    transform: translateY(-3px);
  }
  &:nth-child(2n){
    margin-right: 0;
  }
}
</style>
