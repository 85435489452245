<template>
  <div class="case-list-exhibition-thumb1-pc">
    <div class="container">
      <div class="header-titile">
        应用案例<span>Applications</span>
      </div>
      <div class="list">
        <swiper
          ref="mySwiper"
          :options="swiperOptionsTop"
        >
          <swiper-slide
            v-for="(res, index) in list"
            :key="index"
          >
            <case-item-exhibition-thumb1-pc
              :key="index"
              :info="res"
            />
          </swiper-slide>
        </swiper>
        <div
          v-if="list.length > 1"
          class="npage"
        >
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import '@/assets/swiper.css'
import CaseItemExhibitionThumb1Pc from "./CaseItemExhibitionThumb1";
export default {
  name: 'CaseListExhibitionThumb1Pc',
  components: {CaseItemExhibitionThumb1Pc, Swiper, SwiperSlide},
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return{
      swiperOptionsTop: {
        spaceBetween: 20,
        slidesPerView: 1,
        slidesPerColumn: 1,
        slidesPerGroup: 1,
        slidesPerColumnFill: 'row',
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.case-list-exhibition-thumb1-pc {
  margin-top: 80px;
  .header-titile{
    padding: 0 40px;
    display: inline-block;
    height: 56px;
    line-height: 56px;
    text-align: center;
    font-size: 24px;
    color: #fff;
    background: #000;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    span{
      margin-left: 20px;
    }
  }
  .list{
    background: #fff;
    padding: 40px 40px 40px 40px;
    border-radius: 0 0 28px 28px;
    position: relative;
    .npage{
       display: flex;
       justify-content: center;
       align-items: center;
       height: 56px;
       margin-top: 63px;
       .swiper-button-next{
         position: static;
         &:after{
           display: none!important;
         }
         width: 56px;
         height: 56px;
         background: url("../../../../../assets/images/exhibition_theme_2/swiper_right_on@2x.png");
         background-size: 100% 100%;
         margin-left: 8px;
         margin-top: 0!important;
       }
       .swiper-button-prev{
         position: static;
         &:after{
           display: none!important;
         }
         width: 56px;
         height: 56px;
         background: url("../../../../../assets/images/exhibition_theme_2/swiper_left_on@2x.png");
         background-size: 100% 100%;
         margin-top: 0!important;
       }
     }
  }
}
</style>
