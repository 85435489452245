<template>
  <div
    class="icon-text-address-mobile"
    :class="from"
  >
    <div class="icon"></div>
    <div
      class="label line-1"
      :style="{color: color}"
    >
      {{ label }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextAddressMobile',
  props: {
    label: {
      type: String,
      default: () => ''
    },
    color: {
      type: String,
      default: () => "#F39478"
    },
    from: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.icon-text-address-mobile{
  height: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &.collect{
    .icon{
      background: url("../../../../assets/images/address_white.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .icon{
    width: 22px;
    height: 22px;
    background: url("../../../../assets/images/address.png") no-repeat;
    background-size: 100% 100%;

  }
  .label{
    flex: 1;
    height: 28px;
    color: #F39478;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    margin-left: 8px;
  }
}
</style>
