<template>
  <div class="bbs-list-pc">
    <template v-for="(res, index) in data.list">
      <bbs-item-pc
        :key="index"
        :from="from"
        :info="res"
        :keyword="data.key"
        :refresh="refresh"
      />
    </template>
    <page-common-pc
      :change-page="changePage"
      :data="data"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import BbsItemPc from "@/components/Pc/Bbs/BbsList/item";
import PageCommonPc from "@/components/Pc/common/PageCommon";

export default {
  name: 'BbsListPc',
  components: {PageCommonPc, BbsItemPc},
  props:{
    data: {
      type: Object,
      default: () => {}
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    from: {
      type: String,
      default: () => ''
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.bbs-list-pc{

}
</style>
