<template>
  <div
    class="icon-text-line"
    :class="hover ? 'hover' : ''"
    @click="$emit('click')"
  >
    <div class="icon">
      <img :src="require('../../../../assets/images/'+icon)" />
    </div>
    <div
      class="text line-1"
      :style="{color: color}"
      v-html="(text || '')"
    >
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextLine',
  props: {
    text: {
      type: String,
      default: () => ''
    },
    color: {
      type: String,
      default: () => '#666666'
    },
    icon: {
      type: String,
      default: () => ''
    },
    hover: {
      type: Boolean,
      default: () => true
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }
}
</script>

<style scoped lang="scss">
.icon-text-line{
  line-height: 18px;
  .icon{
    display: inline-block;
    margin-right: 6px;
    cursor: pointer;
    float: left;
    img{
      width: 14px;
      height: 14px;
      vertical-align: top;
    }
  }
  .text{
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
    cursor: pointer;
    margin-left: 18px;
    white-space: nowrap;
  }
  &.hover:hover{
    .text {
      color: #EA4C1E!important;
    }
  }
}
</style>
