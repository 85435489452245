<template>
  <div class="topic-index-pc">
    <div class="container">
      <div class="main-box types">
        <template v-for="(res, index) in options.childType">
          <btn-big
            :key="index"
            class="item"
            :label="res.name"
            :on="options.childTypeId === res.id"
            @click="changeTypeid(res.id)"
          />
        </template>
      </div>
      <split-line-pc
        bg-color="rgba(255,255,255,0.1)"
        height="1px"
        margin-bottom="0"
        margin-top="0"
      />
      <div class="main-box">
        <div class="pic">
          <pic-pc
            :src="options.info.pic"
          />
        </div>
        <template
          v-for="(res, index) in options.info.text"
        >
          <text-content-pc
            :key="index"
            :content="$t2(lang, res.content_cn, res.content_en)"
            margin-bottom="53px"
            margin-top="25px"
            :title="$t(res.title)"
          />
          <div class="exhibition-ad-box" v-if="index == 0 && options.info.banner_pc">
            <a :href="options.info.banner_link_pc" target="_blank"><img :src="options.info.banner_pc" /></a>
          </div>
        </template>
        <div
          v-if="options.info.zhengce.length > 0"
          class="zhengce-list"
        >
          <div class="title title-before-line">
            {{ $t('27009') }}
          </div>
          <div class="list">
            <template v-for="(res, index) in options.info.zhengce">
              <div
                :key="index"
                class="item line-1"
                @click="navigateTo('/news/info?id='+res.id)"
              >
                {{ res.title }}
              </div>
            </template>
          </div>
        </div>
        <topic-zhanshang-list-pc
          v-if="options.info.zhanshang.length>0"
          :list="options.info.zhanshang"
        />
        <list-box-pc
          v-if="options.info.product && options.info.product.length > 0"
          :field="{img: 'imageurl', title: 'productname', title_en: 'productnameen'}"
          :item-click="(info) => navigateTo('/exhibition/productinfo?companyid=' + info.cid+'&'+'id='+info.id)"
          :list="options.info.product"
          :title="$t('20106')"
          type="product"
        />
        <list-box2-pc
          v-if="options.info.news && options.info.news.length > 0"
          :field="{img: 'pic', title: 'title', title_en: 'en_title', description: 'description', description_en: 'en_description', time: 'addtime'}"
          :item-click="(info) => navigateTo('/news/info?id='+info.id)"
          :list="options.info.news"
          :title="$t('50028')"
          type="news"
        />

        <list-box-pc
          v-if="options.info.activity && options.info.activity.length > 0"
          :field="{img: 'pic', title: 'title', title_en: 'en_title', catename: 'catename'}"
          :item-click="(info) => navigateTo('/activity/info?id='+info.id)"
          :list="options.info.activity"
          :title="$t('50022')"
          type="activity"
        />
        <list-box-pc
          v-if="options.info.caselist && options.info.caselist.length > 0"
          :field="{img: 'pic', title: 'title', title_en: 'en_title', catename: 'catename'}"
          :item-click="(info) => navigateTo('/case/info?id='+info.id)"
          :list="options.info.caselist"
          :title="$t('27012')"
          type="case"
        />
        <video-list-pc
          v-if="options.info.videos && options.info.videos.length > 0"
          :hide-player="hidePlayer"
          :list="options.info.videos"
          :show-player="(v) => showPlayer(v)"
          :title="$t('50018')"
          type="videos"
        />
      </div>
    </div>
    <video-player-pc
      v-if="playerShow"
      :hide-player="hidePlayer"
      :video-url="videoUrl"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import BtnBig from "@/components/Pc/common/BtnBig";
import SplitLinePc from "@/components/Pc/common/SplitLine";
import PicPc from "@/components/Pc/common/Pic";
import TextContentPc from "@/components/Pc/common/TextContent";
import TopicZhanshangListPc from "@/components/Pc/Topic/TopicZhanshangList";
import ListBoxPc from "@/components/Pc/common/ListBox";
import ListBox2Pc from "@/components/Pc/common/ListBox2";
import VideoListPc from "@/components/Pc/common/VideoList";
import VideoPlayerPc from "@/components/Pc/common/VideoPlayer";

export default {
  name: 'TopicIndexPc',
  components: {
    VideoPlayerPc,
    VideoListPc, ListBox2Pc, ListBoxPc, TopicZhanshangListPc, TextContentPc, PicPc, SplitLinePc, BtnBig},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeTypeid: {
      type: Function,
      default: () => () => {}
    }
  },
  data() {
    return {
      playerShow: false,
      videoUrl: ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    showPlayer(videoUrl) {
      this.videoUrl = videoUrl
      this.playerShow = true;
    },
    hidePlayer() {
      this.playerShow = false;
    }
  }
}
</script>

<style scoped lang="scss">
.topic-index-pc {
  .types{
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 20px;
    .item{
      margin: 0 8px;
    }
  }
  .zhengce-list{
    margin-bottom: 48px;
    .title{
      height: 32px;
      font-size: 24px;
      font-weight: 600;
      color: #fff;
      line-height: 32px;
      margin-bottom: 12px;
    }
    .list{
      .item{
        height: 40px;
        width: 100%;
        margin-right: 20px;
        line-height: 40px;
        display: inline-block;
        list-style: decimal;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        position: relative;
        padding-left: 15px;
        padding-right: 30px;
        cursor: pointer;
        &:hover{
          color: rgba(255,255,255,0.8);
        }
        &:before{
          content: ' ';
          background: #666;
          width: 9px;
          height: 10px;
          border-radius: 6px;
          position: absolute;
          left: 0;
          top: 14px;
          background: url("../../../assets/images/zhengce_sanjiao.png");
          background-size: 100% 100%;
        }

        &:nth-child(2n){
          margin-right: 0;
        }
      }
    }
  }
  .pic{
    width: 100%;
    height: 320px;
    overflow: hidden;
  }
  .exhibition-ad-box{
    margin-bottom: 53px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}
</style>
