<template>
  <div class="user-collect-default">
    <user-card-pc
      v-if="browser===1"
      :change-page="changePage"
      :change-type="changeType"
      :options="options"
      :refresh="getList"
    />
    <user-card-mobile
      v-if="browser===2"
      :change-page="changePage"
      :change-type="changeType"
      :options="options"
      :refresh="getList"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import UserCardPc from "../../Pc/User/card";
import UserCardMobile from "../../Mobile/User/card";

export default {
  name: 'UserCartDefault',
  components: {UserCardMobile, UserCardPc,},
  data() {
    return {
      options: {
        type: 'bbs',
        data: {
          list: [],
          page: 1,
          pagesize: 6,
          total: 0
        },
        loading: false
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    this.init()
    this.setShare()
  },
  methods: {
    init() {
      this.getList();
    },
    changeType(type){
      this.options.data.list = [];
      this.options.data.page = 1;
      this.options.data.total = 0;
      this.options.type = type
      this.getList()
    },
    changePage(v){
      this.options.data.page = v;
      this.getList()
    },
    getList(){
      let type = 5
      const params = {
        t: type,
        page: this.options.data.page,
        pagesize: this.options.data.pagesize
      }
      this.options.loading = true
      this.$u.api.users.getMyCang(params).then(res => {
        this.options.loading = false
        if(res.status === 1){
          const {data: {lists, totalNum}} = res
          this.options.data.list = lists
          this.options.data.total = totalNum
        }
      })
    },
    setShare(){
      this.getWxConfig('', '')
    }
  }
}
</script>

<style scoped></style>
