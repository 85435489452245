<template>
  <div class="supply-demand-demand-contact-modal-pc">
    <div class="cell">
      <div class="box">
        <div class="close-btn">
          <btn-close-pc
            size="24"
            @click="hideDemandContactModal"
          />
        </div>
        <div class="title-line">
          联系方式
        </div>
        <split-line-pc
          bg-color="#f5f5f5"
          height="1px"
          margin-bottom="23px"
          margin-top="0"
        />
        <div class="info-main">
          <div class="cell-list">
            <div class="label">
              姓名：
            </div>
            <div class="text">
              {{ info.name }}
            </div>
          </div>
          <div class="cell-list">
            <div class="label">
              邮箱：
            </div>
            <div class="text">
              {{ info.email }}
            </div>
          </div>
          <div class="cell-list">
            <div class="label">
              公司：
            </div>
            <div class="text">
              {{ info.company }}
            </div>
          </div>
        </div>
        <div class="btn-line">
          <btn-custom-pc
            class="item on"
            label="取消"
            @click="hideDemandContactModal"
          />
          <btn-custom-pc
            :background="parseInt(info.is_follow) === 0 ? `linear-gradient(223deg, rgba(255, 26, 44, 0.9), rgba(255, 155, 97, 0.8))` : `#eee`"
            class="item"
            :class="parseInt(info.is_follow) === 1 ? `on` : ``"
            :color="parseInt(info.is_follow) === 0 ? `#fff` : `#aaa`"
            :label="parseInt(info.is_follow) === 0 ? `添加到我的供需` : `已添加`"
            @click="guanzhu"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import BtnClosePc from "@/components/Pc/common/BtnClosePc";
import SplitLinePc from "@/components/Pc/common/SplitLine";
import BtnCustomPc from "@/components/Pc/common/BtnCustom";

export default {
  name: 'SupplyDemandDemandContactModalPc',
  components: {BtnCustomPc, BtnClosePc, SplitLinePc},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    hideDemandContactModal: {
      type: Function,
      default: () => () => {}
    },
    user: {
      type: Boolean,
      default: () => false
    },
    setFollow: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods:{
    guanzhu() {
      const params = {
        sid: this.info.id,
      }
      this.$u.api.supplydemands.actfollow(params).then(res => {
        if(res.status === 1){
          this.setFollow(this.info.id, res.data.is_follow)
          this.hideDemandContactModal()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.supply-demand-demand-contact-modal-pc{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  background: rgba(0,0,0,0.6);
  display: table;
  .cell{
    display: table-cell;
    vertical-align: middle;
    .box{
      position: relative;
      width: 480px;
      background: #fff;
      margin: 0 auto;
      .close-btn{
        position: absolute;
        right: -24px;
        top: -24px;
      }
      .title-line{
        text-align: center;
        padding: 24px 32px 16px;
        font-size: 20px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 24px;
      }
      .info-main{
        padding: 0 32px 24px;
        display: table;
        .cell-list{
          display: table-row;
          height: 36px;
          line-height: 36px;
          font-size: 16px;
          font-weight: 500;
          .label{
            display: table-cell;
            color: #262626;
            width: 50px;
          }
          .text{
            display: table-cell;
            color: #333333
          }
        }
      }
      .btn-line{
        text-align: center;
        padding-bottom: 33px;
        .item{
          margin: 0 10px;
        }
      }
    }
  }
}
</style>
