<template>
  <div class="awards-info-mobile">
    <top-nav-mobile
      :backurl="backurl"
      :is-back="true"
    >
      <span class="nav-title">{{ $t('10005') }}</span>
    </top-nav-mobile>
    <div class="container">
      <div class="pic radius">
        <img :src="options.info.pic" />
      </div>
      <div
        class="title mt-20"
      >
        {{ $t2(lang, options.info.title, (options.info.title_en || options.info.title)) }}
      </div>
      <div
        v-if="options.info.title_en"
        class="title-en"
      >
        {{ $t2(lang, (options.info.title_en || options.info.title), options.info.title) }}
      </div>
      <div class="btn-view">
        <div class="other">
          <icon-text-line-mobile
            color="rgba(255,255,255,0.6)"
            icon="jiang_hei.png"
            :text="options.info.catename.name+' '+options.info.catename.ename"
          />
        </div>
        <div class="btns">
          <icon-text-column-mobile
            color="rgba(255,255,255,0.6)"
            icon="views.png"
            text="8294"
          />
          <icon-text-zan-mobile
            :id="options.info.id"
            class="item"
            color="rgba(255,255,255,0.6)"
            font-size="20px"
            :label="$t('50048')"
            :set-zan="setZan"
            :type="2"
            width="32px"
            :zan="parseInt(options.info.is_zan) === 1"
          />
          <icon-text-collect-mobile
            :id="options.info.id"
            class="item"
            color="rgba(255,255,255,0.6)"
            font-size="20px"
            :label="$t('50024')"
            :set-collect="setCollect"
            :shoucang="parseInt(options.info.is_cang) === 1"
            :type="2"
            width="32px"
          />
          <icon-text-column-mobile
            color="rgba(255,255,255,0.6)"
            icon="share.png"
            :text="$t('50016')"
            type="share"
          />
        </div>
      </div>
      <div class="desc">
        <div class="desc-item thin">
          <div class="desc-title">
            {{ $t('23111') }}:
          </div>
          <div class="text">
            <p>{{ $t2(lang, options.info.companyname, options.info.companynameen) }}</p>
            <p>{{ $t2(lang, options.info.companynameen, options.info.companyname) }}</p>
          </div>
        </div>
        <div class="desc-item">
          <div class="desc-title">
            {{ $t('23112') }}:
          </div>
          <div class="text">
            <p>{{ $t2(lang, options.info.diqu, options.info.diqu_en) }}</p>
          </div>
        </div>
        <div class="desc-item">
          <div class="desc-title">
            {{ $t('23113') }}:
          </div>
          <div class="text">
            <p>{{ $t2(lang, options.info.zhanwei, options.info.zhanwei_en) }}</p>
          </div>
        </div>
      </div>
      <text-content-mobile
        :content="$t2(lang, options.info.content, options.info.content_en)"
        title=""
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import IconTextColumnMobile from "../../../components/Mobile/common/IconTextColumn";
import IconTextLineMobile from "../../../components/Mobile/common/IconTextLine";
import TextContentMobile from "../../../components/Mobile/common/TextContent";
import IconTextCollectMobile from "../../../components/Mobile/common/IconText/collect";
import IconTextZanMobile from "../../../components/Mobile/common/IconText/zan";

export default {
  name: 'AwardsInfoMobile',
  components: {
    IconTextZanMobile,
    IconTextCollectMobile,
    TextContentMobile, IconTextLineMobile,  IconTextColumnMobile, TopNavMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    setZan: {
      type: Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      backurl: ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    this.backurl = this.$route.meta.fromUrl === '/' ? '/awards' : this.$route.meta.fromUrl
  },
  mounted() {
    this.backurl = this.$route.meta.fromUrl === '/' ? '/awards' : this.$route.meta.fromUrl
  },
}
</script>

<style scoped lang="scss">
.awards-info-mobile {
  .top-right{
    display: flex;
    .item{
      margin-left: 44px;
    }
  }
  .pic{
    width: 100%;
    text-align: center;
    img{
      width: 100%;
    }
  }
  .title{
    font-size: 36px;
    font-weight: bold;
    color: #fff;
    line-height: 50px;
  }
  .title-en{
    font-size: 30px;
    line-height: 50px;
    color: #fff;
  }

  .desc{
    margin-top: 20px;
    background: rgba(255,255,255,0.1);
    padding: 20px;
    font-weight: 400;
    color: #fff;
    line-height: 40px;
    border-radius: 8px;

    .desc-item{
      margin-bottom: 8px;
      .desc-title{
        line-height: 40px;
        font-size: 24px;
        color: #fff;
        display: inline-block;
        vertical-align: top;
        p{
          margin-top: 2px;
        }
      }
      .text{
        font-size: 24px;
        display: inline-block;
        margin-left: 10px;
      }
    }
  }
  .pic{
    width: 100%;
    text-align: center;
    img{
      max-width: 100%;
    }
  }
  .btn-view {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .other{
      margin-top: 30px;
      width: 50%;
      flex: 1;
    }
    .btns {
      width: 50%;
      flex: 1;
      margin-top: 32px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
}
</style>
