<template>
  <div class="index-default">
    <index-mobile2
      v-if="browser===2"
      :options="options"
      :set-current="setCurrent"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import IndexMobile2 from "../../Mobile/Index/index2.vue";

export default {
  name: 'IndexDefault',
  components: {IndexMobile2},
  data() {
    return {
      options: {
        current: 3,
        titleShow: true,
        list: [
          {
            title: 'VR',
            top: -400,
            icon: require('../../../assets/images/mobile/index_lou_icon_1.png'),
            icon_on: require('../../../assets/images/mobile/index_lou_icon_1_on.png'),
            link: [
              {
                title: 'VR',
                width: 316,
                height: 306,
                left: -95,
                top: 190,
                out: true,
                url: '',
                dot_left: 282,
                dot_top: 148
              }
            ]
          },
          {
            title: '评奖',
            top: 350,
            icon: require('../../../assets/images/mobile/index_lou_icon_2.png'),
            icon_on: require('../../../assets/images/mobile/index_lou_icon_2_on.png'),
            link: [
              {
                title: '评奖',
                width: 310,
                height: 400,
                left: 63,
                top: 590,
                out: false,
                url: '/Awards',
                dot_left: 122,
                dot_top: 270
              }
            ]
          },
          {
            title: '展览',
            top: 1286,
            icon: require('../../../assets/images/mobile/index_lou_icon_3.png'),
            icon_on: require('../../../assets/images/mobile/index_lou_icon_3_on.png'),
            link: [
              {
                title: '展览',
                width: 327,
                height: 437,
                left: 60,
                top: 1135,
                out: false,
                url: '/Exhibition',
                dot_left: 250,
                dot_top: 265
              }
            ]
          },
          {
            title: '论坛',
            top: 2000,
            icon: require('../../../assets/images/mobile/index_lou_icon_4.png'),
            icon_on: require('../../../assets/images/mobile/index_lou_icon_4_on.png'),
            link: [
              {
                title: '论坛',
                width: 220,
                height: 149,
                left: 42,
                top: 1830,
                out: false,
                url: '/Forum',
                dot_left: 103,
                dot_top: 65
              },
            ]
          },
          {
            title: '直播间',
            top: 2783,
            icon: require('../../../assets/images/mobile/index_lou_icon_5.png'),
            icon_on: require('../../../assets/images/mobile/index_lou_icon_5_on.png'),
            link: [
              {
                title: '直播间',
                width: 318,
                height: 351,
                left: 65,
                top: 2202,
                out: false,
                url: '/Live',
                dot_left: 90,
                dot_top: 240
              }
            ]
          },
          {
            title: '搜索&榜单',
            top: 3510,
            icon: require('../../../assets/images/mobile/index_lou_icon_6.png'),
            icon_on: require('../../../assets/images/mobile/index_lou_icon_6_on.png'),
            link: [
              {
                title: '排行榜',
                width: 168,
                height: 221,
                left: 45,
                top: 2681,
                out: false,
                url: '/Rank',
                dot_left: 30,
                dot_top: 170
              },
              {
                title: '搜索',
                width: 112,
                height: 201,
                left: 225,
                top: 2725,
                out: false,
                url: '/Search',
                dot_left: 20,
                dot_top: 160
              }
            ]
          },
          {
            title: '供需对接',
            top: 4100,
            icon: require('../../../assets/images/mobile/index_lou_icon_7.png'),
            icon_on: require('../../../assets/images/mobile/index_lou_icon_7_on.png'),
            link: [
              {
                title: '供需对接',
                width: 352,
                height: 217,
                left: 30,
                top: 3126,
                out: false,
                url: '/supplydemand',
                dot_left: 50,
                dot_top: 10
              }
            ]
          },
          {
            title: '案例',
            top: 4500,
            icon: require('../../../assets/images/mobile/index_lou_icon_8.png'),
            icon_on: require('../../../assets/images/mobile/index_lou_icon_8_on.png'),
            link: [
              {
                title: '案例',
                width: 327,
                height: 240,
                left: 28,
                top: 3431,
                out: false,
                url: '/case',
                dot_left: 135,
                dot_top: 140
              }
            ]
          },
        ]
      },
      defaultOptions: {},
      isFirst: false,
      timeObj: null
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
  },
  mounted() {
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    this.init()
  },
  methods: {
    init(){
      this.timeObj = setTimeout(() => {
        this.options.titleShow = false
      }, 3000)
    },
    setCurrent(i) {
      this.options.titleShow = true
      this.options.tipShow = true
      this.options.current = i
      this.timeObj && clearTimeout(this.timeObj)
      this.timeObj = setTimeout(() => {
        this.options.titleShow = false
      }, 3000)
    }
  }

}
</script>

<style scoped></style>
