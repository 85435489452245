<template>
  <div
    ref="tabsRef"
    class="topic-types-mobile"
  >
    <template v-for="(res, index) in options.childType">
      <topic-types-item-mobile
        :key="index"
        :info="res"
        :on="options.childTypeId === res.id"
        :refs="setItemRef"
        @click="e => tabsOnclick(e, res)"
      />
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopicTypesItemMobile from "./item";

export default {
  name: 'TopicTypesMobile',
  components: {TopicTypesItemMobile},
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    changeTypeid: {
      type: Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      refList: []
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  mounted() {
    console.log(this.options.childTypeId)
    this.$nextTick(() => {
      console.log(this.refList)
    })
  },
  methods: {
    setItemRef(el) {
      this.refList.push(el)
    },
    tabsOnclick(e, res){
      this.tabsClickScroll('tabsRef', e, res)
    },
    tabsClickScroll(refDom, e, a) {
      let scrollLeft= null
      let scrollRight = null
      let moveDistance = null
      const clientWidthHalf = this.$refs[refDom].clientWidth / 2
      // e.layerX获取元素相对refDom元素的位置
      let dom = e.target;
      moveDistance = clientWidthHalf - e.layerX
      scrollLeft= moveDistance
      scrollRight = -(moveDistance)

      // 获取当前元素所在元素的中间位置
      const currentMiddleHalf = (this.$refs[refDom].clientWidth + e.target.offsetWidth) / 2
      // 点击左侧
      // layerX 鼠标相比较于当前坐标系的位置,即如果触发元素没有设置绝对定位或相对定位,以页面为参考点,如果有,将改变参考坐标系
      if (e.layerX <= currentMiddleHalf) {
        this.$refs[refDom].scrollLeft -= scrollLeft
      } else {
        // 点击右侧
        this.$refs[refDom].scrollLeft += scrollRight
      }

      const res= this.$refs[refDom].scrollLeft >= 0 ? this.$refs[refDom].scrollLeft : 0
      this.$refs[refDom].scrollLeft = res
      this.changeTypeid(a.id)
    }
  }
}
</script>

<style scoped lang="scss">
.topic-types-mobile {
  white-space: nowrap;
  overflow-x: scroll;
}
</style>
