<template>
  <div
    class="live-comment-item-pc"
    :class="parseInt(info.mid) === user.mid ? `on` : ``"
  >
    <div class="avatar">
      <img src="../../../../assets/images/live_avatar.png" />
    </div>
    <div class="info">
      <div class="name-line">
        <div class="nickname">
          {{ info.name }}
        </div>
        <div class="time">
          {{ info.addtime }}
        </div>
      </div>
      <div class="content">
        {{ info.comment }}
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'LiveCommentItemPc',
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'user']),
  },
}
</script>

<style scoped lang="scss">
.live-comment-item-pc {
  margin-bottom: 20px;
  &:after{
    content: '';
    width: 0;
    height: 0;
    clear: both;
    display: block;
    overflow: hidden;
  }
  .avatar{
    width: 40px;
    height: 40px;
    background: rgba(255,255,255,0.3);
    border-radius: 40px;
    float: left;
  }
  .info{
    padding-left: 50px;
    .name-line{
      .nickname{
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        line-height: 14px;
        display: inline-block;
      }
      .time{
        font-size: 14px;
        font-weight: 400;
        color: rgba(255,255,255,0.6);
        line-height: 14px;
        display: inline-block;
        margin-left: 12px;
      }
    }
    .content{
      color: rgba(255,255,255,0.6);
      border-radius: 0 8px 8px 8px;
      background: rgba(255,255,255,0.1);
      padding: 12px;
      margin-top: 6px;
      display: inline-block;
    }
  }
  &.on{
    .content{
      background: linear-gradient(223deg, #FF511A 0%, #FF7F34 100%);
      color: #fff;
    }
  }
}
</style>
