<template>
  <div
    class="case-item-pc"
    :class="from"
    @click="navigateTo('/case/info?id='+info.id)"
  >
    <div class="pic">
      <pic-pc :src="info.pic" />
    </div>
    <div class="info">
      <div class="title-view clearfix">
        <div class="left">
          <div class="title line-1">
            <div v-html="title"></div>
          </div>
          <div class="tags-line">
            <div
              v-if="info.catename"
              class="tags"
            >
              <template v-for="(res, index) in cateName">
                <tag-item
                  :key="index"
                  class="item"
                  :dark="true"
                  :label="$t2(lang, res.name, res.ename)"
                />
              </template>
            </div>
          </div>
          <div
            v-if="from==='collect'"
            class="desc line-2"
          >
            {{ $t2(lang, info.description, info.en_description) }}
          </div>
        </div>
        <div class="right">
          <icon-text-collect
            :id="info.id"
            class="item"
            :label="this.$t('50024')"
            :set-collect="setCollect"
            :shoucang="parseInt(info.is_cang) === 1"
            :type="5"
          />
        </div>
      </div>
      <div
        v-if="info.source"
        class="summary line-2"
      >
        <icon-text-line
          color="rgba(255,255,255,0.6)"
          icon="laiyuan.png"
          :text="$t2(lang, info.source.companyname, info.source.companynameen)"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicPc from "@/components/Pc/common/Pic";
import TagItem from "@/components/Pc/common/TagItem";
import IconTextCollect from "@/components/Pc/common/IconText/collect";
import IconTextLine from "../../common/IconTextLine";

export default {
  name: 'CaseItemPc',
  components: {IconTextLine, IconTextCollect, TagItem, PicPc},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    from: {
      type: String,
      default: () => ''
    },
    keyword: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    title(){
      return this.replaceSearch(this.lang, this.keyword, this.info.title, this.info.en_title)
    },
    cateName(){
      let cat = this.info.catename
      if(cat && cat.length > 0){
        cat.sort((a, b) => a.name.length - b.name.length)
      }
      return cat
    }
  },
  methods:{
    setCollect(v) {
      this.info.is_cang = v
    }
  }
}
</script>

<style scoped lang="scss">
.case-item-pc {
  display: inline-block;
  width: 360px;
  height: 352px;
  background: rgba(255,255,255,0.1);
  margin-right: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  &.collect{
    width: 100%;
    display: flex;
    height: 180px;
    .pic{
      width: 270px;
      height: 180px;
    }
    .info{
      .title-view{
        .left{
          width: 540px;
          .title{
            font-size: 20px;
            height: 56px;
            line-height: 28px;
            text-align: left;
          }
          .tags{
            margin-right: 10px;
          }
        }
      }
    }
  }
  &:hover{
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
    .pic{
      div{
        transition: all 0.4s ease-in-out;
        transform: scale(1.1);
      }
    }
  }
  &:nth-child(3n){
    margin-right: 0;
  }
  .pic{
    width: 360px;
    height: 240px;
    overflow: hidden;
  }
  .info{
    padding: 12px 16px 16px 16px;
    .title-view{
      .left{
        float: left;
        width: 300px;
        .title{
          font-size: 16px;
          font-weight: 600;
          color: #fff;
          line-height: 20px;
          text-align: left;
        }
        .tags-line{
          margin-top: 8px;
          .tags{
            height: 24px;
            overflow: hidden;
            margin-right: 40px;
            .item{
              margin-right: 6px;
            }
          }
        }
        .desc{
          color: rgba(255,255,255,0.6);
          margin-top: 10px;
        }
      }
      .right{
        float: right;
      }
    }
    .summary{
      font-size: 14px;
      font-weight: 500;
      color: #999999;
      line-height: 20px;
      margin-top: 16px;
    }
  }
}
</style>
