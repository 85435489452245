<template>
  <div
    class="list-box2-pc"
    :class="more ? `list-box-swiper-pc` : ``"
  >
    <div class="title title-before-line">
      {{ title }}
    </div>
    <div
      v-if="!more"
      class="list"
    >
      <template v-for="(res, index) in list">
        <list-box2-item-pc
          v-if="index < 4"
          :key="index"
          :description="res[lang === 'zh' ? field.description : field.description_en]"
          :imageurl="res[field.img]"
          :info="res"
          :time="res[field.time]"
          :title="res[lang === 'zh' ? field.title : field.title_en]"
          @click="itemClick"
        />
      </template>
    </div>
    <div
      v-if="!more && showMore"
      class="loadmore"
      @click="more = true"
    >
      {{ $t('50008') }}
    </div>
    <div
      v-if="more"
      class="list"
    >
      <swiper
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="(res, index) in list"
          :key="index"
          class="swiper-item-pic"
        >
          <list-box2-item-pc
            :key="index"
            :description="res[lang === 'zh' ? field.description : field.description_en]"
            :imageurl="res[field.img]"
            :info="res"
            :time="res[field.time]"
            :title="res[lang === 'zh' ? field.title : field.title_en]"
            @click="itemClick"
          />
        </swiper-slide>
      </swiper>
      <div
        v-if="more"
        class="swiper-page"
      >
        <div :class="`swiper-pagination swiper-pagination-` + type"></div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import ListBox2ItemPc from "@/components/Pc/common/ListBox2/item";
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import '@/assets/swiper.css'

export default {
  name: 'ListBox2Pc',
  components: {ListBox2ItemPc, Swiper, SwiperSlide},
  directives: {
    swiper: directive
  },
  props: {
    title: {
      type: String,
      default: () => ''
    },
    list: {
      type: Array,
      default: () => []
    },
    itemClick: {
      type: Function,
      default: () => () =>{}
    },
    field: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: () => {}
    }
  },
  data(){
    return {
      more: false,
      swiperOptions: {
        spaceBetween: 20,
        slidesPerView: 2,
        slidesPerColumn: 2,
        slidesPerGroup: 2,
        slidesPerColumnFill: 'row',
        pagination: {
          el: ".swiper-pagination-"+this.type,
          clickable: true,
        },
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    showMore(){
      switch(this.type){
        case 'product':
          if(this.list.length > 3){
            return true
          }
          break;
        case 'activity':
          if(this.list.length > 3){
            return true
          }
          break;
        case 'news':
          if(this.list.length > 4){
            return true
          }
          break;
        case 'case':
          if(this.list.length > 3){
            return true
          }
          break;
      }
      return false
    }
  }

}
</script>

<style scoped lang="scss">
.list-box2-pc{
  margin-bottom: 48px;
  .title{
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    line-height: 32px;
  }
  .list{
    width: 1140px;
    margin-top: 12px;
  }
  .loadmore{
    margin-top: 20px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-weight: 500;
    color: rgba(255,255,255,0.8);
    background: rgba(255,255,255,0.05);
    text-align: center;
  }
}
</style>
