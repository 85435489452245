<template>
  <div class="supply-demand-default">
    <supply-demand-pc
      v-if="browser===1"
      :change-category-current="changeCategoryCurrent"
      :change-key="changeKey"
      :change-type-id="changeTypeId"
      :get-list="getList"
      :options="options"
      :search="search"
    />
    <supply-demand-mobile
      v-if="browser===2"
      :change-category-current="changeCategoryCurrent"
      :change-key="changeKey"
      :change-type-id="changeTypeId"
      :get-list="getList"
      :options="options"
      :search="search"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import SupplyDemandPc from "@/views/Pc/SupplyDemand";
import SupplyDemandMobile from "../../Mobile/SupplyDemand";

export default {
  name: 'SupplyDemandDefault',
  components: {SupplyDemandMobile, SupplyDemandPc},
  data() {
    return {
      options: {
        categoryCurrent: 0,
        category: [
          {
            title: '全部',
            value: -1,
            color: '#333333'
          },
          {
            title: '24001', //供应信息
            value: 0,
            color: '#EA4C1E'
          },
          {
            title: '24002', //采购需求
            value: 1,
            color: '#0032AE'
          },
        ],
        typeList: [],
        typeId: 0,
        loaded: false,
        data: {
          list: [],
          key: '',
          dateid: 0,
          page: 1,
          pagesize: 6,
          total: 0,
          hasmore: true
        },
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    if(this.browser === 1){
      this.options.data.pagesize = 8
    }else{
      this.options.data.pagesize = 6
    }
    this.init()
    this.setShare()
  },
  methods: {
    init() {
      this.getType()
    },
    setShare(){
      this.getWxConfig(this.$t('10013') + this.bankuai(), '在线发布、在线匹配，高效对接，逐渐搭建成标准化的商贸平台。', 'https://virtualobs.ciif-expo.com/shareimg/7.png')
    },
    getType(){
      const params = {
        typeId: 122
      }
      this.$u.api.commons.getChildArchivesType(params).then(res => {
        if(res.status === 1){
          res.data.unshift({
            id: 0,
            name: '全部',
            ename: 'All'
          })
          this.options.typeList = res.data
          this.getList()
        }
      })
    },
    getList(){
      let params = {
        is_type: this.options.category[this.options.categoryCurrent].value,
        typeid: this.options.typeId,
        key: this.options.data.key,
        page: this.options.data.page,
        pagesize: this.options.data.pagesize
      }
      this.$u.api.supplydemands.getLists(params).then(res => {
        if(res.status === 1){
          if(this.options.data.page === 1){
            this.options.data.list = []
          }
          this.options.data.list = this.options.data.list.concat(res.data.lists)
          this.options.data.total = res.data.totalNum
          this.options.data.hasmore = Math.ceil(res.data.totalNum / this.options.data.pagesize) > this.options.data.page
          if(this.options.data.hasmore){
            this.options.data.page++
          }
        }
      })
    },
    changeCategoryCurrent(v){
      this.options.data.page = 1
      this.options.categoryCurrent = v
      this.getList()
    },
    search(){
      this.options.data.page = 1
      this.getList()
    },
    changeKey(key){
      this.options.data.key = key
    },
    changeTypeId(key){
      this.options.data.page = 1
      this.options.typeId = key
      this.getList()
    }
  }

}
</script>

<style scoped lang="scss">
.supply-demand-default{

}
</style>
