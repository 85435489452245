<template>
  <div>
    <div class="user-supply-follow-list-pc">
      <template v-for="(res, index) in options.data.list">
        <div
          :key="index"
          class="item"
        >
          <supply-demmand-item-supply-pc
            from="user"
            :info="res"
            :refresh="refresh"
            @click="show(res)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import SupplyDemmandItemSupplyPc from "../../SupplyDemand/SupplyDemandItem/component/supply";

export default {
  name: 'UserSupplyFollowListPc',
  components: {SupplyDemmandItemSupplyPc},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    showSupplyModal:{
      type: Function,
      default: () => () => {}
    },
    hideSupplyModal:{
      type: Function,
      default: () => () => {}
    },
    refresh:{
      type: Function,
      default: () => () => {}
    },
    changePage:{
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    show(info){
      info.is_follow = 1
      info.is_type = info.type
      this.showSupplyModal(info)
      this.$u.api.supplydemands.getDetail({id: info.id}).then(res => {
      })
    },
  }
}
</script>

<style scoped lang="scss">
.user-supply-follow-list-pc {
  display: flex;
  flex-wrap: wrap;
  .item{
    width: 410px;
    height: 128px;
    background: rgba(255,255,255,0.1);
    padding: 18px 20px;
    margin-right: 16px;
    margin-bottom: 16px;
    &:nth-child(2n){
      margin-right: 0;
    }
  }
}
</style>
