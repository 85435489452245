<template>
  <div class="user-collect-pc user">
    <div class="container">
      <user-left-menu-pc />
      <div class="user-right">
        <div class="collect-type">
          <template v-for="(res, index) in options.typeList">
            <template v-if="lang !== 'en' || (lang ==='en' && !res.enHide)">
              <btn-big
                :key="index"
                class="item"
                :label="$t(res.title)"
                :on="options.type === res.type"
                @click="changeType(res.type)"
              />
            </template>
          </template>
        </div>
        <div class="collect-list">
          <bbs-list-pc
            v-if="options.type === 'bbs'"
            :change-page="changePage"
            :data="options.data"
          />
          <news-list-pc
            v-if="options.type === 'news'"
            :change-page="changePage"
            :data="options.data"
          />
          <awards-list-list-pc
            v-if="options.type === 'awards'"
            :change-page="changePage"
            :data="options.data"
            from="collect"
          />
          <case-list-pc
            v-if="options.type === 'case'"
            :change-page="changePage"
            :data="options.data"
            from="collect"
          />
          <exhibition-list-pc
            v-if="options.type === 'exhibition'"
            :change-page="changePage"
            :data="options.data"
            from="collect"
          />
          <exhibition-list-pc
            v-if="options.type === 'product'"
            :change-page="changePage"
            :data="options.data"
            from="collect"
            :product="true"
          />
          <user-collect-exhibition-card-list-pc
            v-if="options.type === 'exhibition_card'"
            :change-page="changePage"
            :data="options.data"
            from="collect"
          />
          <search-video-list-pc
            v-if="options.type === 'exhibition_video'"
            :change-page="changePage"
            :data="options.data"
            from="collect"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import UserLeftMenuPc from "../../../components/Pc/User/UserLeftMenu";
import BtnBig from "../../../components/Pc/common/BtnBig";
import BbsListPc from "../../../components/Pc/Bbs/BbsList";
import AwardsListListPc from "../../../components/Pc/Awards/AwardsList";
import CaseListPc from "../../../components/Pc/Case/caseList";
import ExhibitionListPc from "../../../components/Pc/Exhibition/ExhibitionList";
import UserCollectExhibitionCardListPc from "../../../components/Pc/User/UserCollectExhibitionCardList";
import SearchVideoListPc from "../../../components/Pc/Search/SearchVideoList/index";
import NewsListPc from "../../../components/Pc/News/NewsList";

export default {
  name: 'UserCollectPc',
  components: {
    NewsListPc,
    SearchVideoListPc,
    UserCollectExhibitionCardListPc,
    ExhibitionListPc, CaseListPc, AwardsListListPc, BbsListPc, BtnBig, UserLeftMenuPc},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeType: {
      type: Function,
      default: () => () => {}
    },
    changePage: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.user-collect-pc {
  .collect-type{
    display: flex;
    justify-content: center;
    align-items: center;
    .item{
      margin: 0 8px;
    }
  }
  .collect-list{
    margin-top: 20px;
  }
}
</style>
