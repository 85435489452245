<template>
  <a
    class="btn-pc"
    :class="on ? `on` : ``"
    :href="link ? link : `javascript: void(0)`"
    :style="{background: inactiveBg, color: inactiveColor}"
    :target="link ? `_blank` : ``"
    @click="$emit('click')"
  >
    {{ label }}
  </a>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'BtnBig',
  props:{
    label: {
      type:String,
      default: () => ''
    },
    link: {
      type:String,
      default: () => ''
    },
    on: {
      type:Boolean,
      default: () => false
    },
    inactiveBg: {
      type: String,
      default: () => 'rgba(255, 255, 255, 0.1000)'
    },
    inactiveColor: {
      type: String,
      default: () => 'rgba(255,255,255,0.8 )'
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.btn-pc{
  cursor: pointer;
  display: inline-block;
  line-height: 40px;
  height: 40px;
  font-weight: 500;
  background: linear-gradient(223deg, #fcede9, #fdf4f0);
  padding: 0 32px;
  color: #EA4C1E;
  vertical-align: top;
  &.on, &:hover{
    background: linear-gradient(223deg, #FF4F1A, #FF9B61)!important;
    color: #fff!important;
  }
}
</style>
