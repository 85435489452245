<template>
  <div class="product-list-exhibition-thumb1-pc">
    <div class="container">
      <div class="header-titile">
        产品介绍<span>Product Description</span>
      </div>
    </div>
    <div class="list">
      <div class="container">
        <swiper
          ref="mySwiper"
          :options="swiperOptionsTop"
        >
          <swiper-slide
            v-for="(res, index) in list"
            :key="index"
          >
            <product-item-exhibition-thumb1-pc
              :key="index"
              :companyid="options.id"
              :info="res"
            />
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination-product-pc"></div>
      </div>
    </div>
  </div>
</template>

<script>

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import '@/assets/swiper.css'
import {mapState} from "vuex";
import ProductItemExhibitionThumb1Pc from "./ProductItemExhibitionThumb1";

export default {
  name: 'ProductListExhibitionThumb1Pc',
  components: {ProductItemExhibitionThumb1Pc, Swiper, SwiperSlide},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return{
      swiperOptionsTop: {
        spaceBetween: 20,
        slidesPerView: 3,
        slidesPerColumn: 1,
        slidesPerGroup: 3,
        slidesPerColumnFill: 'row',
        pagination: {
          el: ".swiper-pagination-product-pc",
          clickable: true,
        },
      },
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>
<style lang="scss">
.product-list-exhibition-thumb1-pc{
  .swiper-pagination-bullet{
    width: 20px;
    height: 20px;
    border: 2px solid #EA882C;
    border-radius: 10px;
    background: rgba(0,0,0,0);
    margin: 0 10px;
    &.swiper-pagination-bullet-active{
      background: #EA882C;
    }
  }
}
</style>
<style scoped lang="scss">
.product-list-exhibition-thumb1-pc {
  margin-top: 100px;
  .swiper-pagination-product-pc{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
  }
  .header-titile{
    padding: 0 40px;
    display: inline-block;
    height: 56px;
    line-height: 56px;
    text-align: center;
    font-size: 24px;
    color: #fff;
    background: #000;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    span{
      margin-left: 20px;
    }
  }
  .list{
    background: #fff;
    padding: 80px 0;
    .container{
      position: relative;
      .swiper-pagination{
        position: absolute;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
