<template>
  <div
    class="video-item-pc"
    :class="from"
    :style="{backgroundImage: 'url('+info.imageurl+')'}"
    @click="$emit('click')"
  >
    <div class="play-btn"></div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'VideoItemPc',
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    from: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.video-item-pc{
  display: inline-block;
  width: 212px;
  height: 310px;
  background-size: cover;
  margin-right: 16px;
  margin-bottom: 16px;
  position: relative;
  transition: all 0.4s ease-in-out;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);

  &.from{
    width:198px; height: 290px;
  }

  &:nth-child(5n){
    margin-right: 0
  }

  &:hover{
    transform: scale(1.05);
  }
  .play-btn{
    width: 58px;
    height: 58px;
    background: url("../../../../assets/images/play-btn.png") no-repeat;
    background-size: 100% 100%;
    background-position: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -29px;
    margin-top: -29px;
  }
}
</style>
