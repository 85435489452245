<template>
  <div
    class="bbs-list-item-mobile"
    @click="navigateTo('/forum/info?id='+info.id)"
  >
    <div class="pic radius">
      <pic-mobile :src="info.pic" />
      <div
        v-if="parseInt(info.zhuangtai) === 2 || parseInt(info.zhuangtai) === 4"
        class="play-btn"
      ></div>
    </div>
    <div class="info">
      <div class="title line-2">
        <liveing-mobile
          class="item"
          :zhuangtai="info.zhuangtai"
        />
        <div
          class="title-html"
          v-html="title"
        ></div>
      </div>
      <div class="tags line-1">
        <template v-for="(res, index) in cateName">
          <tags-item-mobile
            :key="index"
            :dark="true"
            :info="res"
          />
        </template>
      </div>
      <div class="other">
        <div class="left">
          <icon-text-time-mobile
            class="item"
            color="#999999"
            :dark="true"
            :label="info.tag"
          />
          <icon-text-location-mobile
            class="item"
            color="#999999"
            :label="$t(info.shorttitle.toString())"
          />
        </div>
        <bbs-join-btn-mobile
          v-if="!info.isSubmitEndTime && info.id != 336"
          :id="info.id"
          :big="false"
          class="join-btn-mobile"
          height="46"
          width="124"
        />
        <icon-text-collect-mobile
          :id="info.id"
          :bbs="true"
          class="shoucang"
          color="rgba(255,255,255,.7)"
          font-size="20px"
          label=""
          :set-collect="setCollect"
          :shoucang="parseInt(info.is_cang) === 1"
          :type="4"
          width="40px"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "../../common/Pic";
import TagsItemMobile from "../../common/TagsItem";
import IconTextLocationMobile from "../../common/IconText/location";
import IconTextTimeMobile from "../../common/IconText/time";
import IconTextCollectMobile from "../../common/IconText/collect";
import IconTextDelMobile from "../../common/IconText/del";
import LiveingMobile from "../../common/Liveing";
import BbsJoinBtnMobile from "../BbsJoinBtnMobile";

export default {
  name: 'BbsListItemMobile',
  components: {
    IconTextCollectMobile,
    BbsJoinBtnMobile,
    LiveingMobile,
    IconTextTimeMobile, IconTextLocationMobile, TagsItemMobile, PicMobile, IconTextDelMobile},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    keyword: {
      type: String,
      default: () => ''
    },
    type: {
      type: String,
      default: () => ''
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    title(){
      return this.replaceSearch(this.lang, this.keyword, this.info.title, this.info.title_en)
    },
    cateName(){
      let cat = this.info.catename
      if(cat && cat.length > 0){
        cat.sort((a, b) => a.name.length - b.name.length)
      }
      return cat
    }
  },
  methods: {
    setCollect(v){
      this.info.is_cang = v
      this.refresh()
    }
  }
}
</script>

<style scoped lang="scss">
.bbs-list-item-mobile {
  position: relative;
  margin-bottom: 38px;
  background: rgba(255, 255, 255, 0.1000);
  border-radius: 12px;
  .join-btn-mobile{
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  .pic{
    width: 678px;
    height: 382px;
    background: #FFFFFF;
    position: relative;
    .play-btn{
      width: 60px;
      height: 60px;
      background: url("../../../../assets/images/play-btn.png");
      background-size: 100% 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -30px;
      margin-top: -30px;
    }
  }
  .info{
    padding: 20px;
    .shoucang{
      position: relative;
      bottom: 60px;
      right: 20px;
    }
  }
  .title{
    font-size: 30px;
    font-weight: 600;
    color: #fff;
    line-height: 40px;
    margin-top: 18px;
    .item{
      display: inline-block;
    }
    .title-html{
      display: inline
    }
  }
  .tags{
    margin-top: 16px;
    height: 32px;
    width: 80%;
  }
  .other{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left{
      width: calc(100vw - 260px);
      overflow: hidden;
      text-overflow: ellipsis;
      .item{
        margin-top: 10px;
      }
    }
  }
}
</style>
