<template>
  <div class="about-link">
    <div class="box-big-title">
      {{ $t('28010') }}
    </div>
    <div class="about-link-list">
      <template v-for="(res, index) in list">
        <about-link-item
          :key="index"
          :info="res"
        />
      </template>
    </div>
  </div>
</template>

<script>

import AboutLinkItem from "./aboutLink/aboutLinkItem";
export default {
  name: 'AboutLink',
  components: {AboutLinkItem},
  data(){
    return {
      list: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init(){
      this.$u.api.commons.getNewAds({typeId: 2}).then(res => {
        if(res.status === 1){
          this.list = res.data
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.about-link{
  margin-bottom: 48px;
  .about-link-list{
    margin-top: 28px;
  }
}
</style>
