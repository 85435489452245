<template>
  <div
    class="case-item-exhibition-thumb1-mobile"
    @click.stop="navigateTo('/case/info?id=' + info.id)"
  >
    <div class="pic">
      <pic-pc :src="info.pic" />
    </div>
    <div class="info">
      <div class="title">
        {{ info.title }}
      </div>
      <div class="summary">
        {{ info.description }}
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicPc from "../../../common/Pic";

export default {
  name: 'CaseItemExhibitionThumb1Mobile',
  components: {PicPc},
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.case-item-exhibition-thumb1-mobile {
  .pic{
    width: 100%;
    height: 278px;
  }
  .info{
    margin-top: 20px;
    .title{
      height: 42px;
      font-size: 26px;
      font-weight: bold;
      color: #343434;
      line-height: 42px;
    }
  }
}
</style>
