<template>
  <div class="news-index-mobile">
    <top-nav-mobile>
      <span class="nav-title">{{ $t('10006') }}</span>
    </top-nav-mobile>
    <news-swiper-mobile :options="options" />
    <div class="container">
      <news-tabs-mobile
        :change-typeid="changeTypeid"
        class="tabs-view"
        :options="options"
      />
      <news-list-mobile
        :change-page="changePage"
        :data="options.data"
        :options="options"
      />
    </div>
    <news-hezuo-mobile :hezuo="options.hezuo" />
  </div>
</template>

<script>

import {mapState} from "vuex";
import NewsSwiperMobile from "../../../components/Mobile/News/NewsSwiper";
import NewsTabsMobile from "../../../components/Mobile/News/NewsTabs";
import NewsListMobile from "../../../components/Mobile/News/NewsList";
import NewsHezuoMobile from "../../../components/Mobile/News/NewsHezuo";
import TopNavMobile from "../../../components/Mobile/common/TopNav";

export default {
  name: 'NewsIndexMobile',
  components: { TopNavMobile, NewsHezuoMobile, NewsListMobile, NewsTabsMobile, NewsSwiperMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeTypeid: {
      type: Function,
      default: () => () =>{}
    },
    changePage: {
      type: Function,
      default: () => () =>{}
    },
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.news-index-mobile {
  .tabs-view{
    margin-top: 60px;
    margin-bottom: 38px;
  }
}
</style>
