<template>
  <div
    class="bbs-richeng-item-mobile"
  >
    <div class="time">
      {{ info.livestime }}
    </div>
    <div class="text">
      {{ $t2(lang, info.livestitle, info.livestitle_en) }}
    </div>
    <div
      v-if="info.livesurl"
      class="play-btn"
    ></div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'BbsRichengItemMobile',
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },

}
</script>

<style scoped lang="scss">
.bbs-richeng-item-mobile{
  margin-bottom: 20px;
  background: linear-gradient(223deg, rgba(255,79,26,0.17) 0%, rgba(255,155,97,0.19) 100%);
  box-shadow: 0 8px 15px 0 rgba(222, 50, 0, 0.1);
  border-radius: 12px;
  line-height: 32px;
  position: relative;
  cursor: pointer;
  padding: 30px 0 30px 32px;
  vertical-align: top;

  &:hover, &.on{
    background: linear-gradient(223deg, rgba(255, 81, 26, 0.8), rgba(255, 155, 97, 0.9));
    .text{
      color: #fff;
    }
    .time{
      color:#fff;
      opacity: .7;
    }
    &:before{
      display: inline-block;
      position: absolute;
      width: 6px;
      height: 78px;
      background: linear-gradient(223deg, #FF501A, #FF9B61);
      border-radius: 3px;
      content: ' ';
      top: 0;
      left: -38px;
    }
  }
  .time{
    display: inline-block;
    color: rgba(255,255,255,0.7);
    font-weight: 400;
    float: left;
  }
  .text{
    width: calc(100vw - 358px);
    display: inline-block;
    margin-left: 20px;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
  }
  .play-btn{
    width: 32px;
    height: 32px;
    background: url("../../../../assets/images/play-btn.png");
    background-size: 100% 100%;
    background-position: center;
    position: absolute;
    top: 50%;
    right: 30px;
    margin-top: -16px;
    opacity: .5;
    display: none;
  }
  &:hover{
    .play-btn{
      display: inline-block;
    }
  }
}
</style>
