<template>
  <div class="bbs-jiabin-modal-mobile">
    <div class="cell">
      <div class="box">
        <div class="close-btn">
          <btn-close-mobile
            size="68"
            @click="hideJiabin"
          />
        </div>
        <div class="main">
          <div class="pic radius">
            <pic-mobile :src="info.jiabinpic" />
          </div>
          <div class="info">
            <div class="title">
              <div class="bar"></div>
              <div class="title-desc">
                <div class="text">
                  {{ $t2(lang, info.xingming, info.xingming_en) }}
                </div>
                <div class="type">
                  <div class="type-name">
                    {{ $t2(lang, htmlspecialchars_decode(info.qiye), htmlspecialchars_decode(info.qiye_en)) }}
                  </div>
                  <div
                    class="child-type-name"
                    v-html="$t2(lang, htmlspecialchars_decode(info.zhiwu), htmlspecialchars_decode(info.zhiwu_en))"
                  >
                  </div>
                </div>
              </div>
            </div>
            <div class="jieshao-content">
              <text-content-mobile
                :content="info.scontent"
                title=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "../../common/Pic";
import BtnCloseMobile from "../../common/BtnCloseMobile";
import TextContentMobile from "../../common/TextContent";

export default {
  name: 'BbsJiabinModalMobile',
  components: {TextContentMobile, BtnCloseMobile, PicMobile},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    hideJiabin: {
      type: Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      contactShow: false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    contactInfo() {
      return JSON.parse(this.htmlspecialchars_decode(this.info.contact))
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.bbs-jiabin-modal-mobile{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  background: rgba(0,0,0,0.7);
  display: table;
  .cell{
    display: table-cell;
    vertical-align: middle;
    .box {
      position: relative;
      width: 686px;
      background: rgba(26, 26, 26, 1);
      margin: 0 auto;
      .close-btn{
        width: 68px;
        height: 68px;
        position: absolute;
        left: 50%;
        margin-left: -34px;
        bottom: -100px;
      }
      .main{
        padding: 16px;
        &:before{
          content: '';
          display: inline-block;
          width: 13px;
          height: 400px;
          background: url("../../../../assets/images/mobile/jiabin_chi.png");
          background-size: 100% 100%;
          position: absolute;
          top: 0;
          left: 448px;
        }
        .pic{
          height: 400px;
          width: 400px;
          //height: calc(686px - 32px);
          position: relative;
        }
        .info{
          .title{
            margin-top: 52px;
            line-height: 32px;
            color: rgba(255,255,255,0.8);
            .bar{
              width: 6px;
              height: 100px;
              background: linear-gradient(223deg, #FC501E, #FA8958);
              border-radius: 3px;
              display: inline-block;
              vertical-align: top;
              float:left
            }
            .type{
              line-height: 35px;
              .child-type-name{
                color: #fff;
              }
              .type-name{
                color: #999;
              }
            }
            .title-desc{
              vertical-align: top;
              margin-left: 20px;
              .text{
                display: inline-block;
                font-size: 40px;
                font-weight: 500;
                color: #fff;
              }
            }
          }
          .jieshao-content{
            height: 178px;
            overflow-y: auto;
            margin-top: 20px;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
