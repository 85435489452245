<template>
  <div
    class="live-tabs-item-pc"
    :class="on ? `on` : ``"
    @click.stop="changeTypeId(info.id)"
  >
    {{ $t2(lang, info.name, info.name_en) }}
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'LiveTabsItemPc',
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    on: {
      type: Boolean,
      default: () => false,
    },
    changeTypeId: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.live-tabs-item-pc{
  display: inline-block;
  margin: 0 50px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  line-height: 20px;
  position: relative;
  cursor: pointer;
  &.on{
    &:after{
      position: absolute;
      bottom: -10px;
      left: 50%;
      margin-left: -14px;
      content: ' ';
      display: inline-block;
      width: 28px;
      height: 4px;
      background: linear-gradient(223deg, #E94D1D, #FFAB91);
      border-radius: 10px;
    }
  }
}
</style>
