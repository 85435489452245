<template>
  <div class="rank-index-pc">
    <div class="container">
      <div class="rank-box">
        <rank-list-pc
          v-if="lang !== 'en'"
          :field="{title: 'title', en_title: 'title_en', num: 'click'}"
          :list="options.hotList"
          title="50039"
          title-bg="1"
          type="hotList"
        />
        <rank-list-pc
          v-if="lang !== 'en'"
          :field="{title: 'title', en_title: 'en_title', num: 'click'}"
          :list="options.informationList"
          title="50040"
          title-bg="2"
          type="informationList"
        />
        <rank-list-pc
          :field="{title: 'title', en_title: 'title_en', num: 'zan'}"
          :list="options.bbsList"
          title="50041"
          title-bg="3"
          type="bbsList"
        />
        <rank-list-pc
          :field="{title: 'companyname', en_title: 'companynameen', num: 'zan'}"
          :list="options.zhanList"
          title="50042"
          title-bg="4"
          type="zhanList"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import RankListPc from "@/components/Pc/Rank/RankList";

export default {
  name: 'RankIndexPc',
  components: {RankListPc},
  props: {
    options: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.rank-index-pc{
  .rank-box{
    width: 1039px;
    margin: 0 auto;
    overflow: hidden;
  }
}
</style>
