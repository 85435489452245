<template>
  <div class="topic-zhanshang-list-pc">
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'TopicZhanshangListPc',
  props: {
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.topic-zhanshang-list-pc {

}
</style>
