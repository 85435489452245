<template>
  <div class="page-common-pc">
    <div
      v-if="(data.total) / data.pagesize > 1"
      class="page"
    >
      <el-pagination
        background
        :current-page.sync="data.page"
        layout="prev, pager, next, jumper"
        :next-text="$t('下一页')"
        :page-size="data.pagesize"
        :prev-text="$t('上一页')"
        :total="parseInt(data.total)"
        @current-change="changePage"
      />
    </div>
  </div>
</template>

<script>

import '@/assets/element-ui/pagination.css'
export default {
  name: 'PageCommonPc',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    changePage: {
      type: Function,
      default: () => () => {}
    }
  }
}
</script>

<style scoped lang="scss">
.page-common-pc{

}
</style>
