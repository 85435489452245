<template>
  <div
    class="icon-text-line-exhibition-mobile line-1"
    :class="className"
    @click="$emit('click')"
  >
    <div class="icon"></div>
    <div
      class="text"
      :style="{color: color}"
      v-html="(text || '')"
    >
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextLineExhibitionMobile',
  props: {
    icon: {
      type: String,
      default: () => ''
    },
    text: {
      type: String,
      default: () => ''
    },
    className: {
      type: String,
      default: () => ''
    },
    color: {
      type: String,
      default: () => '#666666'
    },
    hover: {
      type: Boolean,
      default: () => true
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }
}
</script>

<style scoped lang="scss">
.icon-text-line-exhibition-mobile{
  margin-bottom: 16px;
  line-height: 30px;
  &.position{
    .icon{
      width: 30px;
      height: 30px;
      margin-right: 13px;
      display: inline-block;
      cursor: pointer;
      float: left;
      background: url("../../../../assets/images/exhibition_theme_1/icon_location@2x.png");
      background-size: 100% 100%;
    }
  }
  &.address{
    .icon{
      width: 30px;
      height: 30px;
      margin-right: 13px;
      display: inline-block;
      cursor: pointer;
      float: left;
      background: url("../../../../assets/images/exhibition_theme_1/icon_address@2x.png");
      background-size: 100% 100%;
    }
  }
  &.tel{
    .icon{
      width: 30px;
      height: 30px;
      margin-right: 13px;
      display: inline-block;
      cursor: pointer;
      float: left;
      background: url("../../../../assets/images/exhibition_theme_1/icon_tel@2x.png");
      background-size: 100% 100%;
    }
  }
  .text{
    font-size: 24px;
    font-weight: 400;
    color: #fff;
    cursor: pointer;
    margin-left: 18px;
    white-space: nowrap;
  }
}
</style>
