<template>
  <div class="live-history-list-mobile">
    <template v-for="(res, index) in (options.history || options.liveList)">
      <live-history-item-mobile
        :key="index"
        :change-live-id="changeLiveId"
        :info="res"
        :options="options"
      />
    </template>
  </div>
</template>

<script>
import {mapState} from "vuex";
import LiveHistoryItemMobile from "./item";

export default {
  name: 'LiveHistoryListMobile',
  components: {LiveHistoryItemMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeLiveId: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.live-history-list-mobile {
  margin-top: 20px;
  overflow: hidden;
}
</style>
