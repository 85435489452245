<template>
  <div class="news-index-pc">
    <div class="container">
      <div class="main-box">
        <news-swiper-pc :list="options.swiper" />
        <div class="types">
          <template v-for="(res, index) in options.childType">
            <btn-big
              :key="index"
              class="item"
              :label="res.name"
              :on="options.childTypeId === res.id"
              @click="changeTypeid(res.id)"
            />
          </template>
        </div>
        <empty-pc
          v-if="!options.loading && parseInt(options.data.total) === 0"
          description="空空如也"
        />
        <news-list-pc
          :change-page="changePage"
          :data="options.data"
          :options="options"
        />
        <news-hezuo-pc :hezuo="options.hezuo" />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import NewsSwiperPc from "@/components/Pc/News/NewsSwiper";
import BtnBig from "@/components/Pc/common/BtnBig";
import NewsListPc from "@/components/Pc/News/NewsList";
import EmptyPc from "@/components/Pc/common/Empty";
import NewsHezuoPc from "../../../components/Pc/News/NewsHezuo";

export default {
  name: 'NewsIndexPc',
  components: {NewsHezuoPc, EmptyPc, NewsListPc, BtnBig, NewsSwiperPc},
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    changeTypeid: {
      type: Function,
      default: () => () =>{}
    },
    changePage: {
      type: Function,
      default: () => () =>{}
    },
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.news-index-pc{
  .types{
    text-align: center;
    margin-top: 48px;
    margin-bottom: 20px;
    .item{
      margin: 0 8px;
    }
  }
}
</style>
