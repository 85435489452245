<template>
  <div
    class="icon-text-share"
    :class="{dark}"
    @click="showQrcode"
    @mouseleave="show=false"
  >
    <div class="icon"></div>
    <div class="label">
      {{ label }}
    </div>
    <div
      v-show="show"
      class="erweima-view"
    >
      <div class="sanjiao"></div>
      <div
        ref="qrCodeUrl"
        class="qrcode"
      >
        <div class="icon-logo">
          <div
            class="img"
            :style="{background:'url('+(icon ? icon : require('../../../../assets/images/logo_ico.png'))+')', backgroundSize: '100% 100%'}"
          ></div>
        </div>
      </div>
      <div class="tips">
        <p>请使用微信扫一扫</p>
        <p>预约本场直播提醒</p>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import QRCode from 'qrcodejs2'
import $ from 'jquery'

export default {
  name: 'IconTextWxShare',
  props: {
    id: {
      type: [String,Number],
      default: () => ''
    },
    label: {
      type: String,
      default: () => ''
    },
    purl: {
      type: String,
      default: () => ''
    },
    icon: {
      type: String,
      default: () => ''
    },
    dark: {
      type: Boolean,
      default: () => false
    }
  },
  data(){
    return {
      show: false,
      url: ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    nowPath: function(){
      return this.$route.path.split('/')[1]
    },
    nowPath1: function(){
      return this.$route.path.split('/')[2]
    },
  },
  mounted() {
    this.url = window.location.href + (window.location.href.indexOf('?') > -1 ? '&' : '?') + 'share=1';
  },
  activated() {
    this.url = window.location.href + (window.location.href.indexOf('?') > -1 ? '&' : '?') + 'share=1';
    this.creatQrCode()
  },
  methods: {
    showQrcode(){
      if(this.show){
        return
      }
      let type = 1, id = this.$route.query.id
      //类型，1展商2评奖3是视频4论坛,5直播,6资讯,7活动,8案例
      switch(this.nowPath.toLowerCase()){
        case 'exhibition':
          type = 1
          if(this.nowPath1.toLowerCase() === 'productinfo'){
            type = 9
          }
          break;
        case 'awards':
          type = 2
          break;
        case 'video':
          type = 3
          break;
        case 'forum':
          type = 4
          break;
        case 'live':
          type = 5
          break;
        case 'news':
          type = 6
          break;
        case 'activity':
          type = 7
          break;
        case 'case':
          type = 8
          break;
      }
      this.$u.api.ajaxs.actShare({t:type, id: id})
      this.creatQrCode();
      this.show=true
    },
    creatQrCode() {
      $('.qrcode').find('img').remove();
      $('.qrcode').find('canvas').remove();
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.purl || this.url, // 需要转换为二维码的内容
        width: 104,
        height: 104,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    }
  }

}
</script>

<style scoped lang="scss">
.icon-text-share{
  display: inline-block;
  width: 20px;
  position: relative;
  z-index: 6;
  cursor: pointer;
  .icon{
    width: 20px;
    height: 20px;
    background: url("../../../../assets/images/yuyue.png") no-repeat;
    background-size: 100% 100%;
    &:hover{
      background: url("../../../../assets/images/yuyue.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  &.dark{
    .icon{
      width: 20px;
      height: 20px;
      background: url("../../../../assets/images/yuyue.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .label{
    height: 20px;
    color: rgba(255,255,255,0.8);
    font-size: 12px;
    white-space: nowrap;
    font-weight: 400;
    line-height: 20px;
    transform: scale(0.9);
    margin-left: -2px;
  }
  .erweima-view{
    position: absolute;
    left: -65px;
    top: 48px;
    width: 138px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient( #FA8958, #FC501E);
    box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.1);
    flex-direction: column;
    padding: 15px 0 10px 0;
    .qrcode{
      width: 104px;
      height: 104px;
      border: 2px solid #fff;
    }
    .icon-logo{
      width: 30px;
      height: 30px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -13px;
      margin-top: -23px;
      overflow: hidden;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      .img{
        width: 26px;
        height: 26px;
        background: url("../../../../assets/images/logo_ico.png");
        background-size: 100% 100%!important;
      }
    }
  }
  .tips{
    text-align: center;
    font-size: 12px;
    color: #fff;
    margin-top: 10px;
  }
  .sanjiao{
    position: absolute;
    top: -5px;
    left: 50%;
    z-index: 3;
    width: 0;
    height: 0;
    border-bottom: 6px solid #FA8958;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }
}
</style>
