<template>
  <div class="user-collect-video-list-mobile">
    <div class="list">
      <template v-for="(res, index) in data.list">
        <video-item-mobile
          :key="index"
          :info="res"
          @click="showPlayer(res.videourl)"
        />
      </template>
      <page-common-mobile
        :change-page="changePage"
        :data="data"
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import VideoItemMobile from "../../common/VideoList/item";
import PageCommonMobile from "../../common/PageCommon";

export default {
  name: 'UserCollectVideoListMobile',
  components: {PageCommonMobile, VideoItemMobile},
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    changePage: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    showPlayer(videoUrl) {
      this.$bus.$emit('showPlayer', videoUrl)
    },
  }
}
</script>

<style scoped lang="scss">
.user-collect-video-list-mobile {
  overflow-x: scroll;
  justify-content: flex-start;
  .list{
    display: flex;
    flex-wrap: nowrap;
  }
}
</style>
