<template>
  <div>
    <div
      v-if="fixed"
      class="top-zhanwei"
    >
    </div>
    <div
      class="top-nav-btn-mobile"
      :style="{position: fixed ? 'fixed' : exhibition ? '' : '', top: 0, left: 0, width: '100%'}"
    >
      <slot name="left"></slot>
      <slot name="right"></slot>
      <div
        v-if="btns"
        class="leftbtns"
        :style="{width: other ? 'auto' : '33.33%'}"
      >
        <homeh-btn-mobile v-if="!isBack && isHome" />
        <back-btn-mobile
          v-if="isBack"
          :backicon="backicon"
          :backurl="backurl"
          :url="url"
        />
      </div>
      <slot
        class="title"
        name="default"
      ></slot>
      <div
        v-if="btns"
        class="btns"
        :style="{width: other ? 'auto' : '33.33%'}"
      >
        <slot name="other"></slot>
        <div
          class="right-btns"
        >
          <homeh-btn-mobile v-if="rightHomeBtn" />
          <vr-btn-mobile
            v-if="vr"
            :vr="vr"
          />
          <lang-btn-mobile />
          <search-btn-mobile v-if="search" />
          <logout-btn-mobile
            v-if="logout"
            class="item"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import HomehBtnMobile from "../HomeBtn";
import SearchBtnMobile from "../SearchBtn";
import LangBtnMobile from "../LangBtn";
import VrBtnMobile from "../VrBtn";
import BackBtnMobile from "../BackBtn";
import LogoutBtnMobile from "../LogoutBtn";

export default {
  name: 'TopNavMobile',
  components: {LogoutBtnMobile, BackBtnMobile, LangBtnMobile, SearchBtnMobile, HomehBtnMobile, VrBtnMobile},
  props: {
    fixed: {
      type: Boolean,
      default: () => true
    },
    isHome: {
      type: Boolean,
      default: () => true
    },
    other: {
      type: Boolean,
      default: () => false
    },
    backicon: {
      type: String,
      default: () => ''
    },
    rightHomeBtn: {
      type: Boolean,
      default: () => false
    },
    exhibition: {
      type: Boolean,
      default: () => false
    },
    isBack: {
      type: Boolean,
      default: () => false
    },
    btns: {
      type: Boolean,
      default: () => true
    },
    search: {
      type: Boolean,
      default: () => true
    },
    logout: {
      type: Boolean,
      default: () => false
    },
    url: {
      type: String,
      default: () => ''
    },
    backurl: {
      type: String,
      default: () => '/'
    },
    vr: {
      type: String,
      default: () => ''
    },
    background: {
      type: String,
      default: () => '#000000'
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.top-zhanwei{
  height: 100px;
  width: 100%;
}
.top-nav-btn-mobile {
  height: 100px;
  line-height: 100px;
  background: url("../../../../assets/images/mobile/header_bg.png");
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 36px;
  z-index: 10;
  position: relative;
  width: 100%;
  overflow: hidden;
  &>div{
    width: 33.33%
  }
  &.fixed{
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 99999;
  }
  .default{
    flex: 1;
  }
  .title{
    font-size: 32px;
    font-weight: 500;
    color: #000000;
    line-height: 38px;
  }
  .right{
    min-width: 50px
  }
  .right-btns{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &>div{
      margin-left: 24px;
    }
    .item{
      margin-left: 24px;
    }
  }
  .btns{
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
