<template>
  <div class="activity-info-pc">
    <div class="container">
      <div class="main-box min-height-770">
        <back-btn-pc backurl="/activity" />
        <div class="info clearfix">
          <div class="pic-line">
            <div class="pic">
              <pic-pc :src="options.info.pic" />
            </div>
            <div class="title-info">
              <div class="title">
                {{ options.info.title }}
              </div>
              <div
                v-if="options.info.catename"
                class="tags"
              >
                <template v-for="(res, index) in options.info.catename">
                  <tag-item
                    :key="index"
                    :dark="true"
                    :label="$t2(lang, res.name, res.ename)"
                  />
                </template>
              </div>
              <div class="desc">
                <icon-text-time :label="timeFormat(options.info.done_start_time, 'yyyy/mm/dd hh:MM') + ` - ` + timeFormat(options.info.done_end_time, 'yyyy/mm/dd hh:MM')" />
                <icon-text-location :label="$t2(lang, options.info.cn_address, options.info.en_address)" />
              </div>
              <div class="btn-line">
                <div class="btn">
                  <activity-btn-pc
                    :info="options.info"
                    :people="options.info.people"
                    :status="options.info.is_status"
                    @click="yuyue"
                  />
                  <div
                    v-if="options.info.is_status === 0"
                    class="people"
                  >
                    剩余{{ options.info.people }}人
                  </div>
                </div>
                <icon-text-share
                  class="share-btn"
                  label="分享"
                />
              </div>
            </div>
          </div>
        </div>
        <text-content-pc
          :content="$t2(lang, options.info.content, options.info.en_content)"
          margin-top="48px"
          title="活动介绍"
        />
      </div>
    </div>
    <activity-reserve-modal-pc
      v-if="reserveShow"
      :hide-reserve-modal="hideReserveModal"
      :info="reserveInfo"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import BackBtnPc from "@/components/Pc/common/BackBtn";
import PicPc from "@/components/Pc/common/Pic";
import TagItem from "@/components/Pc/common/TagItem";
import IconTextTime from "@/components/Pc/common/IconText/time";
import IconTextLocation from "@/components/Pc/common/IconText/location";
import ActivityBtnPc from "@/components/Pc/Activity/ActivityBtn";
import IconTextShare from "@/components/Pc/common/IconText/share";
import TextContentPc from "@/components/Pc/common/TextContent";
import ActivityReserveModalPc from "../../../components/Pc/Activity/ActivityReserveModal";

export default {
  name: 'ActivityInfoPc',
  components: {
    ActivityReserveModalPc,
    TextContentPc, IconTextShare, ActivityBtnPc, IconTextLocation, IconTextTime, TagItem, PicPc, BackBtnPc},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  data(){
    return {
      reserveShow: false,
      reserveInfo: {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods:{
    showReserveModal(){
      this.reserveInfo = this.options.info
      this.reserveShow = true
    },
    hideReserveModal(){
      this.reserveShow = false
    },
    yuyue(){
      if(this.options.info.is_status === 0){
        this.showReserveModal(this.inf)
      }
      if((this.options.info.is_status === 2 || this.options.info.is_status === -1) && this.options.info.live_id > 0){
        this.navigateTo('/Live?typeCurrent=1&liveid=' + this.options.info.live_id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.activity-info-pc {
  .info{
    margin-top: 20px;
    width: 100%;
    .pic-line{
      height: 280px;
      width: 100%;
      background: rgba(255,255,255,0.1);
      .pic{
        width: 420px;
        height: 280px;
        float: left;
      }
      .title-info{
        padding: 30px 30px 30px 0;
        margin-left: 468px;
        .title{
          font-size: 20px;
          font-weight: 600;
          color: #fff;
          line-height: 28px;
          height: 56px;
        }
        .tags{
          margin-top: 8px;
        }
        .desc{
          margin-top: 14px;
        }
        .btn-line{
          margin-top: 28px;
          .btn{
            vertical-align: top;
            display: inline-block;
            width: 160px;
            .people{
              font-size: 12px;
              font-weight: 400;
              color: #B3B3B3;
              line-height: 12px;
              margin-top: 8px;
              text-align: center;
            }
          }
          .share-btn{
            vertical-align: top;
            margin-top: 13px;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
</style>
