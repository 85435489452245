<template>
  <div class="bbs-richeng-list-mobile">
    <template v-for="(res, index) in list">
      <div
        :key="index"
        class="richeng-list"
      >
<!--        <div class="title">-->
<!--          {{ date[index].pub }}-->
<!--        </div>-->
        <div class="list">
          <template v-for="(item, i) in res">
            <bbs-richeng-item-mobile
              :key="i"
              :info="res[i]"
            />
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import BbsRichengItemMobile from "./item";

export default {
  name: 'BbsRichengListMobile',
  components: {BbsRichengItemMobile},
  props: {
     list: {
       type: [Array, Object],
       default: () => []
     },
    date: {
       type: Object,
      default: () => {}
    }
  },
  data(){
    return {

    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },

}
</script>

<style scoped lang="scss">
.bbs-richeng-list-mobile{
  margin-top: 20px;
  .richeng-list{
    padding-top: 20px;
    .title{
      margin-left: 32px;
      font-size: 24px;
      font-weight: 600;
      color: rgba(255,255,255,0.6);
      line-height: 22px;
      opacity: .6;
    }
    .list{
      margin-top: 22px;
    }
  }
}
</style>
