<template>
  <div
    class="awards-item-pc"
    :class="from"
    @click="navigateTo('/awards/info?id='+info.id)"
  >
    <div class="pic">
      <pic-pc :src="info.pic" />
    </div>
    <div class="info">
      <div class="title line-2">
        <div v-html=" title"></div>
      </div>
      <div class="other">
        <div>
          <div class="cat">
            <icon-time-line
              color="rgba(255,255,255,0.6)"
              icon="jiang_hei.png"
              :margin-right="4"
              :size="16"
              :text="$t2(lang, info.catename.name, info.catename.ename)"
            />
          </div>
          <div class="name">
            {{ $t2(lang, info.companyname, info.companynameen) }}
          </div>
        </div>
        <icon-text-collect
          v-if="from==='collect'"
          :id="info.id"
          class="item"
          label=""
          :set-collect="setCollect"
          :shoucang="parseInt(info.is_cang) === 1"
          :type="2"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicPc from "@/components/Pc/common/Pic";
import IconTimeLine from "@/components/Pc/common/IconTimeLine";
import IconTextCollect from "../../common/IconText/collect";

export default {
  name: 'AwardsItemPc',
  components: {IconTextCollect, IconTimeLine, PicPc},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    from: {
      type: String,
      default: ''
    },
    keyword: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    title(){
      return this.replaceSearch(this.lang, this.keyword, this.info.title, this.info.title_en)
    },
  },
  methods: {
    setCollect(v){
      this.info.is_cang = v
    }
  }
}
</script>

<style scoped lang="scss">
.awards-item-pc{
  cursor: pointer;
  width: 550px;
  height: 180px;
  padding: 20px;
  background: rgba(255,255,255,0.1);
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  &.collect{
    width: 413px;
    height: 152px;
    padding: 16px;
    .pic{
      width: 120px;
      height: 120px;
    }
    .info{
      height: 160px;
      margin-left: 136px;
      .title{
        font-size: 16px;
        height: 48px;
        line-height: 24px;
        margin-top: 8px;
      }
      .other{
        bottom: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name{
          margin-top: 4px;
        }
      }
    }
  }
  &:hover{
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-3px);
    .pic{
      div{
        transition: all 0.4s ease-in-out;
        transform: scale(1.1);
      }
    }
  }
  .pic{
    width: 140px;
    height: 140px;
    background: #fff;
    float: left;
    overflow: hidden;
  }
  .info{
    margin-left: 160px;
    height: 180px;
    position: relative;
    .title{
      font-size: 20px;
      font-weight: 600;
      color: #fff;
      line-height: 28px;
    }
    .other{
      position: absolute;
      left: 0;
      bottom: 40px;
      width: 100%;

      .cat{

      }
      .name{
        margin-top: 8px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(255,255,255,0.8);
        line-height: 20px;
      }
    }
  }
}
</style>
