<template>
  <div
    class="btn-loukong-mobile"
    :class="on ? `on` : ``"
    :style="{marginRight: marginRight, marginLeft: marginLeft}"
    @click="$emit('click')"
  >
    {{ text }}
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'BtnLoukongMobile',
  props: {
    text: {
      type: String,
      default: () => ''
    },
    marginRight: {
      type: String,
      default: () => '0'
    },
    marginLeft: {
      type: String,
      default: () => '0'
    },
    on: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.btn-loukong-mobile{
  height: 56px;
  padding: 0 24px;
  border: 1px solid rgba(234,76,30,0.5);
  border-radius: 56px;
  line-height: 56px;
  font-weight: 500;
  text-align: center;
  color: #EA4C1E;
  font-size: 24px;
  cursor: pointer;
  &.on{
    background: #fbdbd2;
  }
}
</style>
