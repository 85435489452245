<template>
  <div class="about-banner">
    <img :src="info.pic" />
  </div>
</template>

<script>

export default {
  name: 'AboutBanner',
  props:{
    info: {
      type: Object,
      default(){
        return {}
      }
    }
  },
}
</script>

<style scoped lang="scss">
.about-banner{
  margin-bottom: 28px;
  img{
    width: 1120px;
  }
}
</style>
