<template>
  <div
    class="icon-text-zan"
    :class="{dark}"
    @click="actZan"
  >
    <div
      class="icon"
      :class="zan ? `on` : ``"
    ></div>
    <div class="label">
      {{ label }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextZan',
  props: {
    label: {
      type: [String, Number],
      default: () => ''
    },
    zan: {
      type: Boolean,
      default: () => false
    },
    type: {
      type: Number,
      default: () => 1
    },
    id: {
      type: [String, Number],
      default: () => 1
    },
    setZan:{
      type: Function,
      default: () => () => {}
    },
    dark: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    actZan(){
      const params = {
        t: this.type,
        id: this.id
      }
      this.$u.api.ajaxs.actZan(params).then(res => {
        if(res.status === 1){
          if(res.msg === '50047'){
            //收藏成功
            this.setZan(1, res.data.zan)
          }else{
            //取消收藏成功
            this.setZan(0, res.data.zan)
          }
        }
      })
    }
  }

}
</script>

<style scoped lang="scss">
.icon-text-zan{
  display: inline-block;
  width: 20px;
  cursor: pointer;
  .icon{
    width: 20px;
    height: 20px;
    background: url("../../../../assets/images/mobile/zan_hei.png") no-repeat;
    background-size: 100% 100%;
    &.on, &:hover{
      background: url("../../../../assets/images/mobile/zan_hei_on.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  &.dark{
    .icon{
      width: 20px;
      height: 20px;
      background: url("../../../../assets/images/mobile/zan_touming.png") no-repeat;
      background-size: 100% 100%;
      &.on, &:hover{
        width: 20px;
        height: 20px;
        background: url("../../../../assets/images/mobile/zan_on.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .label{
    height: 20px;
    color: rgba(255,255,255,0.8);
    font-size: 12px;
    white-space: nowrap;
    font-weight: 400;
    line-height: 20px;
    transform: scale(0.9);
    margin-left: -2px;
    text-align: center;
  }
}
</style>
