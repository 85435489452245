<template>
  <div
    class="video-item-mobile"
    @click="$emit('click')"
  >
    <div class="pic radius">
      <pic-mobile :src="info.imageurl" />
    </div>
    <div class="play-btn"></div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "@/components/Mobile/common/Pic";

export default {
  name: 'VideoItemMobile',
  components: {PicMobile},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.video-item-mobile {
  width: calc((100vw - 110px) / 3);
  margin-bottom: 20px;
  border-radius: 10px;
  position: relative;
  margin-right: 18px;
  display: inline-block;
  overflow: hidden;
  &:nth-child(3n){
    margin-right: 0;
  }
  .pic{
    width: 216px;
    height: 316px;
  }
  .play-btn{
    width: 60px;
    height: 60px;
    background: url("../../../../assets/images/play-btn.png");
    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -30px;
    margin-top: -30px;
  }
}
</style>
