<template>
  <div class="bbs-info-pc">
    <div class="container">
      <div class="main-box">
        <back-btn-pc backurl="/forum" />
        <div
          v-if="options.loaded"
          class="pic"
        >
          <pic-pc
              v-if="!options.info.livesurl"
              :src="options.info.pic"
          />
            <iframe
                v-else
              allowfullscreen="true"
              frameborder="0"
              height="100%"
              mozallowfullscreen="true"
              msallowfullscreen="true"
              oallowfullscreen="true"
              :src="options.info.livesurl"
              webkitallowfullscreen="true"
              width="100%"
            ></iframe>
        </div>
        <div class="title">
          <div class="title-cn">
            <liveing-pc :zhuangtai="options.info.zhuangtai" />
            {{ $t2(lang,options.info.title, options.info.title_en ) }}
          </div>
          <div class="title-en">
            {{ $t2(lang, options.info.title_en,options.info.title) }}
          </div>
        </div>
        <div class="tags">
          <template v-for="(res, index) in options.info.catename">
            <tag-item
              :key="index"
              class="item"
              :dark="true"
              :label="$t2(lang, res.name, res.ename)"
            />
          </template>
        </div>
        <div class="other">
          <icon-time-line
            class="item"
            icon="icon_clock.png"
            :text="options.info.tag"
          />
          <icon-time-line
            class="item"
            icon="icon_position.png"
            :text="options.info.shorttitle ? $t(options.info.shorttitle.toString()) : ''"
          />
          <bbs-join-btn-pc
            v-if="options.info.id > 0 && options.info.id != 336"
            :id="options.info.id"
          />
        </div>
        <div class="tab-list">
          <div class="tab-menu">
            <template v-for="(res, index) in tabMenu">
              <div
                :key="index"
                class="menu-item"
                :class="current === index ? `on` : ``"
                @click="current = index"
              >
                {{ $t(res.title) }}
              </div>
            </template>
            <div class="right-btn-view">
              <icon-text-viewnum
                class="btn-item"
                :label="options.info.click"
              />
              <icon-text-zan
                :id="options.info.id"
                class="btn-item"
                :label="options.info.zan"
                :set-zan="setZan"
                :type="2"
                :zan="parseInt(options.info.is_zan) >= 1"
              />
              <icon-text-collect
                :id="options.info.id"
                class="btn-item"
                :label="this.$t('50024')"
                :set-collect="setCollect"
                :shoucang="parseInt(options.info.is_cang) === 1"
                :type="4"
              />
              <icon-text-share
                class="btn-item"
                :label="options.info.share"
              />
            </div>
          </div>
          <split-line-pc
            bg-color="rgba(234, 76, 30, .1)"
            height="1px"
          />
          <div class="tab-content min-height-800">
            <div
              v-if="current===0"
              class="tab-item"
            >
              <text-content-pc :content="$t2(lang, options.info.content, options.info.content_en)" />
            </div>
            <div
              v-if="current === 1"
              class="tab-item jiabin"
            >
              <template v-for="(res, index) in options.info.jiabinList">
                <bbs-jiabin-item-pc
                  :key="index"
                  :info="res"
                  @click="showJiabin(res.id)"
                />
              </template>
            </div>
            <div
              v-if="current === 2"
              class="tab-item richeng"
            >
              <div class="richeng-view">
                <div class="left">
                </div>
                <div class="right">
                  <bbs-richeng-list-pc
                    :date="options.date"
                    :list="options.info.livesList"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bbs-jiabin-modal-pc
      v-if="options.jiabinShow"
      :hide-jiabin="hideJiabin"
      :info="options.jiabinInfo"
    />
  </div>
</template>
<script>
import {mapState} from "vuex";
import BackBtnPc from "@/components/Pc/common/BackBtn";
import TagItem from "@/components/Pc/common/TagItem";
import IconTimeLine from "@/components/Pc/common/IconTimeLine";
import SplitLinePc from "@/components/Pc/common/SplitLine";
import TextContentPc from "@/components/Pc/common/TextContent";
import BbsJiabinItemPc from "@/components/Pc/Bbs/BbsJiabinItem";
import BbsRichengListPc from "@/components/Pc/Bbs/BbsRichengList";
import IconTextViewnum from "@/components/Pc/common/IconText/viewnum";
import IconTextZan from "@/components/Pc/common/IconText/zan";
import IconTextCollect from "@/components/Pc/common/IconText/collect";
import IconTextShare from "@/components/Pc/common/IconText/share";
import PicPc from "@/components/Pc/common/Pic";
import BbsJiabinModalPc from "../../../components/Pc/Bbs/BbsJiabinModalPc";
import LiveingPc from "../../../components/Pc/common/Liveing";
import HlsPlayer from 'xgplayer-hls.js';
import BbsJoinBtnPc from "../../../components/Pc/Bbs/BbsJoinBtnPc";

export default {
  name: 'BbsInfoPc',
  components: {
    BbsJoinBtnPc,
    LiveingPc,
    BbsJiabinModalPc,
    PicPc,
    IconTextShare,
    IconTextCollect,
    IconTextZan,
    IconTextViewnum,
    BbsRichengListPc, BbsJiabinItemPc, TextContentPc, SplitLinePc, BackBtnPc, TagItem, IconTimeLine},
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    setZan: {
      type: Function,
      default: () => () => {}
    },
    showJiabin: {
      type: Function,
      default: () => () => {}
    },
    hideJiabin: {
      type: Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      current: 0,
      videoPlayer: null,
      loaded: false,
      tabMenu: [
        {
          title: '21101',
        },
        {
          title: '21102',
        },
        {
          title: '50023',
        }
      ],
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'user']),
  },
  watch: {
    options: {
      deep: true,
      handler(newVal, oldVal) {
        console.log(newVal)
        // if(newVal.info.livesurl !== '' && newVal.loaded){
        //   if (this.videoPlayer !== null) {
        //     this.videoPlayer.destroy()
        //     this.videoPlayer = null
        //   }
        //   this.$nextTick(() => {
        //     setTimeout(() => {
        //       this.videoPlayer = new HlsPlayer({
        //         id: 'welcomeVideo',
        //         url: newVal.info.livesurl,
        //         poster: newVal.info.pic,
        //         playsinline: true,
        //         fluid: true
        //       });
        //     },400)
        //   })
        // }
      }
    }
  },
  beforeDestroy() {
    if (this.videoPlayer !== null) {
      this.videoPlayer.dispose()
      this.videoPlayer = null
    }
  },
  activated() {
    if(this.user.mid){
      this.$u.api.ajaxs.actLive({t: 3, is_value: this.options.id});
    }
  },
  mounted() {
    console.log(this.options, this.options.id)
    if(this.user.mid){
      console.log('{t: 3, is_value: this.options.id}', {t: 3, is_value: this.options.id})
      this.$u.api.ajaxs.actLive({t: 3, is_value: this.options.id});
    }
  },
  methods: {
    login(){
      localStorage.setItem('backUrl', this.$router.currentRoute.path)
      localStorage.setItem('backQuery', JSON.stringify(this.$router.currentRoute.query))
      this.$router.push('/user/login')
    }
  }
}
</script>

<style scoped lang="scss">
.bbs-info-pc{
  .pic{
    margin-top: 12px;
    width: 100%;
    height: 630px;
    position: relative;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .title{
    line-height: 32px;
    margin-top: 8px;
    .title-cn{
      font-size: 20px;
      font-weight: 600;
      color: #fff;
      display: flex;
      align-items: center;
      height: 32px;
    }
    .title-en{
      height: 20px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
    }
  }
  .tags{
    margin-top: 16px;
    height: 24PX;
    .item{
      margin-right: 4px
    }
  }
  .other{
    margin-top: 14px;
    position: relative;
    .bbs-join-btn-pc{
      bottom: 0!important;
    }
    .item{
      margin-top: 2px;
      display: inline-block;
      margin-right: 16px;
    }
  }
  .tab-list{
    margin-top: 40px;
    .tab-menu{
      margin: 15px 0;
      .right-btn-view{
        float: right;
        margin-top: -10px;
        .btn-item{
          margin-left: 30px;
        }
      }
      .menu-item{
        display: inline-block;
        margin: 0 44px;
        font-size: 16px;
        font-weight: 700;
        color: #999;
        line-height: 20px;
        position: relative;
        cursor: pointer;
        &.on{
          color: #fff;
          &:after{
            content: ' ';
            width: 32px;
            display: inline-block;
            position: absolute;
            bottom: -20px;
            left: 16px;
            height: 8px;
            background: linear-gradient(223deg, #FF501A, #FF9B61);
            border-radius: 4px;
          }
        }
      }
    }
    .tab-content{
      margin-top: 20px;
    }
    .jiabin{
      margin-top: 40px;
    }
    .richeng{
      margin-top: 40px;
      .richeng-view{
        position: relative;
        .left{
          background: linear-gradient(223deg, #FF1A2C 0%, #FF9B61 100%);
          width: 2px;
          height: 100%;
          opacity: 0.2;
          position: absolute;
          left: 82px;
          top: 0;
        }
        .right{
          margin-left: 118px;
        }
      }
    }
  }
  .play-btn{
    width: 100px;
    height: 100px;
    background: url("../../../assets/images/play-btn.png");
    background-size: 100% 100%;
    background-position: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    &:hover{
        opacity: 0.8;
    }
  }
}
</style>
