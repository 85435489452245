<template>
  <div class="user-supply-my-list-item-pc">
    <div class="title-line clearfix">
      <div
        class="title line-1"
        @click="show(info)"
      >
        {{ info.title || info.title_en }}
      </div>
      <div class="btns">
        <div
          v-if="parseInt(info.is_status) !== 3 && parseInt(info.is_status) !== 4"
          class="edit-btn"
          @click="navigateTo('/user/post_supply?id='+info.id)"
        >
        </div>
        <div
          class="del-btn"
          @click="del(info.id)"
        >
        </div>
      </div>
    </div>
    <div class="summary line-1">
      <div v-html="info.content||info.content_en"></div>
    </div>
    <div class="tags-line">
      <div class="tags">
        <template v-for="(res, index) in info.catename">
          <tag-item
            :key="index"
            class="item"
            :dark="true"
            :label="res.name"
          />
        </template>
      </div>
      <div class="status">
        <span
          v-if="parseInt(info.is_status) === 3"
          :class="`status_`+info.is_status"
          @click="showSupplyProductLists(info.id)"
        >{{ status }}<span class="spanred">{{ info.productCount }}</span>个</span>
        <span
          v-else
          :class="`status_`+info.is_status"
        >{{ status }}</span>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TagItem from "../../common/TagItem";

export default {
  name: 'UserSupplyMyListItemPc',
  components: {TagItem},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    refresh: {
      type: Function,
      default: () => () =>  {}
    },
    showDemandModalInfo: {
      type: Function,
      default: () => () =>  {}
    },
    showDemandContactModalInfo:{
      type: Function,
      default: () => () => {}
    },
    showSupplyProductLists:{
      type: Function,
      default: () => () => {}
    },
    hideUserSupplyProductListModalShow:{
      type: Function,
      default: () => () => {}
    },
    hideDemandContactModal:{
      type: Function,
      default: () => () => {}
    },
    hideDemandModal:{
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    status(){
      switch(parseInt(this.info.is_status)){
        case 0:
          return '已通过'
        case 1:
          return '待审核'
        case 2:
          return '审核不通过'
        case 3:
          return '已匹配的供应产品'
        case 4:
          return '已下架'
      }
      return 0
    }
  },
  methods: {
    show(info){
      this.$u.api.users.getSupplyOne({id: info.id}).then(res => {
        if(res.status === 1){
          this.showDemandModalInfo(res.data)
        }
      })
    },
    del(id){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'pc-message'
      }).then(() => {
        const params = {
          id: id
        }
        this.$u.api.users.actSupplyDelete(params).then(res => {
            if(res.status === 1){
              this.$message.success('删除成功')
              this.refresh()
            }
        })
      }).catch(() => {

      })
    }
  }
}
</script>

<style scoped lang="scss">
.user-supply-my-list-item-pc {
  background: rgba(255,255,255,0.1);
  height: 124px;
  margin-top: 12px;
  padding: 18px 24px;
  .title-line{
    .title{
      font-size: 18px;
      font-weight: 600;
      color: #fff;
      width: 590px;
      line-height: 24px;
      float: left;
      cursor: pointer;
    }
    .btns{
      float: right;
      width: 220px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .edit-btn{
        cursor: pointer;
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-right: 24px;
        background: url("../../../../assets/images/edit.png");
        background-repeat: no-repeat;
        background-size: 100%;
        &:hover{
          background: url("../../../../assets/images/edit_on.png");
          background-repeat: no-repeat;
          background-size: 100%;
        }
      }
      .del-btn{
        width: 20px;
        height: 20px;
        display: inline-block;
        cursor: pointer;
        background: url("../../../../assets/images/del.png");
        background-repeat: no-repeat;
        background-size: 100%;
        &:hover{
          background: url("../../../../assets/images/del_on.png");
          background-repeat: no-repeat;
          background-size: 100%;
        }
      }
    }
  }
  .summary{
    width: 690px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255,255,255,0.6);
    line-height: 18px;
    height: 18px;
    margin-top: 8px;
    overflow: hidden;
  }
  .tags-line{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tags{
      margin-top: 16px;
      .item{
        margin-right: 4px;
      }
    }
    .status{
      float: right;
      line-height: 24px;
      text-align: right;
      width: 220px;
      display: inline-block;
      font-size: 12px;
      color: rgba(255,255,255,0.6);
      margin-top: 16px;
      cursor: pointer;
      span{
        padding: 0 12px;
        height: 24px;
        display: inline-block;
        text-align: center;
      }
      .status_2, .status_4{
        background: rgba(229, 1, 19, 0.1000);
        color: #E50113;
      }
      .status_1{
        background: rgba(0, 212, 0, 0.1000);
        color: rgba(0, 207, 31, 1);
      }
      .spanred{
        color:#EA4C1E;
      }
    }
  }
}
</style>
