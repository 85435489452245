<template>
  <div class="icon-time-line-mobile">
    <div
      class="icon"
      :style="{marginRight: marginRight + 'px'}"
    >
      <img
        :src="require('../../../../assets/images/'+icon)"
        :style="{width: size + 'px', height: size + 'px'}"
      />
    </div>
    <div
      class="text"
      :style="{color: color, fontSize: fontSize}"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTimeLineMoblie',
  props: {
    icon: {
      type: String,
      default: () => ''
    },
    text: {
      type: String,
      default: () => ''
    },
    color: {
      type: String,
      default: () => '#999'
    },
    size: {
      type: [Number, String],
      default: () => 14
    },
    fontSize: {
      type: [Number, String],
      default: () => 12
    },
    marginRight: {
      type: Number,
      default: () => 6
    },
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }
}
</script>

<style scoped lang="scss">
.icon-time-line-mobile{
  .icon{
    display: inline-block;
    margin-right: 6px;
    cursor: pointer;
    img{
      width: 14px;
      height: 14px;
      vertical-align: middle;
    }
  }
  .text{
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: #999;
    line-height: 18px;
    cursor: pointer;
  }
}
</style>
