<template>
  <div class="video-index-mobile">
    <video
      id="welcomeVideo"
      ref="video"
      class="video-js vjs-default-skin"
      playsinline="true"
      style="object-fit: cover; position: absolute; width: 100%; height: 100%; left: 0;top: 0"
      webkit-playsinline="true"
      x5-playsinline="true"
    >
      <source
        id="source"
        src="https://virtualobs.ciif-expo.com/video/89c30cc981e84c0c823a30eb4ca2159b/index.m3u8"
        type="application/x-mpegURL"
      />
    </video>
    <div
      class="tiaoguo"
      @click="navigateTo('/index')"
    >
      跳过
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'VideoIndexMobile',
  props: {
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  mounted() {
    // this.$refs.video.play()
    const _t = this;
    var myVideo = videojs('welcomeVideo', {
      loop: false,
      controls: true,
      preload: 'auto',
      autoplay: true
    }, function onPlayerReady() {
      this.on("ended", function(){
        _t.navigateTo('/index')
      });
    })
    document.addEventListener("WeixinJSBridgeReady", function () {
      myVideo.play()
    }, false);
  }
}
</script>

<style scoped lang="scss">
.video-index-mobile {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  video{
    width: 100%;
    height: 100%;
  }
  .tiaoguo{
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 28px;
    background: #666;
    color: #fff;
    border-radius: 10px;
    padding: 10px 20px;
  }
}
</style>
