<template>
  <div class="news-list-mobile">
    <template v-for="(res, index) in customList.length > 0 ? customList : (data ? data.list : [])">
      <news-list-item-mobile
        :key="index"
        :info="res"
        :keyword="(data && data.key) || ''"
        :options="options"
        :from="from"
      />
    </template>
    <div
      v-if="customList.length <= 0"
      class="page"
    >
      <page-common-mobile
        :change-page="changePage"
        :data="data"
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PageCommonMobile from "../../common/PageCommon";
import NewsListItemMobile from "./item";

export default {
  name: 'NewsListMobile',
  components: {NewsListItemMobile, PageCommonMobile},
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    options: {
      type: Object,
      default: () => {
      }
    },
    from: {
      type: String,
      default: () => ''
    },
    customList: {
      type: Array,
      default: () => []
    },
    changePage: {
      type: Function,
      default: () => () =>{}
    },
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.news-list-mobile {

}
</style>
