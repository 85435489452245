<template>
  <div class="container">
    <div class="about-contract-mobile">
      <div class="title">
        {{ $t('28003') }}
      </div>
      <div class="about-contract-list">
        <template v-for="(res, index) in aboutContractFormats">
          <about-contract-item-mobile
            :key="index"
            :info="res"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import AboutContractItemMobile from "./item";

export default {
  name: 'AboutContractMobile',
  components: {AboutContractItemMobile},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    aboutContractFormats(){
      let arr = [{},{},{}];
      let {json_text} = this.info
      arr.map(function(res, index){
        arr[index] = {
          contact_item_status: json_text['contact_item_status['+index+']'],
          contact_title_cn: json_text['contact_title_cn['+index+']'],
          contact_title_en: json_text['contact_title_en['+index+']'],
          contact_title_1_cn: json_text['contact_title_1_cn['+index+']'],
          contact_title_1_en: json_text['contact_title_1_en['+index+']'],
          contact_content_1_cn: json_text['contact_content_1_cn['+index+']'],
          contact_content_1_en: json_text['contact_content_1_en['+index+']'],
          contact_title_2_cn: json_text['contact_title_2_cn['+index+']'],
          contact_title_2_en: json_text['contact_title_2_en['+index+']'],
          contact_content_2_cn: json_text['contact_content_2_cn['+index+']'],
          contact_content_2_en: json_text['contact_content_2_en['+index+']'],
          contact_title_3_cn: json_text['contact_title_3_cn['+index+']'],
          contact_title_3_en: json_text['contact_title_3_en['+index+']'],
          contact_content_3_cn: json_text['contact_content_3_cn['+index+']'],
          contact_content_3_en: json_text['contact_content_3_en['+index+']'],
        }
      })
      arr = this.sortBykey(arr, 'text_orderlist')
      return arr
    }
  },
}
</script>

<style scoped lang="scss">
.about-contract-mobile {
  margin-bottom: 60px;
  .title{
    height: 44px;
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    line-height: 32px;
    position: relative;
    padding-left: 26px;
  }
  .about-contract-list{
    margin-top: 20px;
    background: rgba(255,255,255,0.1);
    padding: 28px;
    border-radius: 12px;
  }
}
</style>
