<template>
  <div
    class="topic-types-item-mobile"
    :class="on ? `on` : ``"
    @click="$emit('click', $event)"
  >
    {{ $t2(lang, info.name, info.ename) }}
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'TopicTypesItemMobile',
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    on: {
      type: Boolean,
      default: () => false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.topic-types-item-mobile {
  display: inline-block;
  margin-right: 20px;
  line-height: 56px;
  height: 56px;
  padding: 0 20px;
  font-size: 28px;
  font-weight: 400;
  color: #fff;
  border-radius: 4px;
  overflow: hidden;
  text-align: center;
  background: rgba(255,255,255,0.1);
  white-space: nowrap;
  &.on{
    background: linear-gradient(to left, #FF4F1A, #FF9B61);
    color: #fff;
  }
}
</style>
