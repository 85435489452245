<template>
  <div
    class="activity-info-btn-mobile"
  >
    <div
      class="btn"
      :class="statusClass"
      @click.stop="$emit('click')"
    >
      {{ statusTest }}
      <div
        v-if="parseInt(status) === 0"
        class="people"
      >
        剩余{{ people }}人
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  //预约状态，0可预约，1已预约，2预约结束，3即将开始,4已约满
  name: 'ActivityInfoBtnMobile',
  props: {
    status: {
      type: Number,
      default: 0
    },
    people: {
      type: [Number, String],
      default: () => ''
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    statusTest() {
      if(this.from==='user'){
        return '已预约'
      }
      console.log('this.status', this.status)
      switch(this.status){
        case -1:
          if(this.info.live_id > 0){
            return '观看直播'
          }else{
            return '无需预约'
          }
        case 0:
          return '可预约'
        case 1:
          if(this.info.live_id > 0){
            return '观看直播'
          }else{
            return '已预约'
          }
        case 2:
          if(this.info.live_id > 0){
            return '观看直播'
          }else{
            return '预约结束'
          }
        case 3:
          return '即将开始'
        case 4:
          return '已约满'
      }
      return ''
    },
    statusClass() {
      switch(this.status){
        case -1:
          if(this.info.live_id > 0){
            return 'in'
          }else {
            return 'default'
          }
        case 0:
          return 'on'
        case 1:
        case 3:
          return 'in'
        case 2:
          if(this.info.live_id > 0){
            return 'in'
          }else{
            return 'default'
          }
        case 4:
          return 'default'
      }
      return 'default';
    }
  },
}
</script>

<style scoped lang="scss">
.activity-info-btn-mobile {
  width: 578px;
  height: 72px;
  .btn{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 72px;
    text-align: center;
    font-size: 28px;
    border-radius: 8px;
    overflow: hidden;
    &.on{
      background: linear-gradient(223deg, #FC501E, #FA8958);
      color: #fff;
    }
    &.in{
      background: linear-gradient(223deg, #faeee9, #fae9e4);
      color: #EA4C1E;
    }
    &.default{
      background: rgba(204, 204, 204, 0.4);
      color: #989898;
    }
  }
  .people{
    height: 20px;
    font-size: 20px;
    font-weight: 400;
    color: rgba(255,255,255,0.6);
    line-height: 20px;
    text-align: center;
  }
}
</style>
