<template>
  <div
    class="product-item-exhibition-thumb3-mobile"
    @click.stop="navigateTo('/exhibition/productinfo?companyid='+companyid+'&id='+info.id )"
  >
    <div class="pic">
      <pic-pc :src="info.imageurl" />
    </div>
    <div class="info">
      <div class="title line-2">
        {{ $t2(lang, info.productname, info.productnameen) }}
      </div>
      <div class="summary line-6">
        {{ $t2(lang, replaceHtmlTag(info.explains) , replaceHtmlTag(info.explains_en)) }}
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicPc from "../../../common/Pic";

export default {
  name: 'ProductItemExhibitionThumb3Mobile',
  components: {PicPc},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    companyid: {
      type: [Number, String],
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>
<style>
.pc .product-item-exhibition-thumb3-mobile .pic .pic-bg{
  background-size: cover!important;
}
</style>
<style scoped lang="scss">
.product-item-exhibition-thumb3-mobile {
  padding-bottom: 38px;
  .pic{
    width: 100%;
    height: 250px;
  }
  &:hover{
    .arrow-right-icon{
      &:after{
        background: url("../../../../../assets/images/exhibition_theme_2/jiantou_on@2x.png");
        background-size: 100% 100%;
      }
    }
  }
  .info{
    .title{
      font-size: 26px;
      font-weight: bold;
      color: #343434;
      line-height: 42px;
      height: 42px;
    }
    .summary{
      font-size: 20px;
      font-weight: 400;
      color: #666666;
      line-height: 30px;
      margin-top: 16px;
    }
  }
}
</style>
