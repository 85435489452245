<template>
  <div class="supply-demand-pc">
    <div class="container">
      <div class="types clearfix">
        <div
          class="send-btn"
          @click="navigateTo('/user/post_supply')"
        >
          {{ $t('50015') }}
        </div>
        <supply-demand-tabs-pc
          :category="options.category"
          :category-current="options.categoryCurrent"
          :change-category-current="changeCategoryCurrent"
          class="left"
        />
        <div class="right">
          <div class="search">
            <search-view-pc
              bg-color="rgba(255,255,255,0.1)"
              :change-key="changeKey"
              :search="search"
              :show-back="false"
            />
          </div>
          <div class="select">
            <div class="icon"></div>
            <div>{{ $t('50026') }} - </div>
            <el-select
              v-model="form.value"
              popper-class="search-select"
              @change="changeTypeId"
            >
              <el-option
                v-for="item in options.typeList"
                :key="item.value"
                :label="$t2(lang, item.name, item.ename)"
                :value="item.id"
              />
            </el-select>
          </div>
        </div>
      </div>
      <split-line-pc
        bg-color="rgba(255, 255, 255, 0.1000)"
        height="1px"
        margin-bottom="20px"
        margin-top="20px"
      />
      <div class="list">
        <div class="supply-demand-list">
          <template v-for="(res, index) in options.data.list">
            <supply-demand-item-pc
              :key="index"
              :info="res"
              :show-demand-contact-modal-info="showDemandContactModalInfo"
              :show-demand-modal-info="showDemandModalInfo"
              :show-supply-modal-info="showSupplyModalInfo"
            />
          </template>
        </div>
      </div>
      <div
        v-if="options.data.hasmore"
        class="gongxu-bottom-view"
      >
        <div class="gongxu-more">
          <div
            class="gongxu-more-btn"
            @click="getList"
          >
            <div class="text">
              {{ $t('24101') }}
            </div>
            <div class="pic">
              <img :src="require('../../../assets/images/gongxu_more.png')" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg" />
    <div class="bg2" />
    <supply-demand-supply-modal-pc
      v-if="supplyShow"
      :hide-supply-modal="hideSupplyModal"
      :info="supplyModalInfo"
    />
    <supply-demand-demand-modal-pc
      v-if="demandShow"
      :hide-demand-modal="hideDemandModal"
      :info="demandModalInfo"
      :show-demand-contact-modal-info="showDemandContactModalInfo"
    />
    <supply-demand-demand-contact-modal-pc
      v-if="demandContactShow"
      :hide-demand-contact-modal="hideDemandContactModal"
      :info="demandContactModalInfo"
      :set-follow="setFollow"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import SupplyDemandTabsPc from "@/components/Pc/SupplyDemand/SupplyDemandTabs";
import SearchViewPc from "@/components/Pc/common/SearchView";
import SupplyDemandItemPc from "@/components/Pc/SupplyDemand/SupplyDemandItem";
import PicPc from "@/components/Pc/common/Pic";
import SupplyDemandDemandModalPc from "@/components/Pc/SupplyDemand/SupplyDemandDemandModal";
import SupplyDemandSupplyModalPc from "@/components/Pc/SupplyDemand/SupplyDemandSupplyModal";
import SupplyDemandDemandContactModalPc from "../../../components/Pc/SupplyDemand/SupplyDemandContactModal";
import SplitLinePc from "@/components/Pc/common/SplitLine";

export default {
  name: 'SupplyDemandPc',
  components: {
    SupplyDemandDemandContactModalPc,
    SupplyDemandSupplyModalPc,SplitLinePc,
    SupplyDemandDemandModalPc,  SupplyDemandItemPc, SearchViewPc, SupplyDemandTabsPc},
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    changeCategoryCurrent: {
      type: Function,
      default: () => () => {}
    },
    changeKey: {
      type: Function,
      default: () => () => {}
    },
    search: {
      type: Function,
      default: () => () => {}
    },
    getList: {
      type: Function,
      default: () => () => {}
    },
    changeTypeId: {
      type: Function,
      default: () => () => {}
    }
  },
  data() {
    return {
      form: {
        value: 0
      },
      demandModalInfo: {},
      supplyModalInfo: {},
      demandContactModalInfo: {},
      demandShow: false,
      supplyShow: false,
      demandContactShow: false,
      list: [
        {
          label: '1',
          value: 2
        }
      ]
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    showDemandModalInfo(info){
      this.demandShow = true
      this.demandModalInfo = info
    },
    showDemandContactModalInfo(info){
      this.demandShow = false
      this.demandContactShow = true
      this.demandContactModalInfo = JSON.parse(this.htmlspecialchars_decode(info.contact))
      this.demandContactModalInfo.is_follow = info.is_follow
      this.demandContactModalInfo.id = info.id
    },
    showSupplyModalInfo(info){
      if(parseInt(info.is_type)===2){
        this.supplyModalInfo = info
        this.supplyShow = true
        this.$u.api.exhibitions.getProductDetail({id: info.id, companyid: info.mid})
      }else{
        this.getDetail(info.id, (res) => {
          this.supplyModalInfo = res
          this.supplyShow = true
        })
      }
    },
    hideDemandContactModal(){
      this.demandContactShow = false
    },
    hideDemandModal(){
      this.demandShow = false
    },
    hideSupplyModal(){
      this.supplyShow = false
    },
    getDetail(id, callback){
      const params = {
        id: id
      }
      this.$u.api.supplydemands.getDetail(params).then(res => {
          if(res.status === 1){
            callback(res.data)
          }
      })
    },
    setFollow(id, v){
      this.options.data.list.map((res, index) => {
        if(res.id === id){
          this.options.data.list[index].is_follow = v
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">
.supply-demand-pc{
  padding-bottom: 150px;
  .container{
    background: rgba(255,255,255,0.05);
    backdrop-filter: blur(2px);
    padding: 30px;
  }
  .types{
    margin-bottom: 15px!important;
  }
  .send-btn{
    width: 64px;
    padding-bottom: 5px;
    background: url("../../../assets/images/mobile/post_suppy.png") #fffcfc;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: -80px;
    top: 0;
    padding-top: 60px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: #EA4C1E;
    line-height: 16px;
  }
  .main-box{
    position: relative;
    background: rgba(0,0,0,0);
    padding: 5px 0 5px 0;
  }
  .list{
    margin-bottom: 0;
  }
  .bg{
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../../../assets/images/gongxu_bg.png");
    background-size: 100% auto;
    background-position: center bottom;
    background-repeat: no-repeat;
    left: 0;
    bottom: 0;
    z-index: -2;
  }
  .bg2{
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../../../assets/images/gongxu_dibu.png") no-repeat;
    background-size: 100% auto;
    background-position: center bottom;
    left: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none;
  }
  .types{
    margin-bottom: 0;
    .left{
      float: left;
    }
    .right{
      margin-left: 400px;
      height: 40px;
      overflow: hidden;
      .select{
        display: flex;
        justify-content: center;
        align-items: center;
        float: right;
        width: 320px;
        background: rgba(255,255,255,0.1);
        margin-right: 16px;
        padding-left: 50px;
        color: #666666;
        white-space: nowrap;
        line-height: 40px;
        input{
          color: rgba(255,255,255,0.8)!important;
        }
      }
      .search{
        width: 360px;
        float: right;
      }
    }
  }
  .supply-demand-list{
    width: 100%;
  }
  .gongxu-bottom-view{
    background: linear-gradient(180deg, rgba(56, 56, 56, 0.8), rgba(46, 46, 46, 0.8));
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
    text-align: center;
    height: 52px;
    position: relative;
    .gongxu-more{
      padding-top: 10px;
      color: rgba(255,255,255,0.6);
      .gongxu-more-btn{
        text-align: center;
        display: inline-block;
        cursor: pointer;
        .text{
          text-align: center;
        }
        .pic{
          display: inline-block;
          text-align: center;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
</style>
