<template>
  <div class="user-collect-exhibition-card-list-mobile">
    <template v-for="(res, index) in data.list">
      <user-collect-exhibition-card-list-item-mobile
        :key="index"
        :info="res"
        :refresh="refresh"
        :show-modal="showModal"
      />
    </template>
    <page-common-mobile
      :change-page="changePage"
      :data="data"
    />
    <user-collect-exhibition-card-list-modal-mobile
      v-if="show"
      :hide-modal="hideModal"
      :info="info"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import UserCollectExhibitionCardListItemMobile from "./item";
import PageCommonMobile from "../../common/PageCommon";
import UserCollectExhibitionCardListModalMobile from "./modal";

export default {
  name: 'UserCollectExhibitionCardListMobile',
  components: {UserCollectExhibitionCardListModalMobile, PageCommonMobile, UserCollectExhibitionCardListItemMobile},
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      show: false,
      info: {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    showModal(data){
      this.info = data;
      this.show = true;
    },
    hideModal(){
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">
.user-collect-exhibition-card-list-mobile {

}
</style>
