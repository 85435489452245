<template>
  <div class="awards-index-pc">
    <div class="container">
      <div class="main-box">
        <div class="video-view clearfix">
          <div class="video">
            <video
              v-if="options.videoList.length > 0"
              ref="player"
              controls="controls"
              muted="true"
              :poster="options.videoList[videoCurrent].imageurl"
              :src="options.videoList[videoCurrent].videourl"
              @click="stop"
            ></video>
            <span
              v-if="!playing"
              class="play_btn"
              @click="play"
            ></span>
          </div>
          <div class="video-right">
            <div class="video-right-content">
              <div class="title">
                <div class="text">
                  {{ $t('23001') }}
                </div>
                <div
                  class="more"
                  @click="navigateTo('/awards/list?typeid=12')"
                >
                  {{ $t('50017') }}>>
                </div>
              </div>
              <div class="content">
                <div class="content-info">
                  {{ $t('23009') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="awards-content">
          <text-content-pc
            :content="$t('23103')"
            :title="$t('23002')"
            title-size="20px"
          />
        </div>
        <div class="awards-category">
          <template v-for="(res, index) in options.category">
            <awards-category-item-pc
              v-if="index > 0"
              :key="index"
              :info="res"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TextContentPc from "@/components/Pc/common/TextContent";
import AwardsCategoryItemPc from "@/components/Pc/Awards/AwardsCategoryItem/index";

export default {
  name: 'AwardsIndexPc',
  components: {AwardsCategoryItemPc, TextContentPc},
  props: {
    options: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return {
      playing: true,
      videoCurrent: 0,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.play();
      },1000)
    })
  },
  methods:{
    play(){
      this.$refs.player.removeEventListener('play', this.handlePlay);
      this.$refs.player.removeEventListener('pause', this.handlePause);
      this.$refs.player.removeEventListener('ended', this.handleEnded);
      this.$refs.player.addEventListener('play', this.handlePlay);
      this.$refs.player.addEventListener('pause', this.handlePause);
      this.$refs.player.addEventListener('ended', this.handleEnded);
      this.$refs.player.play()
    },
    stop(){
      this.$refs.player.pause()
    },
    handlePlay(){
      this.playing = true
    },
    handlePause(){
      this.playing = false
    },
    handleEnded(){
      if(this.videoCurrent + 1 >= this.options.videoList.length){
        this.videoCurrent = 0;
      }else {
        this.videoCurrent++
      }
      this.$nextTick(()=>{
        this.$refs.player.play()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.awards-index-pc{
  .video-view{
    background: rgba(255,255,255, 0.1);
    .video{
      width: 800px;
      height: 450px;
      background: #f5f5f5;
      float: left;
      position: relative;
      video{
        width: 100%;
        height: 100%;
      }
      .play_btn{
        width: 100px;
        height: 100px;
        background: url("../../../assets/images/play_big.png");
        background-size: 100% 100%;
        background-position: center;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -50px;
        margin-top: -50px;
        cursor: pointer;
      }
    }
    .video-right{
      width: 292px;
      margin-left: 828px;
      display: block;
      padding-right: 20px;
      .video-right-content{
        padding-top: 20px;
        .title{
          margin-bottom: 12px;
          line-height: 56px;
          height: 24px;
          .text{
            float: left;
            display: inline-block;
            font-size: 20px;
            font-weight: 600;
            color: #fff;
            line-height: 24px;
          }
          .more{
            float: right;
            display: inline-block;
            font-size: 14px;
            font-weight: 400;
            color: #EA4C1E;
            line-height: 22px;
          }
        }
        .content{
          height: 372px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          color: rgba(255,255,255,0.8);
          line-height: 24px;
        }
      }
    }
  }
  .awards-content{
    margin-top: 48px;
  }
  .awards-category{
    width: 1140px;
    margin-bottom: 20px;
  }
}
</style>
