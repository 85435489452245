<template>
  <div class="rank-index-default">
    <rank-index-pc
      v-if="browser === 1"
      :options="options"
    />
    <rank-index-mobile
      v-if="browser === 2"
      :options="options"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import RankIndexPc from "@/views/Pc/Rank";
import RankIndexMobile from "../../Mobile/Rank";

export default {
  name: 'RankIndexDefault',
  components: {RankIndexMobile, RankIndexPc},
  data() {
    return {
      options: {
        informationList: [],
        bbsList: [],
        hotList: [],
        zhanList: []
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    this.init()
    this.setShare()
  },
  methods: {
    init() {
      this.$u.api.commons.getRanking().then(res => {
        if(res.status === 1){
          this.options.informationList = res.data.informationList
          this.options.bbsList = res.data.bbsList
          this.options.hotList = res.data.hotList
          this.options.zhanList = res.data.zhanList
        }
      })
    },
    setShare(){
      this.getWxConfig(this.$t('10014') + this.bankuai(), this.$t('10014') + this.bankuai())
    }
  }

}
</script>

<style scoped></style>
