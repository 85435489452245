<template>
  <div class="awards-list-mobile-list-mobile">
    <template v-for="(res, index) in data.list">
      <awards-item-mobile
        :key="index"
        :from="from"
        :info="res"
        :keyword="(data && data.key) || ''"
      />
    </template>
    <page-common-mobile
      :change-page="changePage"
      :data="data"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import AwardsItemMobile from "../AwardsListItem";
import PageCommonMobile from "../../common/PageCommon";

export default {
  name: 'AwardsListMobileListMobile',
  components: {PageCommonMobile, AwardsItemMobile},
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    from: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.awards-list-mobile-list-mobile {
  margin-top: 20px;
  width: 100%;
}
</style>
