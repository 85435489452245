<template>
  <div
    class="lang-btn-bai-mobile"
    @click="changeLang"
  >
    <img
      v-if="lang === 'zh'"
      src="../../../../assets/images/mobile/lang_bai.png"
    />
    <img
      v-if="lang === 'en'"
      src="../../../../assets/images/mobile/zh_bai.png"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import loadLanguageAsync from "@/common/i18n/i18n";
import locale from 'element-ui/lib/locale'
import en from 'element-ui/lib/locale/lang/en'
import zh from 'element-ui/lib/locale/lang/zh-CN'

export default {
  name: 'LangBtnBaiMobile',
  props: {

  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods:{
    changeLang() {
      locale.use(this.lang === 'en' ? 'zh' : 'en')
      loadLanguageAsync(this.lang === 'en' ? 'zh' : 'en')
    }
  }
}
</script>

<style scoped lang="scss">
.lang-btn-bai-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 48px;
    height: 48px;
  }
}
</style>
