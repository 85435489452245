<template>
  <a
    class="live-btn-big-mobile"
    :class="on ? `on` : ``"
    :href="link ? link : `javascript: void(0)`"
    :style="{height: height, lineHeight: height}"
    :target="link ? `_blank` : ``"
    @click="$emit('click')"
  >
    {{ label }}
  </a>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'LiveBtnBigMobile',
  props:{
    label: {
      type:String,
      default: () => ''
    },
    link: {
      type:String,
      default: () => ''
    },
    sub: {
      type:String,
      default: () => ''
    },
    on: {
      type:Boolean,
      default: () => false
    },
    inactiveBg: {
      type: String,
      default: () => 'linear-gradient(223deg, #fcede9, #fdf4f0)'
    },
    inactiveColor: {
      type: String,
      default: () => '#fff'
    },
    height: {
      type: String,
      default: () => '64px'
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.live-btn-big-mobile{
  flex: 1;
  margin-left: 40px;
  text-align: center;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
  height: 64px;
  line-height: 64px;
  font-weight: 500;
  padding: 0 32px;
  font-size: 28px;
  vertical-align: top;
  color: #ccc;
  &.on{
    color: #fff;
    background: linear-gradient(223deg, #FF511A, #FF9B61)!important;
  }
}
</style>
