<template>
  <div class="activity-list-pc">
    <template v-for="(res, index) in data.list">
      <activity-item-pc
        :key="index"
        :from="from"
        :info="res"
        :keyword="data.key"
        :refresh="refresh"
        :show-reserve-modal="showReserveModal"
      />
    </template>
    <page-common-pc
      :change-page="changePage"
      :data="data"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import PageCommonPc from "@/components/Pc/common/PageCommon";
import ActivityItemPc from "@/components/Pc/Activity/ActivityList/item";

export default {
  name: 'ActivityListPc',
  components: {ActivityItemPc, PageCommonPc},
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    showReserveModal: {
      type: Function,
      default: () => () => {}
    },
    from: {
      type: String,
      default: () => ''
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.activity-list-pc {

}
</style>
