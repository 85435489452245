<template>
  <div class="exhibition-index-default">
    <exhibition-index-pc
      v-if="browser===1"
      :list="list"
    />
    <exhibition-index-mobile
      v-if="browser===2"
      :list="list"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import ExhibitionIndexPc from "@/views/Pc/Exhibition";
import ExhibitionIndexMobile from "@/views/Mobile/Exhibition";

export default {
  name: 'ExhibitionIndexDefault',
  components: {ExhibitionIndexMobile, ExhibitionIndexPc},
  data() {
    return {
      list: [],
      swiperCurrent: 0,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.init()
    this.setShare()
  },
  methods: {
    init() {
      this.$u.api.exhibitions.getParentTypes().then((res) => {
        if (res.status === 1) {
          this.list = res.data
        }
      })
    },
    setShare(){
      this.getWxConfig(this.$t('10002') + this.bankuai(), '2000多家企业，汇聚行业发展新动向，轻松获取目标内容。', 'https://virtualobs.ciif-expo.com/shareimg/2.png')
    }
  }

}
</script>

<style scoped></style>
