<template>
  <div
    class="live-comment-list-pc"
  >
    <div
      v-if="tipsShow"
      class="tips"
    >
      欢迎来到直播间。请遵守有关法律法规，严禁发表侮辱、诽谤、教唆、欺诈、诱导、低俗等内容。请自行承担一切因您言行而直接或间接引起的法律责任。您在本站评论发表的言论，本站有权在网站内保留、转载、引用或者删除。
    </div>
    <template v-for="(res, index) in options.comment.list">
      <live-comment-item-pc
        :key="index"
        :info="res"
      />
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import LiveCommentItemPc from "./item";

export default {
  name: 'LiveCommentListPc',
  components: {LiveCommentItemPc},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    getComment: {
      type: Object,
      default: () => {
      }
    }
  },
  data(){
    return {
      tipsShow: true
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  mounted() {
    setTimeout(() => {
      this.tipsShow = false
    }, 5000)
  }
}
</script>

<style scoped lang="scss">
.live-comment-list-pc {
  .tips{
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 32px;
    margin-bottom: 15px;
  }
}
</style>
