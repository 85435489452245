<template>
  <div class="case-info-mobile">
    <top-nav-mobile
      :backurl="backurl"
      :is-back="true"
    >
      <span class="nav-title">{{ $t('10008') }}</span>
    </top-nav-mobile>
    <div class="container">
      <div class="pic radius">
        <img :src="options.info.pic" />
      </div>
      <div class="title mt-20">
        {{ $t2(lang, options.info.title, options.info.en_title) }}
      </div>
      <div class="tags">
        <template v-for="(res, index) in options.info.catename">
          <tags-item-mobile
            v-if="res.name"
            :key="index"
            class="item"
            :dark="true"
            :info="res"
          />
        </template>
      </div>
      <div class="btn-view">
        <div class="other">
          <icon-text-line-mobile
            color="rgba(255,255,255,0.6)"
            icon="laiyuan.png"
            :text="$t2(lang, options.info.source.companyname, options.info.source.companynameen)"
            @click="navigateTo('/exhibition/info?id='+options.info.companyid)"
          />
        </div>
        <div class="btns">
          <icon-text-column-mobile
            color="rgba(255,255,255,0.6)"
            icon="views.png"
            :text="options.info.click"
          />
          <icon-text-zan-mobile
            :id="options.info.id"
            class="item"
            color="rgba(255,255,255,0.6)"
            font-size="20px"
            :label="$t('50048')"
            :set-zan="setZan"
            :type="5"
            width="32px"
            :zan="parseInt(options.info.is_zan) === 1"
          />
          <icon-text-collect-mobile
            :id="options.info.id"
            class="item"
            color="rgba(255,255,255,0.6)"
            font-size="20px"
            :label="$t('50024')"
            :set-collect="setCollect"
            :shoucang="parseInt(options.info.is_cang) === 1"
            :type="5"
            width="32px"
          />
          <icon-text-column-mobile
            color="rgba(255,255,255,0.6)"
            icon="share.png"
            :text="$t('50016')"
            type="share"
          />
        </div>
      </div>
      <split-line-mobile
        bg-color="rgba(224, 224, 224, 0.2000)"
        height="1px"
        margin-bottom="28px"
        margin-top="28px"
      />
      <text-content-mobile
        :content="$t2(lang, options.info.content, options.info.en_content)"
        title=""
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import IconTextColumnMobile from "../../../components/Mobile/common/IconTextColumn";
import IconTextCollectMobile from "../../../components/Mobile/common/IconText/collect";
import TagsItemMobile from "../../../components/Mobile/common/TagsItem";
import IconTextTimeMobile from "../../../components/Mobile/common/IconText/time";
import SplitLineMobile from "../../../components/Mobile/common/SplitLine";
import TextContentMobile from "../../../components/Mobile/common/TextContent";
import LangBtnMobile from "../../../components/Mobile/common/LangBtn";
import BackBtnMobile from "../../../components/Mobile/common/BackBtn";
import IconTextLineMobile from "../../../components/Mobile/common/IconTextLine";
import IconTextZanMobile from "../../../components/Mobile/common/IconText/zan";

export default {
  name: 'CaseInfoMobile',
  components: {
    IconTextZanMobile,
    IconTextLineMobile,
    TextContentMobile,
    SplitLineMobile,
    TagsItemMobile, IconTextCollectMobile, IconTextColumnMobile, TopNavMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    setZan: {
      type: Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      backurl: ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    this.backurl = this.$route.meta.fromUrl === '/' ? '/case' : this.$route.meta.fromUrl
  },
  mounted() {
    this.backurl = this.$route.meta.fromUrl === '/' ? '/case' : this.$route.meta.fromUrl
  },
}
</script>

<style scoped lang="scss">
.case-info-mobile {
  .topnav-right{
    display: flex;
    .item{
      margin-left: 44px;
    }
  }
  .title{
    font-size: 36px;
    font-weight: 500;
    color: #fff;
    line-height: 48px;
  }
  .pic{
    width: 100%;
    text-align: center;
    img{
      width: 100%;
    }
  }
  .tags{
    margin-top: 24px;
    .item{
      margin-bottom: 12px;
    }
  }
  .btn-view {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .other{
      margin-top: 30px;
      width: 50%;
      flex: 1;
    }
    .btns {
      width: 50%;
      flex: 1;
      margin-top: 32px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
}
</style>
