<template>
  <div>
    <SliderIndexPc v-if="videoshow" ref="slider" :stopplay="stopplay" />
    <div v-if="!videoshow">
    <div class="video-btn" @click="showvideo()">
      <div class="icon"></div>
      <div class="text">大楼导览</div>
    </div>
    <div
      class="index-menu-right-mobile"
    >
      <template v-for="(res, index) in options.list">
        <a
            v-if="typeof res.href === 'string'"
          :key="index"
          class="icon"
          :class="current === index ? `on` : ``"
          :style="{backgroundImage:'url('+(current === index ? res.icon_on : res.icon)+')'}"
          :href="res.href"
            target="_blank"
        >
          <div
            class="title"
          >
            {{ res.title }}
          </div>
        </a>
        <div
            v-else
          :key="index"
          class="icon"
          :class="current === index ? `on` : ``"
          :style="{backgroundImage:'url('+(current === index ? res.icon_on : res.icon)+')'}"
          @click="setCurrent(index)"
        >
          <div
            class="title"
          >
            {{ res.title }}
          </div>
        </div>
      </template>
    </div>
    <div
      id="bg"
      class="dalou-main-pc"
    >
      <div class="logo"></div>
      <div
        ref="dalou-left"
        class="dalou-left"
      >
        <div
          class="left-list-view"
          :style="{width: '100%', transform: `translateY(-`+leftTranslateY+`px)`}"
        >
          <template v-for="(res,index) in options.list">
            <div
              :key="index"
              class="dalou-left-item"
              :style="{width: leftWidth + 'px', height: leftHeight + 'px', opacity: current === index ? 1 : 0}"
            >
              <div class="info">
                <div class="title">
                  {{ res.title }}
                </div>
                <div class="desc">
                  {{ res.desc }}
                </div>
                <div class="btn-list">
                  <template v-for="(item, key) in res.link">
                    <a
                      v-if="item.url.indexOf('http') > -1"
                      :key="key"
                      target="_blank"
                      class="btn"
                      :href="item.url"
                    >
                      进入{{ item.title }}
                    </a>
                    <div
                        v-else
                      :key="key"
                      class="btn"
                      @click="navigateTo(item.url)"
                    >
                      进入{{ item.title }}
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div
        ref="dalou-right"
        class="dalou-right"
      >
        <div
          class="position-div"
          :style="{width: '90%', height: rightWidth / 1061 * 10305 * 0.9 + 'px', transform: `translateY(`+(rightWidth / 1061 * 0.9 * parseInt(windowWidth / windowHeight > 1.85 ? options.list[current].top_pc : options.list[current].top_pc_2))+`px)`}"
        >
          <div class="index-pic-img-bg">
          </div>
          <template
            v-for="(res) in options.list"
          >
            <template
              v-for="(item,key) in res.images"
            >
              <div
                :key="key"
                class="item-pic animate__infinite	animate__animated animate__slower"
                :class="item.animate"
                :style="{zIndex: item.zIndex || 3,'--animate-duration': item.animateDuration || '8s', animation: item.animation, width: item.width * rightWidth / 1061 * 0.9 + `px`, height: item.height * rightWidth / 1061 * 0.9 + `px`, top: item.top * rightWidth / 1061 * 0.9 + `px`, left: item.left * rightWidth / 1061 * 0.9 + `px`, background: `url(`+item.pic+`)`, backgroundSize: '100% 100%', opacity: item.opacity || 1}"
              >
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import SliderIndexPc from "./slider.vue";

export default {
  name: 'IndexPc',
  components: {SliderIndexPc},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
  },
  data(){
    return {
      videoshow: false,
      gundong: true,
      current: 4,
      rightWidth: 1200,
      rightHeight: 1080,
      leftWidth: 0,
      leftHeight: 0,
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'windowHeight', 'windowWidth']),
    translateY(){
      return (this.current) * this.rightHeight
    },
    leftTranslateY(){
      return this.leftHeight * 8 - (this.current) * this.leftHeight
    },
  },
  beforeCreate: function () {
    document.getElementsByTagName('body')[0].className = 'index-donghua'
  },
  beforeRouteEnter: function(){
    document.getElementsByTagName('body')[0].className = 'index-donghua'
  },
  activated: function(){
    document.getElementsByTagName('body')[0].className = 'index-donghua'
  },
  beforeDestroy: function(){
    if (this.vantaEffect) {
      this.vantaEffect.destroy()
    }
    document.getElementsByTagName('body')[0].className = ''
  },
  beforeRouteLeave: function(){
    document.getElementsByTagName('body')[0].className = ''
  },
  deactivated: function(){
    document.getElementsByTagName('body')[0].className = ''
  },
  mounted() {
    this.$nextTick(() => {
      this.rightWidth = this.$refs['dalou-right'].offsetWidth
      this.rightHeight = this.$refs['dalou-right'].offsetHeight
      this.leftWidth = this.$refs['dalou-left'].offsetWidth
      this.leftHeight = this.$refs['dalou-left'].offsetHeight
      this.controlDiv = document.querySelector('#bg') //控制器容器元素
      this.maxW = this.controlDiv.offsetWidth
      this.maxH = this.controlDiv.offsetHeight
      this.init()
      var scrollFunc = (e) => {
        e = e || window.event;
        if (e.wheelDelta) {  //判断浏览器IE，谷歌滑轮事件
          if (e.wheelDelta > 0) { //当滑轮向上滚动时
            this.shanggun();
          }
          if (e.wheelDelta < 0) { //当滑轮向下滚动时
            this.xiagun()
          }
        } else if (e.detail) {  //Firefox滑轮事件
          if (e.detail> 0) { //当滑轮向下滚动时
            this.shanggun();
          }
          if (e.detail< 0) { //当滑轮向上滚动时
            this.xiagun()
          }
        }
      }
      /*IE、Opera注册事件*/
      if(document.attachEvent){
        document.attachEvent('onmousewheel',scrollFunc);
      }
      //Firefox使用addEventListener添加滚轮事件
      if (document.addEventListener) {//firefox
        document.addEventListener('DOMMouseScroll', scrollFunc, false);
      }
      //Safari与Chrome属于同一类型
      window.onmousewheel = document.onmousewheel = scrollFunc;
    })
  },
  methods: {
    init(){
      // this.infoTop = this.$refs['go-info-item-'+this.current].offsetTop;
      this.y = (100 - this.options.list[this.current].top_pc + ((document.body.clientHeight) / 2)) / (1000 / (document.body.clientWidth * 0.5))
    },
    xiagun(){
      if(!this.gundong){
        return ;
      }
      this.gundong = false;
      if(this.current < 7){
        this.setCurrent(this.current + 1)
      }else{
        this.setCurrent(7)
      }
      setTimeout(() => {
        this.gundong = true;
      }, 500)
    },
    shanggun(){
      if(!this.gundong){
        return ;
      }
      this.gundong = false;
      if(this.current > 1){
        this.setCurrent(this.current - 1)
      }else{
        this.setCurrent(1)
      }
      setTimeout(() => {
        this.gundong = true;
      }, 500)
    },
    setCurrent(index){
      this.current = index
    },
    showvideo(){
      console.log('this.$refs', this.$refs)
      this.videoshow=true;
      this.$nextTick(() => {
        this.$refs['slider'].play()
      })
    },
    stopplay(){
      this.videoshow=false;
      document.getElementsByTagName('body')[0].className = 'index-donghua'
    }
  },
}
</script>

<style scoped lang="scss">
@keyframes myfirst
{
  0%   {background: red; left:0px; top:0px;}
  100% {background: red; left:0px; top:0px;}
}

@-webkit-keyframes myfirst /* Safari 与 Chrome */
{
  0%   {background: red; left:0px; top:0px;}
  100% {background: red; left:0px; top:0px;}
}
.video-btn{
  position: fixed;
  right: -65px;
  top: 100px;
  display: flex;
  height: 42px;
  padding: 0 0px 0 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 1px solid rgba(246, 130, 63, 0.5);
  border-right:0;
  justify-content: center;
  align-items: center;
  color: #F6823F;
  z-index: 2147483647;
  cursor: pointer;
  width: 121px;
  transition: right .2s;

  .text{
    margin-right: 10px;
    white-space: nowrap;
  }
  .icon{
    width: 24px;
    height: 24px;
    background: url("../../../assets/images/index_video_icon.png");
    background-size: 100% 100%;
    margin-right: 10px;
  }
  &:hover{
    right: 0;
    .text{
      display: block;
    }
  }
}
.dalou-main-pc{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  .logo{
    position: absolute;
    left: 120px;
    top: 40px;
    width: 139px;
    height: 80px;
    background: url("../../../assets/images/newlogo/logo_mobile.png");
    background-size: 100% 100%;
  }
}
.item-pic{
  position: absolute;
  --animate-duration: 8s;
}
@font-face{
  font-family: 'leftFontZh';
  src:url('../../../assets/font/zh.ttf') format('truetype')
}
.dalou-left{
  width: 38%;
  float: left;
  height: 100%;

  .left-list-view{
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    transition: transform 1000ms ease 0s;
    -webkit-transition: transform 1000ms ease 0s;
  }
  .dalou-left-item{
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-animation: opacity 1.3s infinite;
    animation: opacity 1.3s infinite;
    margin-left: 20px;
    .info{
      padding: 40px;
      width: 468px;
      position: relative;
      .title{
        font-size: 68px;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 68px;
        font-family: leftFontZh;
      }
      .desc{
        font-size: 22px;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 33px;
        margin-top: 20px;
        font-family: leftFontZh;
      }
      .btn-list{
        margin-top: 80px;
        .btn{
          display: inline-block;
          margin-right: 20px;
          width: 128px;
          height: 44px;
          background: #EA4C1E;
          border-radius: 4px;
          color: #fff;
          line-height: 44px;
          text-align: center;
          cursor: pointer;
          &:hover{
            opacity: .8;
          }
        }
      }
      &:before{
        content:'';
        width: 18px;
        height: 340px;
        background: url("../../../assets/images/anhei/pc/chi2.png");
        background-size: 100% 100%;
        position: absolute;
        left: -18px;
        top: 0;
      }
      &:after{
        content:'';
        width: 468px;
        height: 16px;
        background: url("../../../assets/images/anhei/pc/chi1.png");
        background-size: 100% 100%;
        position: absolute;
        left: 0;
        bottom: -18px;
      }
    }
  }
}
.dalou-right{
  width: 62%;
  float: right;
  height: 100%;
}
.position-div{
  transition: transform 2000ms ease 0s;
  -webkit-transition: transform 2000ms ease 0s;
  position: relative;
  .index-pic-img-bg{
    background: url("../../../assets/images/pc_dalou33.png");
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 2;
    width: 100%;
    height: 100%;
    position: relative;
  }
}
.index-menu-right-mobile{
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  border-radius: 20px;
  &.tip{
    &:after{
      border-radius: 20px;
      position: absolute;
      right: 2px;
      top: 0;
      content: ' ';
      background: rgba(255,255,255,0.1);
      border: 2px dashed #FF8000;
      width: 100%;
      height: 100%;
      opacity: 0;
      animation-name:fadenum;
      animation-duration: 2.8s;
    }
    .bankuai-tip{
      opacity: 0;
      display: inline-block;
      animation-name:fadenum;
      animation-duration: 2.8s;
    }
  }
  .icon{
    width: 40px;
    height: 40px;
    position: relative;
    background-position: center;
    background-size: 100%;
    &:hover{
      transform: scale(1.1);
      .title{
        display: block;
      }
    }
    .title{
      position: absolute;
      width: 200px;
      left: -210px;
      text-align: right!important;
      color: #EA4E1F;
      top: 10px;
      font-weight: bold;
      font-size: 16px;
      display: none;
    }
    &.on{
      .title{
        display: inline-block;
      }
    }
  }
  .bankuai-tip{
    display: none;
    position: absolute;
    width: 113px;
    height: 66px;
    background: url("../../../assets/images/mobile/index_tip_bankuai_mobile.png");
    background-size: 100% 100%;
    bottom: -75px;
  }
}
</style>
