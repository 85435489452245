<template>
  <div class="user-supply-follow-list-mobile">
    <template v-for="(res, index) in options.data.list">
      <div
        :key="index"
        class="item"
      >
        <supply-demmand-item-supply-mobile
          from="user"
          :info="res"
          :refresh="refresh"
          @click="show(res)"
        />
      </div>
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import SupplyDemmandItemSupplyMobile from "../../SupplyDemand/SupplyDemandItem/component/supply";

export default {
  name: 'UserSupplyFollowListMobile',
  components: {SupplyDemmandItemSupplyMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    showSupplyModal: {
      type: Function,
      default: () => () => {}
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    show(info){
       this.showSupplyModal(info)
    },
  }
}
</script>

<style scoped lang="scss">
.user-supply-follow-list-mobile {
  display: flex;
  flex-wrap: wrap;
  .item{
    margin-bottom: 20px;
    width: 100%;
    overflow: hidden;
    background: rgba(255,255,255,0.1);
    border-radius: 12px;
    padding: 24px;
    &:nth-child(2n){
      margin-right: 0;
    }
  }
}
</style>
