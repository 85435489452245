<template>
  <div class="empty-pc">
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'EmptyPc',
  props: {
    description: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.empty-pc{
  height: 400px;
}
</style>
