<template>
  <div class="search-index-pc">
    <div class="container">
      <div class="search-view">
        <div class="exhibition-list">
          <carousel-3d
            v-if="options.list.length > 0"
            :animation-speed="1000"
            :autoplay="true"
            :autoplay-timeout="3000"
            :border="0"
            :display="9"
            height="80"
            :perspective="0"
            space="60"
            width="80"
          >
            <template v-for="(res, index) in options.list">
              <slide
                :key="index"
                :index="index"
              >
                <div
                  class="pic radius"
                  :class="`pic_`+index"
                >
                  <pic-pc
                    :src="res.smallimg"
                  />
                </div>
              </slide>
            </template>
          </carousel-3d>
        </div>
        <search-form-pc
          :go-search="goSearch"
          width="600px"
        />
        <search-type-pc
          :change-type="changeType"
          :options="options"
        />
        <div class="search-other">
          <div class="history hotkey">
            <dl>
              <dt>{{ $t('24135') }}：</dt>
              <template v-for="(res, index) in $t2(lang, siteConfig.search_keyword_cn.split(','), siteConfig.search_keyword_en.split(','))">
                <dd
                  :key="index"
                  @click="goSearch(res)"
                >
                  {{ res }}
                </dd>
              </template>
            </dl>
          </div>
          <div class="history">
            <dl>
              <dt>{{ $t('50013') }}：</dt>
              <template v-for="(res, index) in options.history">
                <dd
                  :key="index"
                  @click="historySearch(res)"
                >
                  {{ res.keyword }}
                </dd>
              </template>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicPc from "../../../components/Pc/common/Pic";
import SearchFormPc from "../../../components/Pc/Search/SearchForm/index";
import SearchTypePc from "../../../components/Pc/Search/SearchType";
import { Carousel3d, Slide } from '@/common/vue-carousel-3d';

export default {
  name: 'SearchIndexPc',
  components: {SearchTypePc, SearchFormPc, PicPc,  Carousel3d, Slide},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeType: {
      type: Function,
      default: () => () =>{}
    },
    goSearch: {
      type: Function,
      default: () => () =>{}
    },
    historySearch: {
      type: Function,
      default: () => () =>{}
    }
  },
  data(){
    return {
      type: [

      ]
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'siteConfig']),
  },
}
</script>

<style scoped lang="scss">
.search-index-pc {
  .search-view{
    margin-top: 117px;
    .exhibition-list{
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .pic{
        width: 80px;
        height: 80px;
      }
    }
    .search-other{
      margin: 20px 40px 180px;
      .hotkey{
        margin-top: 100px!important;
      }
      .history{
        margin-top: 8px;
        line-height: 40px;
        background: rgba(255,255,255,.1);
        padding: 0 16px;
        dl{
          dt, dd{
            display: inline-block;
          }
          dt{
            font-weight: 600;
            color: #fff;
            font-size: 14px;
          }
          dd{
            color: rgba(255,255,255,0.6);
            font-size: 12px;
            margin: 0 12px;
            cursor: pointer;
            &:hover{
              color: #EA4C1E
            }
          }
        }
      }
    }
  }
}
</style>
