<template>
  <div class="bbs-jiabin-mobile">
    <div class="container">
      <div class="back-line">
        <back-btn-img-mobile />
      </div>
      <div class="pic">
        <pic-mobile
          v-if="options.info"
          :src="options.info.jiabinpic"
        />
      </div>
      <div class="title-line">
        <div class="line"></div>
        <div>
          <div class="title">
            {{ options.info.xingming }}
          </div>
          <div class="posline">
            <div class="tag-line">
              <div class="line1">
                {{ options.info.qiye }}
              </div>
              <div class="line2">
                {{ options.info.zhiwu }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <text-content-mobile
        :content="$t2(lang, options.info.scontent, options.info.scontent_en)"
        margin-top="48px"
        title=""
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import BackBtnImgMobile from "../../../components/Mobile/common/BackBtnImg";
import PicMobile from "../../../components/Mobile/common/Pic";
import TextContentMobile from "../../../components/Mobile/common/TextContent";

export default {
  name: 'BbsJiabinMobile',
  components: {TextContentMobile, PicMobile, BackBtnImgMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.bbs-jiabin-mobile {
  .pic{
    height: 400px;
    width: 400px;
    margin-top: 20px;
    border-radius: 10px;
    overflow: hidden;
  }
  .title-line{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-top: 40px;
    .line{
      height: 100%;
      width: 8px;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(223deg, #FC501E, #FA8958);
      border-radius: 2px;
    }
    .title{
      margin-left: 26px;
      flex: 1;
      font-size: 40px;
      font-weight: 500;
      color: #000000;
      line-height: 56px;
    }
  }
  .posline{
    margin-left: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tag-line{
      flex: 1;
      margin-top: 18px;
      .line1{
        font-size: 20px;
        font-weight: 500;
        color: #999999;
        line-height: 28px;
      }
      .line2{
        margin-top: 8px;
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        line-height: 28px;
      }
    }
  }
}
</style>
