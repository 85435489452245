<template>
  <div class="search-list-default">
    <search-list-pc
      v-if="browser===1"
      :change-page="changePage"
      :change-type="changeType"
      :go-search="init"
      :options="options"
    />
    <search-list-index-mobile
      v-if="browser===2"
      :change-page="changePage"
      :change-type="changeType"
      :go-search="init"
      :options="options"
    />
  </div>
</template>

<script>

import {mapState, mapMutations} from "vuex";
import SearchListPc from "../../Pc/Search/list";
import SearchListIndexMobile from "../../Mobile/Search/list";

export default {
  name: 'SearchListDefault',
  components: {SearchListIndexMobile, SearchListPc},
  data() {
    return {
      options: {
        loading: true,
        typeCurrent: '',
        data: {
          list: [],
          page: 1,
          pagesize: 8,
          total: 0,
          key: '',
          nowType: 0
        },
        empty: false
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init(this.options.data.key)
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    this.options.data.key = this.$route.query.keyword
    this.options.typeCurrent = this.$route.query.type
    this.defaultOptions = this.deepClone(this.options)
    this.init(this.options.data.key)
    this.setShare()
  },
  methods: {
    ...mapMutations(['setHistory']),
    init(key) {
      this.options.data.key = key
      this.options.data.list = []
      this.options.data.total = 0
      let params = {
        keyword: this.options.data.key,
      }
      if(key){
        let query = {type:this.options.data.typeCurrent,keyword: this.options.data.key}
        if (navigator.userAgent.indexOf('Mobile') > -1) {
          this.$router.replace({
            query:query
          })
        }else{
          this.$router.push({
            query:query
          })
        }
      }
      this.options.loading = true
      this.options.empty = false
      this.$u.api.searchs.checkSearchKeyword(params).then(res => {
        if(res.data){
          params.type = res.data.type
          this.changeType(res.data.type)
        }else{
          this.options.empty = true
        }
      })
    },
    getList(){
      this.options.empty = false
      if(this.options.data.key){
        this.setHistory({
          keyword: this.options.data.key,
          type: this.options.typeCurrent,
        })
      }
      let params = {
        keyword: this.options.data.key,
        type: this.options.typeCurrent,
        page: this.options.data.page,
        pagesize: this.options.data.pagesize,
      }
      this.$u.api.searchs.getSearchLists(params).then(res=>{
        this.options.loading = false
        if(res.status === 1){
          this.options.data.list = res.data.lists
          this.options.data.total = res.data.totalNum
          if(parseInt(res.data.totalNum) == 0){
            this.options.empty = true
          }
        }
      })
    },
    changeType(r){
      this.options.data.list = [];
      this.options.data.page = 1;
      this.options.typeCurrent = r
      if (navigator.userAgent.indexOf('Mobile') > -1) {
        this.$router.replace({
          query:{type:r,keyword: this.options.data.key}
        })
      }else{
        this.$router.push({
          query:{type:r,keyword: this.options.data.key}
        })
      }
      this.goSearch(this.options.data.key);
    },
    goSearch(keyword){
      if (navigator.userAgent.indexOf('Mobile') > -1) {
        this.$router.replace({
          query: {type: this.options.typeCurrent, keyword: keyword}
        })
      }else {
        this.$router.push({
          query: {type: this.options.typeCurrent, keyword: keyword}
        })
      }
      this.options.data.page = 1;
      this.options.data.key = keyword || ''
      console.log('this.options.data.key', this.options.data.key)
      this.getList()
    },
    changePage(v){
      this.options.data.page = v;
      this.getList()
    },
    setShare(){
      this.getWxConfig(this.$t('50025'), this.$t('50025'))
    }
  }

}
</script>

<style scoped></style>
