<template>
  <div
    class="tag-item-pc"
    :class="{dark}"
  >
    <span v-html="label"></span>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'TagItem',
  props: {
    label: {
      type: String,
      default: () => ''
    },
    dark: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.tag-item-pc{
  display: inline-block;
  padding: 0 10px;
  height: 24px;
  background: #F0F0F0;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
  &.dark{
    background: rgba(0, 0, 0, 0.30)!important;
    color: #FF754D;
  }
}
</style>
