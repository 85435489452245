<template>
  <div class="case-index-pc">
    <div class="container">
      <div class="search">
        <search-view-pc
          bg-color="rgba(255,255,255,0.1)"
          :change-key="changeKey"
          :search="search"
          :show-back="false"
        />
      </div>
      <div class="types">
          <btn-big
              class="item"
              label="展商动态"
              :on="options.childSubTypeId === 0"
              @click="changeSubTypeId(0)"
          />
          <btn-big
              class="item"
              label="引例场"
              :on="options.childSubTypeId === 39"
              @click="changeSubTypeId(39)"
          />
      </div>
      <template v-if="options.childSubTypeId == 0">
        <case-index-box-pc
          :change-page="(v) => changePage(1, v)"
          :change-type-id="(v) => changeTypeId(1, v)"
          :data="options.pcData[1]"
          :loading="options.loading"
          :set-collect="setCollect"
          :type="1"
        />
        <case-index-box-pc
          :change-page="(v) => changePage(0, v)"
          :change-type-id="(v) => changeTypeId(0, v)"
          :data="options.pcData[0]"
          :loading="options.loading"
          :set-collect="setCollect"
          :type="2"
        />
      </template>
      <template v-if="options.childSubTypeId == 39">
        <news-list-pc
            :change-page="changeNewsPage"
            :data="options.data"
            :options="options"
        />
      </template>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import SearchViewPc from "@/components/Pc/common/SearchView";
import CaseIndexBoxPc from "@/components/Pc/Case/CaseIndexBox";
import BtnBig from "@/components/Pc/common/BtnBig";
import NewsListPc from "@/components/Pc/News/NewsList";

export default {
  name: 'CaseIndexPc',
  components: {CaseIndexBoxPc, BtnBig,SearchViewPc, NewsListPc},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    changeKey: {
      type: Function,
      default: () => () => {}
    },
    changeTypeId: {
      type: Function,
      default: () => () => {}
    },
    search: {
      type: Function,
      default: () => () => {}
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    changeNewsPage: {
      type: Function,
      default: () => () => {}
    },
    changeSubTypeId: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.case-index-pc {
  .search{
    clear: both;
    display: block;
    margin: 0 0 8px;
    padding-top: 20px;
  }
  .types{
    text-align: center;
    margin-bottom: 20px;
    .item{
      margin: 0 8px;
    }
  }
}
</style>

<style lang="scss">
.case-index-pc {
  .news-item-video-pc{
    width: 590px!important;
  }
}
</style>
