<template>
  <div class="case-list-pc">
    <template v-for="(res, index) in data.list">
      <case-item-pc
        :key="index"
        :from="from"
        :info="res"
        :keyword="data.key"
      />
    </template>
    <page-common-pc
      :change-page="changePage"
      class="case-list-page"
      :data="data"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import CaseItemPc from "@/components/Pc/Case/caseList/item";
import PageCommonPc from "@/components/Pc/common/PageCommon";

export default {
  name: 'CaseListPc',
  components: {PageCommonPc, CaseItemPc},
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    changePage: {
      type: Function,
      default: () => () =>{}
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    from: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.case-list-pc {
  .case-list-page{
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}
</style>
