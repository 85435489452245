<template>
  <div class="about-contract-item-mobile">
    <div class="title">
      {{ $t2(lang, info.contact_title_cn, info.contact_title_en) }}
    </div>
    <split-line-mobile
      bg-color="rgba(224, 224, 224, 0.2000)"
      height="1px"
      margin-bottom="20px"
      margin-top="16px"
    />
    <ul>
      <li>
        <span class="label">{{ $t2(lang, info.contact_title_1_cn, info.contact_title_1_en) }}：</span>
        <span class="text">{{ $t2(lang, info.contact_content_1_cn, info.contact_content_1_en) }}</span>
      </li>
      <li>
        <span class="label">{{ $t2(lang, info.contact_title_2_cn, info.contact_title_2_en) }}：</span>
        <span class="text">{{ $t2(lang, info.contact_content_2_cn, info.contact_content_2_en) }}</span>
      </li>
      <li>
        <span class="label">{{ $t2(lang, info.contact_title_3_cn, info.contact_title_3_en) }}：</span>
        <span class="text">{{ $t2(lang, info.contact_content_3_cn, info.contact_content_3_en) }}</span>
      </li>
    </ul>
  </div>
</template>

<script>

import {mapState} from "vuex";
import SplitLineMobile from "../../common/SplitLine";

export default {
  name: 'AboutContractItemMobile',
  components: {SplitLineMobile},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.about-contract-item-mobile {
  margin-bottom: 40px;
  .title{
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    line-height: 28px;
    position: relative;
  }
  ul{
    line-height: 48px;
    font-size: 24px;
    margin-top: 12px;
    li{
      font-size: 24px;
      display: flex;
      .label{
        font-weight: bold;
        color: #e1e1e1;
        line-height: 40px;
        margin-right: 10px;
        width: 80px;
        display: inline-block;
      }
      .text{
        font-weight: 500;
        color: rgba(255, 255, 255, 0.8000);
      }
    }
  }
}
</style>
