<template>
  <div
    class="icon-text-collect-mobile"
    :class="{dark: dark, bbs: bbs}"
    @click.stop="actCang"
  >
    <div
      class="icon"
      :class="icontype + ' ' + (shoucang ? `on` : ``)"
      :style="{width: width, height: width}"
    ></div>
    <div
      v-if="label !== ''"
      class="label"
      :class="icontype"
      :style="{color: color, fontSize: fontSize}"
    >
      {{ label.toString() }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextCollectMobile',
  props: {
    label: {
      type: [String, Number],
      default: () => ''
    },
    color: {
      type: String,
      default: () => '#666'
    },
    width: {
      type: String,
      default: () => '40px'
    },
    fontSize: {
      type: String,
      default: () => '20px'
    },
    shoucang: {
      type: Boolean,
      default: () => false
    },
    dark: {
      type: Boolean,
      default: () => false
    },
    bbs: {
      type: Boolean,
      default: () => false
    },
    type: {
      type: Number,
      default: () => 1
    },
    icontype: {
      type: String,
      default: () => ''
    },
    id: {
      type: [String, Number],
      default: () => 1
    },
    setCollect:{
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    actCang(){
      const params = {
        t: this.type,
        id: this.id
      }
      this.$u.api.ajaxs.actCang(params).then(res => {
        if(res.status === 1){
          // this.$message.success(this.$t(res.msg))
          if(res.msg === '50045'){
            //收藏成功
            this.setCollect(1)
          }else{
            //取消收藏成功
            this.setCollect(0)
          }
        }
      })
    }
  }

}
</script>

<style scoped lang="scss">
.icon-text-collect-mobile{
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .icon{
    width: 40px;
    height: 40px;
    background: url("../../../../assets/images/mobile/live_collect.png") no-repeat;
    background-size: 100% 100%;
    &.dark{
      background: url("../../../../assets/images/mobile/fav_hei.png") no-repeat;
      background-size: 100% 100%;
      &.on{
        width: 32px;
        height: 31px;
        background: url("../../../../assets/images/mobile/fav_hei_on.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    &.bbs{
      background: url("../../../../assets/images/mobile/fav_bbs.png") no-repeat;
      background-size: 100% 100%;
      &.on{
        width: 32px;
        height: 31px;
        background: url("../../../../assets/images/mobile/fav_hei_on.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    &.schedule{
      width: 32px;
      height: 31px;
      background: url("../../../../assets/images/mobile/del.png") no-repeat 100% 100%;
      background-size: 100% 100%;
      &.on{
        width: 32px;
        height: 31px;
        background: url("../../../../assets/images/mobile/del.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    &.on{
      background: url("../../../../assets/images/mobile/fav_on.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  &.dark{
    .icon{
      width: 40px;
      height: 40px;
      background: url("../../../../assets/images/mobile/live_collect.png") no-repeat;
      background-size: 100% 100%;
      &.on{
        width: 32px;
        height: 31px;
        background: url("../../../../assets/images/mobile/fav_hei_on.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  &.bbs{
    .icon{
      width: 40px;
      height: 40px;
      background: url("../../../../assets/images/mobile/fav_bbs.png") no-repeat;
      background-size: 100% 100%;
      &.on{
        width: 32px;
        height: 31px;
        background: url("../../../../assets/images/mobile/fav_hei_on.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .label{
    color: #666;
    font-size: 20px;
    white-space: nowrap;
    font-weight: 500;
    line-height: 24px;
    margin-top: 8px;
    &.schedule{
      display: none;
    }
  }
}
</style>
