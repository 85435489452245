<template>
  <div :class="isBg ? 'isbg' : 'isnobg'">
    <div
      class="tabs-item-pc"
      :class="on && `on`"
      :style="{fontWeight: bold ? 'bold' : '', fontSize: fontSize}"
      @click="click"
    >
      {{ label }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'TabsItemPc',
  props: {
    label: {
      type: String,
      default: () => ''
    },
    isBg: {
      type: Boolean,
      default: () => true
    },
    on: {
      type: Boolean,
      default: () => false
    },
    bold: {
      type: Boolean,
      default: () => false
    },
    change: {
      type: Function,
      default: () => () => {}
    },
    fontSize: {
      type: String,
      default: '12px'
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    click(){
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
.isbg{
  display: inline-block;
  .tabs-item-pc{
    padding: 4px 14px;
    font-size: 12px;
    color: rgba(255,255,255,0.6);
    cursor: pointer;
    margin-right: 2px;
    margin-bottom: 2px;
    &:hover, &.on{
      background: rgba(234, 76, 30, 0.8000);
      color: #fff;
    }
    &:hover, &.on{
      background: rgba(234, 76, 30, 0.8000);
      color: #fff;
    }
  }
}
.isnobg{
  display: inline-block;
  font-weight: 500;
  .tabs-item-pc{
    padding: 4px 10px;
    font-size: 16px;
    color: #666;
    cursor: pointer;
    &:hover, &.on{
      color: #EA4C1E;
    }
    &:hover, &.on{
      color: #EA4C1E;
    }
  }
}
</style>
