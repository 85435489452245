<template>
  <div class="user-supply-product-list-modal">
    <div class="box">
      <div class="close-btn">
        <btn-close-pc @click="hideSupplyProductLists" />
      </div>
      <div class="header">
        匹配产品
      </div>
      <div class="list">
        <template v-for="(res, index) in options.supply_data.list">
          <div
            :key="index"
            class="item"
          >
            <supply-demmand-item-supply-pc
              :info="res"
              @click="showSupplyModalInfo(res)"
            />
          </div>
        </template>
      </div>
    </div>
    <supply-demand-supply-modal-pc
      v-if="supplyShow"
      :hide-supply-modal="hideSupplyModal"
      :info="supplyModalInfo"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import BtnClosePc from "../../common/BtnClosePc";
import SupplyDemmandItemSupplyPc from "../../SupplyDemand/SupplyDemandItem/component/supply";
import SupplyDemandSupplyModalPc from "../../SupplyDemand/SupplyDemandSupplyModal";

export default {
  name: 'UserSupplyProductListModal',
  components: {SupplyDemandSupplyModalPc, SupplyDemmandItemSupplyPc,  BtnClosePc},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    hideSupplyProductLists: {
      type: Function,
      default: () => {}
    },
  },
  data(){
    return {
      supplyShow: false,
      supplyModalInfo: {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    hideSupplyModal(){
      this.supplyShow = false
    },
    showSupplyModalInfo(info){
        this.supplyModalInfo = info
        this.supplyShow = true
        this.$u.api.exhibitions.getProductDetail({id: info.id, companyid: info.mid})
    }
  }
}
</script>

<style scoped lang="scss">
.user-supply-product-list-modal {
  position: absolute;
  z-index: 999;
  background: rgba(0,0,0,0.6000);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  .box{
    width: 1200px;
    height: 600px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -600px;
    margin-top: -300px;
    .close-btn{
      position: absolute;
      right: -40px;
      top: -40px;
    }
    .header{
      height: 80px;
      line-height: 80px;
      padding-left: 30px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      border-bottom: 1px solid #F3F4F9;
    }
    .list{
      margin: 30px;
      height: 460px;
      width: 1160px;
      overflow-y: auto;
      position: relative;
      &::-webkit-scrollbar {/*滚动条整体样式*/
        width: 10px!important;     /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px!important;
        display: inline-block!important;
        position: absolute;
        right: 0;
        top: 0;
      }
      &::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        background: #EDEDED;
      }
      &::-webkit-scrollbar-track {/*滚动条里面轨道*/
        -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        border-radius: 10px;
        background: #fafafa;
      }
      .item{
        cursor: pointer;
        overflow: hidden;
        background: #FAFAFA;
        width: 558px;
        height: 140px;
        padding: 20px;
        margin-right: 16px;
        display: inline-block;
        margin-bottom: 14px;
      }
    }
  }
}
</style>
