<template>
  <div
    class="text-content-mobile"
    :class="{lang, dark}"
    :style="{marginTop: marginTop, marginBottom: marginBottom}"
  >
    <div
      v-if="title"
      class="title"
    >
      {{ title }}
      <a :href="sublink" class="subtitle" target="_blank" v-if="subtitle">{{subtitle}}</a>
    </div>
    <div
      v-if="lineNum <= 0"
      class="content"
    >
      <div v-html="content" />
    </div>
    <div
      v-else
      class="content line-set"
      :style="{'-webkit-line-clamp': lineNum, '-moz-line-clamp': lineNum}"
    >
      <div v-html="content" />
      <div
        class="more"
        @click="setLine(k, 0)"
      >
        {{ $t('50017') }}>>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'TextContentMobile',
  props: {
    title: {
      type: String,
      default: () => ''
    },
    subtitle: {
      type: String,
      default: () => ''
    },
    sublink: {
      type: String,
      default: () => ''
    },
    content: {
      type: String,
      default: () => ''
    },
    marginTop: {
      type: String,
      default: () => '0'
    },
    marginBottom: {
      type: String,
      default: () => '40px'
    },
    lineNum: {
      type: Number,
      default: () => 0
    },
    k: {
      type: Number,
      default: () => 0
    },
    setLine: {
      type: Function,
      default: () => () => {}
    },
    dark: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.text-content-mobile{
  margin-bottom: 48px;
  &.dark{
    .title{
      color: #000!important;
    }
    .content{
      color: #666!important;
    }
  }
  &.en{
    .content{
      //font-size: 34px!important;
    }
  }
  .title{
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    line-height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .subtitle{
      font-size: 18px;
      font-weight: 400;
      color: #EA4C1E;
      line-height: 12px;
      padding-right: 15px;
      position: relative;
      &:after{
        content: ' ';
        background: #666;
        width: 9px;
        height: 10px;
        border-radius: 6px;
        position: absolute;
        right: 0;
        top: 0;
        background: url("../../../../assets/images/zhengce_sanjiao.png");
        background-size: 100% 100%;
      }
    }
  }
  .content{
    font-size: 24px!important;
    font-weight: 400!important;
    color: rgba(255,255,255,0.8)!important;
    line-height: 36px!important;
    margin-top: 28px!important;
    p{
      font-size: 24px!important;
      font-weight: 400!important;
      color: rgba(255,255,255,0.8)!important;
      line-height: 36px!important;
    }
    &.line-set{
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -moz-box;
      -moz-line-clamp: 2;
      -moz-box-orient: vertical;
      overflow-wrap: break-word;
      word-break: break-all;
      white-space: normal;
      overflow: hidden;
      position: relative;
      .more{
        position: absolute;
        font-size: 22px;
        font-weight: 500;
        color: rgba(255,255,255,0.8);
        right: 10px;
        background: #1f201f;
        bottom: 0;
        &:before{
          content: ' ';
          width: 40px;
          height: 32px;
          background: linear-gradient(to right, rgba(0,0,0,0.2), rgba(0,0,0,0.6));
          position: absolute;
          left: -40px;
          top:0;
        }
      }
    }
  }
}
</style>
