<template>
  <div
    class="icon-text-collect-exhibition-theme1"
    :class="`template_`+templateId"
    @click.stop="actCang"
  >
    <div
      class="icon"
      :class="shoucang ? `on` : ``"
    ></div>
    <div class="label">
      {{ label }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextCollectExhibitionTheme1',
  props: {
    label: {
      type: String,
      default: () => ''
    },
    shoucang: {
      type: Boolean,
      default: () => false
    },
    type: {
      type: Number,
      default: () => 1
    },
    id: {
      type: [String, Number],
      default: () => 1
    },
    setCollect:{
      type: Function,
      default: () => () => {}
    },
    templateId: {
      type: [Number, String],
      default: () => 1
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    actCang(){
      const params = {
        t: this.type,
        id: this.id
      }
      this.$u.api.ajaxs.actCang(params).then(res => {
        if(res.status === 1){
          // this.$message.success(this.$t(res.msg))
          if(res.msg === '50045'){
            //收藏成功
            this.setCollect(1)
          }else{
            //取消收藏成功
            this.setCollect(0)
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.icon-text-collect-exhibition-theme1{
  display: inline-block;
  cursor: pointer;
  .icon{
    width: 32px;
    height: 32px;
    background: url("../../../../assets/images/exhibition_theme_1/icon_follow@2x.png") no-repeat;
    background-size: 100% 100%;
    &.on{
      background: url("../../../../assets/images/exhibition_theme_1/icon_follow_on@2x.png") no-repeat;
      background-size: 100% 100%;
    }
    &:hover{
      background: url("../../../../assets/images/exhibition_theme_1/icon_follow_hover@2x.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  &.template_2{
    .icon{
      background: url("../../../../assets/images/exhibition_theme_2/icon_follow@2x.png") no-repeat;
      background-size: 100% 100%;
      &.on{
        background: url("../../../../assets/images/exhibition_theme_2/icon_follow_on@2x.png") no-repeat;
        background-size: 100% 100%;
      }
      &:hover{
        background: url("../../../../assets/images/exhibition_theme_2/icon_follow_hover@2x.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .label{
    height: 20px;
    color: rgba(255,255,255,0.8);
    font-size: 14px;
    white-space: nowrap;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
}
</style>
