<template>
  <div
    class="icon-text-column-mobile"
    :style="{lineHeight: width}"
    @click="click"
  >
    <div class="icon">
      <img
        :src="require('../../../../assets/images/mobile/'+icon)"
        :style="{width: width, height: width}"
      />
    </div>
    <div
      class="text"
      :style="{color: color, fontSize: fontSize}"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextColumnMobile',
  props: {
    icon: {
      type: String,
      default: () => ''
    },
    text: {
      type: String,
      default: () => ''
    },
    width: {
      type: String,
      default: () => '32px'
    },
    color: {
      type: String,
      default: () => '#666'
    },
    fontSize: {
      type: String,
      default: () => '20px'
    },
    type: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    nowPath: function(){
      return this.$route.path.split('/')[1]
    },
    nowPath1: function(){
      return this.$route.path.split('/')[2]
    },
  },
  methods:{
    click(){
      if(this.type === 'share'){
        let type = 1, id = this.$route.query.id
        //类型，1展商2评奖3是视频4论坛,5直播,6资讯,7活动,8案例
        switch(this.nowPath.toLowerCase()){
           case 'exhibition':
             type = 1
             if(this.nowPath1.toLowerCase() === 'productinfo'){
               type = 9
             }
             break;
           case 'awards':
             type = 2
             break;
           case 'forum':
             type = 4
             break;
           case 'video':
             type = 3
             break;
           case 'live':
             type = 5
             break;
           case 'news':
             type = 6
             break;
           case 'activity':
             type = 7
             break;
           case 'case':
             type = 8
             break;
        }
         this.$u.api.ajaxs.actShare({t:type, id: id})
         this.$store.commit('setShare', true)
      }else{
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.icon-text-column-mobile{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .icon{
    img{
      width: 32px;
      height: 32px;
      vertical-align: top;
    }
  }
  .text{
    font-size: 20px;
    font-weight: 500;
    color: #c2c2c2;
    line-height: 24px;
    cursor: pointer;
    margin-top: 8px;
  }
  &:hover{
    .text {
      //color: #EA4C1E!important;
    }
  }
}
</style>
