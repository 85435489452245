<template>
  <div>
    <a
      v-if="user.tokens && !user.qrcode"
      class="bbs-join-btn-pc"
      :class="`status_`+status"
      :href="regurl"
      target="_blank"
      @click.stop=""
    >{{ status_str }}</a>
    <div
      v-else
      class="bbs-join-btn-pc"
      :class="`status_`+status"
      @click.stop="join"
    >
      {{ status_str }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import axios from "axios";

export default {
  name: 'BbsJoinBtnPc',
  props: {
    id: {
      type: [Number, String],
      default: () => 0
    }
  },
  data(){
    return {
      status: -1
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'user']),
    status_str(){
      if(this.status === -1 || !this.user || !this.user.tokens){
        return '立即报名'
      }else{
        return '已报名'
      }
    },
    regurl(){
      return 'https://online.ciif-expo.cn/RegMethod.html?pcurl='+location.href+'&phone='+this.user.mobile
    }
  },
  activated() {
    if(this.user.tokens && this.id){
      this.getJoin()
    }
    if(!this.user.tokens){
      this.status=-1
    }
  },
  mounted() {
    if(this.user.tokens){
      this.getJoin()
    }
  },
  watch: {
    id:{
      immediate:true,
      deep:true,
      handler(Val, oldval){
        if(Val != oldval){
          if(this.user.tokens && this.id){
            this.getJoin()
          }
        }
      }
    }
  },
  methods:{
    getJoin(){
      var url = 'https://forum.ciif-expo.cn/forum/GetSelect';
      const params = {
        Token: 'EChaokj',
        ForumId: this.id,
        AudiencesCnId: this.user.mid
      }
      axios({
        method: 'post',
        url: url,
        // 利用 transformRequest 进行转换配置
        transformRequest: [
          function(oldData){
            // console.log(oldData)
            let newStr = ''
            for (let item in oldData){
              newStr += encodeURIComponent(item) + '=' + encodeURIComponent(oldData[item]) + '&'
            }
            newStr = newStr.slice(0, -1)
            return newStr
          }
        ],
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: params,
      }).then(res => {
        this.status = res.data.code
      })
    },
    join(){
      if(!this.user || !this.user.tokens){
        localStorage.setItem('backUrl', this.$router.currentRoute.path)
        localStorage.setItem('backQuery', JSON.stringify(this.$router.currentRoute.query))
        this.$router.push('/user/login')
        return
      }
      var url = 'https://forum.ciif-expo.cn/forum/Signup';
      const params = {
        Token: 'EChaokj',
        ForumId: this.id,
        AudiencesCnId: this.user.mid
      }
      axios({
        method: 'post',
        url: url,
        // 利用 transformRequest 进行转换配置
        transformRequest: [
          function(oldData){
            // console.log(oldData)
            let newStr = ''
            for (let item in oldData){
              newStr += encodeURIComponent(item) + '=' + encodeURIComponent(oldData[item]) + '&'
            }
            newStr = newStr.slice(0, -1)
            return newStr
          }
        ],
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: params,
      }).then(res => {
        if(res.data.code === 1){
          this.status = res.data.code
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.bbs-join-btn-pc {
  cursor: pointer;
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 120px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: linear-gradient(223deg, #FC501E 0%, #FA8958 100%);
  font-size: 14px;
  color: #fff;
  &.status_1{
    width: 120px;
    height: 32px;
    background: rgba(255,255,255,0.2);
    color: #fff;
  }
}
</style>
