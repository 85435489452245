<template>
  <div class="exhibition-info-default">
    <exhibition-info-pc
      v-if="browser===1"
      :jiaohuanmingpian="jiaohuanmingpian"
      :options="options"
      :set-collect="setCollect"
      :set-zan="setZan"
    />
    <exhibition-info-mobile
      v-if="browser===2"
      :jiaohuanmingpian="jiaohuanmingpian"
      :options="options"
      :set-collect="setCollect"
      :set-zan="setZan"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import ExhibitionInfoPc from "@/views/Pc/Exhibition/info";
import ExhibitionInfoMobile from "@/views/Mobile/Exhibition/info";

export default {
  name: 'ExhibitionInfoDefault',
  components: {ExhibitionInfoMobile, ExhibitionInfoPc},
  data(){
    return {
      options: {
        id: 0,
        info: {}
      },
      first: true,
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'user']),
  },
  activated() {
    this.backurl = this.$route.meta.fromUrl === '/' ? '/Exhibition' : this.$route.meta.fromUrl
    this.$nextTick(() => {
      this.scrollAuto()
    })
    this.options.id = this.$route.query.id
    this.options.share = this.$route.query.share || 0
    if(!this.first){
      this.init()
    }
  },
  mounted() {
    if(this.$route.query.isLogin && (!this.user.tokens)){
      localStorage.setItem('backUrl', this.$router.currentRoute.path)
      localStorage.setItem('backQuery', JSON.stringify(this.$router.currentRoute.query))
      this.navigateTo('/user/login')
    }
    this.backurl = this.$route.meta.fromUrl === '/' ? '/Exhibition' : this.$route.meta.fromUrl
    this.options.id = this.$route.query.id
    this.init()
  },
  methods: {
    init() {
      const params = {
        id: this.options.id,
        share: this.$route.query.share
      }
      this.$u.api.exhibitions.getDetail(params).then(res => {
        this.first = false
        if(res.status === 1){
          this.options.info = res.data
          this.setShare(res)
          this.$forceUpdate()
        }
      })
    },
    setShare(res){
      this.getWxConfig(this.$t2(this.lang, res.data.companyname, res.data.companynameen), this.$t2(this.lang, res.data.content, res.data.content_en), res.data.logoimageurl)
    },
    setCollect(v) {
      this.options.info.is_cang = v
    },
    setZan(v,val) {
      this.options.info.is_zan = v
      this.options.info.zan = val
    },
    jiaohuanmingpian(){
      const params = {
        id: this.options.info.id
      }
      this.$u.api.ajaxs.actCard(params).then(res => {
        if(res.status === 1){
          this.options.info.is_card = true;
        }else{
          this.options.info.is_card = false;
        }
      })
    }
  }

}
</script>

<style scoped></style>
