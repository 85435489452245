<template>
  <div class="user-supply-card-list-pc">
    <template v-for="(res, index) in options.data.list">
      <div
        :key="index"
        class="item"
      >
        <supply-demmand-item-demand-pc
          from="user"
          :info="res"
          :refresh="refresh"
          @click="show(res)"
        />
      </div>
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import SupplyDemmandItemDemandPc from "../../SupplyDemand/SupplyDemandItem/component/demand";

export default {
  name: 'UserSupplyCardListPc',
  components: {SupplyDemmandItemDemandPc},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    showDemandModalInfo:{
      type: Function,
      default: () => () => {}
    },
    showDemandContactModalInfo:{
      type: Function,
      default: () => () => {}
    },
    hideDemandContactModal:{
      type: Function,
      default: () => () => {}
    },
    hideDemandModal:{
      type: Function,
      default: () => () => {}
    },
    refresh:{
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    show(info){
      this.$u.api.supplydemands.getDetail({id: info.id}).then(res => {
        if(res.status === 1){
          this.showDemandModalInfo(res.data)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.user-supply-card-list-pc {
  display: flex;
  flex-wrap: wrap;
  .item{
    width: 410px;
    height: 128px;
    background: rgba(255,255,255,0.1);
    padding: 18px 20px;
    margin-right: 16px;
    margin-bottom: 16px;
    &:nth-child(2n){
      margin-right: 0;
    }
  }
}
</style>
