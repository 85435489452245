<template>
  <div
    class="rank-list-pc"
    :class="`rank-list-pc-`+titleBg"
  >
    <div
      class="header"
      :class="`header_`+titleBg"
    >
      <rank-icon-pc /> {{ $t(title) }}
    </div>
    <div class="rank-list">
      <template v-for="(res, index) in list">
        <rank-item-pc
          :key="index"
          :field="field"
          :info="res"
          :rank="index+1"
          @click="goInfo(res)"
        />
      </template>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import RankItemPc from "@/components/Pc/Rank/RankList/item";
import RankIconPc from "../../common/RankIcon";

export default {
  name: 'RankListPc',
  components: {RankIconPc, RankItemPc},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    titleBg: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    field: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: () => {}
    },
  },
  computed: {
    ...mapState(['browser', 'lang']),
    url(){
      switch(this.type){
        case 'hotList':

          return '/'
        case 'informationList':
          return '/news/info?id='
        case 'bbsList':
          return '/forum/info?id='
        case 'zhanList':
          return '/exhibition/info?id='
      }
      return '';
    }
  },
  methods:{
    goInfo(res){
      if(this.type === 'hotList'){
        const id = res.is_value
        switch(res.type){
          case '展览':
            this.navigateTo('/exhibition/info?id='+id)
            break;
          case '产品':
            this.navigateTo('/exhibition/productinfo?companyid='+res.companyid+'&id='+id)
            break;
          case '资讯':
            this.navigateTo('/news/info?id='+id)
            break;
          case '案例':
            this.navigateTo('/case/info?id='+id)
            break;
          case '评奖':
            this.navigateTo('/awards/info?id='+id)
            break;
          case '论坛':
            this.navigateTo('/forum/info?id='+id)
            break;
          case '活动':
            this.navigateTo('/activity/info?id='+id)
            break;
          case '专题':
            this.navigateTo('/topic?typeid='+id)
            break;
          case '视频':
            this.$bus.$emit('showPlayer', res.videourl)
            break;
        }
      }else{
        this.navigateTo(this.url+res.id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.rank-list-pc{
  width: 500px;
  height: 458px;
  display: inline-block;
  margin-right: 39px;
  background: #fff;
  margin-bottom: 20px;
  overflow: hidden;
  &.rank-list-pc-1{
    background: linear-gradient(223deg, rgba(245,73,73,0.4) 0%, rgba(255,187,181,0.1) 100%);
    backdrop-filter: blur(2px);
  }
  &.rank-list-pc-2{
    background: linear-gradient(223deg, rgba(255,97,51,0.4) 0%, rgba(255,195,170,0.1) 100%);
    backdrop-filter: blur(2px);
  }
  &.rank-list-pc-3{
    background: linear-gradient(223deg, rgba(49,106,251,0.4) 0%, rgba(173,203,255,0.1) 100%);
    backdrop-filter: blur(2px);
  }
  &.rank-list-pc-4{
    background: linear-gradient(223deg, rgba(87,61,255,0.4) 0%, rgba(184,175,255,0.1) 100%);
    backdrop-filter: blur(2px);
  }
  &:nth-child(2n){
    margin-right: 0;
  }
  .header{
    height: 48px;
    line-height: 48px;
    padding-left: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    background: url("../../../../assets/images/anhei/pc/rank_top_bg.png");
    background-size: 100% 100%;
  }
  .rank-list{
  }
}
</style>
