<template>
  <div
    class="home-btn-mobile"
    @click="navigateTo('/')"
  >
    <img src="../../../../assets/images/mobile/mobile_home_icon.png" />
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'HomehBtnMobile',
  props: {
    url: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.home-btn-mobile {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 48px;
    height: 48px;
  }
}
</style>
