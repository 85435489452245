<template>
  <div
    class="awards-list-pc-list-pc"
  >
    <template v-for="(res, index) in data.list">
      <awards-item-pc
        :key="index"
        :from="from"
        :info="res"
        :keyword="data.key"
      />
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import AwardsItemPc from "../AwardsItem";

export default {
  name: 'AwardsListListPc',
  components: {AwardsItemPc},
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    from: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.awards-list-pc-list-pc {
    margin-top: 20px;
    width: 1140px;
}
</style>
