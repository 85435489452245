import { render, staticRenderFns } from "./collect.vue?vue&type=template&id=5e4e3444&scoped=true&"
import script from "./collect.vue?vue&type=script&lang=js&"
export * from "./collect.vue?vue&type=script&lang=js&"
import style0 from "./collect.vue?vue&type=style&index=0&id=5e4e3444&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e4e3444",
  null
  
)

export default component.exports