<template>
  <div class="bbs-jiabin-modal-pc">
    <div class="cell">
      <div class="box">
        <div class="close-btn">
          <btn-close-pc @click="hideJiabin" />
        </div>
        <div class="main">
          <div class="pic">
            <pic-pc :src="info.jiabinpic" />
          </div>
          <div class="info">
            <div class="title">
              <div class="bar"></div>
              <div class="title-desc">
                <div class="text">
                  {{ $t2(lang, info.xingming, info.xingming_en) }}
                </div>
                <div class="type">
                  <div class="type-name">
                    {{ $t2(lang, replaceHtmlTag(htmlspecialchars_decode(info.qiye)), replaceHtmlTag(htmlspecialchars_decode(info.qiye_en))) }}
                  </div>
                  <div
                    class="child-type-name"
                    v-html="$t2(lang, htmlspecialchars_decode(info.zhiwu), htmlspecialchars_decode(info.zhiwu_en))"
                  >
                  </div>
                </div>
              </div>
            </div>
            <div class="jieshao-content">
              <text-content-pc
                :content="replaceHtmlTag(info.scontent)"
                title=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import BtnClosePc from "@/components/Pc/common/BtnClosePc";
import PicPc from "../../common/Pic";
import TextContentPc from "../../common/TextContent";

export default {
  name: 'BbsJiabinModalPc',
  components: {TextContentPc, PicPc, BtnClosePc},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    hideJiabin: {
      type: Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      contactShow: false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    contactInfo() {
      return JSON.parse(this.htmlspecialchars_decode(this.info.contact))
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.bbs-jiabin-modal-pc{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  background: rgba(0,0,0,0.3);
  display: table;
  .cell{
    display: table-cell;
    vertical-align: middle;
    .box {
      position: relative;
      width: 880px;
      background: rgba(38, 38, 38, 1);
      margin: 0 auto;

      .close-btn {
        position: absolute;
        right: -40px;
        top: -40px;
      }
      .main{
        padding: 16px;
        display: flex;
        .pic{
          width: 280px;
          height: 280px;
        }
        .info{
          margin-left: 16px;
          width: 540px;
          .title{
            margin-top: 10px;
            line-height: 32px;
            color: #fff;
            .bar{
              width: 6px;
              height: 78px;
              background: linear-gradient(223deg, #FC501E, #FA8958);
              border-radius: 3px;
              display: inline-block;
              vertical-align: top;
              float:left
            }
            .type{
              line-height: 20px;
            }
            .title-desc{
              vertical-align: top;
              margin-left: 20px;
              .text{
                display: inline-block;
                font-size: 20px;
                font-weight: 600;
                color: #fff;
              }
            }
          }
          .jieshao-content{
            height: 158px;
            overflow-y: auto;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
</style>
