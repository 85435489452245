<template>
  <div class="about-index-pc">
    <div class="container">
      <div class="main-box">
        <template v-for="(res, index) in options.info.json_text.templateSort">
          <about-banner
            v-if="res==='0' && options.info.json_text.pic_status"
            :key="index"
            :info="options.info"
          />
          <about-text
            v-if="res==='1' && options.info.json_text.text_status"
            :key="index"
            :info="options.info"
          />
          <about-contract
            v-if="res==='2' && options.info.json_text.contact_status"
            :key="index"
            :info="options.info"
          />
          <about-link
            v-if="res==='3' && options.info.json_text.ad_status"
            :key="index"
            :info="options.info"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import AboutBanner from "@/components/Pc/About/aboutBanner";
import AboutText from "@/components/Pc/About/aboutText";
import AboutContract from "@/components/Pc/About/aboutContract";
import AboutLink from "@/components/Pc/About/aboutLink";

export default {
  name: 'AboutIndexPc',
  components: {AboutLink, AboutContract, AboutText, AboutBanner},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.about-index-pc {
  .pic{

  }
}
</style>
