<template>
  <div class="exhibition-list-mobile">
    <template v-for="(res, index) in data.list">
      <exhibition-item-mobile
        v-if="!product"
        :key="index"
        :from="from"
        :info="res"
        :keyword="data.key"
      />
      <exhibition-product-item-mobile
        v-else
        :key="index"
        :from="from"
        :info="res"
        :keyword="data.key"
      />
    </template>
    <page-common-mobile
      :change-page="changePage"
      :data="data"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import ExhibitionItemMobile from "@/components/Mobile/Exhibition/ExhibitionList/item";
import PageCommonMobile from "@/components/Mobile/common/PageCommon";
import ExhibitionProductItemMobile from "./productItem";

export default {
  name: 'ExhibitionListMobile',
  components: {ExhibitionProductItemMobile, PageCommonMobile, ExhibitionItemMobile},
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    changePage: {
      type: Function,
      default: () => {return () => {}}
    },
    from: {
      type: String,
      default: () => ''
    },
    product: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.exhibition-list-mobile {

}
</style>
