<template>
  <div class="awards-index-mobile">
    <top-nav-mobile>
      <span class="nav-title">{{ $t('10005') }}</span>
    </top-nav-mobile>
    <div class="container">
      <div class="info">
        <div class="video">
          <video
            v-if="options.videoList.length > 0"
            ref="player"
            muted
            :poster="options.videoList[videoCurrent].imageurl"
            :src="options.videoList[videoCurrent].videourl"
            @click="stop"
          ></video>
          <span
            v-if="!playing"
            class="play_btn"
            @click="play"
          ></span>
        </div>
        <div class="desc">
          <div class="title">
            <div class="text">
              {{ $t('23001') }}
            </div>
            <div
              class="more"
              style="display: inline-block"
              @click="navigateTo('/awards/list?typeid=12')"
            >
              {{ $t('50017') }}>>
            </div>
          </div>
          <div class="content">
            <div class="content-info">
              {{ $t('23009') }}
            </div>
          </div>
        </div>
      </div>
      <div class="zhuanxiang">
        <text-content-mobile
          :content="$t('23103')"
          margin-top="28px"
          :title="$t('23002')"
        />
        <div class="awards-category">
          <template v-for="(res, index) in options.category">
            <awards-category-item-mobile
              v-if="index > 0"
              :key="index"
              :info="res"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TextContentMobile from "../../../components/Mobile/common/TextContent";
import AwardsCategoryItemMobile from "../../../components/Mobile/Awards/AwardsCategoryItem";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import SearchBtnMobile from "../../../components/Mobile/common/SearchBtn";
import LangBtnMobile from "../../../components/Mobile/common/LangBtn";

export default {
  name: 'AwardsIndexMobile',
  components: {TopNavMobile, AwardsCategoryItemMobile, TextContentMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  data(){
    return {
      playing: true,
      videoCurrent: 0,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.play();
      },1000)
    })
  },
  methods:{
    play(){
      this.$refs.player.removeEventListener('play', this.handlePlay);
      this.$refs.player.removeEventListener('pause', this.handlePause);
      this.$refs.player.removeEventListener('ended', this.handleEnded);
      this.$refs.player.addEventListener('play', this.handlePlay);
      this.$refs.player.addEventListener('pause', this.handlePause);
      this.$refs.player.addEventListener('ended', this.handleEnded);
      this.$refs.player.play()
      this.playing = true
    },
    stop(){
      this.$refs.player.pause()
    },
    handlePlay(){
      this.playing = true
    },
    handlePause(){
      this.playing = false
    },
    handleEnded(){
      if(this.videoCurrent + 1 >= this.options.videoList.length){
        this.videoCurrent = 0;
      }else {
        this.videoCurrent++
      }
      this.$nextTick(()=>{
        this.$refs.player.play()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.awards-index-mobile {
  .container{
    //background: #fff;
  }
  .info{
    background: rgba(255,255,255,0.1);
    border-radius: 12px;
    overflow: hidden;
    .video{
      vertical-align: top;
      width: 100%;
      height: 400px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      video{
        width: 100%;
        height: 100%;
        background: #000;
      }
      .play_btn{
        width: 94px;
        height: 94px;
        background: url("../../../assets/images/play_big.png");
        background-size: 100% 100%;
        background-position: center;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -47px;
        margin-top: -47px;
        cursor: pointer;
      }
    }
    .desc{
      padding: 20px;
      .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 16px;
        .text{
          font-size: 32px;
          font-weight: 600;
          color: #fff;
          line-height: 28px;
        }
        .more{
          font-size: 22px;
          font-weight: 400;
          color: #EA4C1E;
          line-height: 32px;
        }
      }
      .content{
        font-size: 24px;
        font-weight: 400;
        color: rgba(255,255,255,0.8);
        line-height: 32px;
      }
    }
  }
  .zhuanxiang{
    margin-top: 20px;
    margin-bottom: 20px;
    background: rgba(255,255,255,0.1);
    border-radius: 12px;
    padding: 1px 20px 1px;
  }
  .awards-category{
  }
}
</style>
