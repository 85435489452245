<template>
  <div class="user-supply-default">
    <user-supply-pc
      v-if="browser===1"
      ref="userSupplyPc"
      :change-page="changePage"
      :change-type="changeType"
      :del-item="delItem"
      :hide-supply-product-lists="hideSupplyProductLists"
      :options="options"
      :refresh="refresh"
      :show-supply-product-lists="showSupplyProductLists"
    />
    <user-supply-mobile
      v-if="browser===2"
      :change-page="changePage"
      :change-type="changeType"
      :del-item="delItem"
      :options="options"
      :refresh="refresh"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import UserSupplyPc from "../../Pc/User/supply";
import UserSupplyMobile from "../../Mobile/User/supply";

export default {
  name: 'UserSupplyDefault',
  components: {UserSupplyMobile, UserSupplyPc},
  data() {
    return {
      options: {
        data: {
          list: [],
          page: 1,
          pagesize: 6,
          total: 0
        },
        supply_data: {
          supply_id: 0,
          list: [],
          page: 1,
          pagesize: 6,
          total: 0
        },
        type: 'my',
        typeList: [
          {
            type: 'my',
            title: '30006',
            // title: '我的发布',
          },
          {
            type: 'follow',
            title: '30011',
            // title: '供需关注',
          },
          {
            type: 'card',
            title: '30012',
            // title: '供需名片',
          },
        ],
        userSupplyProductListModalShow: false,
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    this.init()
    this.setShare()
  },
  methods: {
    init() {
      this.getMy();
    },
    getMy(){
      this.options.loading = true
      const params = {
        page: this.options.data.page,
        pagesize: this.options.data.pagesize
      }
      this.$u.api.users.getSupplyLists(params).then(res => {
        this.options.loading = false
        if(res.status === 1){
          const {data: {lists, totalNum}} = res
          this.options.data.list = lists
          this.options.data.total = totalNum
        }
      })
    },
    getFollow(){
      this.options.loading = true
      const params = {
        page: this.options.data.page,
        pagesize: this.options.data.pagesize
      }
      this.$u.api.users.getMyFollow(params).then(res => {
        this.options.loading = false
        if(res.status === 1){
          const {data: {lists, totalNum}} = res
          this.options.data.list = lists
          this.options.data.total = totalNum
        }
      })
    },
    getCard(){
      this.options.loading = true
      const params = {
        page: this.options.data.page,
        pagesize: this.options.data.pagesize
      }
      this.$u.api.users.getMyMingpian(params).then(res => {
        this.options.loading = false
        if(res.status === 1){
          const {data: {lists, totalNum}} = res
          this.options.data.list = lists
          this.options.data.total = totalNum
        }
      })
    },
    changeType(type){
      this.options.data.list = [];
      this.options.data.page = 1;
      this.options.data.total = 0;
      this.options.type = type
      if(type === 'my'){
        this.getMy()
      }else if(type === 'follow'){
        this.getFollow()
      }else if(type === 'card'){
        this.getCard()
      }
    },
    changePage(v){
      this.options.data.page = v;
      if(this.options.type === 'my'){
        this.getMy()
      }else if(this.options.type === 'follow'){
        this.getFollow()
      }else if(this.options.type === 'card'){
        this.getCard()
      }
    },
    refresh(){
      if(this.options.type === 'my'){
        this.getMy()
      }else if(this.options.type === 'follow'){
        this.getFollow()
      }else if(this.options.type === 'card'){
        this.getCard()
      }
    },
    delItem(id){
      this.options.data.list.map((res, index) => {
        if(parseInt(res.id) === parseInt(id)){
          this.options.data.list.splice(index, 1)
        }
      })
    },
    setShare(){
      this.getWxConfig('', '')
    },
    showSupplyProductLists(id){
      this.$u.api.users.getSupplyProductLists({supply_id:id, pagesize: 100}).then(res => {
        if(res.status === 1){
          this.options.supply_data.list = res.data.lists
        }
      })
      this.options.userSupplyProductListModalShow = true
    },
    hideSupplyProductLists(){
      this.options.userSupplyProductListModalShow = false
    }
  }

}
</script>

<style scoped></style>
