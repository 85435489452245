<template>
  <div
    class="supply-demand-item-supply-pc"
    :class="from"
    @click="$emit('click')"
  >
    <div class="title">
      <span class="text line-1">{{ $t2(lang, info.title, info.title_en) }}</span>
      <span class="tips">供应信息</span>
    </div>
    <div class="summary line-1">
      <span
        class=" line-1"
        v-html="$t2(lang, replaceHtmlTag(info.content), replaceHtmlTag(info.content_en))"
      ></span>
    </div>
    <div class="company-line clearfix">
      <div
        class="company"
      >
        <div class="pic">
          <pic-pc :src="info.logo_url" />
        </div>
        <div class="name">
          {{ info.author }}
        </div>
      </div>
      <div
        v-if="from !== 'user'"
        class="info"
      >
        <icon-time-line
          font-size="14"
          icon="views_hei.png"
          size="20"
          :text="info.hits"
        />
      </div>
      <div
        v-else
        class="right"
      >
        <icon-text-supply-del
          :id="info.id"
          class="item"
          :is-type="info.is_type"
          label=""
          :refresh="refresh"
          :set-collect="setCollect"
          :shoucang="parseInt(info.is_cang) === 1"
          :type="4"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import IconTimeLine from "@/components/Pc/common/IconTimeLine";
import PicPc from "@/components/Pc/common/Pic";
import IconTextSupplyDel from "../../../common/IconText/supplydel";

export default {
  name: 'SupplyDemmandItemSupplyPc',
  components: {IconTextSupplyDel, PicPc, IconTimeLine},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    from: {
      type: String,
      default: ''
    },
    refresh: {
      type: Function,
      default: () => () => {}
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.supply-demand-item-supply-pc{
  cursor: pointer;
  overflow: hidden;
  &.user{
    .title{
      .text{
        width: 235px;
      }
    }
    .company-line{
      margin-top: 10px;
      .right{
        float: right;
      }
    }
  }
  .title{
    .text{
      width: 390px;
      line-height: 28px;
      display: inline-block;
      margin-right: 60px;
      font-size: 18px;
      font-weight: 600;
      color: #fff;
    }
    .tips{
      height: 28px;
      width: 72px;
      line-height: 28px;
      display: inline-block;
      background: linear-gradient(223deg, rgba(255, 79, 26, .2), rgba(255, 155, 97, .2));
      color: #FF6032;
      float: right;
      text-align: center;
    }
  }
  .summary{
    margin-top: 10px;
    width: 390px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255,255,255,0.6);
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .company-line{
    height: 28px;
    line-height: 28px;
    margin-top: 14px;
    .company{
      float: left;
      .pic{
        display: inline-block;
        width: 30px;
        height: 30px;
        overflow: hidden;
        vertical-align: middle;
      }
      .name{
        margin-left: 5px;
        display: inline-block;
        color: rgba(255,255,255,0.8);
        vertical-align: middle;
      }
    }
    .info{
      float: right;
    }
  }
}
</style>
