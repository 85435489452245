<template>
  <div class="exhibition-info-pc-theme-3">
    <div
      class="top-banner"
      :style="{background: 'url('+options.info.template_banner_pc+') #000', backgroundSize: 'contain', backgroundPosition: 'top center'}"
    >
      <div class="container">
        <div class="jieka-logo" :style="{backgroundImage: 'url(\''+options.info.logoimageurl+'\')', backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}"></div>
        <a
          class="bannerlink"
          :href="options.info.vr"
          target="_blank"
        ></a>
        <div class="lianxi-info">
          <span class="boothno">{{ options.info.boothno }}</span>
          <a
            :href="`http://api.map.baidu.com/geocoder?address=`+options.info.address+`&output=html&src=webapp.baidu.openAPIdemo`"
            target="_blank"
          >
            <icon-text-line-exhibition
              class="lianxi-item address"
              class-name="address"
              color="#fff"
              :template-id="options.info.template_id"
              :text="$t2(lang, options.info.address, options.info.address_en)"
            />
          </a>
          <icon-text-line-exhibition
            class="lianxi-item tel"
            class-name="tel"
            color="#fff"
            :hover="false"
            :template-id="options.info.template_id"
            :text="options.info.phone"
          />
        </div>
        <div class="btn-view">
          <icon-text-collect-exhibition-theme1
            :id="options.info.id"
            class="btn-item"
            :label="$t('50024')"
            :set-collect="setCollect"
            :shoucang="parseInt(options.info.is_cang) === 1"
            :template-id="options.info.template_id"
            :type="1"
          />
          <icon-text-zan-exhibition-theme1
            :id="options.info.id"
            class="btn-item"
            :label="options.info.zan"
            :set-zan="setZan"
            :template-id="options.info.template_id"
            :type="1"
            :zan="parseInt(options.info.is_zan) === 1"
          />
          <icon-text-share-exhibition-theme1
            class="btn-item"
            :icon="options.info.logoimageurl"
            :label="$t('50016')"
            :template-id="options.info.template_id"
          />
          <icon-text-jiaohuan-exhibition-theme1
            class="btn-item"
            :jiaohuan="parseInt(options.info.is_card) === 1"
            :label="!options.info.is_card ? $t('20102') : $t('20115')"
            :template-id="options.info.template_id"
            @click="jiaohuanmingpian"
          />
          <a
            v-if="options.info.vr"
            :href="options.info.vr"
            target="_blank"
          >
            <icon-text-vr-exhibition-theme1
              class="btn-item"
              label="VR"
              :template-id="options.info.template_id"
            />
          </a>
        </div>
      </div>
    </div>
    <div
      v-if="options.info.videoList && options.info.videoList.length > 0"
      class="video-list"
      @click="stop"
    >
      <div class="video-main">
        <swiper
          ref="mySwiper"
          :options="swiperOptionsTop"
        >
          <swiper-slide
            v-for="(res, index) in options.info.videoList"
            :key="index"
          >
            <div
              v-if="!playing"
              class="play-btn"
              @click.stop="play()"
            ></div>
            <video
              :ref="'player_'+index"
              :poster="res.imageurl"
              :src="res.videourl"
            ></video>
          </swiper-slide>
        </swiper>
        <div
          v-if="!playing && options.info.videoList.length > 1"
          class="swiper-pagination"
        ></div>
      </div>
    </div>
    <div class="main-box-div">
      <div class="main-content">
        <div class="container">
          <div class="theme-3-about">
            <div class="theme-3-about-header">
              <div class="theme-3-about-header-left"></div>
              <div class="theme-3-about-header-right"></div>
            </div>
            <div class="main-box-view">
              <div class="main-box-view-header"></div>
              <div class="main-box-view-middle">
                <div
                  class="exhibition-content"
                  v-html="$t2(lang, options.info.content, options.info.content_en)"
                >
                </div>
                <div class="content-pic">
                  <img src="https://virtualobs.ciif-expo.com/Uploads/images/2022-11-11/tp636e1aa5a90b71.80019565.png" />
                </div>
              </div>
              <div class="main-box-view-bottom"></div>
            </div>
          </div>
          <div class="list-div">
            <news-list-exhibition-thumb3-pc
              v-if="options.info.informationList.length > 0"
              :list="options.info.informationList"
            />
            <product-list-exhibition-thumb3-pc
              v-if="options.info.productList.length > 0"
              :list="options.info.productList"
              :options="options"
            />
            <case-list-exhibition-thumb3-pc
              v-if="options.info.caselist.length > 0"
              :list="options.info.caselist"
              :options="options"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import '@/assets/swiper.css'
import {mapState} from "vuex";
import IconTextLineExhibition from "../../../../components/Pc/common/IconTextLineExhibition";
import IconTextCollectExhibitionTheme1 from "../../../../components/Pc/Exhibition/Theme1/collect";
import IconTextZanExhibitionTheme1 from "../../../../components/Pc/Exhibition/Theme1/zan";
import IconTextShareExhibitionTheme1 from "../../../../components/Pc/Exhibition/Theme1/share";
import IconTextJiaohuanExhibitionTheme1 from "../../../../components/Pc/Exhibition/Theme1/jiaohuan";
import IconTextVrExhibitionTheme1 from "../../../../components/Pc/Exhibition/Theme3/vr";
import NewsListExhibitionThumb3Pc from "../../../../components/Pc/Exhibition/Theme3/NewsList/NewsListExhibitionThumb3";
import ProductListExhibitionThumb3Pc
  from "../../../../components/Pc/Exhibition/Theme3/ProductList/ProductListExhibitionThumb3";
import CaseListExhibitionThumb3Pc from "../../../../components/Pc/Exhibition/Theme3/CaseList/CaseListExhibitionThumb3";
import PicPc from "../../../../components/Pc/common/Pic";

export default {
  name: 'ExhibitionInfoPcTheme3',
  components: {
    PicPc,
    CaseListExhibitionThumb3Pc,
    ProductListExhibitionThumb3Pc,
    NewsListExhibitionThumb3Pc,
    IconTextVrExhibitionTheme1,
    Swiper,
    SwiperSlide,
    IconTextJiaohuanExhibitionTheme1,
    IconTextShareExhibitionTheme1,
    IconTextZanExhibitionTheme1,
    IconTextCollectExhibitionTheme1,
    IconTextLineExhibition
  },
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    setZan: {
      type: Function,
      default: () => () => {}
    },
    jiaohuanmingpian: {
      type:Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      videoCurrent: 0,
      playing: false,
      swiperOptionsTop: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'windowHeight', 'windowWidth']),
    swiper() {
      return this.$refs.mySwiper ? this.$refs.mySwiper.$swiper : null
    }
  },
  watch: {
    options: {
      deep: true,
      handler: function(newVal, oldVal) {
        if(newVal.info.videoList){
          const _t = this;
          _t.$nextTick(() => {
            _t.swiperOptionsTop = {
              direction: 'vertical',
              height: this.windowHeight - 100,
              width: this.windowWidth,
              autoplay: false,
              pagination: {
                el: ".swiper-pagination",
              },
            }
            _t.$nextTick(() => {
              _t.swiper.on('slideChangeTransitionEnd', function () {
                _t.stop()
                _t.videoCurrent = this.realIndex
              })
            })
          })
        }
      }
    }
  },
  beforeCreate: function () {
    document.getElementsByTagName('body')[0].className = 'exhibition-thumb2'
  },
  beforeRouteEnter: function(){
    document.getElementsByTagName('body')[0].className = 'exhibition-thumb2'
  },
  activated: function(){
    document.getElementsByTagName('body')[0].className = 'exhibition-thumb2'
  },
  beforeDestroy: function(){
    if (this.vantaEffect) {
      this.vantaEffect.destroy()
    }
    document.getElementsByTagName('body')[0].className = ''
  },
  beforeRouteLeave: function(){
    document.getElementsByTagName('body')[0].className = ''
  },
  deactivated: function(){
    document.getElementsByTagName('body')[0].className = ''
  },
  mounted() {
  },
  methods: {
    showPlayer(videoUrl) {
      this.$bus.$emit('showPlayer', videoUrl)
    },
    stop(){
      this.playing = false;
      this.$refs['player_'+this.videoCurrent][0].pause()
    },
    play(){
      const _this = this;
      this.duration = this.$refs['player_'+this.videoCurrent][0].duration
      this.$refs['player_'+this.videoCurrent][0].play()
      this.$refs['player_'+this.videoCurrent][0].onended = () => {
        this.swiper.slideTo(this.videoCurrent + 1, 1000)
      };
      this.$refs['player_'+this.videoCurrent][0].ontimeupdate = () => {
        _this.currentTime = _this.$refs['player_'+_this.videoCurrent][0].currentTime
        _this.rangeValue = (_this.currentTime * 100 /  _this.duration)
      };
      // this.$refs['player_'+this.videoCurrent][0].
      this.playing = true;
    },
  }

}
</script>
<style lang="scss">
.exhibition-info-pc-theme-3{
  .exhibition-content{
    img{
      width: 100%;
    }
  }
  .lianxi-info, .main-box-view-title span{
    font-family: siyuan-medium!important;
  }
  .exhibition-content, .main-box-view-title span:last-child{
    font-family: siyuan-regular!important;
  }
  .main-box-view-title span{
    font-weight: 500;
  }
  .main-box-view-title span:last-child{
    font-weight: 400;
  }
  .info .title{
    font-family: siyuan-bold!important;
  }
  .info .summary{
    font-family: siyuan-regular!important;
  }
}
</style>
<style scoped lang="scss">
.exhibition-info-pc-theme-3{
  .top-banner{
    height: calc(100vh - 120px);
    width: 100%;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    background-position: bottom center!important;
    position: relative;
    .jieka-logo{
      background: url("https://virtualobs.ciif-expo.com/Uploads/images/2022-11-15/tp637383371f7cd7.37618676.png");
      background-size: 100% 100%;
      width: 230px;
      height: 40px;
      position: absolute;
      left: 10px;
      top: 20px;
    }
    .bannerlink{
      width: 100%;
      height: 1080px;
      display: inline-block;
    }
    .container{
      height: 100%;
      position: relative;
      .lianxi-info{
        position: absolute;
        left: 0;
        bottom: 20px;
        .boothno{
          font-size: 48px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 48px;
        }
        .lianxi-item{
          margin-top: 12px;
        }
      }
      .btn-view{
        position: absolute;
        right: 0;
        bottom: 20px;
        .btn-item{
          margin-left: 40px;
        }
      }
    }
  }
  .video-list{
    height: 678px;
    background: #000;
    .video-main{
      width: 1200px;
      margin: 0 auto;
      height: 678px;
    }
    video{
      width: 100%;
      height: 678px;
      object-fit: cover;
    }
    .play-btn{
      width: 120px;
      height: 120px;
      background: url("../../../../assets/images/exhibition_theme_3/play@2x.png");
      background-size: 100% 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -60px;
      margin-top: -60px;
      z-index: 2;
    }
  }
  .main-box-div{
    background: url("../../../../assets/images/exhibition_theme_3/thumb3-bg@2x.png");
    background-size: 100% auto;
    background-position: top center;
    padding-bottom: 50px;
    .main-content{
      .container{
        padding: 40px 0;
        .theme-3-about{
          .theme-3-about-header{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .theme-3-about-header-left{
              width: 324px;
              height: 101px;
              background: url("../../../../assets/images/exhibition_theme_3/thumb3-jianjie@2x.png");
              background-size: 100% 100%;
            }
            .theme-3-about-header-right{
              width: 100px;
              height: 101px;
              background: url("../../../../assets/images/exhibition_theme_3/thumb3-right-jiantou@2x.png");
              background-size: 100% 100%;
            }
          }
        }
        .main-box-view{
          display: flex;
          flex-direction: column;
          margin-top: 16px;
          .main-box-view-header{
            width: 1200px;
            height: 44px;
            background: url("../../../../assets/images/exhibition_theme_3/thumb3-box-top@2x.png");
            background-size: 100% 100%;
          }
          .main-box-view-middle{
            width: 1200px;
            padding: 40px;
            padding-right: 60px;
            background: url("../../../../assets/images/exhibition_theme_3/thumb3-box-bg@2x.png");
            background-size: 100% auto;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
          }
          .main-box-view-bottom{
            width: 1220px;
            height: 44px;
            background: url("../../../../assets/images/exhibition_theme_3/thumb3-box-bottom@2x.png");
            background-size: 100% 100%;
            background-position: -10px;
          }
        }
      }
    }
    .list-div{
      margin-top: 120px;
    }
  }
}
</style>
