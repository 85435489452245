<template>
  <div
    v-if="zhuangtaiStr"
    class="liveing-pc"
    :class="zhuangtaiCss"
  >
    {{ $t(zhuangtaiStr) }}
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'LiveingPc',
  props: {
    zhuangtai: {
      type: [Number, String],
      default: () => 0
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    zhuangtaiStr(){
      switch(parseInt(this.zhuangtai)){
        case 1:
          return '21013'
          // return '未开始'
        case 2:
          return '21012'
          // return '直播中'
        case 3:
          return '21016'
          // return '已结束'
        case 4:
          return '21014'
          // return '回放中'
      }
      return ''
    },
    zhuangtaiCss(){
      switch(parseInt(this.zhuangtai)){
        case 1:
          return 'off'
        case 2:
          return '直播中'
        case 3:
          return 'off'
        case 4:
          return '回放中'
      }
      return ''
    }
  },
}
</script>

<style scoped lang="scss">
.liveing-pc {
  float: left;
  height: 24px;
  background: linear-gradient(223deg, #FF1A2C, #FF9B61);
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 24px;
  text-align: center;
  padding: 0 12px;
  margin-right: 8px;
  &.off{
    background: rgba(255,255,255,0.2);
  }
}
</style>
