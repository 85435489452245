<template>
  <div class="case-info-pc">
    <div class="container">
      <div class="main-box min-height-770">
        <back-btn-pc backurl="/case" />
        <div class="title">
          {{ $t2(lang, options.info.title, options.info.en_title) }}
        </div>
        <div class="desc clearfix">
          <div class="left">
            <div class="tags-line">
              <div
                v-if="options.info.catename"
                class="tags"
              >
                <template v-for="(res, index) in options.info.catename">
                  <tag-item
                    v-if="res.name"
                    :key="index"
                    class="item"
                    :dark="true"
                    :label="$t2(lang, res.name, res.ename)"
                  />
                </template>
              </div>
            </div>
            <div class="time-line">
              <icon-text-line
                icon="laiyuan.png"
                :text="$t2(lang, options.info.source.companyname, options.info.source.companynameen)"
                @click="navigateTo('/exhibition/info?id='+options.info.companyid)"
              />
            </div>
          </div>
          <div class="right">
            <icon-text-viewnum
              class="btn-item"
              :label="options.info.click"
            />
            <icon-text-zan
              :id="options.info.id"
              class="btn-item"
              :label="options.info.zan"
              :set-zan="setZan"
              :type="5"
              :zan="parseInt(options.info.is_zan) >= 1"
            />
            <icon-text-collect
              :id="options.info.id"
              class="btn-item"
              :label="this.$t('50024')"
              :set-collect="setCollect"
              :shoucang="parseInt(options.info.is_cang) === 1"
              :type="5"
            />
            <icon-text-share
              class="btn-item"
              label="分享"
            />
          </div>
        </div>
        <split-line-pc
          bg-color="rgba(255,255,255,0.1)"
          height="1px"
          margin-bottom="19px"
          margin-top="12px"
        />
        <div class="pic">
          <img :src="options.info.pic" />
        </div>
        <text-content-pc
          :content="$t2(lang, options.info.content, options.info.en_content)"
          title=""
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import BackBtnPc from "@/components/Pc/common/BackBtn";
import TagItem from "@/components/Pc/common/TagItem";
import IconTextCollect from "@/components/Pc/common/IconText/collect";
import IconTextShare from "@/components/Pc/common/IconText/share";
import SplitLinePc from "@/components/Pc/common/SplitLine";
import TextContentPc from "@/components/Pc/common/TextContent";
import IconTextLine from "../../../components/Pc/common/IconTextLine";
import IconTextViewnum from "../../../components/Pc/common/IconText/viewnum";
import IconTextZan from "../../../components/Pc/common/IconText/zan";

export default {
  name: 'CaseInfoPc',
  components: {
    IconTextZan,
    IconTextViewnum,
    IconTextLine,
    TextContentPc, SplitLinePc, IconTextShare, IconTextCollect,  TagItem, BackBtnPc},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    setZan: {
      type: Function,
      default: () => () => {}
    },
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.case-info-pc {
  .title{
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    line-height: 28px;
    margin-top: 20px;
  }
  .pic{
    width: 100%;
    text-align: center;
    img{
      max-width: 100%;
    }
  }
  .desc{
    position: relative;
    .left{
      float: left;
      width: 1050px;
      .tags-line{
        margin-top: 16px;
        .item{
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }
    .right{
      float: right;
      position: absolute;
      bottom: -10px;
      right: 0;
      .btn-item{
        margin-left: 35px;
      }
    }
  }
}
</style>
