<template>
  <div
    class="bbs-jiabin-item-mobile"
    @click="$emit('click')"
  >
    <div class="pic radius">
      <pic-mobile :src="info.jiabinpic" />
    </div>
    <div class="info">
      <div class="xingming line-1">
        {{ info.xingming }}
      </div>
      <div class="qiye line-1">
        {{ info.qiye }}
      </div>
      <div
        class="zhiwu line-1"
        v-html="$t2(lang, htmlspecialchars_decode(info.zhiwu), htmlspecialchars_decode(info.zhiwu_en))"
      >
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "../../common/Pic";

export default {
  name: 'BbsJiabinItemMobile',
  components: {PicMobile},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.bbs-jiabin-item-mobile {
  width: 686px;
  height: 180px;
  background:  rgba(255,255,255,0.1);
  box-shadow: 0 5px 15px 0 rgba(30, 115, 234, 0.05);
  opacity: 0.9;
  margin-bottom: 36px;
  padding: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  .pic{
    width: 124px;
    height: 124px;
    position: relative;
  }
  .info{
    margin-left: 35px;
    .xingming{
      height: 36px;
      font-size: 32px;
      font-weight: 900;
      color: #fff;
      line-height: 36px;
    }
    .qiye{
      margin-top: 15px;
      height: 28px;
      font-size: 24px;
      font-weight: 500;
      color: rgba(255,255,255,1);
      line-height: 28px;
      max-width: 410px
    }
    .zhiwu{
      height: 28px;
      font-size: 24px;
      font-weight: 500;
      color: rgba(255,255,255,1);
      line-height: 28px;
      margin-top: 8px;
      max-width: 410px
    }
  }
}
</style>
