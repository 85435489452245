<template>
  <div class="page-common-mobile">
    <div
      v-if="(data.total) / data.pagesize > 1"
      class="page"
    >
      <el-pagination
        background
        :current-page.sync="data.page"
        layout="prev, pager, next, jumper"
        :next-text="$t('下一页')"
        :page-size="data.pagesize"
        :pager-count="3"
        popper-class="page-item"
        :prev-text="$t('上一页')"
        :total="parseInt(data.total)"
        @current-change="changePage"
      />
    </div>
  </div>
</template>

<script>

import '@/assets/element-ui/pagination.css'
export default {
  name: 'PageCommonMobile',
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    changePage: {
      type: Function,
      default: () => () => {}
    }
  }
}
</script>

<style scoped lang="scss">
.page-common-mobile{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
