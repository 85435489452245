<template>
  <div class="case-list-mobile">
    <template v-for="(res, index) in customList.length > 0 ? customList : (data ? data.list : [])">
      <case-list-item-mobile
        :key="index"
        :info="res"
        :keyword="(data && data.key) || ''"
      />
    </template>
    <div
      v-if="!customList || customList.length <= 0"
      class="page-view"
    >
      <page-common-mobile
        :change-page="changePage"
        :data="data"
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import CaseListItemMobile from "./item";
import PageCommonMobile from "../../common/PageCommon";

export default {
  name: 'CaseListMobile',
  components: {PageCommonMobile, CaseListItemMobile},
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    customList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.case-list-mobile {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  .page-view{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
