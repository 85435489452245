<template>
  <div class="news-info-default">
    <news-info-pc
      v-if="browser===1"
      :options="options"
      :set-collect="setCollect"
      :set-zan="setZan"
    />
    <news-info-mobile
      v-if="browser===2"
      :options="options"
      :set-collect="setCollect"
      :set-zan="setZan"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import NewsInfoPc from "@/views/Pc/News/info";
import NewsInfoMobile from "../../Mobile/News/info";

export default {
  name: 'NewsInfoDefault',
  components: {NewsInfoMobile, NewsInfoPc},
  data() {
    return {
      options: {
        id: 0,
        info: {},
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
  },
  mounted() {
    this.options.id = this.$route.query.id
    this.options.share = this.$route.query.share || 0
    this.defaultOptions = this.deepClone(this.options)
    this.init()
  },
  methods: {
    init() {
      const params = {
        id: this.options.id,
        share: this.options.share
      }
      this.$u.api.newss.getDetail(params).then(res => {
        this.isFirst = false
        if(res.status === 1){
          this.options.info = res.data
          this.setShare(res)
        }
      })
    },
    setCollect(v) {
      this.options.info.is_cang = v
      this.$forceUpdate()
    },
    setZan(v, val) {
      this.options.info.is_zan = v
      this.options.info.zan = val
      this.$forceUpdate()
    },
    setShare(res){
      this.getWxConfig(this.$t2(this.lang, res.data.title, res.data.en_title), this.$t2(this.lang,  res.data.cn_source, res.data.en_source), res.data.pic)
    },
  }

}
</script>

<style scoped lang="scss">
.news-info-default{}
</style>
