<template>
  <div
    class="exhibition-item-pc clearfix"
    @click="navigateTo('/exhibition/info?id='+info.id)"
  >
    <div
      v-if="parseInt(info.is_hot) === 1"
      class="tuijian"
    >
      {{ $t('50005') }}
    </div>
    <div class="pic">
      <pic-pc :src="info.logoimageurl" />
    </div>
    <div class="info">
      <div class="title-line">
        <div>
          <div class="title line-1">
            <div v-html="companyname"></div>
          </div>
          <div class="title en line-1">
            <div v-html="companynameen"></div>
          </div>
        </div>
        <icon-text-collect
          v-if="from==='collect'"
          :id="info.id"
          class="item"
          label=""
          :set-collect="setCollect"
          :shoucang="parseInt(info.is_cang) === 1"
          :type="1"
        />
      </div>
      <div class="tags line-1">
        <template v-for="(res, index) in cateName">
          <tag-item
            v-if="index < 3"
            :key="index"
            class="item"
            :dark="true"
            :label="$t2(lang, res.typelistname, res.typelistname_en)"
          />
        </template>
      </div>
      <div class="position">
        <icon-text-location
          color="#FF835F"
          :label="$t2(lang, info.address, info.address_en)"
        />
      </div>
      <div class="desc line-2">
        <div v-html="description">
        </div>
        <div class="more">
          {{ $t('50017') }}>>
        </div>
      </div>
      <a
        v-if="info.vr"
        class="vr"
        :href="info.vr"
        target="_blank"
      >
        <icon-text-vr />
      </a>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import IconTextLocation from "@/components/Pc/common/IconText/location";
import IconTextVr from "@/components/Pc/common/IconText/vr";
import PicPc from "@/components/Pc/common/Pic";
import TagItem from "../../common/TagItem";
import IconTextCollect from "../../common/IconText/collect";

export default {
  name: 'ExhibitionItemPc',
  components: {IconTextCollect, TagItem, PicPc, IconTextVr, IconTextLocation},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    keyword: {
      type: String,
      default: () => ''
    },
    from: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    companyname(){
      return this.htmlToString(this.replaceSearch(this.lang, this.keyword, this.info.companyname, this.info.companynameen))
    },
    companynameen(){
      return this.htmlToString(this.replaceSearch(this.lang, this.keyword, this.info.companynameen, this.info.companyname))
    },
    description() {
      return this.htmlToString(this.replaceSearch(this.lang, this.keyword, this.info.content, this.info.content_en))
    },
    cateName(){
      let cat = this.info.cateName
      if(cat && cat.length > 0){
        cat.sort((a, b) => a.typelistname.length - b.typelistname.length)
      }
      return cat
    }
  },
  methods:{
    imgerr(){
      console.log(11)
    },
    setCollect(v){
      this.info.is_cang = v
    }
  }

}
</script>

<style scoped lang="scss">
.exhibition-item-pc{
  height: 200px;
  background: rgba(255, 255, 255, 0.0500);
  margin-bottom: 16px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  position: relative;
  .tuijian{
    width: 64px;
    height: 56px;
    background: url("../../../../assets/images/tuijian_bg.png");
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 22;
    padding-top: 10px;
    padding-left: 4px;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 16px;
  }
  &:hover{
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-3px);
    .pic{
      div{
        transition: all 0.4s ease-in-out;
        transform: scale(1.1);
      }
    }
  }
  .pic{
    width: 160px;
    height: 160px;
    overflow: hidden;
    background: #F5F6FA;
    background-position: center;
    background-size: 70%;

    img{
      width: 100%;
      height: 100%;
    }
    float:left;
  }
  .title-line{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tags{
    height: 24PX;
    .item{
      margin-right: 4px
    }
  }
  .info{
    margin-left: 192px;
    position: relative;
    height: 160px;
    .title{
      font-size: 20px;
      line-height: 24px;
      height: 24px;
      color: #fff;
    }
    .en{
      font-size: 16px;
      line-height: 20px;
      margin-top: 4px;
      color: #fff;
    }
    .position{
      position: absolute;
      left: 0;
      bottom: 50px;
    }
    .desc{
      font-size: 14px;
      font-weight: 500;
      color: rgba(255,255,255,0.8);
      line-height: 20px;
      height: 40px;
      position: absolute;
      left: 0;
      bottom: 4px;
      width: 100%;
      .more{
        position: absolute;
        font-size: 14px;
        font-weight: 500;
        color: rgba(255,255,255,0.8);
        line-height: 20px;
        right: 0;
        background: #333;
        bottom: 0;
        &:before{
          content: ' ';
          width: 40px;
          height: 20px;
          background: linear-gradient(to right, rgba(250,250,250,0), #333);
          position: absolute;
          left: -40px;
          top:0;
        }
      }
    }
    .vr{
      position: absolute;
      right: 12px;
      top: 0;
    }
  }
}
</style>
