<template>
  <div class="awards-list-pc">
    <div class="container">
      <div class="main-box min-height" style="min-height: 780px;">
        <div class="awards-list-header">
          <back-btn-pc class="back-btn" />
          <div v-if="parseInt(options.typeid) === 12">
            <div class="tags">
              <template v-for="(res, index) in options.category">
                <btn-big
                  :key="index"
                  class="btn-item"
                  inactive-bg="rgba(255, 255, 255, 0.1)"
                  inactive-color="#fff"
                  :label="$t(res.title)"
                  :on="res.timeid === options.timeid"
                  @click="changeTimeid(res.timeid)"
                />
              </template>
            </div>
          </div>
        </div>
        <div>
          <text-content-pc
            v-if="parseInt(options.typeid) === 12"
            :content="$t(options.category[options.timeid].content)"
            margin-bottom="20px"
            margin-top="20px"
            title=""
          />
          <text-content-pc
            v-if="parseInt(options.typeid) === 12"
            :content="$t(options.category[options.timeid].content2)"
            title=""
          />
          <empty-pc
            v-if="!options.loading && parseInt(options.data.total) === 0"
            description="空空如也"
          />
          <awards-list-list-pc :data="options.data" />
          <page-common-pc
            :change-page="changePage"
            :data="options.data"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import BackBtnPc from "@/components/Pc/common/BackBtn";
import '@/assets/element-ui/pagination.css'
import BtnBig from "@/components/Pc/common/BtnBig";
import TextContentPc from "@/components/Pc/common/TextContent";
import PageCommonPc from "@/components/Pc/common/PageCommon";
import EmptyPc from "@/components/Pc/common/Empty";
import AwardsListListPc from "../../../components/Pc/Awards/AwardsList";

export default {
  name: 'AwardsListPc',
  components: {AwardsListListPc, EmptyPc, PageCommonPc, TextContentPc, BtnBig, BackBtnPc},
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    changePage: {
      type: Function,
      default: () => () =>{}
    },
    changeTimeid: {
      type: Function,
      default: () => () =>{}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.awards-list-pc{
  .min-height{
    min-height: 530px;
  }
  .awards-list-header{
    position: relative;
    height: 40px;
    .tags{
      text-align: center;
    }
    .btn-item{
      margin: 0 8px;
    }
    .back-btn{
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
</style>
