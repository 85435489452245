<template>
  <div
    class="bbs-jiabin-item-pc"
    @click="$emit('click')"
  >
    <div class="pic">
      <img :src="info.jiabinpic" />
    </div>
    <div class="info">
      <div class="name">
        {{ $t2(lang, info.xingming, info.xingming_en) }}
      </div>
      <div class="qiye">
        {{ $t2(lang, info.qiye, info.qiye_en) }}
      </div>
      <div
        class="zhiwu"
        v-html="$t2(lang, htmlspecialchars_decode(info.zhiwu), htmlspecialchars_decode(info.zhiwu_en))"
      >
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'BbsJiabinItemPc',
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return {
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.bbs-jiabin-item-pc{
  width: 540px;
  height: 160px;
  cursor: pointer;
  overflow: hidden;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1000);
  margin-bottom: 32px;
  margin-right: 20px;
  padding: 20px;

  &:nth-child(2){
    margin-right: 0
  }
  .pic{
    width: 120px;
    height: 120px;
    float: left;
    img{
      width: 100%;
    }
  }
  .info{
    margin-left: 140px;
    padding-top: 11px;
    .name{
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      line-height: 24px;
    }
    .qiye{
      margin-top: 18px;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      line-height: 20px;
    }
    .zhiwu{
      margin-top: 4px;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      line-height: 20px;
    }
  }
}
</style>
