<template>
  <div class="activity-info-mobile">
    <top-nav-mobile
      backurl="/activity"
      :is-back="true"
    >
      <span class="nav-title">{{ $t('10007') }}</span>
    </top-nav-mobile>
    <div class="container">
      <div class="pic radius">
        <pic-mobile :src="options.info.pic" />
      </div>
      <div class="title">
        {{ $t2(lang, options.info.title, options.info.en_title) }}
      </div>
      <div class="tags">
        <template v-for="(res, index) in options.info.catename">
          <tags-item-mobile
            v-if="res"
            :key="index"
            :dark="true"
            :info="res"
          />
        </template>
      </div>
      <div class="other">
        <icon-text-time-mobile
          class="item"
          :label="timeFormat(options.info.done_start_time, 'yyyy/mm/dd hh:MM') + ' - ' + timeFormat(options.info.done_end_time, 'yyyy/mm/dd hh:MM')"
        />
        <a
          :href="`http://api.map.baidu.com/geocoder?address=`+($t2(lang,
                                                                   options.info.cn_address,
                                                                   options.info.en_address))+`&output=html&src=webapp.baidu.openAPIdemo`"
          target="_blank"
          @click.stop=""
        >
          <icon-text-location-mobile
            class="item"
            color="rgba(255,255,255,0.6)"
            :label="$t2(lang, options.info.cn_address, options.info.en_address)"
          />
        </a>
      </div>
      <split-line-mobile
        bg-color="rgba(255,255,255,0.2)"
        height="1px"
        margin-bottom="47px"
        margin-top="41px"
      />
      <text-content-mobile
        :content="$t2(lang, options.info.content, options.info.en_content)"
        :title="$t('26001')"
      />
    </div>
    <activity-footer-mobile
      :info="options.info"
      :show-reserve-modal="showModal"
    />
    <activity-modal-mobile
      v-if="modalShow"
      :hide-modal="hideModal"
      :info="options.info"
      @showSub="showSub"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import BackBtnImgMobile from "../../../components/Mobile/common/BackBtnImg";
import PicMobile from "../../../components/Mobile/common/Pic";
import TagsItemMobile from "../../../components/Mobile/common/TagsItem";
import IconTextTimeMobile from "../../../components/Mobile/common/IconText/time";
import IconTextLocationMobile from "../../../components/Mobile/common/IconText/location";
import SplitLineMobile from "../../../components/Mobile/common/SplitLine";
import TextContentMobile from "../../../components/Mobile/common/TextContent";
import ActivityFooterMobile from "../../../components/Mobile/Activity/ActivityFooter";
import ActivityModalMobile from "../../../components/Mobile/Activity/ActivityModal";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import SubscribeModalMobile from "@/components/Mobile/common/SubscribeModal/index.vue";

export default {
  name: 'ActivityInfoMobile',
  components: {
    SubscribeModalMobile,
    TopNavMobile,
    ActivityModalMobile,
    ActivityFooterMobile,
    TextContentMobile,
    SplitLineMobile, IconTextLocationMobile, IconTextTimeMobile, TagsItemMobile, PicMobile, BackBtnImgMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  data(){
    return {
      modalShow: false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    showModal(){
      this.modalShow = true
    },
    hideModal(){
      this.modalShow = false
    },
    showSub(id){
      this.$refs.sub.show(id);
    }
  }
}
</script>

<style scoped lang="scss">
.activity-info-mobile {
  .btn-view{
    margin-top: 20px;
  }
  .pic{
    width: 100%;
    height: 452px;
  }
  .title{
    margin-top: 16px;
    font-size: 36px;
    font-weight: 600;
    color: #fff;
    line-height: 48px;
  }
  .tags{
    margin-top: 20px;
  }
  .other{
    margin-top: 16px;
    .item{
      margin-top: 10px;
    }
  }
}
</style>
