<template>
  <div class="search-type-mobile">
    <template v-for="(res, index) in searchType">
      <div
        :key="index"
        class="type-item"
        :class="options.typeCurrent === res.value ? `on` : ``"
        @click="changeType(res.value)"
      >
        {{ $t(res.label) }}
      </div>
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'SearchTypeMobile',
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeType: {
      type: Function,
      default: () => () =>{}
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'searchType']),
  },
}
</script>

<style scoped lang="scss">

.search-type-mobile{
  display: flex;
  align-items: center;
  margin-top: 28px;
  overflow-x: auto;
  .type-item{
    margin: 0 14px;
    font-size: 24px;
    font-weight: 400;
    color: #969799;
    line-height: 24px;
    height: 24px;
    cursor: pointer;
    white-space: nowrap;
    &.on{
      color: #EA4C1E;
    }
  }
}
</style>
