<template>
  <div class="video-list-pc">
    <div class="title title-before-line">
      {{ title }}
    </div>
    <div
      v-if="list.length > 0 || data"
      class="list"
    >
      <template v-for="(res,index) in (list.length > 0 ? list : data.list) ">
        <video-item-pc
          :key="index"
          :info="res"
          @click="showPlayer(res.videourl, res.id)"
        />
      </template>
      <page-common-pc
        :change-page="changePage"
        :data="data"
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import VideoItemPc from "@/components/Pc/common/VideoList/item";
import PageCommonPc from "../PageCommon";
import Vue from "vue";

export default {
  name: 'VideoListPc',
  components: {PageCommonPc, VideoItemPc},
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    list: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: () => ''
    },
    changePage: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    showPlayer(videoUrl, id) {
      this.$u.api.ajaxs.actLive({t: 1, is_value: id});
      if(this.$route.name === 'SearchList'){
        let params = {
          id: id,
          type: '视频',
          keywords: this.$route.query.keyword
        }
        if(id){
          this.$u.api.searchs.getSearchResultInfo(params)
        }
      }
      this.$bus.$emit('showPlayer', videoUrl)
    },
  }

}
</script>

<style scoped lang="scss">
.video-list-pc{
  width: 100%;
  margin-bottom: 48px;
  .title{
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    line-height: 32px;
  }
  .list{
    margin-top: 12px;
    width: 1136px;
  }
}
</style>
