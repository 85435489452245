<template>
  <div
    class="supply-demand-supply-item-mobile"
    @click="$emit('click')"
  >
    <div class="title">
      <span class="text">{{ $t2(lang, info.title, info.title_en) }}</span>
      <span class="tips">{{ $t('24115') }}</span>
    </div>
    <div class="summary line-2">
      <div v-html="$t2(lang, replaceHtmlTag(info.content), replaceHtmlTag(info.content_en))"></div>
    </div>
    <div class="company-line clearfix">
      <div class="company">
        <div class="pic">
          <pic-pc :src="info.logo_url" />
        </div>
        <div class="name">
          {{ $t2(lang, info.author, info.author_en) }}
        </div>
      </div>
      <div class="info">
        <icon-time-line
          v-if="from===''"
          font-size="20px"
          icon="mobile/views_hei.png"
          size="26"
          :text="info.hits"
        />
        <icon-text-supply-del-mobile
          v-if="from==='user'"
          :id="info.id"
          color="#c2c2c2"
          font-size="20px"
          :label="$t('50024')"
          :shoucang="parseInt(info.is_cang) === 1"
          :type="4"
          width="32px"
          @click="guanzhu"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import IconTimeLine from "@/components/Pc/common/IconTimeLine";
import PicPc from "@/components/Pc/common/Pic";
import IconTextSupplyDelMobile from "../../../common/IconText/supplydel";

export default {
  name: 'SupplyDemmandItemSupplyMobile',
  components: {IconTextSupplyDelMobile, PicPc, IconTimeLine},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    from: {
      type: String,
      default: () => ''
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    guanzhu() {
      const params = {
        sid: this.info.id,
        type: this.info.is_type
      }
      this.$u.api.supplydemands.actfollow(params).then(res => {
        if(res.status === 1){
          this.info.is_follow = res.data.is_follow
          this.refresh();
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.supply-demand-supply-item-mobile{
  cursor: pointer;
  overflow: hidden;
  .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text{
      display: inline-block;
      margin-right: 60px;
      font-size: 32px;
      font-weight: 600;
      color: #fff;
    }
    .tips{
      height: 40px;
      width: 40px;
      line-height: 40px;
      display: inline-block;
      background: linear-gradient(223deg, rgba(255, 79, 26, .2), rgba(255, 155, 97, .2));
      color: #FF6032;
      font-size: 20px;
      text-align: center;
      border-radius: 4px;
    }
  }
  .summary{
    margin-top: 10px;
    font-size: 22px;
    font-weight: 400;
    color: rgba(255,255,255,0.8);
    line-height: 32px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .company-line{
    height: 28px;
    line-height: 28px;
    margin-top: 24px;
    .company{
      float: left;
      .pic{
        display: inline-block;
        width: 35px;
        height: 35px;
        border-radius: 35px;
        overflow: hidden;
        vertical-align: middle;
      }
      .name{
        display: inline-block;
        vertical-align: middle;
        font-size: 20px;
        color: #999;
        margin-left: 10px;
      }
    }
    .info{
      float: right;
    }
  }
}
</style>
