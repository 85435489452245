<template>
  <div class="video-list-search-mobile">
    <div class="list">
      <template v-for="(res, index) in data.list">
        <video-item-mobile
          :key="index"
          :info="res"
          @click="showPlayer(res.videourl)"
        />
      </template>
      <page-common-mobile
        :change-page="changePage"
        :data="data"
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import VideoItemMobile from "./item";
import PageCommonMobile from "../PageCommon";

export default {
  name: 'VideoListSearchMobile',
  components: {PageCommonMobile, VideoItemMobile},
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    changePage: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    showPlayer(videoUrl) {
      this.$bus.$emit('showPlayer', videoUrl)
    },
  }
}
</script>

<style scoped lang="scss">
.video-list-search-mobile {
  overflow-x: scroll;
  justify-content: flex-start;
  .list{
  }
}
</style>
