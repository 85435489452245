<template>
  <div>
    <div class="user-collect-exhibition-card-list-pc">
      <template v-for="(res, index) in data.list">
        <user-collect-exhibition-card-list-item-pc
          :key="index"
          :info="res"
          :refresh="refresh"
        />
      </template>
    </div>
    <page-common-pc
      :change-page="changePage"
      :data="data"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import UserCollectExhibitionCardListItemPc from "./item";
import PageCommonPc from "../../common/PageCommon";

export default {
  name: 'UserCollectExhibitionCardListPc',
  components: {PageCommonPc, UserCollectExhibitionCardListItemPc},
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.user-collect-exhibition-card-list-pc {
  display: flex;
  flex-wrap: wrap;
}
</style>
