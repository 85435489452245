<template>
  <div class="exhibition-list-pc">
    <template v-for="(res, index) in data.list">
      <exhibition-item-pc
        v-if="!data.nowType && !product"
        :key="index"
        :from="from"
        :info="res"
        :keyword="data.key"
      />
      <exhibition-item-product-pc
        v-if="data.nowType===1 || product"
        :key="index"
        :from="from"
        :info="res"
        :keyword="data.key"
      />
    </template>
    <page-common-pc
      :change-page="changePage"
      :data="data"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import ExhibitionItemPc from "@/components/Pc/Exhibition/ExhibitionItem";
import PageCommonPc from "@/components/Pc/common/PageCommon";
import ExhibitionItemProductPc from "../ExhibitionItemProduct";

export default {
  name: 'ExhibitionListPc',
  components: {ExhibitionItemProductPc, PageCommonPc, ExhibitionItemPc},
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    from: {
      type: String,
      default: () => ''
    },
    product: {
      type: Boolean,
      default: () => false
    },
    changePage: {
      type: Function,
      default: () => {return () => {}}
    },
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.exhibition-list-pc{

}
</style>
