<template>
  <div class="awards-index-default">
    <awards-index-pc
      v-if="browser===1"
      :options="options"
    />
    <awards-index-mobile
      v-if="browser===2"
      :options="options"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import AwardsIndexPc from "@/views/Pc/Awards";
import AwardsIndexMobile from "../../Mobile/Awards";

export default {
  name: 'AwardsIndexDefault',
  components: {AwardsIndexMobile, AwardsIndexPc},
  data() {
    return {
      options: {
        videoList: [],
        category: []
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    this.init()
    this.setShare()
  },
  methods: {
    init() {
      this.getVideo()
      this.getCategory()
    },
    getVideo(){
      this.$u.api.awardss.getVideo().then(res => {
        if(res.status === 1){
          this.options.videoList = res.data
        }
      })
    },
    getCategory(){
      const params = {
        typeId: 4
      }
      this.$u.api.commons.getChildArchivesType(params).then(res => {
        if(res.status === 1){
          this.options.category = res.data
        }
      })
    },
    setShare(){
      this.getWxConfig(this.$t('10005') + this.bankuai(), 'CIIF大奖在线发布，新产品、新技术见证中国制造新发展。', 'https://virtualobs.ciif-expo.com/shareimg/3.png')
    }
  }

}
</script>

<style scoped></style>
