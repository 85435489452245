<template>
  <div>
    <div
      v-if="!more"
      class="topic-actvity-list-mobile"
    >
      <template v-for="(res, index) in customList.length > 0 ? customList : (data ? data.list : []) ">
        <activity-list-item-mobile
          v-if="index < 2"
          :key="index"
          :info="res"
          :keyword="(data && data.key) || ''"
          :show-modal="showModal"
        />
      </template>
      <activity-modal-mobile
        v-if="modalShow"
        :hide-modal="hideModal"
        :info="activityModalInfo"
      />
    </div>
    <div
      v-if="more"
      class="topic-actvity-list-mobile"
      :class="more ? `topic-list-swiper`: ``"
    >
      <swiper
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="(res, index) in customList.length > 0 ? customList : (data ? data.list : [])"
          :key="index"
          class="swiper-item-pic"
        >
          <activity-list-item-mobile
            :key="index"
            :info="res"
            :keyword="(data && data.key) || ''"
            :show-modal="showModal"
          />
        </swiper-slide>
      </swiper>
      <activity-modal-mobile
        v-if="modalShow"
        :hide-modal="hideModal"
        :info="activityModalInfo"
      />
      <div
        v-if="more"
        class="swiper-page"
      >
        <div class="swiper-pagination-activity"></div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import ActivityListItemMobile from "../../Activity/ActivityList/item";
import ActivityModalMobile from "../../Activity/ActivityModal";
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import '@/assets/swiper.css'

export default {
  name: 'TopicActivityListMobile',
  components: {ActivityModalMobile,  ActivityListItemMobile, Swiper, SwiperSlide},
  directives: {
    swiper: directive
  },
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    customList: {
      type: Array,
      default: () => []
    },
    changePage: {
      type: Function,
      default: () => () =>{}
    },
    more: {
      type: Boolean,
      default: () => false
    }
  },
  data(){
    return {
      modalShow: false,
      activityModalInfo: {},
      swiperOptions: {
        spaceBetween: 20,
        slidesPerView: 2,
        slidesPerColumn: 2,
        slidesPerGroup: 2,
        slidesPerColumnFill: 'row',
        pagination: {
          el: ".swiper-pagination-activity",
          clickable: true,
        },
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    showModal(info){
      this.activityModalInfo = info
      this.modalShow = true
    },
    hideModal(){
      this.modalShow = false
    }
  }
}
</script>

<style scoped lang="scss">
.topic-actvity-list-mobile {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
</style>
