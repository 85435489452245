<template>
  <div
    class="icon-text-zan-mobile"
    :class="{dark: dark}"
    @click="actZan"
  >
    <div
      class="icon"
      :class="zan ? `on` : ``"
      :style="{ width: width, height: width}"
    ></div>
    <div
      class="label"
      :style="{color: color, fontSize: fontSize}"
    >
      {{ label.toString() }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextZanMobile',
  props: {
    label: {
      type: String,
      default: () => ''
    },
    color: {
      type: String,
      default: () => '#666'
    },
    width: {
      type: String,
      default: () => '40px'
    },
    fontSize: {
      type: String,
      default: () => '20px'
    },
    zan: {
      type: Boolean,
      default: () => false
    },
    dark: {
      type: Boolean,
      default: () => false
    },
    type: {
      type: Number,
      default: () => 1
    },
    id: {
      type: [String, Number],
      default: () => 1
    },
    setZan:{
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    actZan(){
      const params = {
        t: this.type,
        id: this.id
      }
      this.$u.api.ajaxs.actZan(params).then(res => {
        if(res.status === 1){
          if(res.msg === '50047'){
            //收藏成功
            this.setZan(1, res.data.zan)
          }else{
            //取消收藏成功
            this.setZan(0, res.data.zan)
          }
        }
      })
    }
  }

}
</script>

<style scoped lang="scss">
.icon-text-zan-mobile{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .icon{
    width: 40px;
    height: 40px;
    background: url("../../../../assets/images/mobile/zan_touming.png") no-repeat;
    background-size: 100% 100%;
    &.on{
      background: url("../../../../assets/images/mobile/zan_on.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  &.dark{
    .icon{
      width: 40px;
      height: 40px;
      background: url("../../../../assets/images/mobile/live_zan.png") no-repeat;
      background-size: 100% 100%;
      &.on{
        background: url("../../../../assets/images/mobile/zan_on.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .label{
    color: #666;
    font-size: 20px;
    white-space: nowrap;
    line-height: 24px;
    font-weight: 500;
    margin-top: 8px;
  }
}
</style>
