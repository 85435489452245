<template>
  <div class="supply-demand-list-mobile">
    <template v-for="(res, index) in list">
      <supply-demand-item-mobile
        :key="index"
        :info="res"
        :show-demand-contact-modal-info="showDemandContactModalInfo"
        :show-demand-modal-info="showDemandModalInfo"
        :show-supply-modal-info="showSupplyModalInfo"
      />
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import SupplyDemandItemMobile from "../SupplyDemandItem";

export default {
  name: 'SupplyDemandListMobile',
  components: {SupplyDemandItemMobile},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    showSupplyModalInfo: {
      type: Function,
      default: () => () => {}
    },
    showDemandModalInfo: {
      type: Function,
      default: () => () => {}
    },
    showDemandContactModalInfo: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.supply-demand-list-mobile {
  margin-top: 20px;
}
</style>
