<template>
  <div class="rank-icon-pc">
    <div class="rank-line1"></div>
    <div class="rank-line2"></div>
    <div class="rank-line3"></div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'RankIconPc',
  props: {
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.rank-icon-pc {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 17px;
  margin-right: 10px;
  .rank-line1{
    width: 3px;
    height: 7px;
    background: linear-gradient(223deg, rgba(255, 255, 255, 0.5) 0%, #FFFFFF 100%);
  }
  .rank-line2{
    width: 3px;
    height: 18px;
    background: linear-gradient(223deg, rgba(255, 255, 255, 0.5) 0%, #FFFFFF 100%);
  }
  .rank-line3{
    width: 3px;
    height: 12px;
    background: linear-gradient(223deg, rgba(255, 255, 255, 0.5) 0%, #FFFFFF 100%);
  }
}
</style>
