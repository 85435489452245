<template>
  <div class="common-class-pc clearfix">
    <div class="type-item clearfix">
      <div class="left-text">
        {{ typeName }}：
      </div>
      <div class="right-box">
        <div class="types">
          <template v-for="(res, key) in typeList">
            <tabs-item-pc
              :key="key"
              :bold="true"
              font-size="14px"
              :label="$t2(lang, res.name, res.ename)"
              :on="parseInt(res.id) === parseInt(typeId)"
              @click="changeTypeId(res.id)"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TabsItemPc from "@/components/Pc/common/TabsItem";

export default {
  name: 'CommonClassPc',
  components: {TabsItemPc},
  props: {
    typeList: {
      type: Array,
      default: () => []
    },
    typeId: {
      type: [String, Number],
      default: () => 0
    },
    typeName: {
      type: [String],
      default: () => 0
    },
    changeTypeId: {
      type: Function,
      default: () => {return () => {}}
    },
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.common-class-pc{
  width: 100%;
  padding-bottom: 15px;
  .type-item{
    width: 100%;
    &:last-child{
      margin-bottom: 0;
    }
    .left-text{
      float: left;
      width: 80px;
      text-align: center;
      height: 48px;
      line-height: 48px;
      color: #fff;
    }
    .right-box{
      padding: 12px 20px 10px;
      margin-left: 80px;
      background: rgba(255,255,255,0.1);
      .types{
        width: 100%;
        //white-space: nowrap;
        overflow-x: scroll;
        overflow-y: auto;
        //height: 24px;
      }
    }
  }
}
</style>
