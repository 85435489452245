<template>
  <div class="supply-demand-supply-modal-pc">
    <div class="cell">
      <div class="box">
        <div class="close-btn">
          <btn-close-pc
            size="24"
            @click="hideSupplyModal"
          />
        </div>
        <div class="title-line clearfix">
          <div class="title">
            <div class="text">
              {{ $t2(lang, info.title, info.title_en) }}
            </div>
          </div>
          <div
            class="guanzhu-btn"
            @click="guanzhu"
          >
            <btn-loukong-pc
              :on="parseInt(info.is_follow )=== 1"
              :text="parseInt(info.is_follow )=== 1 ? '已关注' : '+ 关注'"
            />
          </div>
        </div>
        <split-line-pc
          bg-color="rgba(255,255,255,0.1)"
          height="1px"
          margin-top="0"
        />
        <div class="info-main">
          <div class="company-line clearfix">
            <div
              class="company"
              @click="navigateTo('/exhibition/info?id='+info.mid)"
            >
              <div class="pic">
                <pic-pc :src="info.logo_url" />
              </div>
              <div class="name">
                {{ info.author }}
              </div>
            </div>
            <div class="info">
              <icon-time-line
                font-size="14"
                icon="views.png"
                size="20"
                :text="info.hits"
              />
            </div>
          </div>
          <div
            v-if="info.imageurl"
            class="info-pic"
          >
            <img :src="info.imageurl" />
          </div>
          <text-content-pc
            class="content"
            :content="$t2(lang, info.content, info.content_en)"
            margin-bottom="0"
            title=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicPc from "@/components/Pc/common/Pic";
import IconTimeLine from "@/components/Pc/common/IconTimeLine";
import TextContentPc from "@/components/Pc/common/TextContent";
import BtnLoukongPc from "@/components/Pc/common/BtnLoukong";
import BtnClosePc from "@/components/Pc/common/BtnClosePc";
import SplitLinePc from "@/components/Pc/common/SplitLine";

export default {
  name: 'SupplyDemandSupplyModalPc',
  components: {SplitLinePc, BtnClosePc, BtnLoukongPc, TextContentPc, IconTimeLine, PicPc},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    hideSupplyModal: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    guanzhu() {
      const params = {
        sid: this.info.id,
        type: this.info.is_type
      }
      this.$u.api.supplydemands.actfollow(params).then(res => {
        if(res.status === 1){
          this.info.is_follow = res.data.is_follow
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.supply-demand-supply-modal-pc{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  background: rgba(0,0,0,0.6);
  display: table;
  .info-pic{
    margin-top: 24px;
  }
  .cell{
    display: table-cell;
    vertical-align: middle;
    .box{
      position: relative;
      width: 880px;
      background: #262626;
      margin: 0 auto;
      .close-btn{
        position: absolute;
        right: -24px;
        top: -24px;
      }
      .title-line{
        padding: 24px 32px 16px;
        .title{
          float: left;
          display: inline-block;
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          line-height: 24px;
          .text{
            display: inline-block;
            vertical-align: middle;
          }
          .pic{
            margin-left: 4px;
            display: inline-block;
            width: 18px;
            height: 18px;
            vertical-align: middle;
          }
        }
        .guanzhu-btn{
          float: right;
        }
      }
      .info-main{
        padding: 0 32px 24px;
        max-height: 427px;
        overflow-y: auto;
        .company-line{
          height: 28px;
          line-height: 28px;
          margin-top: 12px;
          .company{
            float: left;
            .pic{
              display: inline-block;
              width: 30px;
              height: 30px;
              overflow: hidden;
              vertical-align: middle;
            }
            .name{
              margin-left: 5px;
              display: inline-block;
              vertical-align: middle;
              color: #fff;
            }
          }
          .info{
            float: right;
          }
        }
      }
    }
  }
}
</style>
