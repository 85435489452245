<template>
  <div
    class="rank-list-item-mobile line-1"
    :class="`rank-item-`+rank"
    @click="$emit('click')"
  >
    <div
      class="rank-num"
      :class="`rank-num-`+rank"
      :style="{color: color[rank]}"
    >
      {{ rank }}
    </div>
    <div
      class="title"
      :class="`title-`+rank"
    >
      <span
        class="line-1"
        :style="{fontWeight: rank <= 3 ? '500': '400'}"
      >{{ $t2(lang, info[field['title']], info[field['en_title']]) }}</span>
    </div>
    <div class="right">
      <div
        v-if="rank<=3"
        class="icon"
      >
        <img src="../../../../assets/images/rank_hot.png" />
      </div>
      <div class="num">
        {{ info[field['num']] }}
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'RankListItemMobile',
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    field: {
      type: Object,
      default: () => {
      }
    },
    rank: {
      type: Number,
      default: () => 0
    }
  },
  data(){
    return {
      color: {
        1: '#E50113',
        2: '#EA4C1E',
        3: '#F7AB00',
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.rank-list-item-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 96px;
  line-height: 96px;
  padding: 0 20px;
  &.rank-item-1{
    background: linear-gradient(270deg, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.15) 100%);
  }
  &.rank-item-2{
    background: linear-gradient(270deg, rgba(255, 176, 0, 0) 0%, rgba(255, 156, 0, 0.11) 100%);
  }
  &.rank-item-3{
    background: linear-gradient(270deg, rgba(11, 21, 255, 0) 0%, rgba(51, 123, 234, 0.07) 100%);
  }
  .rank-num{
    font-size: 32px;
    font-family: Helvetica;
    color: #B3B3B3;
    display: inline-block;
    width: 45px;
    text-align: center;

    &.rank-num-1{
      background: url("../../../../assets/images/mobile/rank_1.png");
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      height: 36px;
      font-size: 0;
      margin-bottom: 4px;
    }
    &.rank-num-2{
      background: url("../../../../assets/images/mobile/rank_2.png");
      background-size: 100% 100%;
      background-position: center;
      height: 36px;
      background-repeat: no-repeat;
      font-size: 0;
      margin-bottom: 4px;
    }
    &.rank-num-3{
      background: url("../../../../assets/images/mobile/rank_3.png");
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      height: 36px;
      font-size: 0;
      margin-bottom: 10px;
    }
  }
  .title{
    font-size: 26px;
    font-weight: 400;
    color: #000000;
    width: 450px;
    margin-left: 17px;
  }
  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 110px;
    .icon{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 4px;
      width: 28px;
      height: 28px;
      img{
        width: 100%;
        height: 100%;
        vertical-align: middle;
      }
    }
    .num{
      font-size: 24px;
      font-weight: 400;
      color: #999999;
    }
  }
}
</style>
