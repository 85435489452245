<template>
  <div
    class="actvity-list-mobile"
  >
    <template v-for="(res, index) in customList.length > 0 ? customList : (data ? data.list : []) ">
      <activity-list-item-mobile
        v-if="from===''"
        :key="index"
        :from="from"
        :info="res"
        :keyword="(data && data.key) || ''"
        :show-modal="showModal"
      />
      <activity-list-item-user-mobile
        v-if="from==='user'"
        :key="index"
        :from="from"
        :info="res"
        :keyword="(data && data.key) || ''"
        :refresh="refresh"
        :show-modal="showModal"
      />
    </template>
    <div
      v-if="!customList"
      class="page"
    >
      <page-common-mobile
        :change-page="changePage"
        :data="data"
      />
    </div>
    <activity-modal-mobile
      v-if="modalShow"
      :hide-modal="hideModal"
      :info="activityModalInfo"
      @showSub="showSub"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import ActivityListItemMobile from "./item";
import PageCommonMobile from "../../common/PageCommon";
import ActivityModalMobile from "../ActivityModal";
import ActivityListItemUserMobile from "./item_user";
import SubscribeModalMobile from "@/components/Mobile/common/SubscribeModal/index.vue";

export default {
  name: 'ActivityListMobile',
  components: {
    SubscribeModalMobile,
    ActivityListItemUserMobile, ActivityModalMobile, PageCommonMobile, ActivityListItemMobile},
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    customList: {
      type: Array,
      default: () => []
    },
    changePage: {
      type: Function,
      default: () => () =>{}
    },
    refresh: {
      type: Function,
      default: () => () =>{}
    },
    from: {
      type: String,
      default: () => ''
    }
  },
  data(){
    return {
      modalShow: false,
      activityModalInfo: {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    showModal(info){
      this.activityModalInfo = info
      this.modalShow = true
    },
    hideModal(){
      this.modalShow = false
    },
    showSub(id){
      this.$bus.$emit('showSub', {id: id, typeName: '活动', template: '0TmvQEKhXVy3vNX3T6oWGZPMPM1awQEljfyfUvnqpwE,YQvZ9T0sBdCqvnRVJYVj3NXNl0I0cPb_vscUBCCM1UI', type: 'activity'})
    }
  }
}
</script>

<style scoped lang="scss">
.actvity-list-mobile {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
</style>
