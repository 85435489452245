<template>
  <div class="user-types-mobile">
    <template v-for="(res, index) in options.typeList">
      <template v-if="lang !== 'en' || (lang ==='en' && !res.enHide)">
        <user-types-item-mobile
          :key="index"
          :info="res"
          :on="options.type === res.type"
          @click="changeType(res.type)"
        />
      </template>
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import UserTypesItemMobile from "./item";

export default {
  name: 'UserTypesMobile',
  components: {UserTypesItemMobile},
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    changeType: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.user-types-mobile {
  white-space: nowrap;
  overflow-x: scroll;
}
</style>
