<template>
  <div
    class="exhibition-product-item-mobile"
    @click="navigateTo('/exhibition/productinfo?companyid='+info.companyid+'&id='+info.productid)"
  >
    <div
      v-if="parseInt(info.is_hot) === 1"
      class="tuijian"
    >
      {{ $t('50005') }}
    </div>
    <div class="pic">
      <pic-mobile :src="info.imageurl" />
    </div>
    <div class="info">
      <div class="title line-1">
        <div v-html="productname">
        </div>
        <icon-text-vr-mobile v-if="info.vr" />
      </div>
      <div class="tags line-1">
        <template v-for="(res, index) in info.cateName">
          <tags-item-mobile
            v-if="index < 3"
            :key="index"
            :dark="true"
            :info="res"
          />
        </template>
      </div>
      <div class="label">
        <icon-text-line-mobile
          class="item"
          icon="laiyuan.png"
          :style="{maxWidth: from==='user' ? '350px' : '100%'}"
          :text="replaceHtmlTag(companyname)"
        />
        <icon-text-collect-mobile
          v-if="from==='user'"
          :id="info.id"
          class="item"
          color="#c2c2c2"
          font-size="20px"
          label=""
          :set-collect="setCollect"
          :shoucang="parseInt(info.is_cang) === 1"
          :type="4"
          width="32px"
        />
      </div>
      <div class="other ">
        <div class="summary line-2 margin-right">
          <div v-html="replaceHtmlTag(explains)" />
          <div class="more">
            {{ $t('50017') }}>>
          </div>
        </div>
        <icon-text-collect-mobile
            v-if="from==='user'"
            :id="info.id"
            class="item"
            color="#c2c2c2"
            font-size="20px"
            label=""
            :set-collect="setCollect"
            :shoucang="parseInt(info.is_cang) === 1"
            :type="7"
            width="32px"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "@/components/Mobile/common/Pic";
import IconTextVrMobile from "@/components/Mobile/common/IconText/vr";
import TagsItemMobile from "../../common/TagsItem";
import IconTextCollectMobile from "../../common/IconText/collect";
import IconTextLineMobile from "../../common/IconTextLine";

export default {
  name: 'ExhibitionProductItemMobile',
  components: {
    IconTextLineMobile,
    TagsItemMobile, IconTextVrMobile, PicMobile, IconTextCollectMobile},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    keyword: {
      type: String,
      default: () => ''
    },
    from: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    productname(){
      return this.replaceSearch(this.lang, this.keyword, this.info.productname, this.info.productnameen)
    },
    companyname(){
      return this.replaceSearch(this.lang, this.keyword, this.info.companyname, this.info.companynameen)
    },
    explains() {
      return this.replaceSearch(this.lang, this.keyword, this.info.explains, this.info.explains_en)
    }
  },
  methods: {
    setCollect(v){
      this.info.is_cang = v
    }
  }
}
</script>

<style scoped lang="scss">
.exhibition-product-item-mobile {
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: self-start;
  background: rgba(255,255,255,0.1);
  margin-bottom: 20px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;

  .tuijian{
    width: 92px;
    height: 80px;
    background: url("../../../../assets/images/tuijian_bg.png");
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    padding-top: 10px;
    padding-left: 4px;
    font-size: 24px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 28px;
  }
  .pic{
    width: 300px;
    height: 200px;
    border-radius: 6px;
    overflow: hidden;
    background-color: #f5f7fa;
  }
  .info{
    flex: 1;
    margin-left: 24px;
    width: 232px;
    .tags{
      margin-top: 5px;
    }
    .title{
      font-size: 32px;
      font-weight: 500;
      color: #fff;
      line-height: 44px;
      height: 44px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .label{
      margin-top: 12px;
      width: 400px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .other{
      color: rgba(153, 153, 153, 1);
      font-size: 22px;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .margin-right{
        margin-right: 10px;
      }
    }
    .summary{
      font-size: 22px;
      margin-top: 8px;
      font-weight: 500;
      color: rgba(255,255,255,0.8);
      position: relative;
      height: 64px;
      line-height: 32px;

      .more{
        position: absolute;
        font-size: 22px;
        font-weight: 500;
        color: rgba(255,255,255,0.8);
        right: 0;
        background: #333;
        bottom: 0;
        &:before{
          content: ' ';
          width: 40px;
          height: 32px;
          background: linear-gradient(to right, rgba(250,250,250,0), #333);
          position: absolute;
          left: -40px;
          top:0;
        }
      }
    }
  }
}
</style>
