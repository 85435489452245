<template>
  <div class="bbs-list-mobile">
    <template v-for="(res, index) in data.list">
      <bbs-list-item-mobile
        :key="index"
        :info="res"
        :keyword="(data && data.key) || ''"
        :refresh="refresh"
        :type="type"
      />
    </template>
    <page-common-mobile
      :change-page="changePage"
      :data="data"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import BbsListItemMobile from "./item";
import PageCommonMobile from "../../common/PageCommon";

export default {
  name: 'BbsListMobile',
  components: {PageCommonMobile, BbsListItemMobile},
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    refresh: {
      type: Function,
      default: () => () => {}
    },
    type: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.bbs-list-mobile {
  margin-top: 20px;
}
</style>
