<template>
  <div
    v-lazy:background-image="src"
    class="pic-bg"
    @click="$emit('click')"
  ></div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'PicMobile',
  props:{
    src: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.pic-bg{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}
</style>
