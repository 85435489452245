<template>
  <div
    class="supply-demand-tabs-item-pc"
    :class="on ? `on` : ``"
    @click="$emit('click')"
  >
    {{ $t(info.title) }}
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'SupplyDemandTabsItemPc',
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    on: {
      type: Boolean,
      default: () => false
    },
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.supply-demand-tabs-item-pc{
  display: inline-block;
  margin-right: 40px;
  font-size: 16px;
  font-weight: 500;
  color: #858585;
  line-height: 40px;
  vertical-align: middle;
  cursor: pointer;
  &.on{
    color: #fff;
    font-size: 20px;
  }
}
</style>
