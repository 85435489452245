<template>
  <div class="live-tabs-pc">
    <template v-for="(res, index) in list">
      <live-tabs-item-pc
        v-if="res.id !== 0 || (res.id === 0 && siteConfig.is_live) || true"
        :key="index"
        :change-type-id="changeTypeId"
        :info="res"
        :on="res.id === childTypeId"
      />
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import LiveTabsItemPc from "@/components/Pc/Live/LiveTabs/item";

export default {
  name: 'LiveTabsPc',
  components: {LiveTabsItemPc},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    childTypeId: {
      type: [Number, String],
      default: () => 0
    },
    liveCommentStae: {
      type: [Number, String],
      default: () => 0
    },
    liverichang: {
      type: Boolean,
      default: () => false
    },
    changeTypeId: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'siteConfig']),
  },
}
</script>

<style scoped lang="scss">
.live-tabs-pc{
  height: 30px;
  text-align: center;
}
</style>
