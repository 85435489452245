<template>
  <div class="bbs-jiabin-pc">
    <div class="container">
      <div class="main-box">
        <back-btn-pc />
        <div class="info">
          <div class="pic">
            <pic-pc
              v-if="options.info.jiabinpic"
              :src="options.info.jiabinpic"
            />
          </div>
          <div class="title">
            <div class="bar"></div>
            <div class="title-desc">
              <div class="text">
                {{ $t2(lang, options.info.xingming, options.info.xingming_en) }}
              </div>
              <div class="type">
                <div class="type-name">
                  {{ $t2(lang,options.info.qiye,options.info.qiye_en) }}
                </div>
                <div class="child-type-name">
                  {{ $t2(lang,options.info.zhiwu,options.info.zhiwu_en) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <text-content-pc
          :content="options.info.scontent"
          title=""
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import BackBtnPc from "@/components/Pc/common/BackBtn";
import SplitLinePc from "@/components/Pc/common/SplitLine";
import TextContentPc from "@/components/Pc/common/TextContent";
import PicPc from "@/components/Pc/common/Pic";

export default {
  name: 'BbsJiabinPc',
  components: {PicPc, TextContentPc, BackBtnPc},
  props: {
    options: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.bbs-jiabin-pc{
  .info{
    margin-top: 20px;
    .pic{
      width: 420px;
      height: 280px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .title{
      margin-top: 20px;
      line-height: 32px;
      .bar{
        width: 6px;
        height: 68px;
        background: linear-gradient(223deg, #FC501E, #FA8958);
        border-radius: 3px;
        display: inline-block;
        vertical-align: top;
        float:left
      }
      .title-desc{
        vertical-align: top;
        margin-left: 20px;
        .text{
          display: inline-block;
          font-size: 20px;
          font-weight: 600;
          color: #000000;
        }
      }
    }
    .type{
      .type-name{
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        line-height: 16px;
      }
      .child-type-name{
        margin-top: 2px;
        font-size: 12px;
        font-weight: 500;
        color: #999999;
        line-height: 16px;
      }
    }
  }
}
</style>
