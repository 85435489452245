<template>
  <div
    class="icon-text-card-del"
    @click.stop="actCang"
  >
    <div
      class="icon"
      :class="shoucang ? `on` : ``"
    ></div>
    <div class="label">
      {{ label }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextCardDel',
  props: {
    label: {
      type: String,
      default: () => ''
    },
    shoucang: {
      type: Boolean,
      default: () => false
    },
    type: {
      type: Number,
      default: () => 1
    },
    id: {
      type: [String, Number],
      default: () => 1
    },
    setCollect:{
      type: Function,
      default: () => () => {}
    },
    refresh:{
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    actCang(){
      const params = {
        id: this.id
      }
      this.$u.api.ajaxs.actCard(params).then(res => {
          this.refresh()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.icon-text-card-del{
  width: 20px;
  .icon{
    width: 20px;
    height: 20px;
    background: url("../../../../assets/images/del.png") no-repeat;
    background-size: 100% 100%;
    &:hover{
      background: url("../../../../assets/images/del_on.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
