<template>
  <div
    class="news-list-pc"
    :class="(options && (parseInt(options.childTypeId)===39 || parseInt(options.childSubTypeId) === 39)) ? 'flex' : ''"
  >
    <template v-for="(res, index) in data.list">
      <news-item-pc
        v-if="(options && parseInt(options.childTypeId)!==37 && (parseInt(options.childTypeId)!==39 && parseInt(options.childSubTypeId) !== 39)) || !options"
        :key="index"
        :info="res"
        :keyword="data.key"
      />
      <news-item-renwu-pc
        v-if="options && parseInt(options.childTypeId)===37"
        :key="index"
        :info="res"
      />
      <news-item-video-pc
        v-if="options && (parseInt(options.childTypeId)===39 || parseInt(options.childSubTypeId) === 39)"
        :key="index"
        :info="res"
      />
    </template>
    <page-common-pc
      :change-page="changePage"
      :data="data"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import NewsItemPc from "@/components/Pc/News/NewsList/item";
import PageCommonPc from "@/components/Pc/common/PageCommon";
import NewsItemRenwuPc from "./itemRenwu";
import NewsItemVideoPc from "./itemVideo";

export default {
  name: 'NewsListPc',
  components: {NewsItemVideoPc, NewsItemRenwuPc, PageCommonPc, NewsItemPc},
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.news-list-pc {
  &.flex{
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
