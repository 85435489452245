<template>
  <div class="user-login-mobile">
    <div class="bg"></div>
    <top-nav-mobile
      :is-back="true"
      :search="false"
      url="/exhibition"
    />
    <div class="container">
      <div>
        <div class="logo-view">
          <div class="logo">
            <pic-mobile :src="require('../../../assets/images/newlogo/logo_mobile.png')" />
          </div>
        </div>
        <div class="user-login-div">
          <div class="user-login-form">
            <div class="login-title">
              {{ $t('10011') }}/{{ $t('10016') }}
            </div>
            <el-form
              ref="form"
              label-position="top"
              label-width="80px"
              :model="form"
            >
              <el-form-item :label="$t('10017')">
                <el-input v-model="form.mobile" />
              </el-form-item>
              <el-form-item :label="$t('50009')">
                <div class="yanzhengma">
                  <el-input v-model="form.key" />
                  <div
                    class="btn-yanzhengma"
                    :class="(options.smsTime > 0 || form.mobile.length !== 11) ? `disabled` : ``"
                    @click="sendSms(form.mobile)"
                  >
                    {{ options.smsTime > 0 ? $t('10018') + '('+options.smsTime+')' : $t('50010') }}
                  </div>
                </div>
              </el-form-item>
              <el-form-item
                class="btn-view"
                :class="( form.mobile.length !== 11 || form.key.length !== 6) ? `disabled` : ``"
              >
                <btn-big-block-mobile
                  :disabled="form.mobile.length !== 11 || form.key.length !== 6"
                  height="80px"
                  :label="$t('50034')"
                  @click="submit(form)"
                />
              </el-form-item>
              <el-form-item>
                <div class="zhengce-btn">
                  登录即代表您同意<span @click="zhengce=true">《隐私政策》</span>
                </div>
              </el-form-item>
            </el-form>
            <div
              class="about"
              @click="navigateTo('/about')"
            >
              {{ $t('10010') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="modalShow"
      class="qrcode-modal"
      @click.stop="closeModal"
    >
      <div class="qrcode-modal-main-body">
        <div class="qrcode-modal-main-body-title">
          登录成功
        </div>
        <div class="qrcode-modal-main-body-title-tip">
          欢迎来到中国工博会在线
        </div>
        <div class="qrcode-modal-main-body-info">
          <div class="info-left">
            <div class="desc">
              <p>关注工博会服务号</p>
              <p>热点消息不错过</p>
            </div>
            <div class="bottom-tip">
              微信公众号：CIIFexpo
            </div>
          </div>
          <div class="info-right">
            <div
              style="width: 128px; height: 128px;"
            >
              <div
                class="qrcode"
              >
                <img src="https://virtualobs.ciif-expo.com/Uploads/images/2022-06-10/tp62a2af3a9ce7b4.49799495.jpeg" />
              </div>
            </div>
            <div class="qrcode-tip">
              <img
                class="text-icon"
                src="../../../assets/images/login-qrcode-icon.png"
              />
              微信扫码
            </div>
          </div>
        </div>

        <div class="close-btn">
          <btn-close-mobile
            size="68"
            @click.stop="closeModal"
          />
        </div>
      </div>
    </div>
    <div class="zhengce-model" v-if="zhengce">
      <div class="zhengce-view">
        <btn-close-mobile @click="zhengce = false" />
        <div class="zhengce-title">隐私政策</div>
        <div class="zhengce-info">
          <p>中国国际工业博览会组委会（以下简称为“组委会”或“我们”）高度重视对您的个人信息的保护。在您同意向我们提交您的个人数据之前，请您仔细阅读并理解本《隐私政策》（以下简称“本政策”）。本政策将阐述您在注册及参与第23届中国国际工业博览会时我们如何收集、使用、传输、披露和保护您的个人信息，以及您如何维护您的合法权益。本政策按照中国相关的法律、法规、标准制订，请您仔细阅读。</p>
          <h4>一、 我们如何收集您的个人信息</h4>
          <p>为向您提供更好的服务并满足本政策第二部分所载的目的，组委会将会在您注册及参与我们的活动、使用我们的服务或与我们联系时收集您的个人信息，包括个人基本资料（姓名、性别、邮箱、职位、部门、公司名称、地区、地址、手机号码等）、个人身份信息（身份证号码等）、账号密码、照片、声音、影像等。在此过程中，我们还可能收集公司的个人联络人或公司员工的个人信息。</p>
          <p>如登记表或信息采集页面中所载，某些要求提供的信息是强制性的，某些则由您自愿提供。如果要求提供但没有提供特定强制性信息，组委会可能无法提供所需的服务。组委会仅出于下述所载目的保留个人信息。</p>
          <h4>二、我们如何使用您的个人信息</h4>
          <p>我们会将您的信息用于以下目的：</p>
          <ul>
            <li>处理注册等；</li>
            <li>提供展会服务，邀请您参加线上或线下的活动等；</li>
            <li>纳入我们的数据库，进行分析、研究及审计等运作；</li>
            <li>向您发送您可能感兴趣的产品和服务信息；</li>
            <li>宣传、邀请您参与我们组织的其他展览会或活动；</li>
            <li>应您的要求变更或者提供您要求的信息；</li>
            <li>为您提供个性化用户体验和个性化内容。</li>
          </ul>
          <p>当我们需要将个人信息用于本政策未载明的其它用途时，会事先征求您的同意。</p>
          <p>当我们需要将基于特定目的收集的个人信息用于其他目的时，会事先征求您的同意。</p>
          <p>在法律允许的范围内，我们将会在以下情形中收集、使用您的个人信息，而无需征得您的授权同意，且我们可能不会响应您提出的更正/修改、删除、注销、撤回同意、索取信息的请求：</p>
          <ul>
            <li>与国家安全、国防安全有关的；</li>
            <li>与公共安全、公共卫生、重大公共利益有关的；</li>
            <li>与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</li>
            <li>出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</li>
            <li>您自行向社会公众公开的个人信息；</li>
            <li>从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</li>
            <li>根据与您签订和履行相关协议或其他书面文件所必需的；</li>
            <li>用于维护所提供的产品及/或服务的安全稳定运行所必需的，例如发现、处置产品及/或服务的故障；</li>
            <li>为合法的新闻报道所必需的；</li>
            <li>学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</li>
            <li>法律法规规定的其他情形。</li>
          </ul>
          <h4>三、Cookie和同类技术</h4>
          <p>Cookie和同类技术是互联网中的通用常用技术。为确保网站正常高效运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们有时会在您的设备终端/系统上存储名为 Cookie的小数据文件。Cookie会帮助您在后续访问网站时调用您的信息，简化您填写个人信息（例如登录等）的流程。我们不会将Cookie用于本政策所述目的之外的任何用途，您可根据自己偏好管理进行操作，大部分浏览器上都设有阻止Cookie的功能。 如果您的浏览器或浏览器附加服务允许，您可修改对Cookie的接受程度或拒绝我们的Cookie。但如果您这么做，在某些情况下可能会影响您安全访问我们的网站，且可能需要在每一次访问我们的网站时更改用户设置。</p>
          <h4>四、我们如何传输、公开披露您的个人信息</h4>
          <p>我们对我们所持有的个人信息保密，但可能会将个人信息提供或传输予：</p>
          <ul>
            <li>与我们业务运作或服务有关的代理、顾问、核数师、承办商、或服务提供商，为促进及加强组委会的运作，将所收集的个人信息作分析、研究及审计；</li>
            <li>组委会的办事处、关联公司、合资伙伴，亦是其他展览的主办单位，目的是为有关组委会在中国及世界各地主办的展览会的宣传或邀请进行直接促销；</li>
            <li>根据中国境内或境外适用法律，组委会须向其做出披露的人士。</li>
          </ul>
          <p>根据法律规定，共享、转让、公开披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，我们对此类数据的处理将无需另行向您通知并征得您的同意。</p>
          <h4>五、我们如何保护您的个人信息</h4>
          <p>我们高度重视您的个人信息安全。例如，我们使用加密、脱敏等技术提高个人信息的安全性；使用受信赖的保护机制防止个人信息遭到恶意攻击；部署访问控制机制，防止未授权的访问；举办安全和隐私保护培训课程。我们采取的措施旨在为您提供与处理您的个人信息产生的风险相匹配的安全保障级别。我们会采取合理可行的措施，尽力避免收集无关的个人信息。</p>
          <p>我们只会在达成本政策所述目的所需的期限内保留您的个人信息，但法律法规另有规定的除外。当所留存的个人信息超过保存期限、我们停止运营服务或在您注销账户后，我们会将您的个人信息删除或将其匿名化，如果不能采取这类措施（比如，因您的信息已存储在备份存档中），则我们将安全地存储您的个人信息并在能够删除此类信息前避免对其做进一步的处理。</p>
          <p>尽管没有百分之百安全的网络传播或电子存储方法，这使我们不能保证个人信息的绝对安全，但我们将采取适当的技术与组织保障措施，以保护您的信息不被盗取、丢失、滥用以及任何未经授权的访问、复制、收集、使用、披露、更改或者损毁。</p>
          <h4>六、您的权利</h4>
          <p>当适用的法律要求的情况下，您可以：</p>
          <ul>
            <li>有权访问我们持有的关于您的特定的个人信息；</li>
            <li>要求我们更新或者更正您的不准确个人信息；</li>
            <li>拒绝或限制我们使用您的个人信息；</li>
            <li>或要求我们删除您的个人信息；</li>
            <li>获取您的个人数据副本。</li>
          </ul>
          <p>如果您想行使相关的权利，请通过“九、如何联系我们”与我们取得联系。</p>
          <p>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份上存放的信息）的请求，我们可能会予以拒绝。</p>
          <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
          <ul>
            <li>与国家安全、国防安全直接相关的；</li>
            <li>与公共安全、公共卫生、重大公共利益直接相关的；</li>
            <li>与犯罪侦查、起诉、审判和判决执行等直接相关的；</li>
            <li>有充分证据表明您存在主观恶意或滥用权利的；</li>
            <li>响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</li>
            <li>涉及商业秘密的。</li>
          </ul>
          <h4>七、如何更新本政策</h4>
          <p>我们可能会根据我们的服务或数据处理的变化不时更新或修改本政策。如果我们更新本政策，我们会将本政策的最新版发布在网站上，并自发布时立即生效。建议您定期查看网站和本政策以了解任何更改。如果我们对本政策做出任何重大更改（变更信息收集方式、范围或使用目的、范围），我们将通过适当渠道通知您，并获取您的同意。在前述情况下，若您继续使用我们的服务，即表示同意接受修改后的《隐私政策》并受之约束。</p>
          <h4>八、政府法律及管辖权</h4>
          <p>本政策受中国的法律管辖。如果双方就本政策或展览会有任可争议或分歧，任何一方可将该争议提交组委会所在地有关法院进行审理。</p>
          <h4>九、如何联系我们</h4>
          <p>如果您想联系我们以获取更多与本隐私政策或个人信息相关的内容，请通过以下方式与我们联系：</p>
          <p>联系邮箱：ciif@shanghaiexpogroup.com</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import PicMobile from "../../../components/Mobile/common/Pic";
import BtnBigBlockMobile from "../../../components/Mobile/common/BtnBigBlock";
import QRCode from 'qrcodejs2'
import $ from 'jquery'
import BtnCloseMobile from "../../../components/Mobile/common/BtnCloseMobile";

export default {
  name: 'UserLoginMobile',
  components: {BtnCloseMobile, BtnBigBlockMobile, PicMobile, TopNavMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    sendSms: {
      type: Function,
      default: () => () => {}
    },
    submit: {
      type: Function,
      default: () => () => {}
    },
    modalShow: {
      type: Boolean,
      default: () => false
    },
    qrcodeurl: {
      type: String,
      default: () => false
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data(){
    return {
      form: {
        mobile: '',
        key: ''
      },
      zhengce: false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    creatQrCode() {
      // $('.qrcode').find('img').remove();
      // $('.qrcode').find('canvas').remove();
      // var qrcode = new QRCode(this.$refs.qrCodeUrl, {
      //   text: this.qrcodeurl,
      //   width: 128,
      //   height: 128,
      //   colorDark: '#000000',
      //   colorLight: '#ffffff',
      //   correctLevel: QRCode.CorrectLevel.H
      // })
    }
  }
}
</script>

<style scoped lang="scss">
.user-login-mobile {
  .bg{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  .logo-view{
    display: flex;
    justify-content: center;
    align-items: center;
    .logo{
      z-index: 1;
      position: relative;
      width: 200px;
      height: 115px;
    }
  }
  .about{
    height: 24px;
    font-size: 24px;
    font-weight: 500;
    color: rgba(255,255,255,0.6);
    line-height: 24px;
    margin-top: 20px;
    text-align: center;
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
  .user-login-div{
    margin-top: 120px;
    z-index: 1;
    position: relative;
    .user-login-form{
      .login-title{
        height: 62px;
        font-size: 44px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.8500);
        line-height: 62px;
      }
      width: 100%;
      height: 720px;
      background: rgba(255, 255, 255, 0.1000);
      padding: 60px;
      border-radius: 12px;
      .title{
        font-size: 36px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.8500);
        line-height: 50px;
      }
      .btn-view{
        margin-top: 64px;
        position: relative;
        &.disabled{
          opacity: 0.5;
        }
      }
      .yanzhengma{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        overflow: hidden;
        .el-input{
          flex: 1;
          overflow: hidden;
        }
        .btn-yanzhengma{
          position: absolute;
          right: 1px;
          top: 0;
          background: linear-gradient(223deg, rgba(255, 26, 44, 0.9) 0%, rgba(255, 155, 97, 0.8) 100%);
          color: #fff;
          padding: 0 25px;
          height: 88px;
          line-height: 88px;
          overflow: hidden;
          opacity: 1;
          cursor: pointer;
          border-radius: 0 8px 8px 0;
          &:hover, &.on{
            opacity: 0.5;
          }
          &.disabled{
            opacity: 0.5;
          }
        }
      }
    }
  }
}
.qrcode-modal{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.4);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  &-main-body{
    width: 80%;
    height: 570px;
    background: linear-gradient( #FFEDE5, #FF9C7F);
    box-shadow: 0 3px 12px 0 rgba(30,115,234,0.1);
    border-radius: 28px;
    border: 1px solid #FFC1B0;
    position: relative;
    &:before{
      width: 206px;
      height: 206px;
      position: absolute;
      background: url("../../../assets/images/login-success.png");
      background-size: 100% 100%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      content: '';
    }
    &-title{
      width: 100%;
      text-align: center;
      font-size: 48px;
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
      color: #EA4C1E;
      line-height: 42px;
      margin-top: 80px;
    }
    &-title-tip{
      width: 100%;
      text-align: center!important;
      font-size: 28px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: bold;
      color: #330B00;
      line-height: 14px;
      margin-top: 50px;
    }
    &-info{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 80px 20px 20px 20px;
      background: #fff;
      border-radius: 28px;
      padding: 40px;
      .info-left{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 190px;
        flex: 1;
        .desc{
          font-size: 32px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #330B00;
          line-height: 42px;
          height: 100%;
        }
        .bottom-tip{
          font-size: 22px;
        }
      }
      .info-right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-right: 20px;
        .qrcode{
          width: 156px;
          height: 156px;
          padding: 15px;
          border: 1px solid #ccc;
          border-radius: 10px;
          margin-bottom: 10px;
        }
        .qrcode-tip{
          text-align: center;
          margin-top: 40px;
          .text-icon{
            width: 28px;
            height: 28px;
          }
          font-size: 22px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
  .close-btn{
    width: 68px;
    height: 68px;
    position: absolute;
    left: 50%;
    margin-left: -34px;
    bottom: -100px;
  }
}
.zhengce-btn{
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 24px;
  margin-top: 20px;
  span{
    color: rgba(255, 26, 44, 0.9);
    cursor: pointer;
  }
}
.zhengce-model{
  position: fixed;
  z-index: 2147483638;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.6);
  .zhengce-view{
    margin: 0 32px;
    height: 860px;
    border-radius: 12px;
    background: #262626;
    .zhengce-title{
      height: 120px;
      line-height: 120px;
      color: #fff;
      padding: 0 32px;
      font-size: 40px;
      font-weight: bold;
      border-bottom: 1px solid rgba(245,245,245,0.1);
    }
    .zhengce-info{
      padding: 32px;
      color: rgba(255,255,255,0.8);
      font-size: 26px;
      height: 700px;
      overflow-y: auto;
      line-height: 42px;
      h4{
        font-size: 28px;
        color: #fff;
        font-weight: bold;
        line-height: 80px;
      }
      ul{
        padding-left: 2rem;
        li{
          list-style: disc;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.zhengce-view {
  position: relative;
  .btn-close-mobile {
    position: absolute;
    left: 50%;
    margin-left: -34px;
    bottom: -100px;
    width: 68px!important;
    height: 68px!important;
  }
}
@media screen and(max-height: 1200px) {
  .about{
    display: none;
  }
}
</style>
