<template>
  <div class="supply-demand-demand-contact-modal-mobile">
    <div class="cell">
      <div class="box">
        <div class="title-line">
          联系方式
        </div>
        <div class="info-main">
          <div class="cell-list">
            <div class="label">
              姓名：
            </div>
            <div class="text">
              {{ info.name }}
            </div>
          </div>
          <div class="cell-list">
            <div class="label">
              邮箱：
            </div>
            <div class="text">
              {{ info.email }}
            </div>
          </div>
          <div class="cell-list">
            <div class="label">
              公司：
            </div>
            <div class="text">
              {{ info.company }}
            </div>
          </div>
        </div>
        <div class="btn-line">
          <btn-custom-mobile
            border-radius="0"
            class="item"
            label="关闭"
            @click="hideDemandContactModal"
          />
          <btn-custom-mobile
            :background="parseInt(demandModalInfo.is_follow) === 0 ? `linear-gradient(223deg, rgba(255, 26, 44, 0.9), rgba(255, 155, 97, 0.8))` : `#F7F7F7`"
            border-radius="0"
            class="item"
            :color="parseInt(demandModalInfo.is_follow) === 0 ? `#fff` : `#aaa`"
            :label="from !== 'user' ? (parseInt(demandModalInfo.is_follow) === 0 ? `添加到我的供需` : `已添加`) : `删除名片`"
            @click="guanzhu"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import BtnCustomMobile from "../../common/BtnCustom";

export default {
  name: 'SupplyDemandDemandContactModalMobile',
  components: {BtnCustomMobile},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    demandModalInfo: {
      type: Object,
      default: () => {}
    },
    hideDemandContactModal: {
      type: Function,
      default: () => () => {}
    },
    delItem: {
      type: Function,
      default: () => () => {}
    },
    from: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    guanzhu(){
      if(this.from !== 'user' ){
          const params = {
            sid: this.info.id,
          }
          this.$u.api.supplydemands.actfollow(params).then(res => {
            if (res.status === 1) {
              this.demandModalInfo.is_follow = res.data.is_follow
            }
          })
      }else{
        this.$confirm('是否要删除该名片', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'mobile-message'
        }).then(() => {
          const params = {
            sid: this.info.id,
          }
          this.$u.api.supplydemands.actfollow(params).then(res => {
            if (res.status === 1) {
              this.demandModalInfo.is_follow = res.data.is_follow
              if (parseInt(res.data.is_follow) === 0){
                this.delItem(this.info.id)
              }
              this.hideDemandContactModal()
            }
          })
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.supply-demand-demand-contact-modal-mobile{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  background: rgba(0,0,0,0.3);
  display: table;
  .box{
    position: absolute;
    width: 670px;
    background: #fff;
    margin-left: -335px;
    margin-top: -230px;
    border-radius: 12px;
    overflow: hidden;
    top: 50%;
    left: 50%;
    .close-btn{
      position: absolute;
      right: -40px;
      top: -40px;
    }
    .title-line{
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 100px;
      height: 100px;
      border-bottom: 1px solid #ebebeb;
    }
    .info-main{
      padding: 40px;
      display: table;
      .cell-list{
        display: table-row;
        height: 56px;
        line-height: 56px;
        font-size: 26px;
        font-weight: 500;
        .label{
          display: table-cell;
          color: #aaa;
          width: 80px;
        }
        .text{
          display: table-cell;
          color: #333333
        }
      }
    }
    .btn-line{
      text-align: center;
      .item{
        width: 50%;
      }
    }
  }
}
</style>
