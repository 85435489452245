<template>
  <div class="awards-list-default">
    <awards-list-pc
      v-if="browser===1"
      :change-page="changePage"
      :change-timeid="changeTimeid"
      :options="options"
    />
    <awards-list-mobile
      v-if="browser===2"
      :change-page="changePage"
      :change-timeid="changeTimeid"
      :options="options"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import AwardsListPc from "@/views/Pc/Awards/list";
import AwardsListMobile from "@/views/Mobile/Awards/list";

export default {
  name: 'AwardsListDefault',
  components: {AwardsListMobile, AwardsListPc},
  data() {
    return {
      options: {
        typeid: 0,
        timeid: 1,
        loading: true,
        data: {
          list: [],
          page: 1,
          pagesize: 8,
          total: 0
        },
        category: {
          1: {
            title: '23101',
            content: '23103',
            content2: '23104',
            timeid: 1,
          },
          2: {
            title: '23102',
            content: '',
            content2: '',
            timeid: 2,
          },
        }
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.options.typeid = this.$route.query.typeid
        this.init()
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.options.typeid = this.$route.query.typeid
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    this.init()
    this.setShare()
  },
  methods: {
    init() {
      this.getList()
    },
    getList(){
      this.loading = true;
      const params = {
        typeid: this.options.typeid,
        page: this.options.data.page,
        timeid: parseInt(this.options.typeid) === 12 ? this.options.timeid : null,
        pagesize: this.options.data.pagesize
      }
      this.$u.api.awardss.getLists(params).then(res => {
        this.loading = false
        if(res.status === 1){
          this.options.data.list = res.data.lists
          this.options.data.total = res.data.totalNum
          this.goTop()
        }
      })
    },
    changePage(page){
      this.options.data.page = page
      this.getList()
    },
    changeTimeid(v){
      this.options.data.page = 1
      this.options.timeid = v
      this.getList()
    },
    setShare(){
      this.getWxConfig(this.$t('10005'), this.$t('v'))
    }
  }

}
</script>

<style scoped></style>
