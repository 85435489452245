<template>
  <div class="qrcode-modal-mobile">
    <div class="cell">
      <div class="box">
        <div class="close-btn">
          <btn-close-mobile @click="closeQrcodeModal" />
        </div>
        <div class="main">
          <div class="pic">
            <pic-mobile :src="src" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "../../common/Pic";
import BtnCloseMobile from "../../common/BtnCloseMobile";

export default {
  name: 'QrcodeModalMobile',
  components: {BtnCloseMobile, PicMobile},
  props: {
    src: {
      type: String,
      default: () => {}
    },
    closeQrcodeModal: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang'])
  }
}
</script>

<style scoped lang="scss">
.qrcode-modal-mobile{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  background: rgba(0,0,0,0.3);
  display: table;
  .cell{
    display: table-cell;
    vertical-align: middle;
    .box {
      position: relative;
      width: 500px;
      background: #fff;
      margin: 0 auto;
      .close-btn{
        width: 100%;
        height: 68px;
        position: absolute;
        bottom: -120px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .main{
        padding: 16px;
        .pic{
          width: 100%;
          height: calc(500px - 32px);
        }
     }
    }
  }
}
</style>
