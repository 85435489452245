<template>
  <div class="exhibition-productinfo-mobile">
    <top-nav-mobile
      :backurl="backurl"
      :is-back="true"
    >
      <span class="nav-title">{{ $t('10002') }}</span>
    </top-nav-mobile>
    <div class="container">
      <div class="pic radius">
        <div
          class="zoomin"
          v-if="options.info.productRow.imageurl"
          @click="show(options.info.productRow.imageurl)"
        ></div>
        <img
            v-if="options.info.productRow.imageurl"
            :src="options.info.productRow.imageurl"
            @click="show(options.info.productRow.imageurl)"
        />
        <img
            v-else
            src="../../../assets/images/newlogo/logo_chang1.png"
            @click="show(options.info.productRow.imageurl)"
        />
      </div>
      <div class="title-line">
        <div class="line"></div>
        <div class="title">
          {{ $t2(lang, options.info.productRow.productname, options.info.productRow.productnameen) }} <span
            v-if="options.info.productRow.leixing"
            class="leixing"
          >{{ $t2(lang, options.info.productRow.leixing, options.info.productRow.leixing_en) }}</span>
        </div>
      </div>
      <div class="btn-view">
        <div class="other">
          <div class="posline">
            <div class="tag-line">
              <div class="line1">
                {{ $t2(lang,options.info.zhanRow.projectteamname,options.info.zhanRow.projectteamname_en) }}
              </div>
              <div class="line2">
                {{ $t2(lang,options.info.zhanRow.applicationsarea,options.info.zhanRow.applicationsarea_en) }}
              </div>
            </div>
          </div>
        </div>
        <div class="btns">
          <icon-text-column-mobile
            class="item"
            color="rgba(255,255,255,0.6)"
            icon="views.png"
            :text="options.info.productRow.click"
          />
          <icon-text-zan-mobile
            :id="options.info.productRow.id"
            class="item"
            color="rgba(255,255,255,0.6)"
            :dark="true"
            font-size="20px"
            :label="options.info.productRow.zan"
            :set-zan="setZan"
            :type="6"
            width="32px"
            :zan="parseInt(options.info.productRow.is_zan) >= 1"
          />
          <icon-text-collect-mobile
            :id="options.info.productRow.id"
            class="item"
            color="rgba(255,255,255,0.6)"
            font-size="20px"
            :label="this.$t('50024')"
            :set-collect="setCollect"
            :shoucang="parseInt(options.info.productRow.is_cang) === 1"
            :type="7"
            width="32px"
          />
          <icon-text-column-mobile
            class="item"
            color="rgba(255,255,255,0.6)"
            icon="share.png"
            :text="$t('50016')"
            type="share"
          />
        </div>
      </div>
      <split-line-mobile
        bg-color="rgba(245, 245, 245, .2)"
        height="1px"
        margin-bottom="0"
        margin-top="20px"
      />
      <text-content-mobile
        :content="$t2(lang, options.info.productRow.explains, options.info.productRow.explains_en)"
        margin-top="48px"
        :title="$t('20111')"
      />
    </div>
    <div
      v-if="images.length > 0"
      class="imgmodal"
      style="width: 100%; height: 100%; position: absolute; left: 0; top: 0; z-index: 999"
    >
      <div class="close-btn">
        <btn-close-mobile
          size="60"
          @click="hide"
        />
      </div>
      <viewer
        ref="viewer"
        class="viewer"
        :images="images"
        :options="optionsViewer"
        style="width: 100%; height: 100%;  background-size: contain;background-repeat: no-repeat;
                background-position: center;background-color: rgba(0,0,0,0.5);"
      >
        <img
          v-for="(img, index) in images"
          :key="index"
          :src="img"
          style="display: none; height: 630px;"
        />
      </viewer>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import Vue from "vue";
import TopNavMobile from "@/components/Mobile/common/TopNav";
import TextContentMobile from "@/components/Mobile/common/TextContent";
import BtnCloseMobile from "../../../components/Mobile/common/BtnCloseMobile";
import IconTextColumnMobile from "../../../components/Mobile/common/IconTextColumn";
import IconTextZanMobile from "../../../components/Mobile/common/IconText/zan";
import IconTextCollectMobile from "../../../components/Mobile/common/IconText/collect";
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import SplitLineMobile from "../../../components/Mobile/common/SplitLine";
Vue.use(Viewer)

export default {
  name: 'ExhibitionProductMobile',
  components: {
    SplitLineMobile,
    TextContentMobile, BtnCloseMobile, TopNavMobile, IconTextColumnMobile, IconTextZanMobile, IconTextCollectMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    setZan: {
      type: Function,
      default: () => () => {}
    },
  },
  data(){
    return {
      backurl: '',
      images: [],
      optionsViewer: {
        zIndex: 99999,
        inline: true,
        minHeight: 640,
        button: false,
        navbar: false,
        title: false,
        toolbar: false,
        tooltip: false,
        movable: true,
        zoomable: true,
        rotatable: false,
        scalable: false,
        transition: false,
        fullscreen: false,
        keyboard: false,
      },
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    this.backurl = this.$route.meta.fromUrl === '/' ? '/exhibition' : this.$route.meta.fromUrl
  },
  mounted() {
    this.backurl = this.$route.meta.fromUrl === '/' ? '/exhibition' : this.$route.meta.fromUrl
  },
  methods:{
    show (img) {
      this.images = [img]
      this.$nextTick(() => {
        this.$refs.viewer.$viewer.show()
      })
    },
    hide(){
      this.images = []
      this.$refs.viewer.$viewer.hide()
    }
  }
}
</script>

<style scoped lang="scss">
.exhibition-productinfo-mobile {
  .imgmodal{
    .close-btn{
      position: absolute;
      left: 50%;
      bottom: 160px;
      margin-left: -32px;
      z-index: 999999;
    }
  }
  .pic{
    max-height: 452px;
    max-width: 678px;
    position: relative;
    display: inline-block;
    .zoomin{
      position: absolute;
      right: 10px;
      bottom: 10px;
      width: 50px;
      height: 50px;
      background: url("../../../assets/images/zoom-in.png");
      background-size: 100%;
    }
    img{
      max-height: 452px;
      max-width: 678px;
    }
  }
  .title-line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-top: 40px;
    .line{
      height: 100%;
      width: 8px;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(223deg, #FC501E, #FA8958);
      border-radius: 2px;
    }
    .title {
      margin-left: 26px;
      flex: 1;
      font-size: 40px;
      font-weight: 500;
      color: #fff;
      line-height: 56px;

      .leixing {
        margin-top: -10px;
        vertical-align: middle;
        margin-left: 14px;
        height: 40px;
        background: url('../../../assets/images/tag_center@2x.png');
        background-size: 100% 100%;
        display: inline-block;
        font-size: 24px;
        color: #fff;
        line-height: 40px;
        text-align: center;
        padding-left: 16px;
        padding-right: 12px;
        background-position: 5px 0!important;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        position: relative;
        &::before{
          position: absolute;
          left: 0;
          top: 0;
          content: '';
          display: inline-block;
          width: 16px;
          height: 40px;
          background: url('../../../assets/images/tag_left@2x.png');
          background-size: 100% 100%;
        }
        &::after{
          position: absolute;
          right: -15px;
          top: 0;
          content: '';
          display: inline-block;
          width: 16px;
          height: 40px;
          background: url('../../../assets/images/tag_right@2x.png') repeat-x;
          background-size: 100% 100%;
        }
      }
    }
  }
  .posline{
    margin-left: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tag-line{
      flex: 1;
      margin-top: 18px;
      .line1{
        font-size: 20px;
        font-weight: 500;
        color: #E1E1E1;
        line-height: 28px;
      }
      .line2{
        margin-top: 8px;
        font-size: 20px;
        font-weight: 500;
        color: #E1E1E1;
        line-height: 28px;
      }
    }
  }

  .btn-view{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .other{
      margin-top: 24px;
      width: 50%;
      flex: 1;
      .item{
        margin-top: 12px;
      }
    }
    .btns{
      width: 40%;
      margin-top: 32px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .item{
        margin-left: 20px;
      }
    }
  }
}
</style>
