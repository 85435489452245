<template>
  <svg
    v-if="countDown>0"
    class="ring-container"
    :style="`width: ${size}; height: ${size}`"
  >
    <circle
      :cx="cx"
      :cy="cx"
      fill="none"
      :r="r"
      :stroke="backgroundColor"
      stroke-linecap="round"
      :stroke-width="width"
    />
    <circle
      class="ring"
      :cx="cx"
      :cy="cx"
      fill="none"
      :r="r"
      :stroke="color"
      :stroke-dasharray="`${circum}`"
      stroke-linecap="round"
      :stroke-width="width"
    >
      <animate
        attributeName="stroke-dashoffset"
        :dur="originCountDown"
        :from="circum"
        :to="endCircum"
      />
    </circle>
    <text
      :fill="fontColor"
      :font-size="fontSize + 5"
      text-anchor="end"
      :x="cx+8"
      :y="cx+11"
    >
      {{ countDown }}
    </text>
  </svg>
</template>

<script>
export default {
  name: "CircleLoad",
  props: {
    fontSize: {
      type: Number,
      default: 26
    },
    size: {
      type: Number,
      default: 60
    },
    width: {
      type: Number,
      default: 5
    },
    backgroundColor: {
      type: String,
      default: '#f0f0f0'
    },
    color: {
      type: String,
      default: '#EA4C1E'
    },
    fontColor:{
      type: String,
      default: '#EA4C1E'
    },
    originCountDown:{
      type: Number,
      default: 15
    }
  },
  data(){
    return{
      endCircum:0,
      countDown:this.originCountDown,
    }
  },
  computed: {
    cx() { // 中心点位置
      return this.size / 2
    },
    r() { // 半径
      return this.size / 2 - this.width
    },
    circum() {
      return parseInt(this.r * Math.PI * 2)
    }
  },
  mounted(){
    this.interval = setInterval(() => {
      let diff = '1';
      this.countDown -= diff;
      if (this.countDown <= 0) {
        clearInterval(this.interval)
      }
    }, 1000)
  }
}
</script>

<style lang="scss">
.ring-container {
  .ring {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
}
</style>

