<template>
  <div class="post-supply-default">
    <post-supply-pc
      v-if="browser===1"
      ref="pc"
      :options="options"
    />
    <post-supply-mobile
      v-if="browser===2"
      ref="mobile"
      :options="options"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import PostSupplyPc from "../../Pc/User/postSupply";
import PostSupplyMobile from "../../Mobile/User/postSupply";

export default {
  name: 'PostSupplyDefault',
  components: {PostSupplyMobile, PostSupplyPc},
  data() {
    return {
      options: {
        loaded: false,
        id: 0,
        list: [],
        typeList: [],
        info: {}
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    this.options.id = this.$route.query.id
    if(this.options.id !== 0){
      this.getEdit();
    }else{
      this.options.loaded = true
    }
    this.init()
    this.setShare()
  },
  methods: {
    init() {
      this.getType()
    },
    getType(){
      const params = {
        typeId: 122
      }
      this.$u.api.commons.getChildArchivesType(params).then(res => {
        if(res.status === 1){
          this.options.typeList = res.data
        }
      })
    },
    getEdit(){
      const params = {
        id: this.options.id
      }
      this.$u.api.users.getSupplyOne(params).then(res => {
        if(res.status === 1){
          this.options.info = res.data
          this.options.loaded = true
        }
      })
    },
    setShare(){
      this.getWxConfig('', '')
    }
  }

}
</script>

<style scoped></style>
