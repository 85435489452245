<template>
  <div class="bbs-footer-mobile">
    <div class="left">
      <icon-text-zan-mobile
        :id="info.id"
        class="item"
        color="rgba(255,255,255,.7)"
        font-size="20px"
        :label="$t('50048')"
        :set-zan="setZan"
        :type="2"
        :dark="true"
        width="32px"
        :zan="parseInt(info.is_zan) === 1"
      />
      <icon-text-collect-mobile
        :id="info.id"
        class="item"
        color="rgba(255,255,255,.7)"
        font-size="20px"
        :label="$t('50024')"
        :set-collect="setCollect"
        :shoucang="parseInt(info.is_cang) === 1"
        :type="4"
        width="32px"
      />
      <icon-text-column-mobile
        class="item"
        color="rgba(255,255,255,.7)"
        icon="share.png"
        :text="info.share"
        type="share"
      />
      <bbs-join-btn-mobile
          v-if="info.id != 336"
        :id="$route.query.id"
        :big="true"
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import IconTextColumnMobile from "../../common/IconTextColumn";
import IconTextZanMobile from "../../common/IconText/zan";
import IconTextCollectMobile from "../../common/IconText/collect";
import BbsJoinBtnMobile from "../BbsJoinBtnMobile";

export default {
  name: 'BbsFooterMobile',
  components: {BbsJoinBtnMobile, IconTextCollectMobile, IconTextZanMobile, IconTextColumnMobile},
  props: {
    showReserveModal: {
      type: Function,
      default: () => () => {}
    },
    info: {
      type: Object,
      default: () => {}
    },
    id: {
      type: [Number, String],
      default: () => 0
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    setZan: {
      type: Function,
      default: () => () => {}
    },
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods:{
    yuyue(){
      if(this.info.is_status === 0){
        this.showReserveModal(this.info)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.bbs-footer-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 36px;
  background: #000;
  .left{
    display: flex;
    justify-content: space-around;
    align-items: center;
    .item{
      margin: 0 20px;
    }
  }
}
</style>
