<template>
  <div
    class="supply-demand-item-supply-mobile"
    @click="$emit('click')"
  >
    <div class="title">
      <span class="text line-1">{{ info.title }}</span>
      <span class="tips">{{ $t('24116') }}</span>
    </div>
    <div class="summary line-2">
      <div v-html="$t2(lang, replaceHtmlTag(info.content), replaceHtmlTag(info.content_en))"></div>
    </div>
    <div class="tags-line">
      <div
        v-if="info.catename !== ''"
        class="tags line-1"
      >
        <div>
          <template v-for="(res, index) in info.catename">
            <tags-item-mobile
              :key="index"
              class="item"
              :dark="true"
              :info="res"
            />
          </template>
        </div>
      </div>
      <div v-else></div>
      <icon-text-supply-del-mobile
        v-if="from==='user'"
        :id="info.id"
        class="item"
        color="#c2c2c2"
        font-size="20px"
        :label="$t('50024')"
        :set-collect="setCollect"
        :shoucang="parseInt(info.is_cang) === 1"
        :type="4"
        width="32px"
        @click="guanzhu"
      />
      <div
        v-if="from===''"
        class="info"
        @click.stop="show(info)"
      >
        {{ $t('24003') }} >
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TagsItemMobile from "../../../common/TagsItem";
import IconTextSupplyDelMobile from "../../../common/IconText/supplydel";

export default {
  name: 'SupplyDemmandItemDemandMobile',
  components: {TagsItemMobile, IconTextSupplyDelMobile},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    showDemandContactModalInfo: {
      type: Function,
      default: () => () => {}
    },
    refresh: {
      type: Function,
      default: () => () => {}
    },
    from: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    show(info){
      this.showDemandContactModalInfo(info)
    },
    guanzhu() {
      const params = {
        sid: this.info.id,
      }
      this.$u.api.supplydemands.actfollow(params).then(res => {
        if(res.status === 1){
          this.info.is_follow = res.data.is_follow
          this.refresh();
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.supply-demand-item-supply-mobile{
  overflow: hidden;
  cursor: pointer;
  .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text{
      width: 390px;
      line-height: 44px;
      display: inline-block;
      margin-right: 60px;
      font-size: 32px;
      font-weight: 600;
      color: #fff;
    }
    .tips{
      height: 40px;
      width: 40px;
      line-height: 40px;
      display: inline-block;
      background: linear-gradient(223deg, rgba(0, 92, 179, .2), rgba(69, 151, 229, .2));
      color: #6591FF;
      text-align: center;
      font-size: 20px;
    }
  }
  .summary{
    margin-top: 10px;
    font-size: 22px;
    font-weight: 400;
    color: rgba(255,255,255,0.8);
    line-height: 32px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tags-line{
    height: 36px;
    line-height: 36px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tags{
      .item{
        margin-right: 10px;
      }
    }
    .info{
      font-size: 20px;
      font-weight: 400;
      color: rgba(255,255,255,0.8);
      line-height: 28px;
    }
  }
}
</style>
