<template>
  <div class="live-index-default">
    <live-index-pc
      v-if="browser===1"
      ref="liveIndexPc"
      :change-live-id="changeLiveId"
      :change-type-id="changeTypeId"
      :change-live-id-history="changeLiveIdHistory"
      :get-comment="getComment"
      :options="options"
      :send-comment="sendComment"
      :set-zan="setZan"
    />
    <live-index-mobile
      v-if="browser===2"
      ref="liveIndexMobile"
      :change-live-id="changeLiveId"
      :change-type-id="changeTypeId"
      :change-live-id-history="changeLiveIdHistory"
      :change-live-id-mobile="changeLiveIdMobile"
      :get-comment="getComment"
      :options="options"
      :send-comment="sendComment"
      :set-zan="setZan"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import LiveIndexPc from "@/views/Pc/Live";
import LiveIndexMobile from "../../Mobile/Live";
import moment from "moment";

export default {
  name: 'LiveIndexDefault',
  components: {LiveIndexMobile, LiveIndexPc},
  data(){
    return {
      options: {
        list: [],
        info: {},
        liveid: 0,
        getComment: false,
        date: {},
        loaded: false,
        history: [],
        live_comment_stae: false,
        comment: {
          list: [],
          page: 1,
          pagesize: 100,
          total: 0
        },
        childType: [],
        childTypeId: 0
      },
      defaultOptions: {},
      isFirst: false,
      timeObj: null
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'siteConfig', 'user']),
  },
  activated() {
    if(this.$route.query.liveid){
      this.options.liveid = this.$route.query.liveid
    }
    if(this.$route.meta.needRefresh){
      if(!this.isFirst){
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    this.options.share = this.$route.query.share || 0
    if(this.$route.query.liveid){
      this.options.liveid = this.$route.query.liveid
    }
    this.setShare()
    this.init()
  },
  methods: {
    init(){
      this.$u.api.commons.getLiveDate().then(res => {
        let date = {}
        for( var i in res.data){
          date[res.data[i].id] = res.data[i]
        }
        this.options.date = date
      })

      this.$u.api.lives.getLists().then(res => {
        if(res.status === 1){
          console.log(res)
          var arr = []; //定义数组

          for (var i in res.data) {
            arr.push(res.data[i]);
          }
          this.options.childType = arr
          this.options.childType.map(item => {
            if(this.$route.query.childTypeId && item.id == this.$route.query.childTypeId){
              this.options.childTypeId = item.id
            }
          })
          if(!this.options.childTypeId){
            this.options.childTypeId = this.options.childType[0].id
          }
          this.getHistory();
        }
      })
    },
    getHistory(){
      if(!this.options.childTypeId){
        return
      }
      this.$u.api.lives.getHistoryLists({typeid: this.options.childTypeId}).then(res => {
        if(res.status === 1){
          this.options.history = res.data
          if(!parseInt(this.options.liveid)){
            let weikaishi_id = 0, huikan_id = 0, jin_id = 0, jin_last = 0;
            const nowTime = new Date().getTime() / 1000
            let timearr = []
            res.data.map(res => {
              if(parseInt(res.state) === 0){
                weikaishi_id = res.id
              }
              if(parseInt(res.state) === 3 && huikan_id === 0){
                huikan_id = res.id
              }
              timearr.push({
                id: res.id,
                info: res,
                v: Math.abs(nowTime - res.live_start_time)
              })
            })
            timearr.sort(function(a, b) { // 会把对应的数据给到a,b
              return a['v'] - b['v']; // 根据数组的第一列排列
            })
            if(this.$route.query.liveid){
              this.options.liveid = this.$route.query.liveid
            }else{
              this.options.liveid = timearr[0].id
            }
            this.changeLiveIdMobile(this.options.liveid)
            this.options.liverichang = true
            this.getComment()
            const params = {
              id: this.options.liveid
            }
            this.tongji(res.data.id)
            this.$u.api.lives.getDetail(params).then(res => {
              this.options.loaded = true
              if(res.status === 1){
                this.options.info = res.data
                if(this.$refs.liveIndexMobile && this.$refs.liveIndexMobile.typeCurrent === 1){
                  this.$refs.liveIndexMobile.setTabCurrent('history')
                  this.getWxConfig(res.data.title, moment.unix(res.data.live_start_time).format('YYYY/MM/DD HH:mm') + ' ~ ' + moment.unix(res.data.live_end_time).format('HH:mm'), res.data.pic)
                }
              }
            })
          }
        }
      })
    },
    getInfo(){
      this.options.loaded = false
      let liveids = []
      this.options.list.map(item => {
        liveids.push(parseInt(item.id))
      })
      console.log(
          'this.options.liveid', this.options.liveid
      )
      const params = {
        id: this.options.liveid
      }
      if(this.$route.query.liveid == 0){
        this.options.liverichang = true
      }else if(liveids.indexOf(parseInt(this.$route.query.liveid)) != -1 && this.$route.query.liveid != 0){
        this.options.liverichang = false
      }else if(liveids.indexOf(this.$route.query.liveid) != -1 && this.$route.query.liveid != 0){
        this.options.liverichang = true
      }else{
        this.options.liverichang = true
      }
      this.tongji(this.options.liveid)
      this.$u.api.lives.getDetail(params).then(res => {
        this.options.loaded = true
        if(res.status === 1){
          this.options.info = res.data
          if(this.$refs.liveIndexMobile && this.$refs.liveIndexMobile.typeCurrent === 1) {
            this.$refs.liveIndexMobile.setTabCurrent('history')
            this.getWxConfig(res.data.title, moment.unix(res.data.live_start_time).format('YYYY/MM/DD HH:mm') + ' ~ ' + moment.unix(res.data.live_end_time).format('HH:mm'), res.data.pic)
          }
        }
      })
    },
    tongji(id){
      if(this.user.mid && localStorage.getItem('lastid') !== id){
        localStorage.setItem('lastid', id)
        this.$u.api.ajaxs.actLive({t: 2, is_value: id});
      }
    },
    getComment(){
      this.timeObj && clearTimeout(this.timeObj)
      if(!this.options.liveid || this.options.getComment || !this.options.liverichang || (this.options.comment.page - 1) * this.options.comment.pagesize > this.options.comment.total){
        return
      }
      this.timeObj && clearTimeout(this.timeObj)
      this.options.getComment = true
      this.$u.api.lives.getCommentLists({liveId: this.options.liveid, page: this.options.comment.page, pagesize: this.options.comment.pagesize}).then(res => {
        if(res.status === 1){
          this.options.getComment = false
          if(this.options.comment.list !== res.data.lists){
            if(this.options.comment.page === 1){
              this.options.comment.list = []
            }
            this.options.comment.list = this.options.comment.list.concat(res.data.lists)
            this.options.comment.total = parseInt(res.data.totalNum)
            this.options.comment.page++
            this.$nextTick(() => {
              this.timeObj = setTimeout(() => {
                this.options.comment.page = 1
                this.getComment()
              }, 2000)
            })
          }
        }
      })
    },
    changeTypeId(v){
      this.options.childTypeId = v
      this.options.liveid = 0
      if(this.browser === 2 && v !== 0 && this.isIOS){
        window.location.href = '/Live?typeCurrent=1&'+'&childTypeId='+this.options.childTypeId
      }else{
        this.$router.push({
          path: '/Live',
          query: {typeCurrent: 1, childTypeId: this.options.childTypeId}
        })
        document.getElementById('app').scrollTop = 0
      }
      this.getHistory();
    },
    changeLiveId(v){
      this.options.liveid = v
      if(this.$refs.liveIndexMobile && this.$refs.liveIndexMobile.typeCurrent === 1){
        if(this.browser === 2 && v !== 0 && this.isIOS){
          window.location.href = '/Live?typeCurrent=1&liveid='+v+'&childTypeId='+this.options.childTypeId
        }else{
          window.location.href = '/Live?typeCurrent=1&liveid='+v+'&childTypeId='+this.options.childTypeId
        }
      }
      this.getInfo();
      if(v===0) {
        this.$refs.liveIndexPc && this.$refs.liveIndexPc.setCurrent('history')
        this.$refs.liveIndexMobile && this.$refs.liveIndexMobile.setCurrent('history')
      }else{
        this.$refs.liveIndexPc && this.$refs.liveIndexPc.setCurrent('content')
        this.$refs.liveIndexMobile && this.$refs.liveIndexMobile.setCurrent('history')
      }
    },
    changeLiveIdMobile(v){
      this.options.liveid = v
      this.getInfo();
      if(v===0) {
        this.$refs.liveIndexPc && this.$refs.liveIndexPc.setCurrent('history')
        this.$refs.liveIndexMobile && this.$refs.liveIndexMobile.setCurrent('history')
      }else{
        this.$refs.liveIndexPc && this.$refs.liveIndexPc.setCurrent('content')
        this.$refs.liveIndexMobile && this.$refs.liveIndexMobile.setCurrent('history')
      }
    },
    changeLiveIdHistory(v){
      this.options.loaded = true
      this.options.liveid = v
      if(this.browser === 2 && v !== 0 && this.isIOS){
        window.location.href = '/Live?typeCurrent=1&liveid='+v+'&childTypeId='+this.options.childTypeId
      }else{
        this.$router.push({
          path: '/Live',
          query: {typeCurrent: 1, liveid: v, childTypeId: this.options.childTypeId}
        })
        document.getElementById('app').scrollTop = 0
      }
      this.options.comment.page = 1
      this.getInfo();
      this.getComment();
    },
    setZan(v, val) {
      this.options.info.is_zan = v
      this.options.info.zan = val
      this.$forceUpdate()
    },
    setShare(){
      this.getWxConfig(this.$t('10004'), '主题丰富、形式多样，打造行业直播新平台。', 'https://virtualobs.ciif-expo.com/shareimg/5.png')
    },
    sendComment(comment){
      if(!comment || !comment.trim()){
        if(this.browser === 1){
          this.$message.error('评论内容不能为空')
        }else{
          this.toast('评论内容不能为空')
        }
        return
      }
      this.$u.api.ajaxs.actComment({liveId: this.options.liveid, comment}).then(res => {
        if(res.status === 1){
          if(this.browser === 1){
            this.$message.success(this.$t('24113'))
          }else{
            this.toast(this.$t('24113'))
          }
          this.$refs.liveIndexPc && this.$refs.liveIndexPc.setCurrent('history')
          this.$refs.liveIndexMobile && this.$refs.liveIndexMobile.setCurrent('history')
          this.options.comment.page = 1;
          this.getComment()
        }
      })
    }
  }

}
</script>

<style scoped></style>
