<template>
  <div class="topic-zhanshang-list-mobile">
    <div class="title">
      {{ $t('27010') }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'TopicZhanshangListMobile',
  props: {
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.topic-zhanshang-list-mobile {
  .title{
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    line-height: 44px;
  }
}
</style>
