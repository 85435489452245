<template>
  <div class="search-list-item-mobile line-1">
    <div
      v-if="type === ''"
      @click="navigateTo('/exhibition/info?id='+info.id)"
    >
      {{ $t2(lang, info.companyname, info.companynameen) }}
    </div>
    <div
      v-if="type === 'bbs'"
      @click="navigateTo('/forum/info?id='+info.id)"
    >
      {{ $t2(lang, info.title, info.title_en) }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'SearchListItemMobile',
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    type: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.search-list-item-mobile {
  height: 36px;
  font-size: 28px;
  font-weight: 400;
  color: #333333;
  line-height: 36px;
  margin: 52px 0;
}
</style>
