<template>
  <div class="topic-index-default">
    <topic-index-pc
      v-if="browser===1"
      :change-typeid="changeTypeid"
      :options="options"
    />
    <topic-index-mobile
      v-if="browser===2"
      :change-typeid="changeTypeid"
      :options="options"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopicIndexPc from "@/views/Pc/Topic";
import TopicIndexMobile from "../../Mobile/Topic";

export default {
  name: 'TopicIndexDefault',
  components: {TopicIndexMobile, TopicIndexPc},
  data() {
    return {
      options: {
        childType: [],
        childTypeId: 0,
        info: {
          category: [],
          banner: '',
          text: [],
          zhanshang: [],
          product: [],
          zhengce: [],
          news: [],
          activity: [],
          caselist: [],
          videos: []
        }
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        if(this.$route.query.typeid){
          this.options.childTypeId = this.$route.query.typeid
        }
        this.init()
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    if(this.$route.query.typeid){
      this.options.childTypeId = this.$route.query.typeid
    }
    this.defaultOptions = this.deepClone(this.options)
    this.init()
    this.setShare()
  },
  methods: {
    init() {
      this.getChildType()
    },
    changeTypeid(v){
      this.options.childTypeId = v
      this.getInfo()
    },
    getChildType(){
      this.$u.api.topics.getCategory({}).then((res) => {
        if(res.status === 1){
          let category = []
          res.data.map(item => {
            item.category.id = item.id
            item.category.typeid = item.typeid
            category.push(item.category)
            if(this.options.typeid && (parseInt(this.options.typeid) === parseInt(item.typeid))){
              this.options.childTypeId = item.id
            }
          })
          if(!this.options.childTypeId){
            this.options.childTypeId = res.data[0].id
          }
          this.options.childType = category
          this.getInfo()
        }
      })
    },
    getInfo(){
      this.$u.api.topics.getLists({id: this.options.childTypeId}).then(res => {
        this.options.info.text = [
          {
            title: '27008',
            content_cn: res.data.ztjs_content_cn,
            content_en: res.data.ztjs_content_en,
          },
          // {
          //   title: '27009',
          //   content_cn: res.data.zyzc_content_cn,
          //   content_en: res.data.zyzc_content_en,
          // },
        ]
        console.log('res.data', res.data)
        this.options.info.product = res.data.productList
        this.options.info.activity = res.data.activityList
        this.options.info.news = res.data.informationList
        this.options.info.caselist = res.data.caseList
        this.options.info.zhengce = res.data.informationZCList
        this.options.info.videos = res.data.videoList
        this.options.info.pic = res.data.pic
        this.options.info.id = res.data.companyid
        this.options.info.banner_pc = res.data.banner_pc
        this.options.info.banner_wap = res.data.banner_wap
        this.options.info.banner_link_pc = res.data.banner_link_pc
        this.options.info.banner_link_wap = res.data.banner_link_wap
        this.options.info.pic_wap = res.data.pic_wap
        this.options.childType.map(info => {
          if(info.id === this.options.childTypeId){
            this.getWxConfig(this.$t2(this.lang, info.name, info.ename) + '专题', '', res.data.pic)
          }
        })
      })
    },
    setShare(){
      this.getWxConfig(this.$t('10009') + this.bankuai(), this.$t('10009') + this.bankuai())
    }
  }

}
</script>

<style scoped>
.topic-index-default{

}
</style>
