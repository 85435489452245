<template>
  <div class="supply-demand-supply-modal-mobile">
    <div class="cell">
      <div class="box">
        <div class="title-line clearfix">
          <div class="title">
            <div class="text">
              {{ $t2(lang, info.title, info.title_en) }}
            </div>
            <div class="pic">
              <pic-mobile :src="require('../../../../assets/images/mobile/huo2.png')" />
            </div>
          </div>
          <div
            class="guanzhu-btn"
            @click="guanzhu"
          >
            <btn-loukong-mobile
              :on="parseInt(info.is_follow) === 1"
              :text="parseInt(info.is_follow) === 0 ? `+ 关注` : `已关注`"
            />
          </div>
        </div>
        <div class="company-view">
          <div class="company-line clearfix">
            <div
              class="company"
              @click="navigateTo('/exhibition/info?id='+info.mid)"
            >
              <div class="pic">
                <pic-mobile :src="info.logo_url" />
              </div>
              <div class="name">
                {{ info.author }}
              </div>
            </div>
            <div class="info">
              <icon-time-line
                font-size="20px"
                icon="views.png"
                size="26"
                :text="info.hits"
              />
            </div>
          </div>
        </div>
        <split-line-pc
          bg-color="rgba(255,255,255,0.1)"
          height="1px"
          margin-top="0"
        />
        <div class="info-main">
          <div
            v-if="info.imageurl"
            class="info-pic"
          >
            <img :src="info.imageurl" />
          </div>
          <text-content-mobile
            class="content"
            :content="$t2(lang, info.content, info.content_en)"
            margin-bottom="0"
            title=""
          />
        </div>
        <div class="close-btn">
          <btn-close-mobile
            size="68"
            @click="hideSupplyModal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import IconTimeLine from "@/components/Pc/common/IconTimeLine";
import SplitLinePc from "@/components/Pc/common/SplitLine";
import PicMobile from "../../common/Pic";
import BtnLoukongMobile from "../../common/BtnLoukong";
import TextContentMobile from "../../common/TextContent";
import BtnCloseMobile from "../../common/BtnCloseMobile";

export default {
  name: 'SupplyDemandSupplyModalMobile',
  components: {
    BtnCloseMobile,
    TextContentMobile, BtnLoukongMobile, PicMobile, SplitLinePc, IconTimeLine},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    hideSupplyModal: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    guanzhu() {
      const params = {
        sid: this.info.id,
        type: this.info.is_type
      }
      this.$u.api.supplydemands.actfollow(params).then(res => {
        if(res.status === 1){
          this.info.is_follow = res.data.is_follow
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.supply-demand-supply-modal-mobile{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  background: rgba(0,0,0,0.7);
  display: table;
  .cell{
    display: table-cell;
    vertical-align: middle;
    .close-btn{
      width: 68px;
      height: 68px;
      position: absolute;
      left: 50%;
      margin-left: -34px;
      bottom: -100px;
    }
    .box{
      position: relative;
      width: 686px;
      background: rgba(26, 26, 26, 1);
      margin: 0 auto;
      border-radius: 12px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.02);
      .title-line{
        padding: 24px 32px 16px;
        .title{
          float: left;
          display: inline-block;
          font-size: 32px;
          font-weight: 600;
          color: #fff;
          .text{
            display: inline-block;
            vertical-align: middle;
          }
          .pic{
            margin-left: 4px;
            display: inline-block;
            width: 28px;
            height: 28px;
            vertical-align: middle;
          }
        }
        .guanzhu-btn{
          float: right;
        }
      }
      .company-view{
        padding: 0 32px 24px;
        .company-line{
          height: 35px;
          line-height: 35px;
          margin-top: 12px;
          .company{
            float: left;
            .pic{
              display: inline-block;
              width: 35px;
              height: 35px;
              border-radius: 35px;
              overflow: hidden;
              vertical-align: middle;
            }
            .name{
              margin-left: 10px;
              font-size: 20px;
              color: rgba(255,255,255,0.8);
              display: inline-block;
              vertical-align: middle;
            }
          }
          .info{
            float: right;
          }
        }
      }
      .info-main{
        max-height: 800px;
        overflow-y: auto;
        padding: 0 32px 24px;
        .info-pic{
          margin-top: 30px;
        }
        .content{
          width: 100%;
          max-height: 700px;
          overflow-y: scroll;
        }
      }
    }
  }
}
</style>
