<template>
  <div
    class="news-list-item-mobile"
    @click="(options && (parseInt(options.childTypeId)===39 ) || parseInt(options.childSubTypeId)===39 ) ? showPlayer(info.video) : replaceTo('/news/info?id='+info.id)"
  >
    <div class="pic radius">
      <pic-mobile :src="info.pic" />
    </div>
    <div class="info">
      <div
        class="title line-2"
        v-html="title"
      >
      </div>
      <div
        v-if="options && parseInt(options.childTypeId) !== 37"
        class="other"
      >
        <icon-text-from-mobile
          class="item"
          :dark="true"
          :label="$t2(lang, info.cn_source, info.en_source)"
        />
        <icon-text-time-mobile
          class="item"
          :label="timeFormat(info.addtime, 'yyyy/mm/dd')"
        />
      </div>
      <div class="other "
           v-else>
        <div
            class="line-2 margin-right"
        >
          <div v-html="description" />
        </div>
        <icon-text-collect-mobile
            v-if="from==='user'"
            :id="info.id"
            class="item"
            color="#c2c2c2"
            font-size="20px"
            label=""
            :set-collect="setCollect"
            :shoucang="parseInt(info.is_cang) === 1"
            :type="6"
            width="32px"
        />
      </div>
      </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "../../common/Pic";
import IconTextFromMobile from "../../common/IconText/from";
import IconTextTimeMobile from "../../common/IconText/time";
import IconTextCollectMobile from "../../common/IconText/collect";

export default {
  name: 'NewsListItemMobile',
  components: {IconTextTimeMobile, IconTextFromMobile, PicMobile, IconTextCollectMobile},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    options: {
      type: Object,
      default: () => {
      }
    },
    from: {
      type: String,
      default: () => ''
    },
    keyword: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    title(){
      return this.replaceSearch(this.lang, this.keyword, this.info.title, this.info.en_title)
    },
    description(){
      return this.replaceSearch(this.lang, this.keyword, this.info.description, this.info.description_en)
    },
  },
  mounted() {
    this.info.is_cang = 1;
  },
  methods: {
    showPlayer(videoUrl) {
      this.$bus.$emit('showPlayer', videoUrl)
    },
    setCollect(v){
      this.info.is_cang = v
    }
  }
}
</script>

<style scoped lang="scss">
.news-list-item-mobile {
  width: 100%;
  background: rgba(255, 255, 255, 0.1000);
  box-shadow:  0px 5px 20px 0px rgba(0,0,0,0.1);
  margin-bottom: 20px;
  padding: 24px;
  display: flex;
  border-radius: 12px;
  .pic{
    width: 240px;
    height: 160px;
    margin-right: 24px;
  }
  .info{
    width: 366px;
    .title{
      height: 80px;
      font-size: 28px;
      font-weight: 500;
      color: #fff;
      line-height: 40px;
    }
    .other{
      color: rgba(153, 153, 153, 1);
      font-size: 22px;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .margin-right{
        margin-right: 10px;
      }
    }
  }
}
</style>
