<template>
  <div class="search-list-pc">
    <div class="container">
      <search-form-pc :go-search="goSearch" />
      <search-type-pc
        :change-type="changeType"
        :options="options"
      />
      <div class="main-box mt40 min-height">
        <exhibition-list-pc
          v-if="options.typeCurrent==='展览'"
          :change-page="changePage"
          :data="options.data"
        />
        <exhibition-list-pc
          v-if="options.typeCurrent==='产品'"
          :change-page="changePage"
          :data="options.data"
          :product="true"
        />
        <bbs-list-pc
          v-if="options.typeCurrent==='论坛'"
          :change-page="changePage"
          :data="options.data"
        />
        <search-video-list-pc
          v-if="options.typeCurrent==='视频'"
          :change-page="changePage"
          :data="options.data"
        />
        <awards-list-list-pc
          v-if="options.typeCurrent==='评奖'"
          :change-page="changePage"
          :data="options.data"
        />
        <news-list-pc
          v-if="options.typeCurrent==='资讯'"
          :change-page="changePage"
          :data="options.data"
        />
        <activity-list-pc
          v-if="options.typeCurrent==='活动'"
          :change-page="changePage"
          :data="options.data"
        />
        <case-list-pc
          v-if="options.typeCurrent==='案例'"
          :change-page="changePage"
          :data="options.data"
        />
        <topic-list-pc
          v-if="options.typeCurrent==='专题'"
          :change-page="changePage"
          :data="options.data"
        />
        <div
          v-if="options.empty"
          class="empty"
        >
          <img
            height="160"
            src="../../../assets/images/mobile/search_empty.png"
            width="160"
          />
          <h3>抱歉， 没有搜到您查询的内容</h3>
          <h5>请尝试搜索其他关键词搜索</h5>
        </div>
        <!--        <empty-pc-->
        <!--          v-if="!options.loading && parseInt(options.data.total) === 0"-->
        <!--          description="空空如也"-->
        <!--        />-->
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import SearchFormPc from "../../../components/Pc/Search/SearchForm";
import SearchTypePc from "../../../components/Pc/Search/SearchType";
import ExhibitionListPc from "../../../components/Pc/Exhibition/ExhibitionList";
import BbsListPc from "../../../components/Pc/Bbs/BbsList";
import AwardsListListPc from "../../../components/Pc/Awards/AwardsList";
import NewsListPc from "../../../components/Pc/News/NewsList";
import ActivityListPc from "../../../components/Pc/Activity/ActivityList";
import CaseListPc from "../../../components/Pc/Case/caseList";
import SearchVideoListPc from "../../../components/Pc/common/VideoList";
import TopicListPc from "../../../components/Pc/Topic/TopicList";
import EmptyPc from "../../../components/Pc/common/Empty";

export default {
  name: 'SearchListPc',
  components: {
    EmptyPc,
    TopicListPc,
    SearchVideoListPc,
    CaseListPc,
    ActivityListPc, NewsListPc, AwardsListListPc,  BbsListPc, ExhibitionListPc, SearchTypePc, SearchFormPc},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeType: {
      type: Function,
      default: () => () => {}
    },
    goSearch: {
      type: Function,
      default: () => () => {}
    },
    changePage: {
      type: Function,
      default: () => () =>{}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.search-list-pc {
  .min-height{
    min-height: 630px;
  }
  .empty{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    flex-direction: column;
    margin-top: 100px;
    h3{
      margin-top: 40px;
    }
    h5{
      margin-top: 20px;
      color: rgba(255,255,255,0.8)
    }
  }
}
</style>
