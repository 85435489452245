<template>
  <div
    class="news-list-exhibition-thumb1-mobile"
  >
    <div class="container">
      <div class="header-titile">
        资讯热点<span>Information Hotspot</span>
      </div>
      <div class="list">
        <template v-for="(res, index) in list">
          <news-item-exhibition-thumb1-mobile
            :key="index"
            :info="res"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import NewsItemExhibitionThumb1Mobile from "./NewsItemExhibitionThumb1";

export default {
  name: 'NewsListExhibitionThumb1Mobile',
  components: {NewsItemExhibitionThumb1Mobile},
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.news-list-exhibition-thumb1-mobile {
  .header-titile{
    width: auto;
    display: inline-block;
    padding: 0 32px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 28px;
    color: #fff;
    background: #000;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    span{
      margin-left: 12px;
    }
  }
  .list{
    background: #fff;
    padding: 50px 50px 0 50px;
    border-radius: 0 0 28px 28px;
    max-height: 914px;
    overflow-y: auto;
  }
}
</style>
