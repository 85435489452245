<template>
  <div>
    <div
      v-if="!more"
      class="topic-case-list-mobile"
    >
      <template v-for="(res, index) in customList.length > 0 ? customList : (data ? data.list : [])">
        <case-list-item-mobile
          v-if="index < 6"
          :key="index"
          :info="res"
          :keyword="(data && data.key) || ''"
        />
      </template>
      <div
        v-if="!customList"
        class="page"
      >
        <page-common-mobile
          :change-page="changePage"
          :data="data"
        />
      </div>
    </div>
    <div
      v-else
      class="topic-case-list-mobile"
      :class="more ? `topic-list-swiper`: ``"
    >
      <swiper
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="(res, index) in customList.length > 0 ? customList : (data ? data.list : [])"
          :key="index"
          class="swiper-item-pic"
        >
          <case-list-item-mobile
            :key="index"
            :info="res"
            :keyword="(data && data.key) || ''"
          />
        </swiper-slide>
      </swiper>
      <div
        v-if="more"
        class="swiper-page"
      >
        <div class="swiper-pagination-case"></div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import CaseListItemMobile from "../../Case/CaseList/item";
import PageCommonMobile from "../../common/PageCommon";
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import '@/assets/swiper.css'

export default {
  name: 'TopicCaseListMobile',
  components: {PageCommonMobile, CaseListItemMobile, Swiper, SwiperSlide},
  directives: {
    swiper: directive
  },
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    customList: {
      type: Array,
      default: () => []
    },
    more: {
      type: Boolean,
      default: () => false
    }
  },
  data(){
    return {
      swiperOptions: {
        spaceBetween: 20,
        slidesPerView: 2,
        slidesPerColumn: 2,
        slidesPerGroup: 2,
        slidesPerColumnFill: 'row',
        pagination: {
          el: ".swiper-pagination-case",
          clickable: true,
        },
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.topic-case-list-mobile {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
</style>
