<template>
  <div class="user-index-pc user">
    <div class="container">
      <user-left-menu-pc />
      <div class="user-right">
        <div class="user-info">
          <div class="user-title">
            {{ $t('30001') }}
          </div>
          <div
            class="user-main-box"
            :class="user.qrcode ? `user-main-box-qrcode` : ``"
          >
            <div class="user-index-item avatar-line">
              <div class="title">
                {{ $t('60001') }}
              </div>
              <div class="right avatar">
                <img
                  height="40"
                  src="../../../assets/images/avatar.png"
                  width="40"
                />
              </div>
            </div>
            <div class="user-index-item">
              <div class="title">
                {{ $t('60002') }}
              </div>
              <div class="right">
                {{ user.name }}
              </div>
            </div>
            <div class="user-index-item">
              <div class="title">
                {{ $t('24111') }}
              </div>
              <div class="right">
                {{ user.companyname }}
              </div>
            </div>
            <div class="user-index-item">
              <div class="title">
                {{ $t('28008') }}
              </div>
              <div class="right">
                {{ user.mobile }}
              </div>
            </div>
            <div class="user-index-item">
              <div class="title">
                {{ $t('50027') }}
              </div>
              <div class="right">
                {{ user.email }}
              </div>
            </div>
            <div
              v-if="!user.qrcode"
              class="btn-box"
            >
              <a
                class="btn"
                :href="`https://online.ciif-expo.cn/RegMethod.html?pcurl=https://virtual.ciif-expo.com/login&phone=`+user.mobile"
                target="_blank"
              >{{ $t('24126') }}</a>
            </div>
            <div
              v-else
              class="qrcode-box"
            >
              <div class="qrcode-title">
                {{ $t('60003') }}
              </div>
              <div
                ref="qrCodeUrl"
                class="qrcode"
              >
                <div class="icon-logo">
                  <div class="img"></div>
                </div>
              </div>
              <div class="qrcode-tips">
                {{ $t('60101') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import UserLeftMenuPc from "../../../components/Pc/User/UserLeftMenu";
import QRCode from 'qrcodejs2'

export default {
  name: 'UserIndexPc',
  components: {UserLeftMenuPc},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'user']),
  },
  mounted() {
    this.creatQrCode();
  },
  methods: {
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: this.user.qrcode, // 需要转换为二维码的内容
        width: 280,
        height: 280,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    }
  }
}
</script>

<style scoped lang="scss">
.user-index-pc {
  .container{
    display: flex;
    .user-right{
      .user-title{
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        line-height: 20px;
        margin-bottom: 16px;
        position: relative;
        padding-left: 12px;
        &::before{
          content: '';
          width: 4px;
          height: 16px;
          background: linear-gradient(223deg, #FC501E 0%, #FA8958 100%);
          position: absolute;
          left: 0;
          top: 2px;
          display: inline-block;
        }
      }
      .user-main-box{
        background: url('../../../assets/images/pc_user_bg.png') no-repeat;
        background-size: 100% ;
        width: 480px;
        height: 308px;
        padding: 5px 20px 15px 20px;
        margin: 0 auto;
        border-radius: 10px;
        &.user-main-box-qrcode{
          width: 480px;
          height: 618px;
          background: url('../../../assets/images/pc_user_bg_qrcode.png') no-repeat;
          background-size: 100% ;
        }
      }
      .btn-box{
        margin-top: 30px;
        .btn{
          width: 440px;
          height: 40px;
          background: linear-gradient(to left, #FC501E, #FA8958);
          border-radius: 4px;
          text-align: center;
          color: #fff;
          line-height: 40px;
          display: inline-block;
          &:hover{
            opacity: 0.8;
          }
        }
      }
      .user-index-item{
        padding: 12px;
        border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
        color: rgba(255, 255, 255, 0.6);
        font-size: 12px;
        &.avatar-line{
          height: 64px;
          line-height: 40px;
        }
        .avatar{
          width: 40px;
          height: 40px;
          img{
            width: 40px;
            height: 40px;
          }
        }
        .title{
          float: left;
        }
        .right{
          float: right;
        }
        &:after{
          content: '';
          clear: both;
          display: inline-block;
        }
        &:last-child{
          border-bottom: 0;
        }
      }
    }
  }
  .qrcode-box{
    text-align: center;
    margin-top: 40px;
  }
  .qrcode-title{
    font-size: 16px;
    font-weight: 600;
    color: rgba(255,255,255,0.8);
    line-height: 20px;
    margin-bottom: 16px;
  }
  .qrcode{
    margin: 0 auto;
    text-align: center;
    width: 280px;
    img{
      margin: 0 auto;
    }
  }
  .qrcode-tips{
    font-size: 12px;
    color: #EA4C1E;
    margin-top: 10px;
  }
}
</style>
