<template>
  <div class="bbs-info-mobile">
    <top-nav-mobile
      :backurl="backurl"
      :is-back="true"
    >
      <span class="nav-title">{{ $t('10003') }}</span>
    </top-nav-mobile>
    <div class="pic">
      <pic-mobile
          v-if="!options.info.livesurl"
          :src="options.info.pic"
      />
        <iframe v-else
            allowfullscreen="true"
            frameborder="0"
            height="100%"
            mozallowfullscreen="true"
            msallowfullscreen="true"
            oallowfullscreen="true"
            :src="options.info.livesurl"
            webkitallowfullscreen="true"
            width="100%"
        ></iframe>
    </div>
    <div class="info">
      <div class="container">
        <div class="title">
          <liveing-mobile
            class="live-btn"
            height="40px"
            margin-top="4px"
            :zhuangtai="options.info.zhuangtai"
          />{{ $t2(lang, options.info.title, options.info.title_en) }}
        </div>
        <div class="tags">
          <template v-for="(res, index) in options.info.catename">
            <tags-item-mobile
              :key="index"
              :dark="true"
              :info="res"
            />
          </template>
        </div>
        <div class="other">
          <icon-text-time-mobile
            class="item"
            color="rgba(255,255,255,0.6)"
            :label="options.info.tag"
          />
          <div
            class="divline item"
          >
            <icon-text-location-mobile
              color="rgba(255,255,255,0.6)"
              :label="options.info.shorttitle ? $t(options.info.shorttitle.toString()) : ''"
            />
            <div class="divline">
              <icon-text-line-mobile
                color="rgba(255,255,255,0.6)"
                icon="icon_views@2x.png"
                :text="options.info.click"
                width="28px"
              />
              <icon-text-line-mobile
                color="rgba(255,255,255,0.6)"
                icon="icon_zan@2x.png"
                style="margin-left: 24px;"
                :text="options.info.zan"
                width="28px"
              />
            </div>
          </div>
        </div>
        <split-line-mobile
          bg-color="rgba(255,255,255,0.2)"
          height="2px"
          margin-bottom="0"
          margin-top="20px"
        />
      </div>
    </div>
    <div class="container">
      <div class="tabs-view">
        <div class="tabs-menu">
          <template v-for="(res, index) in tabMenu">
            <div
              :key="index"
              class="item"
              :class="current === index ? `on` : ``"
              @click="current = index"
            >
              {{ $t(res.title) }}
            </div>
          </template>
        </div>
        <div class="tabgs-content">
          <text-content-mobile
            v-if="current===0"
            :content="options.info.content"
            title=""
          />
          <div
            v-if="current===1"
            class="jiabin-list"
          >
            <template v-for="(res, index) in options.info.jiabinList">
              <bbs-jiabin-item-mobile
                :key="index"
                :info="res"
                @click="showJiabin(res.id)"
              />
            </template>
          </div>
          <div
            v-if="current === 2"
            class="tab-item richeng"
          >
            <div class="richeng-view">
              <div class="left">
              </div>
              <div class="right">
                <bbs-richeng-list-mobile
                  :date="options.date"
                  :list="options.info.livesList"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bbs-jiabin-modal-mobile
      v-if="options.jiabinShow"
      :hide-jiabin="hideJiabin"
      :info="options.jiabinInfo"
    />
    <bbs-footer-mobile
      :id="options.id"
      :info="options.info"
      :set-collect="setCollect"
      :set-zan="setZan"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "../../../components/Mobile/common/Pic";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import TagsItemMobile from "../../../components/Mobile/common/TagsItem";
import IconTextTimeMobile from "../../../components/Mobile/common/IconText/time";
import IconTextLocationMobile from "../../../components/Mobile/common/IconText/location";
import TextContentMobile from "../../../components/Mobile/common/TextContent";
import BbsJiabinItemMobile from "../../../components/Mobile/Bbs/BbsJiabinItem";
import BbsRichengListMobile from "../../../components/Mobile/Bbs/BbsRichengList";
import BbsJiabinModalMobile from "../../../components/Mobile/Bbs/BbsJiabinModalMobile";
import LiveingMobile from "../../../components/Mobile/common/Liveing";
import HlsPlayer from 'xgplayer-hls.js';
import BbsFooterMobile from "../../../components/Mobile/Bbs/BbsFooter";
import IconTextLineMobile from "../../../components/Mobile/common/IconTextLine";
import SplitLineMobile from "../../../components/Mobile/common/SplitLine";
import PicPc from "@/components/Pc/common/Pic/index.vue";
export default {
  name: 'BbsInfoMobile',
  components: {
    PicPc,
    IconTextLineMobile,
    BbsFooterMobile,
    LiveingMobile,
    BbsJiabinModalMobile,
    BbsRichengListMobile,
    BbsJiabinItemMobile,
    TextContentMobile,
    SplitLineMobile,
    IconTextLocationMobile,
    IconTextTimeMobile, TagsItemMobile, TopNavMobile, PicMobile
  },
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    setZan: {
      type: Function,
      default: () => () => {}
    },
    showJiabin: {
      type: Function,
      default: () => () => {}
    },
    hideJiabin: {
      type: Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      backurl: '',
      videoPlayer: null,
      current: 0,
      tabMenu: [
        {
          title: '21101',
        },
        {
          title: '21102',
        },
        {
          title: '50023',
        }
      ]
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'user']),
  },
  watch: {
    options: {
      deep: true,
      handler(newVal, oldVal) {
        if(newVal.info.livesurl !== '' && newVal.loaded){
          if (this.videoPlayer !== null) {
            this.videoPlayer.destroy()
            this.videoPlayer = null
          }
          this.$nextTick(() => {
            setTimeout(() => {
              this.videoPlayer = new HlsPlayer({
                id: 'welcomeVideo',
                url: newVal.info.livesurl,
                poster: newVal.info.pic,
                playsinline: true,
                fluid: true
              });
            },400)
          })
        }
      }
    }
  },
  activated() {
    this.backurl = this.$route.meta.fromUrl === '/' ? '/Forum' : this.$route.meta.fromUrl
    if(this.user.mid){
      this.$u.api.ajaxs.actLive({t: 3, is_value: this.options.id});
    }
  },
  mounted() {
    this.backurl = this.$route.meta.fromUrl === '/' ? '/Forum' : this.$route.meta.fromUrl
    if(this.user.mid){
      this.$u.api.ajaxs.actLive({t: 3, is_value: this.options.id});
    }
  },
  methods: {
    login(){
      localStorage.setItem('backUrl', this.$router.currentRoute.path)
      localStorage.setItem('backQuery', JSON.stringify(this.$router.currentRoute.query))
      this.$router.push('/user/login')
    }
  }
}
</script>

<style scoped lang="scss">
.bbs-info-mobile {
  margin-bottom: 130px;
  .pic{
    width: 750px;
    height: 422px;
  }
  .divline{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .info{
    padding-top: 16px;
    padding-bottom: 16px;
    .title{
      font-size: 36px;
      font-weight: 600;
      color: #fff;
      line-height: 48px;
      &:after{
        content: ' ';
        clear: both;
        display: block;
      }
      .live-btn{
        float: left
      }
    }
    .tags{
      margin-top: 16px;
    }
    .other{
      .item{
        margin-top: 12px;
      }
    }
    .btns{
      margin-top: 32px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
  .tabs-view{
    margin-top: 36px;
    .tabs-menu{
      display: flex;
      justify-content: center;
      align-items: center;
      .item{
        margin: 0 28px;
        color: rgba(255,255,255,.7);
        font-size: 28px;
        font-weight: 500;
        line-height: 28px;
        position: relative;
        &.on{
          color: #fff;
          &:after{
            content: '';
            position: absolute;
            left: 50%;
            bottom: -17px;
            margin-left: -16px;
            display: block;
            width: 32px;
            height: 8px;
            background: linear-gradient(223deg, #FF4E1A 0%, #FF9B61 100%);
            border-radius: 4px;
          }
        }
      }
    }
    .tabgs-content{
      margin-top: 53px;
      .richeng{
        margin-top: 40px;
        .richeng-view{
          position: relative;
          .left{
            background: linear-gradient(223deg, #FF1A2C 0%, #FF9B61 100%);
            width: 2px;
            height: 100%;
            opacity: 0.2;
            position: absolute;
            left: 17px;
            top: 0;
          }
          .right{
            margin-left: 56px;
          }
        }
      }
    }
  }
  .play-btn{
    width: 100px;
    height: 100px;
    background: url("../../../assets/images/play-btn.png");
    background-size: 100% 100%;
    background-position: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    &:hover{
      opacity: 0.8;
    }
  }
}
</style>
