<template>
  <div
    class="news-item-exhibition-thumb1-mobile"
    @click.stop="navigateTo('/news/info?id=' + info.id)"
  >
    <div class="pic">
      <pic-mobile :src="info.pic" />
    </div>
    <div class="info">
      <div class="title line-2">
        {{ info.title }}
      </div>
      <div class="summary line-2">
        {{ info.description }}
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "../../../common/Pic";

export default {
  name: 'NewsItemExhibitionThumb1Mobile',
  components: {PicMobile},
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.news-item-exhibition-thumb1-mobile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  padding-bottom: 50px;
  cursor: pointer;
  margin-bottom: 50px;

  &:last-child{
    margin-bottom: 0;
    &::after{
      display: none!important;
    }
  }
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: rgba(247,0,111,0.2);
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .pic{
    width: 270px;
    height: 180px;
    background: #D8D8D8;
  }
  .info{
    height: 180px;
    flex: 1;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .title{
      font-size: 26px;
      font-weight: bold;
      color: #333333;
      line-height: 42px;
    }
    .summary{
      font-size: 22px;
      font-weight: 400;
      color: #666666;
      line-height: 36px;
      margin-top: 32px;
    }
  }
  &:hover{
    .info{
      .title{
        color: rgba(247, 0, 111, 1)
      }
    }
  }
}
</style>
