<template>
  <div class="user-collect-mobile">
    <top-nav-mobile
      backurl="/user"
      :is-back="true"
      :search="false"
    >
      <span class="nav-title">{{ $t('30002') }}</span>
    </top-nav-mobile>
    <div class="container">
      <div class="types-view">
        <user-types-mobile
          :change-type="changeType"
          :options="options"
        />
      </div>
      <div class="collect-list">
        <bbs-list-mobile
          v-if="options.type === 'bbs'"
          :change-page="changePage"
          :data="options.data"
          type="user"
        />
        <awards-list-mobile-list-mobile
          v-if="options.type === 'awards'"
          :change-page="changePage"
          :data="options.data"
          from="user"
        />
        <news-list-mobile
          v-if="options.type === 'news'"
          :change-page="changePage"
          :data="options.data"
          from="user"
        />
        <case-list-mobile
          v-if="options.type === 'case'"
          :change-page="changePage"
          :data="options.data"
        />
        <exhibition-list-mobile
          v-if="options.type === 'exhibition'"
          :change-page="changePage"
          :data="options.data"
          from="user"
        />
        <exhibition-list-mobile
          v-if="options.type === 'product'"
          :change-page="changePage"
          :data="options.data"
          from="user"
          :product="true"
        />
        <user-collect-exhibition-card-list-mobile
          v-if="options.type === 'exhibition_card'"
          :change-page="changePage"
          :data="options.data"
        />
        <user-collect-video-list-mobile
          v-if="options.type === 'exhibition_video'"
          :change-page="changePage"
          :data="options.data"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import LangBtnMobile from "../../../components/Mobile/common/LangBtn";
import BackBtnMobile from "../../../components/Mobile/common/BackBtn";
import UserTypesMobile from "../../../components/Mobile/common/UserTypes";
import BbsListMobile from "../../../components/Mobile/Bbs/BbsList";
import AwardsListMobileListMobile from "../../../components/Mobile/Awards/AwardsList";
import CaseListMobile from "../../../components/Mobile/Case/CaseList";
import ExhibitionListMobile from "../../../components/Mobile/Exhibition/ExhibitionList";
import UserCollectExhibitionCardListMobile from "../../../components/Mobile/User/UserCollectExhibitionCardList";
import UserCollectVideoListMobile from "../../../components/Mobile/User/UserCollectVideoList";
import NewsListMobile from "../../../components/Mobile/News/NewsList";

export default {
  name: 'UserCollectMobile',
  components: {
    NewsListMobile,
    UserCollectVideoListMobile,
    UserCollectExhibitionCardListMobile,
    ExhibitionListMobile,
    CaseListMobile,
    AwardsListMobileListMobile, BbsListMobile, UserTypesMobile, BackBtnMobile, LangBtnMobile, TopNavMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeType: {
      type: Function,
      default: () => () => {}
    },
    changePage: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.user-collect-mobile {
  .collect-list{
    margin-top: 32px;
  }
}
</style>
