<template>
  <div
    class="awards-category-item-pc"
    @click="navigateTo('/awards/list?typeid='+info.id)"
  >
    <div class="pic">
      <pic-pc :src="info.pic" />
    </div>
    <div class="title line-1">
      {{ $t2(lang, info.name, info.ename) }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicPc from "@/components/Pc/common/Pic";

export default {
  name: 'AwardsCategoryItemPc',
  components: {PicPc},
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.awards-category-item-pc{
  margin-right: 20px;
  display: inline-block;
  cursor: pointer;

  .pic{
    width: 170px;
    height: 170px;
    background: #f5f5f5;
  }
  .title{
    width: 170px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(255,255,255,0.8);
    line-height: 20px;
    margin-top: 8px;
  }
}
</style>
