<template>
  <div>
    <div
      v-if="!more"
      class="topic-product-list-mobile"
    >
      <template
        v-for="(res, index) in list"
      >
        <product-item-mobile
          v-if="index < 4"
          :key="index"
          :companyid="companyid"
          :info="res"
        />
      </template>
    </div>
    <div
      v-if="more"
      class="topic-product-list-mobile"
      :class="more ? `topic-list-swiper`: ``"
    >
      <swiper
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="(res, index) in list"
          :key="index"
          class="swiper-item-pic"
        >
          <product-item-mobile
            :key="index"
            class="item"
            :companyid="companyid"
            :info="res"
          />
        </swiper-slide>
      </swiper>
      <div
        v-if="more"
        class="swiper-page"
      >
        <div class="swiper-pagination swiper-pagination-product"></div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import ProductItemMobile from "@/components/Mobile/common/ProductList/item";
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import '@/assets/swiper.css'

export default {
  name: 'TopicProductListMobile',
  components: {ProductItemMobile, Swiper, SwiperSlide},
  directives: {
    swiper: directive
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    companyid: {
      type: [Number, String],
      default: () => 0
    },
    more: {
      type: Boolean,
      default: () => false
    }
  },
  data(){
    return {
      swiperOptions: {
        spaceBetween: 20,
        slidesPerView: 2,
        slidesPerColumn: 3,
        slidesPerGroup: 2,
        slidesPerColumnFill: 'row',
        pagination: {
          el: ".swiper-pagination-product",
          clickable: true,
        },
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.topic-product-list-mobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .swiper-item-pic{
    height: 292px;
  }
}
</style>
