<template>
  <div class="search-index-mobile">
    <top-nav-mobile
      backurl="/index"
      :is-back="true"
      :search="false"
    >
      <span class="nav-title">{{ $t('50025') }}</span>
    </top-nav-mobile>
    <div class="container">
      <div class="exhibition-list">
        <carousel-3d
          v-if="options.list.length > 0"
          :animation-speed="1000"
          :autoplay="true"
          :autoplay-timeout="3000"
          :border="0"
          :display="9"
          height="180"
          :perspective="0"
          width="200"
        >
          <template v-for="(res, index) in options.list">
            <slide
              :key="index"
              :index="index"
            >
              <div
                class="pic radius"
                :class="`pic_`+index"
              >
                <pic-mobile
                  :src="res.smallimg"
                />
              </div>
            </slide>
          </template>
        </carousel-3d>
      </div>
      <div class="search-form">
        <search-view-mobile
          class-name="search-index"
          :height="84"
          :search="goSearch"
        />
      </div>
      <div class="search-type">
        <search-type-mobile
          :change-type="changeType"
          :options="options"
        />
      </div>
      <div class="search-other">
        <div class="history">
          <dl>
            <dt>{{ $t('24135') }}</dt>
            <template v-for="(res, index) in (lang === 'zh' ? siteConfig.search_keyword_cn.split(',') : siteConfig.search_keyword_en.split(','))">
              <dd
                :key="index"
                @click="goSearch(res)"
              >
                <tags-item-mobile
                  bg="rgba(255,255,255,0.1)"
                  :dark="true"
                  height="52px"
                  :info="{name: res, ename: res}"
                  padding="0 16px"
                />
              </dd>
            </template>
          </dl>
        </div>
        <div class="history">
          <dl>
            <dt>{{ $t('50013') }}</dt>
            <template v-for="(res, index) in options.history">
              <dd
                :key="index"
                @click="historySearch(res)"
              >
                <tags-item-mobile
                  bg="rgba(255,255,255,0.1)"
                  :dark="true"
                  height="52px"
                  :info="{name: res.keyword, ename: res.keyword}"
                  padding="0 16px"
                />
              </dd>
            </template>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import LangBtnMobile from "../../../components/Mobile/common/LangBtn";
import BackBtnMobile from "../../../components/Mobile/common/BackBtn";
import { Carousel3d, Slide } from 'vue-carousel-3d';
import PicMobile from "../../../components/Mobile/common/Pic";
import SearchViewMobile from "../../../components/Mobile/common/SearchView";
import TagsItemMobile from "../../../components/Mobile/common/TagsItem";
import SearchTypeMobile from "../../../components/Mobile/SearchType/SearchType";


export default {
  name: 'SearchIndexMobile',
  components: {
    SearchTypeMobile,
    TagsItemMobile, SearchViewMobile, PicMobile, BackBtnMobile, LangBtnMobile, TopNavMobile, Carousel3d, Slide},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeType: {
      type: Function,
      default: () => () =>{}
    },
    goSearch: {
      type: Function,
      default: () => () =>{}
    },
    historySearch: {
      type: Function,
      default: () => () =>{}
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'siteConfig']),
  },
}
</script>
<style scoped lang="scss">
.search-index-mobile {
  .exhibition-list{
    .pic{
      width: 160px;
      height: 160px;
    }
  }
  .search-type{
    overflow-x: auto;
  }
  .search-form{
    margin-top: 40px;
  }
  .search-other{
    margin: 120px 0 0;
    .history{
      margin-top: 50px;
      line-height: 53px;
      padding: 0 16px;
      dl{
        dt, dd{
          display: block;
        }
        dt{
          font-weight: 600;
          color: #fff;
          font-size: 26px;
        }
        dd{
          display: inline-block;
          color: #666;
          font-size: 12px;
          margin-right: 6px;
          margin-top: 16px;
        }
      }
    }
  }
}
</style>
