<template>
  <div
    class="fixed-menu-item-mobile"
    @click="$emit('click')"
  >
    <div class="pic">
      <img :src="info.icon" />
    </div>
    <div class="title">
      {{ $t(info.title) }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'FixedMenuItemMobile',
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.fixed-menu-item-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px 0;
  .pic{
    width: 48px;
    height: 48px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .title{
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 8px;
    color: #4C4C4C;
    text-align: center!important;
  }
}
</style>
