<template>
  <div class="activity-index-mobile">
    <top-nav-mobile>
      <span class="nav-title">{{ $t('10007') }}</span>
    </top-nav-mobile>
    <div class="container">
      <activity-tabs-list-mobile
        :change-type-id="changeTypeId"
        class="tabs"
        :options="options"
      />
      <activity-list-mobile
        :change-page="changePage"
        :data="options.data"
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import ActivityTabsListMobile from "../../../components/Mobile/Activity/ActivityTabsList";
import ActivityListMobile from "../../../components/Mobile/Activity/ActivityList";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import SearchBtnMobile from "../../../components/Mobile/common/SearchBtn";

export default {
  name: 'ActivityIndexMobile',
  components: {SearchBtnMobile, TopNavMobile, ActivityListMobile, ActivityTabsListMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeTypeId: {
      type: Function,
      default: () => () => {}
    },
    changePage: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.activity-index-mobile {
  .tabs{
    margin-top: 32px;
  }
}
</style>
