<template>
  <div>
    <a
      v-if="user.tokens && !user.qrcode"
      class="activity-btn-pc"
      :class="statusClass"
      :href="regurl"
      target="_blank"
      @click.stop=""
    >{{ statusTest }}</a>
    <div
      v-else
      class="activity-btn-pc"
      :class="statusClass"
      @click.stop="$emit('click')"
    >
      {{ statusTest }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  //预约状态，0可预约，1已预约，2预约结束，3即将开始,4已约满
  name: 'ActivityBtnPc',
  props: {
    status: {
      type: Number,
      default: 0
    },
    people: {
      type: [Number, String],
      default: () => ''
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'user']),
    statusTest() {
      switch(this.status){
        case -1:
          if(this.info.live_id > 0){
            return '观看直播'
          }else {
            return '无需预约'
          }
        case 0:
          return '可预约'
        case 1:
          if(this.info.live_id > 0){
            return '观看直播'
          }else{
            return '已预约'
          }
        case 2:
          if(this.info.live_id > 0){
            return '观看直播'
          }else{
            return '预约结束'
          }
        case 3:
          return '即将开始'
        case 4:
          return '已约满'
      }
      return ''
    },
    statusClass() {
      switch(this.status){
        case -1:
          if(this.info.live_id > 0){
            return 'in'
          }else {
            return 'default'
          }
        case 0:
          return 'on'
        case 1:
        case 3:
          return 'in'
        case 2:
          if(this.info.live_id > 0){
            return 'in'
          }else{
            return 'default'
          }
        case 4:
          return 'default'
      }
      return 'default';
    },
    regurl(){
      return 'https://online.ciif-expo.cn/RegMethod.html?pcurl='+location.href+'&phone='+this.user.mobile
    }
  },
}
</script>

<style scoped lang="scss">
.activity-btn-pc {
  width: 100%;
  height: 32px;
  line-height: 32px;
  text-align: center;
  margin-top: 16px;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
  &.on{
    background: linear-gradient(223deg, #FC501E, #FA8958);
    color: #fff;
  }
  &.in{
    background: linear-gradient(223deg, #faeee9, #fae9e4);
    color: #EA4C1E;
  }
  &.default{
    background: rgba(204, 204, 204, 0.4);
    color: #989898;
  }
}
</style>
