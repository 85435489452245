<template>
  <div class="about-text">
    <template v-for="(res, index) in aboutTextFormats">
      <about-text-item
        :key="index"
        :info="res"
      />
    </template>
  </div>
</template>

<script>

import AboutTextItem from "./aboutText/aboutTextItem";
export default {
  name: 'AboutText',
  components: {AboutTextItem},
  props: {
    info: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  computed: {
    aboutTextFormats(){
      let arr = [{},{}];
      let {json_text} = this.info
      arr.map(function(res, index){
        arr[index] = {
          text_title_cn: json_text['text_title_cn['+index+']'],
          text_title_en: json_text['text_title_en['+index+']'],
          text_content_cn: json_text['text_content_cn['+index+']'],
          text_content_en: json_text['text_content_en['+index+']'],
          text_item_status: json_text['text_item_status['+index+']'],
          text_orderlist: parseInt(json_text['text_orderlist['+index+']']),
        }
      })
      arr = this.sortBykey(arr, 'text_orderlist')
      return arr
    }
  }
}
</script>

<style scoped lang="scss">
.about-text{
  margin-bottom: 48px;
}
</style>
