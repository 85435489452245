<template>
  <div class="about-index-default">
    <about-index-pc
      v-if="browser===1 && options.loaded === true"
      :options="options"
    />
    <about-index-mobile
      v-if="browser===2 && options.loaded === true"
      :options="options"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import AboutIndexPc from "@/views/Pc/About";
import AboutIndexMobile from "../../Mobile/About";

export default {
  name: 'AboutIndexDefault',
  components: {AboutIndexMobile, AboutIndexPc},
  data() {
    return {
      options: {
        info: {},
        loaded: false
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    this.init()
    this.setShare()
  },
  methods: {
    init() {
      this.$u.api.commons.getAboutDetail().then(res => {
        if(res.status === 1){
          res.data.json_text = JSON.parse(this.htmlspecialchars_decode(res.data.json_text))
          res.data.json_text.templateSort = res.data.json_text.templateSort.split('|')
          this.options.info = res.data
          this.options.loaded = true
        }
      })
    },
    setShare(){
      this.getWxConfig(this.$t('10010'), this.$t('10010'))
    }
  }

}
</script>

<style scoped></style>
