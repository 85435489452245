<template>
  <div class="exhibition-info-mobile">
    <exhibition-info-mobile-default
      v-if="options.info.template_id === '0'"
      :jiaohuanmingpian="jiaohuanmingpian"
      :options="options"
      :set-collect="setCollect"
      :set-zan="setZan"
    />
    <exhibition-info-mobile-theme1
      v-else-if="options.info.template_id === '1'"
      :jiaohuanmingpian="jiaohuanmingpian"
      :options="options"
      :set-collect="setCollect"
      :set-zan="setZan"
    />
    <exhibition-info-mobile-theme2
      v-else-if="options.info.template_id === '2'"
      :jiaohuanmingpian="jiaohuanmingpian"
      :options="options"
      :set-collect="setCollect"
      :set-zan="setZan"
    />
    <exhibition-info-mobile-theme3
      v-else
      :jiaohuanmingpian="jiaohuanmingpian"
      :options="options"
      :set-collect="setCollect"
      :set-zan="setZan"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import ExhibitionInfoMobileDefault from "./components/infoDefault";
import ExhibitionInfoMobileTheme1 from "./components/infoTheme1";
import ExhibitionInfoMobileTheme2 from "./components/infoTheme2";
import ExhibitionInfoMobileTheme3 from "./components/infoTheme3";

export default {
  name: 'ExhibitionInfoMobile',
  components: {ExhibitionInfoMobileTheme3, ExhibitionInfoMobileTheme2, ExhibitionInfoMobileTheme1, ExhibitionInfoMobileDefault},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    setZan: {
      type: Function,
      default: () => () => {}
    },
    jiaohuanmingpian: {
      type:Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.exhibition-info-mobile {

}
</style>
