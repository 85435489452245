<template>
  <div class="user-supply-card-list-mobile">
    <template v-for="(res, index) in options.data.list">
      <div
        :key="index"
        class="item"
      >
        <supply-demmand-item-demand-mobile
          from="user"
          :info="res"
          :refresh="refresh"
          :show-demand-contact-modal-info="showDemandContactModalInfo"
          @click="show(res)"
        />
      </div>
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import SupplyDemmandItemDemandMobile from "../../SupplyDemand/SupplyDemandItem/component/demand";

export default {
  name: 'UserSupplyCardListMobile',
  components: {SupplyDemmandItemDemandMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    showDemandModalInfo: {
      type: Function,
      default: () => () => {}
    },
    showDemandContactModalInfo: {
      type: Function,
      default: () => () => {}
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    show(info){
      this.$u.api.supplydemands.getDetail({id: info.id}).then(res => {
        if(res.status === 1){
          this.showDemandModalInfo(res.data)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.user-supply-card-list-mobile {
  display: flex;
  flex-wrap: wrap;
  .item{
    margin-bottom: 20px;
    width: 100%;
    height: 240px;
    overflow: hidden;
    background: rgba(255,255,255,0.1);
    border-radius: 12px;
    padding: 24px;
    &:nth-child(2n){
      margin-right: 0;
    }
  }
}
</style>
