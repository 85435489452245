<template>
  <div
    class="live-tabs-item-mobile"
    :class="on ? `on` : ``"
    @click="changeTypeId(info.id)"
  >
    {{ $t2(lang, info.name, info.name_en) }}
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'LiveTabsItemMobile',
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    on: {
      type: Boolean,
      default: () => false,
    },
    changeTypeId: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.live-tabs-item-mobile{
  display: inline-block;
  margin: 0 16px;
  padding: 0 28px;
  font-size: 24px;
  font-weight: 500;
  line-height: 42px;
  position: relative;
  cursor: pointer;
  background: rgba(255,255,255,0.1);
  color: rgba(255,255,255,0.7);
  border-radius: 4px;
  border: 1px solid rgba(255,255,255,0.1);
  overflow: hidden;
  &.on{
    overflow: hidden;
    color: #FFFFFF;;
    font-weight: 500;
    line-height: 42px;
    background: rgba(255,255,255,0.2);
    border:  1px solid rgba(255,255,255,0.5);;
  }
}
</style>
