<template>
  <div class="supplydemand-mobile">
    <div
      v-if="false"
      class="bg"
    />
    <div class="bg2" />
    <top-nav-mobile
      :backurl="backurl"
      :is-back="true"
    >
      <span class="nav-title">{{ $t('10013') }}</span>
    </top-nav-mobile>
    <div
      class="container filter"
    >
      <supply-demand-filter-mobile
        :change-key="changeKey"
        :change-type-id="changeTypeId"
        :options="options"
        :search="search"
      />
    </div>
    <div
      class="container z"
    >
      <div class="category">
        <supply-demand-category-mobile
          :category="options.category"
          :category-current="options.categoryCurrent"
          :change-category-current="changeCategoryCurrent"
        />
        <icon-text-line-mobile
          icon="gongxu_add.png"
          width="40px"
          @click="navigateTo('/user/post_supply')"
        />
      </div>
      <supply-demand-list-mobile
        :list="options.data.list"
        :show-demand-contact-modal-info="showDemandContactModalInfo"
        :show-demand-modal-info="showDemandModalInfo"
        :show-supply-modal-info="showSupplyModalInfo"
      />
      <div
        v-if="options.data.hasmore"
        class="gongxu-bottom-view"
      >
        <div class="gongxu-more">
          <div
            class="gongxu-more-btn"
            @click="getList"
          >
            <div class="text">
              {{ $t('24101') }}
            </div>
            <div class="pic">
              <pic-mobile :src="require('../../../assets/images/gongxu_more.png')" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <supply-demand-supply-modal-mobile
      v-if="supplyShow"
      :hide-supply-modal="hideSupplyModal"
      :info="supplyModalInfo"
    />
    <supply-demand-demand-modal-mobile
      v-if="demandShow"
      :hide-demand-modal="hideDemandModal"
      :info="demandModalInfo"
      :show-demand-contact-modal-info="showDemandContactModalInfo"
    />
    <supply-demand-demand-contact-modal-mobile
      v-if="demandContactShow"
      :demand-modal-info="demandModalInfo"
      :hide-demand-contact-modal="hideDemandContactModal"
      :info="demandContactModalInfo"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import LangBtnMobile from "../../../components/Mobile/common/LangBtn";
import SupplyDemandFilterMobile from "../../../components/Mobile/SupplyDemand/SupplyDemandFilter";
import BackBtnMobile from "../../../components/Mobile/common/BackBtn";
import SupplyDemandCategoryMobile from "../../../components/Mobile/SupplyDemand/SupplyDemandCategory";
import SupplyDemandListMobile from "../../../components/Mobile/SupplyDemand/SupplyDemandList";
import IconTextLineMobile from "../../../components/Mobile/common/IconTextLine";
import SupplyDemandSupplyModalMobile from "../../../components/Mobile/SupplyDemand/SupplyDemandSupplyModal";
import SupplyDemandDemandModalMobile from "../../../components/Mobile/SupplyDemand/SupplyDemandDemandModal";
import SupplyDemandDemandContactModalMobile
  from "../../../components/Mobile/SupplyDemand/SupplyDemandDemandContactModal";
import PicMobile from "../../../components/Mobile/common/Pic";

export default {
  name: 'SupplyDemandMobile',
  components: {
    PicMobile,
    SupplyDemandDemandContactModalMobile,
    SupplyDemandDemandModalMobile,
    SupplyDemandSupplyModalMobile,
    IconTextLineMobile,
    SupplyDemandListMobile,
    SupplyDemandCategoryMobile, SupplyDemandFilterMobile,  TopNavMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeTypeId: {
      type: Function,
      default: () => () => {}
    },
    changeKey: {
      type: Function,
      default: () => () => {}
    },
    changeCategoryCurrent: {
      type: Function,
      default: () => () => {}
    },
    getList: {
      type: Function,
      default: () => () => {}
    },
    search: {
      type: Function,
      default: () => () => {}
    }
  },
  data() {
    return {
      backurl: '',
      form: {
        value: 0
      },
      supplyModalInfo: {},
      demandModalInfo: {},
      demandContactModalInfo: {},
      demandShow: false,
      demandContactShow: false,
      supplyShow: false,
      list: [
        {
          label: '1',
          value: 2
        }
      ]
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    this.backurl = this.$route.meta.fromUrl === '/' ? '/exhibition' : this.$route.meta.fromUrl
  },
  mounted() {
    this.backurl = this.$route.meta.fromUrl === '/' ? '/exhibition' : this.$route.meta.fromUrl
  },
  methods: {
    showDemandModalInfo(info){
      this.demandShow = true
      this.demandModalInfo = info
    },
    showSupplyModalInfo(info){
      if(parseInt(info.is_type)===2){
        this.supplyModalInfo = info
        this.supplyShow = true
      }else {
        this.getDetail(info.id, (res) => {
          this.supplyModalInfo = res
          this.supplyShow = true
        })
      }
    },
    hideDemandModal(){
      this.demandShow = false
    },
    hideSupplyModal(){
      this.supplyShow = false
    },
    getDetail(id, callback){
      const params = {
        id: id
      }
      this.$u.api.supplydemands.getDetail(params).then(res => {
        if(res.status === 1){
          callback(res.data)
        }
      })
    },
    showDemandContactModalInfo(info){
      this.demandShow = false
      this.demandContactShow = true
      this.demandModalInfo = info
      this.demandContactModalInfo = JSON.parse(this.htmlspecialchars_decode(info.contact))
      this.demandContactModalInfo.id = info.id
    },
    hideDemandContactModal(){
      this.demandContactShow = false
    },
  }
}
</script>

<style scoped lang="scss">
.supplydemand-mobile {
  .container{
    &.z{
      position: relative;
      z-index: 1;
      margin-top: 20px;
      padding-bottom: 170px;
    }
  }
  .filter{
    position: relative;
    z-index: 99;
  }
  .bg{
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../../../assets/images/mobile/gongxu_bg.png");
    background-size: 100% auto;
    background-position: center bottom;
    background-repeat: no-repeat;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
  .bg2{
    position: absolute;
    width: 100%;
    background: url("../../../assets/images/mobile/gongxu_bg.png") no-repeat;
    background-size: 100% auto;
    background-position: center bottom;
    left: 0;
    bottom: 0;
    z-index: 2;
    pointer-events: none;
    height: 456px;
  }
  .category{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .gongxu-bottom-view{
    text-align: center;
    height: 52px;
    position: relative;
    bottom: 20px;
    left: 0;
    width: 100%;
    .gongxu-more{
      padding-top: 10px;
      .gongxu-more-btn{
        text-align: center;
        display: inline-block;
        cursor: pointer;
        .text{
          text-align: center;
          color: #fff;
        }
        .pic{
          display: inline-block;
          text-align: center;
          width: 26px;
          height: 26px;
        }
      }
    }
  }
}
</style>
