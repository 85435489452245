<template>
  <div class="activity-modal-mobile">
    <div class="modal-content">
      <div class="header">
        {{ $t('21002') }}
      </div>
      <split-line-mobile
        bg-color="rgba(255,255,255,0.1)"
        height="1px"
      />
      <div class="content">
        <div class="item line-1">
          <span>{{ $t('24106') }}：</span>
          {{ info.title }}
        </div>
        <div class="item line-1">
          <span>{{ $t('24133') }}：</span>
          {{ info.cn_address }}
        </div>
        <div class="item line-1">
          <span>{{ $t('24134') }}：</span>
          {{ timeFormat(info.done_start_time, 'yyyy/mm/dd hh:MM') }} - {{ timeFormat(info.done_end_time, 'yyyy/mm/dd hh:MM') }}
        </div>
      </div>
      <div class="btns">
        <div
          class="cancel"
          @click="hideModal"
        >
          {{ $t('50020') }}
        </div>
        <a
          v-if="user.tokens && !user.qrcode"
          class="submit"
          :href="regurl"
          target="_blank"
          @click.stop=""
        >{{ $t('50034') }}</a>
        <div
          v-else
          class="submit"
          @click="yuyue"
        >
          {{ $t('50034') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import SplitLineMobile from "../../common/SplitLine";
import SubscribeModalMobile from "@/components/Mobile/common/SubscribeModal/index.vue";

export default {
  name: 'ActivityModalMobile',
  components: {SubscribeModalMobile, SplitLineMobile},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    hideModal: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'user']),
    regurl(){
      return 'https://online.ciif-expo.cn/RegMethod.html?pcurl='+location.href+'&phone='+this.user.mobile
    }
  },
  methods: {
    yuyue(){
      if(!this.user || !this.user.tokens){
        localStorage.setItem('backUrl', this.$router.currentRoute.path)
        localStorage.setItem('backQuery', JSON.stringify(this.$router.currentRoute.query))
        this.$router.replace('/user/login')
        return
      }
      if(parseInt(this.info.catename[0].id) === 46 && this.user.qrcode){
        window.open('https://online.ciif-expo.cn/RegMethod.html?pcurl='+location.href+'&phone='+this.user.mobile)
        return ;
      }
      const params = {
        aid: this.info.id
      }
      this.$u.api.activitys.actSaveMessage(params).then(res => {
        if(res.status === 1){
          this.info.is_status = 1
          this.info.people--
        }
        this.$bus.$emit('showSub', {id: this.info.id, typeName: '活动', template: '0TmvQEKhXVy3vNX3T6oWGZPMPM1awQEljfyfUvnqpwE,YQvZ9T0sBdCqvnRVJYVj3NXNl0I0cPb_vscUBCCM1UI', type: 'activity'})
        this.hideModal()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.activity-modal-mobile {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.6);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-content{
    background: #1F1F1F;
    width: 670px;
    .header{
      font-size: 32px;
      font-weight: 600;
      color: #fff;
      text-align: center;
      height: 100px;
      line-height: 100px;
    }
    .content{
      padding: 40px;
      font-size: 26px;
      .item{
        margin: 10px 0;
        color: #fff;
        span{
          color: rgba(255, 255, 255, 0.85);
        }
      }
    }
    .btns{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 28px;
      .cancel{
        flex: 1;
        line-height: 80px;
        height: 80px;
        background: #333;
        color: #ddd;
        text-align: center;
      }
      .submit{
        flex: 1;
        height: 80px;
        line-height: 80px;
        color: #fff;
        text-align: center;
        background: linear-gradient(223deg, rgba(255, 26, 44, 0.9), rgba(255, 155, 97, 0.8));
      }
    }
  }
}
</style>
