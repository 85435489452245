<template>
  <div class="about-contract-item">
    <div class="title title-before-line">
      {{ $t2(lang, info.contact_title_cn, info.contact_title_en) }}
    </div>
    <ul>
      <li>
        <dl>
          <dt>{{ $t2(lang, info.contact_title_1_cn, info.contact_title_1_en) }}</dt>
          <dd>{{ $t2(lang, info.contact_content_1_cn, info.contact_content_1_en) }}</dd>
        </dl>
      </li>
      <li>
        <dl>
          <dt>{{ $t2(lang, info.contact_title_2_cn, info.contact_title_2_en) }}</dt>
          <dd>{{ $t2(lang, info.contact_content_2_cn, info.contact_content_2_en) }}</dd>
        </dl>
      </li>
      <li>
        <dl>
          <dt>{{ $t2(lang, info.contact_title_3_cn, info.contact_title_3_en) }}</dt>
          <dd>{{ $t2(lang, info.contact_content_3_cn, info.contact_content_3_en) }}</dd>
        </dl>
      </li>
    </ul>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'AboutContractItem',
  props: {
    info:{
      type: Object,
      default(){
        return {}
      }
    }
  },
  computed:  {
    ...mapState(['lang'])
  },
}
</script>

<style scoped lang="scss">
.about-contract-item{
  display: inline-block;
  width: 340px;
  margin-right: 40px;
  &:last-child{
    margin-right: 0;
  }
  .title{
    margin-top: 24px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    line-height: 40px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
  }
  ul{
    line-height: 28px;
    font-size: 14px;
    margin-top: 12px;
    li{
      dl{
        margin: 0;
        white-space: nowrap;
        dt{
          display: inline-block;
          font-weight: 600;
          color: rgba(255, 255, 255, 0.8);
          width: 50px;
          vertical-align: top;
        }
        dd{
          font-weight: 600;
          vertical-align: top;
          display: inline-block;
          color: rgba(255, 255, 255, 0.6);
          white-space: pre-wrap;
          line-height: 30px;
          max-width: 290px;
        }
      }
    }
  }
}
</style>
