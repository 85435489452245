<template>
  <div class="supply-demand-tabs-pc">
    <template v-for="(res, index) in category">
      <supply-demand-tabs-item-pc
        :key="index"
        :info="res"
        :on="categoryCurrent === index"
        @click="changeCategoryCurrent(index)"
      />
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import SupplyDemandTabsItemPc from "@/components/Pc/SupplyDemand/SupplyDemandTabs/item";

export default {
  name: 'SupplyDemandTabsPc',
  components: {SupplyDemandTabsItemPc},
  props: {
    category: {
      type: Array,
      default: () => []
    },
    categoryCurrent: {
      type: Number,
      default: () => 0
    },
    changeCategoryCurrent: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.supply-demand-tabs-pc{

}
</style>
