<template>
  <a
    v-if="showLang"
    class="vr-btn-mobile"
    :href="vr"
    target="_blank"
  >
    <img
      src="../../../../assets/images/mobile/mobile_vr1.png"
    />
  </a>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'VrBtnMobile',
  props: {
    vr: {
      type: String,
      default: () => ""
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'hideEnMenu']),
    nowPath: function(){
      return this.$route.path.split('/')[1]
    },
    showLang(){
      if(this.hideEnMenu){
        if(this.hideEnMenu.indexOf('/'+this.nowPath.toLowerCase()) > -1){
          return false
        }else{
          return true
        }
      }
      return true
    }
  },
  methods:{

  }
}
</script>

<style scoped lang="scss">
.vr-btn-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 48px;
    height: 48px;
  }
}
</style>
