<template>
  <div
    class="list-box-item-pc"
    @click="$emit('click', info)"
  >
    <div class="pic">
      <img :src="imageurl" />
    </div>
    <div class="title">
      {{ title }}
    </div>
    <div
      v-if="tags.constructor === Array && tags.length > 0"
      class="tags line-1"
    >
      <template v-for="(res, index) in tags">
        <tag-item
          :key="index"
          class="item"
          :dark="true"
          :label="$t2(lang, res.name, res.ename)"
        />
      </template>
    </div>
    <div
      v-if="tags.constructor === Object"
      class="tags"
    >
      <tag-item
        :dark="true"
        :label="$t2(lang, tags.name, tags.ename)"
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TagItem from "@/components/Pc/common/TagItem";

export default {
  name: 'ListBoxItemPc',
  components: {TagItem},
  props: {
    imageurl: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    info: {
      type: Object,
      default: () => {}
    },
    tags: {
      type: [Array, Object],
      default: () => []
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.list-box-item-pc{
  display: inline-block;
  margin-bottom: 20px;
  margin-right: 20px;
  width: 360px;
  transition: all 0.4s ease-in-out;
  &:hover{
    transform: translateY(-3px);
    .pic{
      overflow: hidden;
      img{
        transform: scale(1.1);
      }
    }
  }
  .pic{
    width: 360px;
    height: 240px;
    box-shadow: 0 3px 8px 5px rgba(0, 0, 0, 0.1);
    overflow: visible;
    img{
      width: 100%;
      height: 100%;
      object-fit: fill;
      transition: all 0.4s ease-in-out;
    }
  }
  .title{
    margin-top: 12px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .tags{
    margin-top: 8px;
    height: 24px;
    .item{
      margin-right: 10px;
    }
  }
}
</style>
