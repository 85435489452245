<template>
  <div
    class="icon-text-share-exhibition-mobile-theme1"
    @click="click"
  >
    <div class="icon"></div>
    <div class="label">
      {{ label }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import QRCode from 'qrcodejs2'
import $ from 'jquery'

export default {
  name: 'IconTextShareExhibitionMobileTheme1',
  props: {
    label: {
      type: String,
      default: () => ''
    },
    purl: {
      type: String,
      default: () => ''
    }
  },
  data(){
    return {
      show: false,
      url: '',
      act: false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    nowPath: function(){
      return this.$route.path.split('/')[1]
    },
  },
  mounted() {
    this.url = window.location.href + (window.location.href.indexOf('?') > -1 ? '&' : '?') + 'share=1';

  },
  methods: {
    click(){
      let type = 1, id = this.$route.query.id
      //类型，1展商2评奖3是视频4论坛,5直播,6资讯,7活动,8案例
      switch(this.nowPath.toLowerCase()){
        case 'exhibition':
          type = 1
          break;
        case 'awards':
          type = 2
          break;
        case 'forum':
          type = 3
          break;
        case 'video':
          type = 4
          break;
        case 'live':
          type = 5
          break;
        case 'news':
          type = 6
          break;
        case 'activity':
          type = 7
          break;
        case 'case':
          type = 8
          break;
      }
      this.$u.api.ajaxs.actShare({t:type, id: id})
      this.$store.commit('setShare', true)
    }
  }

}
</script>

<style scoped lang="scss">
.icon-text-share-exhibition-mobile-theme1{
  display: inline-block;
  position: relative;
  z-index: 6;
  cursor: pointer;
  .icon{
    width: 40px;
    height: 40px;
    background: url("../../../../assets/images/exhibition_theme_1/icon_share@2x.png") no-repeat;
    background-size: 100% 100%;
    &.on{
      background: url("../../../../assets/images/exhibition_theme_1/icon_share_hover@2x.png") no-repeat;
      background-size: 100% 100%;
    }
    &:hover{
      background: url("../../../../assets/images/exhibition_theme_1/icon_share_hover@2x.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .label{
    height: 20px;
    color: rgba(255,255,255,0.8);
    font-size: 20px;
    white-space: nowrap;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
  .erweima-view{
    position: absolute;
    left: -52px;
    bottom: -138px;
    width: 138px;
    height: 138px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
    .qrcode{
      width: 104px;
      height: 104px;
    }
    .icon-logo{
      width: 30px;
      height: 30px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -13px;
      margin-top: -13px;
      overflow: hidden;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      .img{
        width: 26px;
        height: 26px;
        background: url("../../../../assets/images/logo_ico.png");
        background-size: 100% 100%;
      }
    }
  }
  .sanjiao{
    position: absolute;
    top: -10px;
    right: 10px;
    z-index: 3;
    width: 0;
    height: 0;
    border-bottom: 6px solid white;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }
}
</style>
