<template>
  <div class="index-default">
    <index-mobile
      v-if="browser===2"
      :options="options"
      :set-current="setCurrent"
    />
    <index-pc
      v-if="browser===1"
      :options="options"
      :set-current="setCurrent"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import IndexMobile from "../../Mobile/Index/index.vue";
import IndexPc from "../../Pc/Index";

export default {
  name: 'IndexDefault',
  components: {IndexPc, IndexMobile},
  data() {
    return {
      options: {
        current: 4,
        titleShow: true,
        dotShow: true,
        list: [
          {
            title: '工博会官网',
            top: 600,
            top_pc: -150,
            top_pc_2: -150,
            icon: require('../../../assets/images/mobile/index_lou_icon_0.png'),
            icon_on: require('../../../assets/images/mobile/index_lou_icon_0_on.png'),
            href: 'https://www.ciif-expo.com/',
          },
          {
            title: 'VR',
            top: 600,
            top_pc: -150,
            top_pc_2: -150,
            icon: require('../../../assets/images/mobile/index_lou_icon_1.png'),
            icon_on: require('../../../assets/images/mobile/index_lou_icon_1_on.png'),
            desc: '人工智能、虚拟现实、MGC......，元宇宙时代的平行展，探索真正的“工业元宇宙”。',
            url: 'http://virtual_vr.ciif-expo.com//tour/b7da61693c02c352',
            images: [
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_1_1@2x.png',
                width: 485,
                height: 132,
                left: 200,
                top: 400,
                mobile: {
                  width: 485,
                  height: 132,
                  left: 200,
                  top: 400,
                },
                animate: 'animate__shakeY'
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_1_2@2x.png',
                width: 343,
                height: 94,
                left: 50,
                top: 650,
                mobile: {
                  width: 343,
                  height: 94,
                  left: 50,
                  top: 650,
                },
                animate: 'animate__pulse'
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_1_3@2x.png',
                width: 59,
                height: 92,
                left: 500,
                top: 770,
                mobile: {
                  width: 59,
                  height: 92,
                  left: 500,
                  top: 770,
                }
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_1_4@2x.png',
                width: 222,
                height: 248,
                left: 750,
                top: 700,
                mobile: {
                  width: 222,
                  height: 248,
                  left: 750,
                  top: 700,
                },
                animation: 'yinxian 4s linear infinite'
              },
            ],
            link: [
              {
                title: 'VR',
                width: 316,
                height: 306,
                left: 197,
                top: 435,
                out: true,
                dot_left: 100,
                dot_top: 100,
                url: 'http://virtual_vr.ciif-expo.com/tour/b7da61693c02c352',
              }
            ]
          },
          {
            title: '评奖',
            top: 1400,
            top_pc: -1700,
            top_pc_2: -1550,
            icon: require('../../../assets/images/mobile/index_lou_icon_2.png'),
            icon_on: require('../../../assets/images/mobile/index_lou_icon_2_on.png'),
            desc: 'CIIF大奖、CIIF专项奖等在线发布，新产品、新技术见证中国制造新发展。',
            images: [
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_2_1@2x.png',
                width: 700,
                height: 429,
                left: 170,
                top: 2150,
                mobile: {
                  width: 700,
                  height: 429,
                  left: 170,
                  top: 2150,
                }
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_2_2@2x.png',
                width: 418,
                height: 130,
                left: 235,
                top: 2400,
                animate: 'animate__shakeY',
                mobile: {
                  width: 418,
                  height: 130,
                  left: 235,
                  top: 2400,
                }
              },
            ],
            link: [
              {
                title: '评奖',
                width: 310,
                height: 400,
                left: 163,
                top: 1190,
                out: false,
                url: '/Awards',
                dot_left: 130,
                dot_top: 170
              }
            ]
          },
          {
            title: '展览',
            top: 2400,
            top_pc: -3000,
            top_pc_2: -2900,
            icon: require('../../../assets/images/mobile/index_lou_icon_3.png'),
            icon_on: require('../../../assets/images/mobile/index_lou_icon_3_on.png'),
            desc: '9大专业展，2000多家企业的产品、活动、资讯等信息，汇聚行业发展新动向，让您轻松获取目标内容。',
            images: [
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_3_1@2x.png',
                width: 214,
                height: 214,
                left: 590,
                top: 2900,
                mobile: {
                  width: 214,
                  height: 214,
                  left: 590,
                  top: 2900,
                },
                animation: 'rotate 10s linear infinite'
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_3_2@2x.png',
                width: 519,
                height: 690,
                left: 190,
                top: 3200,
                mobile: {
                  width: 519,
                  height: 690,
                  left: 190,
                  top: 3200,
                },
                animate: 'animate__shakeX'
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_3_3@2x.png',
                width: 440,
                height: 195,
                left: 390,
                top: 3650,
                animate: 'animate__shakeY',
                mobile: {
                  width: 440,
                  height: 195,
                  left: 390,
                  top: 3650,
                }
              },
            ],
            link: [
              {
                title: '展览',
                width: 327,
                height: 437,
                left: 140,
                top: 2135,
                out: false,
                url: '/Exhibition',
                dot_left: 250,
                dot_top: 285
              }
            ]
          },
          {
            title: '欢迎大厅',
            top: 3400,
            top_pc: -4300,
            top_pc_2: -4250,
            icon: require('../../../assets/images/mobile/index_lou_icon_4.png'),
            icon_on: require('../../../assets/images/mobile/index_lou_icon_4_on.png'),
            desc: '聚焦国家战略和市场需求，瞄准产业升级、新经济、新赛道等热议话题，组织行业专家、企业高管等重量嘉宾，共话行业发展新趋势；大会论坛全程直播，支持点播回看。',
            images: [
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_4_1@2x.png',
                width: 345,
                height: 142,
                left: 250,
                top: 4950,
                animate: 'animate__shakeY',
                mobile: {
                  width: 345,
                  height: 142,
                  left: 250,
                  top: 4950,
                }
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_4_2@2x.png',
                width: 147,
                height: 161,
                left: 80,
                top: 4850,
                mobile: {
                  width: 147,
                  height: 161,
                  left: 80,
                  top: 4850,
                }
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_4_3@2x.png',
                width: 88,
                height: 68,
                left: 630,
                top: 4750,
                animate: 'animate__shakeX',
                animateDuration: '10s',
                mobile: {
                  width: 88,
                  height: 68,
                  left: 630,
                  top: 4750,
                }
              },
            ],
            link: [
              {
                title: '论坛',
                width: 220,
                height: 149,
                left: 152,
                top: 3360,
                out: false,
                url: '/Forum',
                dot_left: 103,
                dot_top: 65
              },
            ]
          },
          {
            title: '直播间',
            top: 4383,
            top_pc: -5600,
            top_pc_2: -5530,
            icon: require('../../../assets/images/mobile/index_lou_icon_5.png'),
            icon_on: require('../../../assets/images/mobile/index_lou_icon_5_on.png'),
            desc: '通过“官方直播间”、“官方云逛展”等直播频道，生产主题更加丰富、形式更加多样的直播内容，打造行业直播新平台。',
            images: [
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_5_1@2x.png',
                width: 289,
                height: 134,
                left: 495,
                top: 6320,
                animate: 'animate__shakeY',
                mobile: {
                  width: 289,
                  height: 134,
                  left: 495,
                  top: 6320,
                }
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_5_2@2x.png',
                width: 294,
                height: 401,
                left: 440,
                top: 5780,
                opacity: 0.4,
                mobile: {
                  width: 294,
                  height: 401,
                  left: 440,
                  top: 5780,
                },
                animate: 'animate__flash'
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_5_3@2x.png',
                width: 170,
                height: 114,
                left: 770,
                top: 6160,
                opacity: 0.4,
                mobile: {
                  width: 170,
                  height: 114,
                  left: 770,
                  top: 6160,
                }
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_5_4@2x.png',
                width: 82,
                height: 105,
                left: 250,
                top: 6200,
                mobile: {
                  width: 82,
                  height: 105,
                  left: 250,
                  top: 6200,
                },
                animation: 'down 10s linear infinite'
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_5_5@2x.png',
                width: 71,
                height: 159,
                left: 378,
                top: 6125,
                mobile: {
                  width: 71,
                  height: 159,
                  left: 378,
                  top: 6125,
                },
                animation: 'up 10s linear infinite'
              },
            ],
            link: [
              {
                title: '直播间',
                width: 318,
                height: 351,
                left: 165,
                top: 4102,
                out: false,
                url: '/Live',
                dot_left: 90,
                dot_top: 240
              }
            ]
          },
          {
            title: '搜索&榜单',
            top: 5210,
            top_pc: -6700,
            top_pc_2: -6600,
            icon: require('../../../assets/images/mobile/index_lou_icon_6.png'),
            icon_on: require('../../../assets/images/mobile/index_lou_icon_6_on.png'),
            desc: '建立超级搜索库，精准定位企业信息；通过大数据功能，直观呈现行业热点。',
            images: [
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_6_1@2x.png',
                width: 469,
                height: 180,
                left: 80,
                top: 6880,
                animate: 'animate__shakeY',
                mobile: {
                  width: 469,
                  height: 180,
                  left: 80,
                  top: 6880,
                }
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_6_2@2x.png',
                width: 237,
                height: 422,
                left: 340,
                top: 7050,
                opacity: 0.2,
                mobile: {
                  width: 237,
                  height: 422,
                  left: 340,
                  top: 7050,
                },
                animate: 'animate__flash'
              },
            ],
            link: [
              {
                title: '超级搜索',
                width: 112,
                height: 201,
                left: 355,
                top: 5060,
                out: false,
                url: '/Search',
                dot_left: 20,
                dot_top: 160
              },
              {
                title: '排行榜',
                width: 168,
                height: 221,
                left: 100,
                top: 5000,
                out: false,
                url: '/Rank',
                dot_left: 30,
                dot_top: 170
              }
            ]
          },
          {
            title: '供需对接',
            top: 6200,
            top_pc: -7750,
            top_pc_2: -7650,
            icon: require('../../../assets/images/mobile/index_lou_icon_7.png'),
            icon_on: require('../../../assets/images/mobile/index_lou_icon_7_on.png'),
            desc: '供需信息在线发布、在线匹配，通过平台流量和目标客群，实现供需精准、高效对接，逐渐搭建成标准化的商贸平台。',
            images: [
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_7_1@2x.png',
                width: 335,
                height: 177,
                left: 40,
                top: 7880,
                animate: 'animate__shakeY',
                mobile: {
                  width: 335,
                  height: 177,
                  left: 40,
                  top: 7880,
                }
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_7_2@2x.png',
                width: 233,
                height: 179,
                left: 100,
                top: 8200,
                mobile: {
                  width: 233,
                  height: 179,
                  left: 100,
                  top: 8200,
                }
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_7_3@2x.png',
                width: 299,
                height: 105,
                left: 130,
                top: 8380,
                mobile: {
                  width: 299,
                  height: 105,
                  left: 130,
                  top: 8380,
                }
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_7_4@2x.png',
                width: 352,
                height: 95,
                left: 90,
                top: 8480,
                zIndex: 1,
                mobile: {
                  width: 352,
                  height: 95,
                  left: 90,
                  top: 8480,
                },
                animation: 'chuan 10s linear infinite'
              },
            ],
            link: [
              {
                title: '供需对接',
                width: 352,
                height: 217,
                left: 130,
                top: 5826,
                out: false,
                url: '/supplydemand',
                dot_left: 50,
                dot_top: 10
              }
            ]
          },
          {
            title: '案例',
            top: 6600,
            top_pc: -8630,
            top_pc_2: -8500,
            icon: require('../../../assets/images/mobile/index_lou_icon_8.png'),
            icon_on: require('../../../assets/images/mobile/index_lou_icon_8_on.png'),
            desc: '行业应用案例大合集，可通过应用领域和专业展进行筛选查找，为您提供行业解决方案的案例参考。',
            images: [
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_8_1@2x.png',
                width: 361,
                height: 153,
                left: 80,
                top: 9040,
                animate: 'animate__shakeY',
                mobile: {
                  width: 361,
                  height: 153,
                  left: 80,
                  top: 9040,
                }
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_8_2@2x.png',
                width: 158,
                height: 99,
                left: 200,
                top: 8930,
                mobile: {
                  width: 158,
                  height: 99,
                  left: 200,
                  top: 8930,
                }
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_8_3@2x.png',
                width: 28,
                height: 116,
                left: 520,
                top: 8980,
                mobile: {
                  width: 28,
                  height: 116,
                  left: 520,
                  top: 8980,
                }
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_8_4@2x.png',
                width: 66,
                height: 115,
                left: 130,
                top: 9600,
                mobile: {
                  width: 66,
                  height: 115,
                  left: 130,
                  top: 9600,
                }
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_8_5@2x.png',
                width: 74,
                height: 74,
                left: 500,
                top: 9400,
                animation: 'rotate 10s linear infinite',
                mobile: {
                  width: 74,
                  height: 74,
                  left: 500,
                  top: 9400,
                }
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_8_6@2x.png',
                width: 69,
                height: 70,
                left: 530,
                top: 9150,
                animation: 'rotate 10s linear infinite',
                mobile: {
                  width: 69,
                  height: 70,
                  left: 530,
                  top: 9150,
                }
              },
              {
                pic: 'https://virtualobs.ciif-expo.com/video/dalou/dalou_8_7@2x.png',
                width: 103,
                height: 103,
                left: 40,
                top: 9300,
                animation: 'rotate 10s linear infinite',
                mobile: {
                  width: 103,
                  height: 103,
                  left: 40,
                  top: 9300,
                }
              },
            ],
            link: [
              {
                title: '案例',
                width: 327,
                height: 240,
                left: 128,
                top: 6400,
                out: false,
                url: '/case',
                dot_left: 135,
                dot_top: 140
              }
            ]
          },
        ],
        gundong: true,
      },
      defaultOptions: {},
      isFirst: false,
      timeObj: null,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    this.init()
    this.setShare()
  },
  methods: {
    init(){
      this.timeObj = setTimeout(() => {
        this.options.titleShow = false
      }, 13000)
    },
    setCurrent(i) {
      this.options.gundong = false
      this.options.dotShow = false
      this.options.titleShow = true
      this.options.tipShow = true
      this.options.current = i
      this.timeObj && clearTimeout(this.timeObj)
      this.timeObj = setTimeout(() => {
        this.options.titleShow = false
      }, 13000)
      setTimeout(() => {
        this.options.gundong = true
      },300)
      setTimeout(() => {
        this.options.dotShow = true
      },1000)
    },
    setShare(){
      this.getWxConfig('数字工博GoCIIF，等您来探索', '5+365工业新生态。汇聚行业优质内容，打造工业元宇宙。', 'https://virtualobs.ciif-expo.com/shareimg/index.jpg')
    }
  }

}
</script>

<style scoped></style>
