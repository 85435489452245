<template>
  <div
    class="icon-text-jiaohuan-exhibition-mobile-theme1"
    @click="$emit('click')"
  >
    <div
      class="icon"
      :class="jiaohuan ? `on` : ``"
    ></div>
    <div class="label">
      {{ label }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextJiaohuanExhibitionMobileTheme1',
  props: {
    label: {
      type: [String, Number],
      default: () => ''
    },
    jiaohuan: {
      type: Boolean,
      default: () => false
    },
    type: {
      type: Number,
      default: () => 1
    },
    id: {
      type: [String, Number],
      default: () => 1
    },
    setZan:{
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },

}
</script>

<style scoped lang="scss">
.icon-text-jiaohuan-exhibition-mobile-theme1{
  display: inline-block;
  cursor: pointer;
  text-align: center;
  .icon{
    margin: 0 auto;
    width: 40px;
    height: 40px;
    background: url("../../../../assets/images/exhibition_theme_1/icon_jiaohuan@2x.png") no-repeat;
    background-size: 100% 100%;
    &.on{
      background: url("../../../../assets/images/exhibition_theme_1/icon_jiaohuan_hover@2x.png") no-repeat;
      background-size: 100% 100%;
    }
    &:hover{
      background: url("../../../../assets/images/exhibition_theme_1/icon_jiaohuan_hover@2x.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .label{
    height: 20px;
    color: rgba(255,255,255,0.8);
    font-size: 20px;
    white-space: nowrap;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
}
</style>
