<template>
  <div>
    <slider-mobile v-if="browser===2" />
    <slider-index-pc v-if="browser ===1" />
  </div>
</template>

<script>

import {mapState} from "vuex";
import SliderMobile from "@/views/Mobile/Slider";
import SliderIndexPc from "../../Pc/Slider";

export default {
  name: 'Index',
  components: {SliderIndexPc, SliderMobile},
  computed: {
    ...mapState(['browser', 'lang']),
  },
  mounted() {
    this.setShare()
  },
  methods: {
    setShare(){
      this.getWxConfig('', '')
    }
  }
}
</script>

<style scoped></style>
