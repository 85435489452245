<template>
  <div class="bbs-info-default">
    <bbs-info-pc
      v-if="browser===1"
      :hide-jiabin="hideJiabin"
      :options="options"
      :set-collect="setCollect"
      :set-zan="setZan"
      :show-jiabin="showJiabin"
    />
    <bbs-info-mobile
      v-if="browser===2"
      :hide-jiabin="hideJiabin"
      :options="options"
      :set-collect="setCollect"
      :set-zan="setZan"
      :show-jiabin="showJiabin"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import BbsInfoPc from "@/views/Pc/Bbs/info";
import BbsInfoMobile from "../../Mobile/Bbs/info";

export default {
  name: 'BbsInfoDefault',
  components: {BbsInfoMobile, BbsInfoPc},
  data(){
    return {
      options: {
        id: 0,
        info: {},
        loaded: false,
        date: {},
        jiabinShow: false,
        jiabinInfo: {}
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  beforeMount() {
    this.options.id = this.$route.query.id
    this.options.share = this.$route.query.share || 0
  },
  activated() {
    this.scrollAuto()
  },
  mounted() {
    this.scrollAuto()
    this.init()
  },
  methods: {
    init(){
      const params = {
        id: this.options.id,
        share: this.options.share
      }
      this.options.loaded = false
      this.$u.api.bbss.getDetail(params).then(res => {
        this.options.loaded = true
        if(res.status === 1){
          this.options.info = res.data
          this.setShare(res)
        }
      })
      this.$u.api.commons.getLiveDate().then(res => {
        let date = {}
        for( var i in res.data){
          date[res.data[i].id] = res.data[i]
        }
        this.options.date = date
      })
    },
    setShare(res){
      let catname = []
      res.data.catename.map(a => {
        catname.push(this.$t2(this.lang, a.name, a.ename))
      })
      this.getWxConfig(this.$t2(this.lang, res.data.title, res.data.title_en), catname.join('|'), res.data.pic)
    },
    setCollect(v) {
      this.options.info.is_cang = v
      this.$forceUpdate()
    },
    setZan(v, val) {
      this.options.info.is_zan = v
      this.options.info.zan = val
      this.$forceUpdate()
    },
    showJiabin(id){
      const params = {
        id: id,
      }
      this.$u.api.bbss.getJiabinDetail(params).then(res => {
        if(res.status === 1){
          this.options.jiabinInfo = res.data
          this.options.jiabinShow = true
        }
      })
    },
    hideJiabin(){
      this.options.jiabinShow = false
    }
  }

}
</script>

<style scoped></style>
