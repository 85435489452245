<template>
  <div class="topc-index-mobile">
    <top-nav-mobile>
      <span class="nav-title">{{ $t('10009') }}</span>
    </top-nav-mobile>
    <div class="container">
      <div class="types-view">
        <topic-types-mobile
          :change-typeid="changeTypeid"
          :options="options"
        />
      </div>
      <div
        v-if="options.info.pic_wap"
        class="pic radius"
      >
        <pic-mobile :src="options.info.pic_wap" />
      </div>
      <template
        v-for="(res, index) in options.info.text"
      >
        <text-content-mobile
          :key="index"
          :content="$t2(lang, res.content_cn, res.content_en)"
          :k="index"
          :line-num="lineNum[index]"
          margin-bottom="53px"
          margin-top="25px"
          :set-line="setLine"
          :title="$t(res.title)"
        />
        <div class="exhibition-ad-box" v-if="index == 0 && options.info.banner_wap">
          <a :href="options.info.banner_link_wap" target="_blank"><img :src="options.info.banner_wap" /></a>
        </div>
      </template>
      <div
        v-if="options.info.zhengce.length > 0"
        class="zhengce-list"
      >
        <div class="title">
          {{ $t('27009') }}
        </div>
        <div class="list">
          <template v-for="(res, index) in options.info.zhengce">
            <div
              :key="index"
              class="item line-1"
              @click="navigateTo('/news/info?id='+res.id)"
            >
              {{ res.title }}
            </div>
          </template>
        </div>
      </div>
      <topic-zhanshang-list-mobile v-if="options.info.zhanshang.length > 0" />
      <custom-list-mobile
        v-if="options.info.product.length > 0"
        :list="options.info.product"
        margin-top="60px"
        :title="$t('20106')"
        type="product"
      />
      <custom-list-mobile
        v-if="options.info.activity.length > 0"
        :list="options.info.activity"
        margin-top="60px"
        :title="$t('50022')"
        type="activity"
      />
      <custom-list-mobile
        v-if="options.info.news.length > 0"
        :list="options.info.news"
        margin-top="60px"
        :options="options"
        :title="$t('50028')"
        type="news"
      />
      <custom-list-mobile
        v-if="options.info.caselist.length > 0"
        :list="options.info.caselist"
        margin-top="60px"
        :title="$t('应用案例')"
        type="case"
      />
      <custom-list-mobile
        v-if="options.info.videos.length > 0"
        :list="options.info.videos"
        margin-top="60px"
        :title="$t('50018')"
        type="video"
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopicTypesMobile from "../../../components/Mobile/Topic/TopicTypes";
import PicMobile from "../../../components/Mobile/common/Pic";
import TextContentMobile from "../../../components/Mobile/common/TextContent";
import TopicZhanshangListMobile from "../../../components/Mobile/Topic/TopicZhanshang";
import CustomListMobile from "../../../components/Mobile/common/CustomList";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import SearchBtnMobile from "../../../components/Mobile/common/SearchBtn";
import $ from "jquery";

export default {
  name: 'TopicIndexMobile',
  components: {
    TopNavMobile, CustomListMobile, TopicZhanshangListMobile, TextContentMobile, PicMobile, TopicTypesMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeTypeid: {
      type: Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      lineNum: [5, 5]
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'windowWidth']),
  },
  activated() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.setTabView()
      }, 1000)
    })
  },
  methods: {
    setLine(i,v){
      this.lineNum[i] = v
      this.$forceUpdate()
    },
    setTabView(){
      this.$nextTick(() => {
        let father = $('.topic-types-mobile');
        let scrollTo = $('.topic-types-item-mobile.on');
        if(scrollTo.offset().left && father.offset().left){
          father.animate({
            scrollLeft: (scrollTo.offset().left - father.offset().left + father.scrollLeft()) - (this.windowWidth / 2) + $(scrollTo).width()
          }, 0);
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.topc-index-mobile {
  .types-view{
    //margin-top: 32px;
  }
  .zhengce-list{
    margin-bottom: 48px;
    .title{
      height: 44px;
      font-size: 32px;
      font-weight: 600;
      color: #fff;
      line-height: 44px;
      margin-bottom: 20px;
      position: relative;
      padding-left: 26px;
      &:before{
        content: '';
        height: 36px;
        width: 8px;
        position: absolute;
        left: 0;
        top: 6px;
        background: linear-gradient(223deg, #FC501E, #FA8958);
        border-radius: 4px;
      }
    }
    .list{
      .item{
        height: 50px;
        width: 100%;
        line-height: 50px;
        display: inline-block;
        list-style: decimal;
        font-size: 28px;
        font-weight: 400;
        color: rgba(255,255,255,0.8);
        position: relative;
        padding-left: 30px;
        padding-right: 60px;
        cursor: pointer;
        &:before{
          content: ' ';
          background: #666;
          width: 18px;
          height: 20px;
          border-radius: 10px;
          position: absolute;
          left: 0;
          top: 15px;
          background: url("../../../assets/images/zhengce_sanjiao.png");
          background-size: 100% 100%;
        }
      }
    }
  }
  .pic{
    width: 100%;
    height: 339px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
</style>
