<template>
  <div class="activity-reserve-modal-pc">
    <div class="cell">
      <div class="box">
        <div class="close-btn">
          <btn-close-pc @click="hideReserveModal" />
        </div>
        <div class="title-line">
          {{ $t('28003') }}
        </div>
        <split-line-pc
          bg-color="rgba(255,255,255,0.2)"
          height="1px"
          margin-bottom="23px"
          margin-top="0"
        />
        <div class="info-main">
          <div class="cell-list">
            <div class="label">
              {{ $t('24106') }}：
            </div>
            <div class="text">
              {{ $t2(lang, info.title, info.en_title) }}
            </div>
          </div>
          <div class="cell-list">
            <div class="label">
              {{ $t('24133') }}：
            </div>
            <div class="text">
              {{ $t2(lang, info.cn_address, info.en_address) }}
            </div>
          </div>
          <div class="cell-list">
            <div class="label">
              {{ $t('24134') }}：
            </div>
            <div class="text">
              {{ timeFormat(info.done_start_time, 'yyyy/mm/dd hh:MM') }} - {{ timeFormat(info.done_end_time, 'yyyy/mm/dd hh:MM') }}
            </div>
          </div>
        </div>
        <div class="btn-line">
          <btn-custom-pc
            background="rgba(204, 204, 204, 0.4)"
            class="item"
            color="#989898"
            :label="$t('50020')"
            @click="hideReserveModal"
          />
          <btn-custom-pc
            background="linear-gradient(223deg, rgba(255, 26, 44, 0.9), rgba(255, 155, 97, 0.8))"
            class="item"
            color="#fff"
            :label="$t('50034')"
            @click="yuyue"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import BtnClosePc from "@/components/Pc/common/BtnClosePc";
import SplitLinePc from "@/components/Pc/common/SplitLine";
import BtnCustomPc from "@/components/Pc/common/BtnCustom";

export default {
  name: 'ActivityReserveModalPc',
  components: {BtnCustomPc, BtnClosePc, SplitLinePc},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    hideReserveModal: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang','user']),
  },
  methods: {
    yuyue(){
      if(!this.user || !this.user.tokens){
        localStorage.setItem('backUrl', this.$router.currentRoute.path)
        localStorage.setItem('backQuery', JSON.stringify(this.$router.currentRoute.query))
        this.$router.push('/user/login')
        return
      }
      if(parseInt(this.info.catename[0].id) === 46 && !this.user.qrcode){
        window.open('https://online.ciif-expo.cn/RegMethod.html?pcurl='+location.href+'&phone='+this.user.mobile)
        return ;
      }
      const params = {
        aid: this.info.id
      }
      this.$u.api.activitys.actSaveMessage(params).then(res => {
        if(res.status === 1){
          this.info.is_status = 1
          this.info.people--
        }
        this.hideReserveModal()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.activity-reserve-modal-pc{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  background: rgba(0,0,0,0.3);
  display: table;
  .cell{
    display: table-cell;
    vertical-align: middle;
    .box{
      position: relative;
      width: 480px;
      background: #1F1F1F;
      margin: 0 auto;
      .close-btn{
        position: absolute;
        right: -40px;
        top: -40px;
      }
      .title-line{
        text-align: center;
        padding: 24px 32px 16px;
        font-size: 20px;
        font-weight: 600;
        color: #fff;
        line-height: 24px;
      }
      .info-main{
        padding: 0 32px 24px;
        display: table;
        .cell-list{
          display: table-row;
          height: 36px;
          line-height: 36px;
          font-size: 16px;
          font-weight: 500;
          .label{
            display: table-cell;
            color: #fff;
            width: 50px;
          }
          .text{
            display: table-cell;
            color: #fff
          }
        }
      }
      .btn-line{
        text-align: center;
        padding-bottom: 33px;
        .item{
          margin: 0 10px;
        }
      }
    }
  }
}
</style>
