<template>
  <div class="supply-product-default">
    <supply-product-mobile :options="options" />
  </div>
</template>

<script>

import {mapState} from "vuex";
import SupplyProductMobile from "../../Mobile/User/supplyProduct";

export default {
  name: 'SupplyProductDefault',
  components: {SupplyProductMobile},
  data() {
    return {
      options: {
        id: 0,
        list: []
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
  },
  mounted() {
    this.options.id = this.$route.query.id
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    this.init()
  },
  methods: {
    init() {
      this.$u.api.users.getSupplyProductLists({supply_id:this.options.id, pagesize: 100}).then(res => {
        if(res.status === 1){
          this.options.list = res.data.lists
        }
      })
    }
  }

}
</script>

<style scoped></style>
