<template>
  <div
    class="user-collect-exhibition-card-list-item-mobile"
    @click="showModal(info)"
  >
    <div class="pic radius">
      <pic-mobile :src="info.logoimageurl" />
    </div>
    <div class="info">
      <icon-text-card-del-mobile
        :id="info.id"
        class="delbtn"
        color="#c2c2c2"
        font-size="20px"
        :label="$t('50024')"
        :refresh="refresh"
        :shoucang="parseInt(info.is_cang) === 1"
        :type="4"
        width="32px"
      />
      <div class="title">
        {{ $t2(lang, info.companyname, info.companynameen) }}
      </div>
      <div class="desc">
        <div class="desc-1">
          {{ info.boothno }}
        </div>
        <div class="desc-2">
          {{ $t2(lang, info.address, info.address_en) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "../../common/Pic";
import IconTextCardDelMobile from "../../common/IconText/carddel";

export default {
  name: 'UserCollectExhibitionCardListItemMobile',
  components: {IconTextCardDelMobile, PicMobile},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    showModal: {
      type: Function,
      default: () => () => {}
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.user-collect-exhibition-card-list-item-mobile {
  height: 248px;
  background: rgba(255,255,255,0.1);
  border-radius: 12px;
  width: 100%;
  margin-bottom: 24px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .pic{
    width: 200px;
    height: 200px;
    margin-right: 24px;
    background-color: #f5f7fa;
  }
  .info{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 200px;
    flex-direction: column;
    position: relative;
    .title{
      height: 88px;
      font-size: 32px;
      font-weight: 500;
      color: #fff;
      line-height: 44px;
    }
    .delbtn{
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .desc{
    margin-top: 20px;
    .desc-1{
      font-size: 22px;
      font-weight: 500;
      color: rgba(255,255,255,0.8);
      line-height: 22px;
    }
    .desc-2{
      font-size: 20px;
      font-weight: 500;
      color: rgba(255,255,255,0.8);
      line-height: 20px;
      margin-top: 8px;
    }
  }
}
</style>
