<template>
  <div
    v-infinite-scroll="getComment"
    class="live-index-pc"
  >
    <div class="container">
      <div class="main-box">
        <live-tabs-pc
          :change-type-id="changeTypeId"
          class="live-tabs"
          :list="options.childType"
          :live_comment_stae="options.live_comment_stae"
          :childTypeId="options.childTypeId"
          :liverichang="options.liverichang"
          v-if="options.childType.length > 1"
        />
        <div class="live-info">
          <div
            class="pic"
          >
            <pic-pc
              v-if="options.info.livesurl === ''"
              :src="options.info.pic"
            />
            <iframe
              v-else
              allowfullscreen="true"
              frameborder="0"
              height="100%"
              mozallowfullscreen="true"
              msallowfullscreen="true"
              oallowfullscreen="true"
              :src="options.info.livesurl"
              webkitallowfullscreen="true"
              width="100%"
            ></iframe>
          </div>
          <div class="tab-list">
            <div class="tab-menu">
              <template v-for="(res, index) in (options.liverichang ? tabMenu2: tabMenu)">
                <div
                  :key="index"
                  class="menu-item"
                  :class="(current === res.value) ? `on` : ``"
                  @click="setTabCurrent(res.value)"
                >
                  {{ $t(res.title) }}
                </div>
              </template>
              <div class="right-btn-view">
                <icon-text-viewnum
                  class="btn-item"
                  :label="options.info.click"
                />
                <icon-text-zan
                  :id="options.info.id"
                  class="btn-item"
                  :label="options.info.zan"
                  :set-zan="setZan"
                  :type="2"
                  :zan="parseInt(options.info.is_zan) === 1"
                />
                <icon-text-share
                  class="btn-item"
                  :label="this.$t('50016')"
                  :purl="url"
                />
                <icon-text-wx-share
                  v-if="options.info.state==0"
                  class="btn-item"
                  label="预约"
                  :purl="url"
                />
                <div
                  v-if="options.liverichang && parseInt(options.info.live_comment_stae) === 1"
                  class="comment-form"
                >
                  <input
                    v-model="comment"
                    :placeholder="$t('21106')"
                    type="text"
                  />
                  <div
                    class="submit-btn"
                    @click="sendCommentAction"
                  >
                    {{ $t('21107') }}
                  </div>
                </div>
              </div>
            </div>
            <split-line-pc
              bg-color="rgba(234, 76, 30, .1)"
              height="1px"
            />
            <div class="tab-content min-height-800">
              <live-comment-list-pc
                v-if="options.liverichang && current === `comment`"
                :options="options"
              />
              <live-history-list-pc
                v-if="options.liverichang && current === `history`"
                :change-live-id="changeLiveIdHistory"
                :options="options"
              />
              <text-content-pc
                v-if="current===`content`"
                :content="$t2(lang, options.info.content, options.info.content_en)"
              />
              <div
                v-if="current === `richeng`"
                class="tab-item richeng"
              >
                <div class="richeng-view">
                  <div class="left">
                  </div>
                  <div class="right">
                    <live-richeng-list-pc
                      :date="options.date"
                      :list="options.info.livesList"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {mapState} from "vuex";
import LiveTabsPc from "@/components/Pc/Live/LiveTabs";
import PicPc from "@/components/Pc/common/Pic";
import SplitLinePc from "@/components/Pc/common/SplitLine";
import TextContentPc from "@/components/Pc/common/TextContent";
import LiveRichengListPc from "@/components/Pc/Live/LiveRichengList";
import IconTextViewnum from "../../../components/Pc/common/IconText/viewnum";
import IconTextZan from "../../../components/Pc/common/IconText/zan";
import IconTextShare from "../../../components/Pc/common/IconText/share";
import IconTextWxShare from "../../../components/Pc/common/IconText/wxshare";
import HlsPlayer from 'xgplayer-hls.js';
import LiveCommentListPc from "../../../components/Pc/Live/LiveCommentList";
import LiveHistoryListPc from "../../../components/Pc/Live/LiveHistoryList";
import $ from "jquery";

export default {
  name: 'LiveIndexPc',
  components: {
    LiveHistoryListPc,
    LiveCommentListPc,
    IconTextShare,
    IconTextWxShare,
    IconTextZan, IconTextViewnum, LiveRichengListPc,  TextContentPc, SplitLinePc, PicPc, LiveTabsPc},
  props:{
    options: {
      type: Object,
      default: () => {}
    },
    changeTypeId: {
      type: Function,
      default: () => () => {}
    },
    changeLiveId: {
      type: Function,
      default: () => () => {}
    },
    changeLiveIdHistory: {
      type: Function,
      default: () => () => {}
    },
    setZan: {
      type: Function,
      default: () => () => {}
    },
    sendComment: {
      type: Function,
      default: () => () => {}
    },
    getComment: {
      type: Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      comment: '',
      current: 'content',
      videoPlayer: null,
      tabMenu: [
        {
          title: '22003',
          value: 'content'
        },
        {
          title: '50023',
          value: 'richeng'
        }
      ],
      tabMenu2: [
        {
          title: '21104',
          value: 'comment'
        },
        {
          title: '22003',
          value: 'content'
        },
        {
          title: '21105',
          value: 'history'
        }
      ],
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    url(){
      if(window.location.href.indexOf('liveid') > -1){
        var str = window.location.href.split('liveid=')
        return str[0] +'liveid='+ this.options.liveid
      }else{
        return window.location.href + (window.location.href.indexOf('liveid') > -1 ? '' : '?typeCurrent=1&liveid='+this.options.liveid);
      }
    }
  },
  // watch: {
  //   options: {
  //     deep: true,
  //     handler(newVal, oldVal) {
  //       if(newVal.info.livesurl !== ''){
  //         if (this.videoPlayer !== null) {
  //           this.videoPlayer.destroy()
  //           this.videoPlayer = null
  //         }
  //         this.$nextTick(() => {
  //           setTimeout(() => {
  //             this.videoPlayer = new HlsPlayer({
  //               id: 'welcomeVideo',
  //               url: newVal.info.livesurl,
  //               poster: newVal.info.pic,
  //               playsinline: true,
  //               fluid: true
  //             });
  //           }, 500)
  //         })
  //       }
  //     }
  //   }
  // },
  beforeDestroy() {
    if (this.videoPlayer !== null) {
      this.videoPlayer.destroy()
      this.videoPlayer = null
    }
  },
  methods: {
    sendCommentAction(){
      this.sendComment(this.comment)
      this.comment = ''
    },
    setCurrent(current){
      this.current = current
    },
    setTabCurrent(index){
      this.current = index
      index === 'history' && this.setHistoryView();
    },
    setHistoryView(){
      this.$nextTick(() => {
        let father = $('.live-history-list-pc');
        let scrollTo = $('.live-history-item-pc.on');
        father.animate({
          scrollTop: scrollTo.offset().top - father.offset().top + father.scrollTop() - 20
        }, 1000);
      })
    }
  }

}
</script>

<style scoped lang="scss">
.live-index-pc{
  .live-tabs{
    margin-bottom: 12px;
  }
  .live-info{
    .pic{
      width: 1120px;
      height: 630px;
    }

    .tab-list {
      margin-top: 40px;

      .tab-menu {
        margin: 15px 0;

        .right-btn-view {
          float: right;
          margin-top: -10px;

          .btn-item {
            margin-left: 30px;
          }
        }

        .menu-item {
          display: inline-block;
          margin-right: 44px;
          font-size: 16px;
          font-weight: 700;
          color: #999;
          line-height: 20px;
          position: relative;
          cursor: pointer;

          &.on {
            color: #fff;

            &:after {
              content: ' ';
              width: 32px;
              display: inline-block;
              position: absolute;
              bottom: -20px;
              left: 16px;
              height: 8px;
              background: linear-gradient(223deg, #FF501A, #FF9B61);
              border-radius: 4px;
            }
          }
        }
      }
      .tab-content{
        margin-top: 20px;
      }
    }
  }
  .comment-form{
    width: 380px;
    height: 36px;
    display: inline-block;
    vertical-align: top;
    margin-left: 18px;
    input{
      display: inline-block;
      border: 0;
      width: 300px;
      padding: 5px 10px;
      height: 36px;
      line-height: 36px;
      background: rgba(255,255,255,.1);
      color: rgba(255,255,255,0.8)
    }
    .submit-btn{
      display: inline-block;
      width: 80px;
      cursor: pointer;
      line-height: 36px;
      text-align: center;
      color: #fff;
      height: 36px;
      background: #EA4C1E;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
</style>
