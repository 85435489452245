<template>
  <div
    class="about-link-item-mobile-item"
  >
    <div
      @click="$emit('click')"
    >
      <div class="about-link-item-mobile">
        <pic-mobile :src="info.pic" />
      </div>
    </div>
  </div>
</template>

<script>

import PicMobile from "../../common/Pic";
export default {
  name: 'AboutLinkItemMobile',
  components: {PicMobile},
  props: {
    info: {
      type: Object,
      default(){
        return {}
      }
    }
  }
}
</script>

<style scoped lang="scss">
.about-link-item-mobile-item{
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  .about-link-item-mobile{
    display: inline-block;
    width: 64px;
    height: 64px;
  }
}
</style>
