<template>
  <div class="icon-text-location">
    <div
      class="icon"
      :style="{background: 'url('+require('../../../../assets/images/'+icon)+')', backgroundSize: '100% 100%'}"
    ></div>
    <div
      class="label"
      :style="{color: color}"
    >
      {{ label }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextLocation',
  props: {
    label: {
      type: String,
      default: () => ''
    },
    color: {
      type: String,
      default: () => '#F39478'
    },
    icon: {
      type: String,
      default: () => 'icon_position.png'
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.icon-text-location{
  height: 20px;
  white-space: nowrap;
  .icon{
    width: 14px;
    height: 14px;
    background: url("../../../../assets/images/icon_position.png") no-repeat;
    background-size: 100% 100%;
    float: left;
    margin-top: 3px;
    margin-right: 6px;
  }
  .label{
    height: 20px;
    color: #F39478;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}
</style>
