<template>
  <div
    class="icon-text-viewnum"
    :class="{dark}"
  >
    <div class="icon"></div>
    <div class="label">
      {{ label }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextViewnum',
  props: {
    label: {
      type: String,
      default: () => ''
    },
    dark: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.icon-text-viewnum{
  display: inline-block;
  width: 20px;
  .icon{
    width: 20px;
    height: 20px;
    background: url("../../../../assets/images/mobile/views.png") no-repeat;
    background-size: 100% 100%;
  }
  &.dark{
    .icon{
      width: 20px;
      height: 20px;
      background: url("../../../../assets/images/mobile/views_hei.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .label{
    height: 20px;
    color: rgba(255,255,255,0.8);
    font-size: 12px;
    white-space: nowrap;
    font-weight: 400;
    line-height: 20px;
    transform: scale(0.9);
    margin-left: -2px;
    text-align: center;
  }
}
</style>
