<template>
  <div class="about-text-item">
    <text-content-pc
      :content="$t2(lang, info.text_content_cn, info.text_content_en)"
      :title="$t2(lang, info.text_title_cn, info.text_title_en)"
      :subtitle="info.text_title_cn === '中国国际工业博览会' ? $t('60004') : ``"
      sublink="https://www.ciif-expo.com/"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import TextContentPc from "@/components/Pc/common/TextContent";

export default {
  name: 'AboutTextItem',
  components: {TextContentPc},
  props: {
    info: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  computed:  {
    ...mapState(['lang'])
  },
}
</script>

<style scoped lang="scss">
.about-text-item{
  margin-bottom: 48px;
}
</style>
