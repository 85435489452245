<template>
  <div class="exhibition-list-index-pc container min-height-770">
    <div
      v-show="searchBarFixed"
      class="searchBarZhanwei"
    ></div>
    <div
      id="searchBar"
      class="fixed-search"
      :class="searchBarFixed ? 'fixed' : ''"
    >
      <div
        class="main-box"
        :style="{paddingBottom: '20px' }"
      >
        <search-view-pc
          :change-key="changeKey"
          :change-type="changeType"
          :search="search"
          :show-select="true"
        />
        <exhibitbion-class-pc
          v-if="!searchBarFixed"
          :change-child-type-id="changeChildTypeId"
          :change-type-id="changeTypeId"
          :child-type-id="options.childTypeId"
          :child-type-list="options.childTypeList"
          :type-id="options.typeId"
          :type-list="options.typeList"
        />
      </div>
    </div>
    <div class="exhibition-ad-box" v-if="options.ads.length > 0">
      <!-- 配置slider组件 -->
      <slider ref="slider" :options="slideOptions">
        <!-- 直接使用slideritem slot -->
        <slideritem v-for="(item,index) in options.ads" :key="index" :style="item.style">
          <a :href="item.linkurl" target="_blank"><img :src="item.pic" /></a>
        </slideritem>
        <!-- 设置loading,可自定义 -->
      </slider>
    </div>
    <div
      class="main-box"
      style="min-height: 530px;"
    >
      <empty-pc
        v-if="!options.loading && parseInt(options.data.total) === 0"
        description="空空如也"
      />
      <exhibition-list-pc
        :change-page="changePage"
        :data="options.data"
      />
    </div>
  </div>
</template>

<script>
import { slider, slideritem } from 'vue-concise-slider'// import slider components

import SearchViewPc from "@/components/Pc/common/SearchView";
import ExhibitbionClassPc from "@/components/Pc/Exhibition/ExhibitionClass";
import ExhibitionListPc from "@/components/Pc/Exhibition/ExhibitionList";
import EmptyPc from "@/components/Pc/common/Empty";
export default {
  name: 'ExhibitionListIndexPc',
  components: {EmptyPc, ExhibitionListPc, ExhibitbionClassPc, SearchViewPc, slider, slideritem},
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    changeChildTypeId: {
      type: Function,
      default: () => {return () => {}}
    },
    changeTypeId: {
      type: Function,
      default: () => {return () => {}}
    },
    changePage: {
      type: Function,
      default: () => {return () => {}}
    },
    changeKey: {
      type: Function,
      default: () => {return () => {}}
    },
    changeType: {
      type: Function,
      default: () => {return () => {}}
    },
    search: {
      type: Function,
      default: () => {return () => {}}
    },
  },
  data(){
    return {
      searchBarFixed: false,
      slideOptions: {
        currentPage: 0,
        thresholdDistance:500,
        thresholdTime:100,
        autoplay: 5000,
        pagination:false,
        loop:true,
        loopedSlides:1,
        slidesToScroll:1,
        timingFunction: 'ease',
        speed: 300
      }
    }
  },
  computed:{
    ads(){
      if(this.options.ads.length > 0){
        let randomIndex = Math.floor(Math.random() * this.options.ads.length);
        return this.options.ads[randomIndex];
      }else{
        return null
      }
    }
  },
  watch: {
    options: {
      //侦听器处理函数
      handler(newVal) {
        if(newVal.ads.length > 1){
          this.slideOptions.pagination = true
        }else{
          this.slideOptions.pagination = false
        }
      },
      //使用deep开启深度监听，只要info对象中任何一个属性变化，都会触发‘对象的侦听器’
      deep: true
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll,true)
  },
  methods: {
    handleScroll(){
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      var page = document.querySelector('#app')
      if(page && document.querySelector('#searchBar')){
        scrollTop = page.scrollTop
        var offsetTop = document.querySelector('#searchBar').offsetTop
        if (scrollTop > offsetTop) {
          this.searchBarFixed = true
        } else {
          this.searchBarFixed = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.exhibition-list-index-pc{
  .searchBarZhanwei{
    height: 100px;
  }
  .fixed-search{
    width: 100%;
    z-index: 99;
    &.fixed{
      position: fixed;
      top: 0;
      left: 0;
      .main-box{
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;
        box-shadow: 0 3px 3px 0 rgba(0,0,0,0.1);
      }
    }
  }
  .exhibition-ad-box{
    margin-bottom: 10px;
  }
}
</style>
