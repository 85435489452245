<template>
  <div>
    <a
      v-if="user.tokens && !user.qrcode && !disabled"
      class="bbs-join-btn-mobile"
      :class="(`status_`+ status) + ' ' + (big ? 'big' : '')"
      :href="regurl"
      target="_blank"
      @click.stop=""
    >{{ status_str }}</a>
    <div
      v-else
      class="bbs-join-btn-mobile"
      :class="((`status_` + status) + ' ' + (big ? 'big' : '')) + ' ' + ((disabled) ? `disabled` : ``) "
      :style="{width: width + 'px', height: height + 'px', lineHeight: height + 'px'}"
      @click.stop="join"
    >
      {{ status_str }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import axios from "axios";
import SubscribeModalMobile from "../../common/SubscribeModal";

export default {
  name: 'BbsJoinBtnMobile',
  components: {SubscribeModalMobile},
  props: {
    id: {
      type: [Number, String],
      default: () => 0
    },
    width: {
      type: [Number, String],
      default: () => 434
    },
    height: {
      type: [Number, String],
      default: () => 72
    },
    big: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  data(){
    return {
      status: -1
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'user']),
    status_str(){
      if(this.disabled){
        return '无需报名'
      }
      if(this.status === -1){
        return '立即报名'
      }else{
        return '已报名'
      }
    },
    regurl(){
      return 'https://online.ciif-expo.cn/RegMethod.html?pcurl='+location.href+'&phone='+this.user.mobile
    }
  },
  activated() {
    if(this.user.tokens && this.id){
      this.getJoin()
    }
    if(!this.user.tokens){
      this.status=-1
    }
  },
  watch: {
    id:{
      immediate:true,
      deep:true,
      handler(Val, oldval){
        if(Val != oldval){
          if(this.user.tokens && this.id){
            this.getJoin()
          }
        }
      }
    }
  },
  mounted() {
    if(this.user.tokens && this.id){
      this.getJoin()
    }
  },
  methods:{
    getJoin(){
      var url = 'https://forum.ciif-expo.cn/forum/GetSelect';
      const params = {
        Token: 'EChaokj',
        ForumId: this.id,
        AudiencesCnId: this.user.mid
      }
      axios({
        method: 'post',
        url: url,
        // 利用 transformRequest 进行转换配置
        transformRequest: [
          function(oldData){
            // console.log(oldData)
            let newStr = ''
            for (let item in oldData){
              newStr += encodeURIComponent(item) + '=' + encodeURIComponent(oldData[item]) + '&'
            }
            newStr = newStr.slice(0, -1)
            return newStr
          }
        ],
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: params,
      }).then(res => {
        this.status = res.data.code
      })
    },
    join(){
      if(this.disabled || this.status == 1){
        return
      }
      if(!this.user || !this.user.tokens){
        localStorage.setItem('backUrl', this.$router.currentRoute.path)
        localStorage.setItem('backQuery', JSON.stringify(this.$router.currentRoute.query))
        this.$router.replace('/user/login')
        return
      }
      var url = 'https://forum.ciif-expo.cn/forum/Signup';
      const params = {
        Token: 'EChaokj',
        ForumId: this.id,
        AudiencesCnId: this.user.mid
      }
      axios({
        method: 'post',
        url: url,
        // 利用 transformRequest 进行转换配置
        transformRequest: [
          function(oldData){
            // console.log(oldData)
            let newStr = ''
            for (let item in oldData){
              newStr += encodeURIComponent(item) + '=' + encodeURIComponent(oldData[item]) + '&'
            }
            newStr = newStr.slice(0, -1)
            return newStr
          }
        ],
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: params,
      }).then(res => {
        this.$bus.$emit('showSub', {id: this.id, typeName: '论坛', template: '0TmvQEKhXVy3vNX3T6oWGZPMPM1awQEljfyfUvnqpwE,xEj6TvupILQrlX6PAm4D-CmLPPsRUe88c-AHALr4S5U', type: 'forum'})
        if(res.data.code === 1){
          this.status = res.data.code
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.bbs-join-btn-mobile {
  border-radius: 8px;
  cursor: pointer;
  width: 124px;
  height: 46px;
  display: inline-block;
  line-height: 52px;
  text-align: center;
  background: linear-gradient(223deg, #FC501E 0%, #FA8958 100%);
  font-size: 22px;
  color: #fff;
  &.disabled{
    background: rgba(204, 204, 204, 0.4)!important;
    color: #989898!important;
    border-radius: 8px!important;;
    filter: blur(0px)!important;;
  }
  &.big{
    width: 434px;
    height: 72px;
    line-height: 72px;
    &.status_1{
      width: 434px;
      height: 72px;
      line-height: 72px;
      background: rgba(255, 255, 255, 0.2);
      color: #fff;
    }
  }
  &.status_1{
    width: 136px;
    height: 52px;
    line-height: 52px;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
  }
}
</style>
