<template>
  <div class="bbs-date-list-mobile">
    <template v-for="(res, index) in options.date">
      <bbs-date-item-mobile
        :key="index"
        :info="res"
        :on="options.data.dateid === res.id"
        @click="changeDateAction(res.id)"
      />
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import BbsDateItemMobile from "./item";

import $ from "jquery";
export default {
  name: 'BbsDateListMobile',
  components: {BbsDateItemMobile},
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    changeDate: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'windowWidth']),
  },
  methods: {
    changeDateAction(id){
      this.changeDate(id)
      this.$nextTick(() => {
        setTimeout(() => {
          this.setTabView()
        }, 300)
      })
    },
    setTabView(){
      this.$nextTick(() => {
        let father = $('.bbs-date-list-mobile');
        let scrollTo = $('.bbs-date-item-mobile.on');
        if(scrollTo.offset().left && father.offset().left){
          father.animate({
            scrollLeft: (scrollTo.offset().left - father.offset().left + father.scrollLeft()) - (this.windowWidth / 2) + $(scrollTo).width()
          }, 0);
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.bbs-date-list-mobile {
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 20px;
  overflow-x: scroll;
}
</style>
