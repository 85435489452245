<template>
  <div class="search-video-list-pc">
    <div class="list">
      <template v-for="(res,index) in data.list">
        <video-item-pc
          :key="index"
          :from="from"
          :info="res"
          @click="showPlayer(res.videourl)"
        />
      </template>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import VideoItemPc from "@/components/Pc/common/VideoList/item";
import Vue from "vue";

export default {
  name: 'SearchVideoListPc',
  components: {VideoItemPc},
  props: {
    data: {
      type: Object,
      default: () => []
    },
    title: {
      type: String,
      default: () => ''
    },
    from: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    showPlayer(videoUrl) {
      this.$bus.$emit('showPlayer', videoUrl)
    },
  }
}
</script>

<style scoped lang="scss">
.video-list-pc{
  width: 100%;
  overflow: hidden;
  margin-bottom: 48px;
  .title{
    font-size: 24px;
    font-weight: 600;
    color: #000000;
    line-height: 32px;
  }
  .list{
    margin-top: 12px;
    width: 1136px;
  }
}
</style>
