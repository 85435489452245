<template>
  <div class="news-list-exhibition-thumb3-pc main-box-view">
    <div class="main-box-view">
      <div class="main-box-view-header"></div>
      <div class="main-box-view-middle">
        <div class="main-box-view-title">
          <span>产品介绍</span>
          <span>Product Description</span>
        </div>
        <div class="swiper-view">
          <swiper
            ref="mySwiper"
            :options="swiperOptionsTop"
          >
            <swiper-slide
              v-for="(res, index) in list"
              :key="index"
            >
              <product-item-exhibition-thumb3-pc
                :key="index"
                :companyid="options.id"
                :info="res"
              />
            </swiper-slide>
          </swiper>
          <div class="swiper-pagination-news-pc">
            <div
              v-if="list.length > 1"
              class="npage"
            >
              <div class="swiper-button-prev-product"></div>
              <div class="swiper-button-next-product"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-box-view-bottom"></div>
    </div>
  </div>
</template>

<script>

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import '@/assets/swiper.css'
import {mapState} from "vuex";
import ProductItemExhibitionThumb3Pc from "./ProductItemExhibitionThumb3";

export default {
  name: 'ProductListExhibitionThumb3Pc',
  components: {ProductItemExhibitionThumb3Pc, Swiper, SwiperSlide},
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return {
      swiperOptionsTop: {
        spaceBetween: 28,
        slidesPerView: 3,
        slidesPerColumn: 1,
        slidesPerGroup: 3,
        slidesPerColumnFill: 'row',
        navigation: {
          nextEl: ".swiper-button-next-product",
          prevEl: ".swiper-button-prev-product",
        },
      },
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.news-list-exhibition-thumb3-pc {
  margin-top: 120px!important;
  .main-box-view{
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    .main-box-view-header{
      width: 1200px;
      height: 44px;
      background: url("../../../../../assets/images/exhibition_theme_3/thumb3-box-top@2x.png");
      background-size: 100% 100%;
    }
    .main-box-view-middle{
      width: 1200px;
      padding: 40px;
      padding-right: 60px;
      background: url("../../../../../assets/images/exhibition_theme_3/thumb3-box-bg@2x.png");
      background-size: 100% auto;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      position: relative;
      .main-box-view-title{
        background: url("../../../../../assets/images/exhibition_theme_3/thumb3-title-bg@2x.png");
        background-size: 100% auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 268px;
        height: 60px;
        margin-left: 50%;
        transform: translateX(-50%);
        font-size: 24px;
        color: #D80C1E;
        line-height: 30px;
        margin-bottom: 57px;
        span{
          &:last-child{
            font-size: 20px;
          }
        }
      }
      .swiper-pagination-news-pc{
        .npage{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 56px;
          margin-top: 48px;
          .swiper-button-next-product{
            position: static;
            &:after{
              display: none!important;
            }
            width: 40px;
            height: 40px;
            background: url("../../../../../assets/images/exhibition_theme_3/icon_right_2@2x.png");
            background-size: 100% 100%;
            margin-left: 8px;
            margin-top: 0!important;
            &:hover{
              background: url("../../../../../assets/images/exhibition_theme_3/icon_right_3@2x.png")!important;
              background-size: 100% 100%!important;
            }
          }
          .swiper-button-prev-product{
            position: static;
            &:after{
              display: none!important;
            }
            width: 40px;
            height: 40px;
            background: url("../../../../../assets/images/exhibition_theme_3/icon_left_2@2x.png");
            background-size: 100% 100%;
            margin-top: 0!important;
            &:hover{
              background: url("../../../../../assets/images/exhibition_theme_3/icon_left_3@2x.png")!important;
              background-size: 100% 100%!important;
            }
          }
        }
      }
    }
    .main-box-view-bottom{
      width: 1220px;
      height: 44px;
      background: url("../../../../../assets/images/exhibition_theme_3/thumb3-box-bottom@2x.png");
      background-size: 100% 100%;
      background-position: -10px;
    }
  }
  .list{
    background: #fff;
    padding: 40px 40px 0 40px;
    border-radius: 0 0 28px 28px;
  }
}
</style>
