<template>
  <div class="exhibition-class-pc clearfix">
    <div class="left-text">
      {{ $t('20001') }}：
      <p v-if="typeId > 0">
        {{ $t('10023') }}：
      </p>
    </div>
    <div class="right-box">
      <div
        class="types "
        :class="typeId > 0 ? `border-bottom` : ``"
      >
        <template v-for="(res, key) in typeList">
          <tabs-item-pc
            :key="key"
            :bold="true"
            font-size="14px"
            :label="$t2(lang, res.typename, res.typename_en)"
            :on="res.id === typeId"
            @click="changeTypeId(res.id)"
          />
        </template>
      </div>
      <div
        v-if="typeId>0"
        class="types"
      >
        <template v-for="(res, key) in childTypeList">
          <tabs-item-pc
            :key="key"
            :bold="true"
            font-size="14px"
            :label="$t2(lang, res.typelistname, res.typelistname_en)"
            :on="res.id === childTypeId"
            @click="changeChildTypeId(res.id)"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TabsItemPc from "@/components/Pc/common/TabsItem";

export default {
  name: 'ExhibitbionClassPc',
  components: {TabsItemPc},
  props: {
    typeList: {
      type: Array,
      default: () => []
    },
    childTypeList: {
      type: Array,
      default: () => []
    },
    childTypeId: {
      type: [String, Number],
      default: () => 0
    },
    typeId: {
      type: [String, Number],
      default: () => 0
    },
    changeChildTypeId: {
      type: Function,
      default: () => {return () => {}}
    },
    changeTypeId: {
      type: Function,
      default: () => {return () => {}}
    },
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.exhibition-class-pc{
  width: 100%;
  .left-text{
    float: left;
    width: 60px;
    text-align: right;
    height: 40px;
    line-height: 40px;
    color: #333;
    position: relative;
    p{
      width: 100px;
      position: absolute;
      left: -40px;
      top: 76px;
    }
  }
  .right-box{
    padding: 12px 20px;
    margin-left: 60px;
    background: #FAFAFA;
    .types{
      width: 100%;
      //white-space: nowrap;
      overflow-x: scroll;
      overflow-y: auto;
      //height: 24px;
      margin-bottom: 12px;
      &.border-bottom{
        //height: 36px;
        border-bottom: 1px solid rgba(255,255,255,0.1);
        padding-bottom: 12px;
      }
      &:last-child{
        margin-bottom: 0;
        padding-bottom: 12px;
      }
    }
  }
}
</style>
