<template>
  <div
    class="about-link-item"
  >
    <a
      v-if="info.linkurl"
      :href="info.linkurl"
      target="_blank"
      @mouseleave="show=false"
      @mouseover="show=true"
    >
      <pic-pc
        border-radius="10"
        :src="info.pic"
      />
      <div
        v-show="show"
        class="erweima-view"
      >
        <div
          ref="qrCodeUrl"
          class="qrcode"
        >
          <img :src="info.pic_ewm">
        </div>
      </div>
    </a>
    <div
      v-else
      @mouseleave="show=false"
      @mouseover="show=true"
    >
      <pic-pc :src="info.pic" />
      <div
        v-show="show"
        class="erweima-view"
      >
        <div
          ref="qrCodeUrl"
          class="qrcode"
        >
          <img :src="info.pic_ewm">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import PicPc from "@/components/Pc/common/Pic";
import QRCode from 'qrcodejs2'
export default {
  name: 'AboutLinkItem',
  components: {PicPc},
  props: {
    info: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  data(){
    return {
      show: false,
    }
  },
}
</script>

<style scoped lang="scss">
.about-link-item{
  display: inline-block;
  position: relative;
  margin-right: 28px;
    width: 40px;
    height: 40px;

  .erweima-view{
    position: absolute;
    left: -52px;
    bottom: 58px;
    width: 138px;
    height: 138px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    .qrcode{
      width: 104px;
      height: 104px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .icon-logo{
      width: 30px;
      height: 30px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -13px;
      margin-top: -13px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      .img{
        width: 26px;
        height: 26px;
        background: url("../../../../assets/images/logo_ico.png");
        background-size: 100% 100%;
      }
    }
  }
  .sanjiao{
    position: absolute;
    top: -10px;
    right: 10px;
    z-index: 3;
    width: 0;
    height: 0;
    border-bottom: 6px solid white;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }
}
</style>
