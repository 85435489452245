<template>
  <div
    class="live-history-list-pc"
  >
    <template v-for="(res, index) in (options.history || options.liveList)">
      <live-history-item-pc
        :key="index"
        :change-live-id="changeLiveId"
        :info="res"
        :options="options"
      />
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import LiveHistoryItemPc from "./item";

export default {
  name: 'LiveHistoryListPc',
  components: {LiveHistoryItemPc},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeLiveId: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.live-history-list-pc {
  max-height: 800px;
  overflow-y: auto;
}
</style>
