<template>
  <div class="icon-text-vr">
    <div class="icon"></div>
    <div class="label">
      {{ $t('10012') }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextVr',
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.icon-text-vr{
  width: 32px;
  text-align: center;
  display: inline-block;
  .icon{
    width: 32px;
    height: 32px;
    background: url("../../../../assets/images/pc_vr.png") no-repeat;
    background-size: 100% 100%;
    margin-top: 3px;
    display: block;
  }
  .label{
    display: block;
    height: 14px;
    text-align: center;
    color: #EA4C1E;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    white-space: nowrap;
    transform: scale(0.8);
    margin-left: -2px;
  }
}
</style>
