<template>
  <div
    class="exhibition-item-mobile"
    @click="navigateTo('/exhibition/info?id='+info.id)"
  >
    <div
      v-if="parseInt(info.is_hot) === 1"
      class="tuijian"
    >
      {{ $t('50005') }}
    </div>
    <div class="pic">
      <pic-mobile :src="info.logoimageurl" />
    </div>
    <div class="info">
      <div class="title line-2">
        <div v-html="companyname">
        </div>
        <icon-text-vr-mobile v-if="info.vr" />
      </div>
      <div class="tags line-1">
        <template v-for="(res, index) in cateName">
          <tags-item-mobile
            v-if="index < 3"
            :key="index"
            :dark="true"
            :info="res"
          />
        </template>
      </div>
      <div class="label">
        <icon-text-location-mobile
          :label="$t2(lang, info.address, info.address_en)"
          :style="{maxWidth: from==='user' ? '350px' : '100%'}"
        />
        <icon-text-collect-mobile
          v-if="from==='user'"
          :id="info.id"
          class="item"
          color="#c2c2c2"
          font-size="20px"
          label=""
          :set-collect="setCollect"
          :shoucang="parseInt(info.is_cang) === 1"
          :type="4"
          width="32px"
        />
      </div>
      <div class="summary line-2">
        <div v-html="replaceHtmlTag(description)" />
        <div class="more">
          {{ $t('50017') }}>>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "@/components/Mobile/common/Pic";
import IconTextLocationMobile from "@/components/Mobile/common/IconText/location";
import IconTextVrMobile from "@/components/Mobile/common/IconText/vr";
import TagsItemMobile from "../../common/TagsItem";
import IconTextCollectMobile from "../../common/IconText/collect";

export default {
  name: 'ExhibitionItemMobile',
  components: {TagsItemMobile, IconTextVrMobile, IconTextLocationMobile, PicMobile, IconTextCollectMobile},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    keyword: {
      type: String,
      default: () => ''
    },
    from: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    companyname(){
      return this.replaceSearch(this.lang, this.keyword, this.info.companyname, this.info.companynameen)
    },
    description() {
      return this.replaceSearch(this.lang, this.keyword, this.info.content, this.info.content_en)
    },
    cateName(){
      let cat = this.info.cateName
      if(cat && cat.length > 0){
        cat.sort((a, b) => a.typelistname.length - b.typelistname.length)
      }
      return cat
    }
  },
  methods: {
    setCollect(v){
      this.info.is_cang = v
    }
  }
}
</script>

<style scoped lang="scss">
.exhibition-item-mobile {
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: self-start;
  background: rgba(255, 255, 255, 0.1000);
  margin-bottom: 20px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;

  .tuijian{
    width: 92px;
    height: 80px;
    background: url("../../../../assets/images/tuijian_bg.png");
    background-size: 100% 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    padding-top: 10px;
    padding-left: 4px;
    font-size: 24px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 28px;
  }
  .pic{
    width: 200px;
    height: 200px;
    border-radius: 12px;
    overflow: hidden;
    background-color: #f5f7fa;
  }
  .info{
    flex: 1;
    margin-left: 24px;
    width: 232px;
    .title{
      font-size: 32px;
      font-weight: 500;
      color: #fff;
      line-height: 44px;
      height: 88px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
    .label{
      margin-top: 12px;
      width: 400px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .tags{
      height: 32px;
    }
    .summary{
      font-size: 22px;
      margin-top: 8px;
      font-weight: 500;
      color: #999999;
      position: relative;
      height: 64px;
      line-height: 32px;

      .more{
        position: absolute;
        font-size: 22px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.7);
        right: 0;
        bottom: 0;
        background: #333;
        &:before{
          content: ' ';
          width: 40px;
          height: 32px;
          background: linear-gradient(to right, rgba(0,0,0,0), #333);
          position: absolute;
          left: -40px;
          top:0;
        }
      }
    }
  }
}
</style>
