<template>
  <div
    class="user-schedule-activity-list-item-pc clearfix"
    @click="navigateTo('/activity/info?id='+info.id)"
  >
    <div class="pic">
      <pic-pc :src="info.pic" />
    </div>
    <div class="info">
      <div class="title-line">
        <div class="title line-1">
          <span v-html="$t2(lang, info.title, info.title_en)"></span>
        </div>
        <div class="title-en line-1">
          <span v-html="$t2(lang, info.title_en, info.title)"></span>
        </div>
      </div>

      <div class="tags line-1">
        <tag-item
          class="item"
          :dark="true"
          :label="$t2(lang, info.catename.name, info.catename.ename)"
        />
      </div>
      <div class="other">
        <icon-time-line
          class="item"
          icon="icon_clock.png"
          :text="timeFormat(info.start_time, 'yyyy-mm-dd hh:MM') + '-' + timeFormat(info.end_time, 'hh:MM')"
        />
        <div class="location-line">
          <icon-time-line
            class="item"
            icon="icon_position.png"
            :text="$t2(lang, info.cn_address.toString(), info.en_address.toString())"
          />
          <div
            v-if="from === 'schedule'"
            class="right"
          >
            <icon-text-schedule-del
              :id="info.id"
              class="item"
              label=""
              :refresh="refresh"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import TagItem from "@/components/Pc/common/TagItem";
import IconTimeLine from "@/components/Pc/common/IconTimeLine";
import {mapState} from "vuex";
import PicPc from "@/components/Pc/common/Pic";
import IconTextDel from "../../common/IconText/del";
import IconTextScheduleDel from "../../common/IconText/scheduledel";
export default {
  name: 'UserScheduleActivityListItemPc',
  components: {IconTextScheduleDel, PicPc, IconTimeLine, TagItem},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    from: {
      type: String,
      default: () => ''
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    setCollect(v){
      this.info.is_cang = v
    }
  }
}
</script>

<style scoped lang="scss">
.user-schedule-activity-list-item-pc{
  margin-bottom: 20px;
  transition: all 0.4s ease-in-out;
  background: rgba(255,255,255,0.1);
  &:hover{
    //box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  }
  .pic{
    width: 320px;
    height: 180px;
    overflow: hidden;
    float: left;
  }
  .info{
    margin-left: 354px;
    padding-top: 16px;
    .title-line{
      position: relative;
      .title{
        font-size: 20px;
        font-weight: 600;
        color: rgba(255,255,255,0.8);
        line-height: 24px;
        height: 24px;
      }
      .title-en{
        font-size: 16px;
        font-weight: 600;
        color: rgba(255,255,255,0.8);
        line-height: 20px;
        height: 20px;
      }
    }
    .tags{
      margin-top: 16px;
      height: 24PX;
      .item{
        margin-right: 4px
      }
    }
    .other{
      margin-top: 14px;
      .location-line{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .right{
          margin-right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .item{
        margin-top: 2px;
      }
    }
  }
}
</style>
