<template>
  <div
    class="awards-list-item-mobile"
    @click="navigateTo('/awards/info?id='+info.id)"
  >
    <div class="pic radius">
      <pic-mobile :src="info.pic" />
    </div>
    <div class="info">
      <div
        class="title"
        v-html="title"
      >
      </div>
      <div class="other">
        <div>
          <icon-text-line-mobile
            v-if="info.catename"
            color="#F39478"
            icon="jiang_hei.png"
            :text="$t2(lang, info.catename.name, info.catename.ename)"
          />
          <div class="name">
            {{ $t2(lang, info.companyname, info.companynameen) }}
          </div>
        </div>
        <icon-text-collect-mobile
          v-if="from==='user'"
          :id="info.id"
          class="item"
          color="#c2c2c2"
          font-size="20px"
          label=""
          :set-collect="setCollect"
          :shoucang="parseInt(info.is_cang) === 1"
          :type="2"
          width="32px"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "../../common/Pic";
import IconTextLineMobile from "../../common/IconTextLine";
import IconTextCollectMobile from "../../common/IconText/collect";

export default {
  name: 'AwardsListItemMobile',
  components: {IconTextCollectMobile, IconTextLineMobile, PicMobile},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    keyword: {
      type: String,
      default: () => ''
    },
    from:{
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    title(){
      return this.replaceSearch(this.lang, this.keyword, this.info.title, this.info.title_en)
    },
  },
  mounted() {
    this.info.is_cang = 1;
  },
  methods: {
    setCollect(v){
      this.info.is_cang = v
    }
  }
}
</script>

<style scoped lang="scss">
.awards-list-item-mobile {
  display: flex;
  padding: 24px;
  background: rgba(255,255,255,0.1);
  margin-bottom: 20px;
  border-radius: 12px;
  overflow: hidden;
  .pic{
    width: 200px;
    height: 200px;
    border-radius: 12px;
    overflow: hidden;
  }
  .info{
    flex: 1;
    margin-left: 24px;
    .title{
      font-size: 32px;
      font-weight: 500;
      color: #fff;
      line-height: 44px;
    }
    .other{
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name{
        margin-top: 10px;
        font-size: 22px;
        font-weight: 500;
        color: rgba(255,255,255,0.8);
        line-height: 32px;
      }
    }
  }
}
</style>
