<template>
  <div class="topic-cover-default">
    <topic-cover-mobile v-if="browser===2" />
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopicCoverMobile from "../../Mobile/Topic/cover";

export default {
  name: 'TopicCoverDefault',
  components: {TopicCoverMobile},
  data() {
    return {

    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    if(this.$route.query.typeid){
      this.options.typeid = this.$route.query.typeid
    }
    this.defaultOptions = this.deepClone(this.options)
    this.init()
    this.setShare()
  },
  methods: {
    init(){

    },
    setShare(){
      this.getWxConfig(this.$t('10009') + this.bankuai(), this.$t('10009') + this.bankuai())
    }
  }

}
</script>

<style scoped>
.topic-cover-default{

}
</style>
