<template>
  <div class="topic-list-mobile">
    <template v-for="(res, index) in data.list">
      <topic-list-item-mobile
        :key="index"
        :info="res"
        :keyword="data.key"
      />
    </template>
    <page-common-mobile
      :change-page="changePage"
      :data="data"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopicListItemMobile from "./item";
import PageCommonMobile from "../../common/PageCommon";

export default {
  name: 'TopicListMobile',
  components: {PageCommonMobile, TopicListItemMobile},
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    changePage: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.topic-list-mobile {

}
</style>
