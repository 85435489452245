<template>
  <div class="rank-list-mobile">
    <template v-for="(res, index) in list">
      <rank-list-item-mobile
        :key="index"
        :field="field"
        :info="res"
        :rank="index+1"
        @click="goInfo(res)"
      />
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import RankListItemMobile from "./item";

export default {
  name: 'RankListMobile',
  components: {RankListItemMobile},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    field: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: () => 'listField'
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    url(){
      switch(this.type){
        case 'hotList':

          return '/'
        case 'informationList':
          return '/news/info?id='
        case 'bbsList':
          return '/forum/info?id='
        case 'zhanList':
          return '/exhibition/info?id='
      }
      return '';
    }
  },
  methods:{
    goInfo(res){
      if(this.type === 'hotList'){
        const id = res.is_value
        switch(res.type){
          case '展览':
            this.navigateTo('/exhibition/info?id='+id)
            break;
          case '产品':
            this.navigateTo('/exhibition/productinfo?companyid='+res.companyid+'&id='+id)
            break;
          case '资讯':
            this.navigateTo('/news/info?id='+id)
            break;
          case '案例':
            this.navigateTo('/case/info?id='+id)
            break;
          case '评奖':
            this.navigateTo('/awards/info?id='+id)
            break;
          case '论坛':
            this.navigateTo('/forum/info?id='+id)
            break;
          case '活动':
            this.navigateTo('/activity/info?id='+id)
            break;
          case '专题':
            this.navigateTo('/topic?typeid='+id)
            break;
          case '视频':
            this.$bus.$emit('showPlayer', res.videourl)
            break;
        }
      }else{
        this.navigateTo(this.url+res.id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.rank-list-mobile {
  background: #F8F8FA;
  border-radius: 12px;
  margin-top: 28px;
  margin-left: 10px;
  margin-right: 10px;
  overflow: hidden;
}
</style>
