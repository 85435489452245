<template>
  <div
    class="live-comment-item-mobile"
    :class="parseInt(info.mid) === user.mid ? `on` : ``"
  >
    <div class="avatar">
      <img src="../../../../assets/images/live_avatar.png" />
    </div>
    <div class="info">
      <div class="name-line">
        <div class="nickname">
          {{ info.name }}
        </div>
        <div class="time">
          {{ info.addtime }}
        </div>
      </div>
      <div class="content">
        {{ info.comment }}
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'LiveCommentItemMobile',
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'user']),
  },
}
</script>

<style scoped lang="scss">
.live-comment-item-mobile {
  margin-bottom: 40px;
  &:after{
    content: '';
    width: 0;
    height: 0;
    clear: both;
    display: block;
    overflow: hidden;
  }
  .avatar{
    width: 64px;
    height: 64px;
    background: #fafafa;
    border-radius: 64px;
    float: left;
    & img{
      width: 100%;
      height: 100%;
    }
  }
  .info{
    padding-left: 80px;
    .name-line{
      .nickname{
        font-size: 24px;
        font-weight: 500;
        color: #666666;
        line-height: 14px;
        display: inline-block;
      }
      .time{
        font-size: 24px;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
        display: inline-block;
        margin-left: 12px;
      }
    }
    .content{
      font-size: 24px;
      background: #fff;
      border-radius: 0 8px 8px 8px;
      padding: 20px;
      margin-top: 12px;
      display: inline-block;
    }
  }
  &.on{
    .content{
      background: linear-gradient(223deg, #FF511A 0%, #FF7F34 100%);
      color: #fff;
    }
  }
}
</style>
