<template>
  <div
    class="exhibition-index-mobile"
  >
    <div
      v-if="false"
      class="bg-video"
    >
      <canvas
        id="c-1"
        height="1334"
        width="750"
      ></canvas>
    </div>
    <top-nav-mobile>
      <span class="nav-title">{{ $t('10002') }}</span>
    </top-nav-mobile>
    <div class="layout">
      <div
        class="z1 zl_pic rel"
        :style="{boxShadow: ('0 4px 20px 0 '+list[current].color)}"
      >
        <template v-for="(res, index) in list">
          <a
            :key="index"
            class="db rel"
            :class="index===currentClass ? `on` : ``"
            href="javascript:void(0)"
            :style="{display: (index === current || index === currentLast) ? 'block' : 'none', boxShadow: (index === currentClass) ? ('0 4px 20px 0 '+res.color) : ''}"
            @click="navigateTo('/Exhibition/list?typeid='+res.id)"
          >
            <div class="picl">
              <img
                alt=""
                class="db"
                :src="res.smallimg1"
              >
            </div>
            <div class="picr">
              <img
                alt=""
                class="db"
                :src="res.smallimg4"
              >
            </div>
            <div class="picc">
              <img
                alt=""
                class="db"
                :src="res.smallimg2"
              >
            </div>
            <div
              class="picp"
              :style="{transform: index === currentClass ? `scale(1)` : `scale(0)`, opacity: index === currentClass ? 1 : 0}"
            >
              <img
                alt=""
                class="db"
                :src="res.smallimg3"
              >
            </div>
          </a>
        </template>
      </div>
      <div class="bot z1">
        <div class="swiper-container swiper-container-horizontal">
          <div class="swiper-wrapper">
            <template v-for="(res, index) in list">
              <div
                :key="index"
                class="swiper-slide swiper-slide-active"
                :style="{width: index === current ? `140px` : `120px`}"
              >
                <div
                  class="item trans"
                  :class="index === current ? `on` : ``"
                  data-id="1"
                  @click="showSwiper(index)"
                >
                  <img
                    alt=""
                    class="db"
                    :src="res.smallimg"
                    :style="{boxShadow: index === current ? '0 4px 20px 0 '+res.color : ''}"
                  >
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed-menu">
      <template v-for="(res, index) in fixedMenu">
        <fixed-menu-item-mobile
          :key="index"
          :info="res"
          @click="res.click"
        />
        <split-line-mobile
          v-if="index < fixedMenu.length - 1"
          :key="`split`+index"
          bg-color="rgba(234, 76, 30, .1)"
          height="1px"
        />
      </template>
    </div>
    <announcement-mobile
      v-if="announcementShow"
      :hide-announcement="hideAnnouncement"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import frame_ani from '@/common/frame-animation'
import FixedMenuItemMobile from "@/components/Mobile/Exhibition/FixedMenuItem";
import SplitLineMobile from "@/components/Mobile/common/SplitLine";
import AnnouncementMobile from "@/components/Mobile/common/Announcement";
import $ from 'jquery'
import TopNavMobile from "../../../components/Mobile/common/TopNav";
export default {
  name: 'ExhibitionIndexMobile',
  components: {TopNavMobile, AnnouncementMobile, SplitLineMobile, FixedMenuItemMobile},
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return {
      announcementShow: false,
      current: 0,
      currentClass: 0,
      currentLast: 0,
      timer: null,
      timer1: null,
      timera: null,
      fixedMenu: [
        {
          title: '10013',
          icon: require('../../../assets/images/anhei/mobile/exhibition_icon_gongxu.png'),
          click: () => {
            this.navigateTo('/supplydemand')
          }
        },
        {
          title: '10012',
          icon: require('../../../assets/images/anhei/mobile/exhibition_icon_vr.png'),
          click: () => {
            window.location.href = 'http://virtual_vr.ciif-expo.com//tour/b7da61693c02c352'
          }
        },
        {
          title: '10014',
          icon: require('../../../assets/images/anhei/mobile/exhibition_icon_paihang.png'),
          click: () => {
            this.navigateTo('/rank')
          }
        },
        {
          title: '10019',
          icon: require('../../../assets/images/mobile/announcement.png'),
          click: () => {this.showAnnouncement()}
        },
      ],
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  beforeDestroy() {
    this.timer && clearTimeout(this.timer)
    this.timer1 && clearTimeout(this.timer1)
    this.timera && clearTimeout(this.timera)
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    init(){
      var framesUrl = [];
      for (let i = 1; i < 126; i++) {
        framesUrl.push('https://virtualobs.ciif-expo.com/animation/frame-' + this.padding4(i, 5) + '.png');
      }
      this.showSwiper(0)
      // frame animation
      // setTimeout(() => {
      //   var ani = new frame_ani({
      //     canvasTargetId: "c-1", // target canvas ID
      //     framesUrl: framesUrl, // frames url
      //     loop: true, // if loop
      //     height: wh, // source image's height (px)
      //     width:ww, // source image's width (px)
      //     frequency: 20, // count of frames in one second
      //     audioIonName: "bgm_1", // ion.sound audio name
      //     onComplete: function () { // complete callback
      //       // console.log("Animation loop.");
      //     },
      //   });
      //   this.$nextTick(() => {
      //     ani.initialize(() => {
      //       ani.play();
      //     });
      //   })
      // }, 500)
      // preload & play
    },
    showSwiper(index)  {
      clearTimeout(this.timer)
      clearTimeout(this.timer1)
      clearTimeout(this.timera)
      this.currentLast = JSON.parse(JSON.stringify(this.current))
      this.timer = setTimeout(() => {
        this.currentLast = index
      }, 700)
      this.current = index
      this.timer1 = setTimeout(() => {
        this.currentClass = index
        this.$nextTick(() => {
          if($('.swiper-wrapper .swiper-slide .on').length > 0){
            const itemleft = $('.swiper-wrapper .swiper-slide .on').parent().position().left;
            const itemscrollleft = $('.swiper-wrapper').scrollLeft();
            $('.swiper-wrapper').scrollLeft(itemscrollleft + itemleft - 270)
          }
        })
      }, 500)
      this.startSwiper()
    },
    startSwiper(){
      this.timera = setTimeout(() => {
        let curI = this.current + 1
        if(curI >= this.list.length){
          curI=0;
        }
        this.showSwiper(curI)
      },5000)
    },
    showAnnouncement() {
      this.announcementShow = true
    },
    hideAnnouncement() {
      this.announcementShow = false
    }
  }
}
</script>

<style scoped lang="scss">
.exhibition-index-mobile {
  width: 100%;
  height: 100%;
  .bg-video{
    canvas{
      width:100%;
      height: 100%;
      position: fixed;
      top:0;
      left:0;
      -webkit-transform:scale(1.3);
      transform:scale(1.3);
      -webkit-transform-origin: left top;
      transform-origin: left top;
    }
  }
  .rel{
    position: relative;
  }
  .swiper-slide{
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .layout{
    width: 750px;
    background-size: cover!important;
    padding-top: 65px;
    height: 100%;
    padding-bottom: 60px;
    position: fixed;
    top: 0;
    left: 50%;
    margin-left: -375px;
    overflow: hidden;

    .zl_pic {
      width: 486px;
      height: 896px;
      position: relative;
      margin: 65px auto 0;
      -webkit-transform: scale(.85);
      transform: scale(.85);
      border-radius: 15px;
      overflow: hidden;
    }
    .zl_pic a {
      width: 486px;
      height: 896px;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 15px;
      overflow: hidden;
      display: none;
    }
    .zl_pic a:nth-child(1) {
      display: block;
    }
    .zl_pic a.on .picl {
      top: 0;
      opacity: 1;
    }
    .zl_pic a.on .picr {
      bottom: 0;
      opacity: 1;
    }
    .zl_pic a.on .picc {
      opacity: 1;
      -webkit-transition-delay: 0.6s;
      transition-delay: 0.6s;
    }
    .zl_pic a.on .picp {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    .zl_pic a .picl {
      position: absolute;
      -webkit-transition: all 0.7s ease-in-out;
      transition: all 0.7s ease-in-out;
      top: -150px;
      left: 0;
      width: 486px;
      height: 258px;
      z-index: 3;
      opacity: 0;
    }
    .zl_pic a .picl img {
      width: 486px;
    }
    .zl_pic a .picr {
      position: absolute;
      -webkit-transition: all 0.7s ease-in-out;
      transition: all 0.7s ease-in-out;
      bottom: -150px;
      right: 0;
      opacity: 0;
      width: 486px;
      box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.1);
      height: 500px;
      z-index: 1;
    }
    .zl_pic a .picr img {
      width: 486px;
    }
    .zl_pic a .picc {
      position: absolute;
      -webkit-transition: all 0.7s ease-in-out;
      transition: all 0.7s ease-in-out;
      top: 112px;
      left: 0;
      width: 486px;
      height: 386px;
      box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.1);
      opacity: 0;
    }
    .zl_pic a .picc img {
      width: 486px;
    }
    .zl_pic a .picp {
      position: absolute;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      top: 10px;
      left: 0;
      width: 486px;
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 0;
      -webkit-transition-delay: 0.2s;
      transition-delay: 0.2s;
      z-index: 2;
    }
    .zl_pic a .picp img {
      display: block;
      margin: 0 auto;
    }
    .bot {
      margin-top: 0;
      overflow-x: scroll;
      height: 160px;
      .swiper-wrapper{
        white-space: nowrap;
        overflow-x: scroll;
        height: 100%;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
      }
      .swiper-container{
        height: 100%;
      }
    }
    .bot .item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 160px;
      -webkit-transform: scale(0.7);
      transform: scale(0.7);
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      margin: 0 auto;
    }
    .bot .item.on {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    .bot img {
      width: 120px;
      height: 120px;
      border-radius: 10px;
      overflow: hidden;
    }
    .bot1 {
      margin-top: 20px;
    }
  }
  .fixed-menu{
    position: absolute;
    border: 1px solid rgba(255, 216, 204, 1);
    background: rgba(255,255,255,0.8);
    padding: 10px;
    overflow: hidden;
    right: 10px;
    top: 50%;
    transform: translateY(-80%);
    width: 88px;
    z-index: 199;
    border-radius: 10px;
    text-align: center;
  }
}
</style>
