<template>
  <div class="awards-info-pc">
    <div class="container">
      <div class="main-box min-height-770">
        <back-btn-pc backurl="/Awards" />
        <div class="info">
          <div class="title">
            {{ $t2(lang, options.info.title, options.info.title_en) }}
          </div>
          <div class="title-en">
            {{ $t2(lang, options.info.title_en, options.info.title) }}
          </div>
          <div class="cat clearfix">
            <div class="left">
              <icon-time-line
                color="#F39478"
                icon="jiang.png"
                :margin-right="4"
                :margin-top="10"
                :size="16"
                :text="$t('23001')"
              />
            </div>
            <div class="btn-view">
              <icon-text-collect
                :id="options.info.id"
                class="btn-item"
                :label="this.$t('50024')"
                :set-collect="setCollect"
                :shoucang="parseInt(options.info.is_cang) === 1"
                :type="2"
              />
              <icon-text-zan
                :id="options.info.id"
                class="btn-item"
                :label="options.info.zan"
                :set-zan="setZan"
                :type="2"
                :zan="parseInt(options.info.is_zan) === 1"
              />
              <icon-text-share
                class="btn-item"
                :label="$t('50016')"
              />
            </div>
          </div>
          <div class="desc">
            <div class="desc-item thin">
              <div class="title">
                {{ $t('23111') }}:
              </div>
              <div class="text">
                <p>{{ $t2(lang, options.info.companyname, options.info.companynameen) }}</p>
                <p>{{ $t2(lang, options.info.companynameen, options.info.companyname) }}</p>
              </div>
            </div>
            <div class="desc-item">
              <div class="title">
                {{ $t('23112') }}:
              </div>
              <div class="text">
                <p>{{ $t2(lang, options.info.diqu, options.info.diqu_en) }}</p>
              </div>
            </div>
            <div class="desc-item">
              <div class="title">
                {{ $t('23113') }}:
              </div>
              <div class="text">
                <p>{{ $t2(lang, options.info.zhanwei, options.info.zhanwei_en) }}</p>
              </div>
            </div>
          </div>
        </div>
        <split-line-pc
          bg-color="rgba(255, 255, 255, 0.1)"
          height="1px"
          margin-bottom="20px"
          margin-top="20px"
        />
        <div class="pic">
          <img :src="options.info.pic" />
        </div>
        <div class="info-content">
          <text-content-pc
            :content="$t2(lang, options.info.content, options.info.content_en)"
            title=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import BackBtnPc from "@/components/Pc/common/BackBtn";
import IconTimeLine from "@/components/Pc/common/IconTimeLine";
import IconTextCollect from "@/components/Pc/common/IconText/collect";
import IconTextZan from "@/components/Pc/common/IconText/zan";
import IconTextShare from "@/components/Pc/common/IconText/share";
import TextContentPc from "@/components/Pc/common/TextContent";
import SplitLinePc from "@/components/Pc/common/SplitLine";

export default {
  name: 'AwardsInfoPc',
  components: {TextContentPc,  IconTextShare, IconTextZan, IconTextCollect, IconTimeLine, BackBtnPc, SplitLinePc},
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    setZan: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.awards-info-pc{
  .info{
    margin-top: 20px;
    .title{
      font-size: 20px;
      font-weight: 600;
      color: rgba(255,255,255,0.8);
      line-height: 24px;
    }
    .title-en{
      font-size: 16px;
      font-weight: 600;
      color: rgba(255,255,255,0.8);
      line-height: 20px;
      margin-top: 8px;
    }
    .cat{
      margin-top: 16px;
      .left{
        float: left;
        height: 40px;
        line-height: 40px;
      }
      .btn-view{
        float: right;
        .btn-item{
          margin-left: 35px;
        }
      }
    }
    .desc{
      margin-top: 8px;
      background: rgba(255, 255, 255, 0.1000);
      padding: 16px 20px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(255,255,255,0.8);
      line-height: 18px;
    }
    .desc-item{
      margin-bottom: 8px;
      .title{
        line-height: 18px;
        font-size: 14px;
        color: rgba(255,255,255,0.6);
        display: inline-block;
        vertical-align: top;
        p{
          margin-top: 2px;
        }
      }
      &.thin{
        .title, .text {
          font-weight: 400 !important;
        }
      }
      .text{
        display: inline-block;
        margin-left: 10px;
      }
    }
  }
  .pic{
    width: 100%;
    img{
      width: 100%;
    }
  }
  .info-content{
    margin-top: 20px;
    .pic{
      width: 100%;
      text-align: center;
      img{
        max-width: 100%;
      }
    }
  }
}
</style>
