<template>
  <div
    class="slider-index-pc"
  >
    <div id="welcomeVideo"></div>
    <div
      class="tiaoguo"
      @click="tiaoguo"
    >
    </div>
    <div
      class="tiaoguo1"
      @click="cancelmuted"
    >
      <div
        class="music"
        :class="{guanbi: muted}"
      ></div>
    </div>
    <div
      v-if="!playing"
      class="play-btn"
      style="display: none"
      @click="myVideo.play()"
    >
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import $ from 'jquery'
import HlsJsPlayer from 'xgplayer-hls.js';

import Player from 'xgplayer';
export default {
  name: 'SliderIndexPc',
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    stopplay: {
      type: Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      myVideo: null,
      muted: true,
      menu: [
        {
          title: '展览',
          icon: require('../../../assets/images/pc_slider_icon_1.png'),
          url: '/Exhibition'
        },
        {
          title: '论坛',
          icon: require('../../../assets/images/pc_slider_icon_2.png'),
          url: '/Forum'
        }  ,
        {
          title: '直播间',
          icon: require('../../../assets/images/pc_slider_icon_3.png'),
          url: '/Live'
        }  ,
        {
          title: '评奖',
          icon: require('../../../assets/images/pc_slider_icon_4.png'),
          url: '/Awards'
        }
      ],
      playing: false,
      video: false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  beforeCreate: function () {
    document.getElementsByTagName('body')[0].className = 'index-donghua'
  },
  beforeRouteEnter: function(){
    document.getElementsByTagName('body')[0].className = 'index-donghua'
  },
  activated: function(){
    document.getElementsByTagName('body')[0].className = 'index-donghua'
  },
  beforeDestroy: function(){
    if (this.vantaEffect) {
      this.vantaEffect.destroy()
    }
    // document.getElementsByTagName('body')[0].className = ''
  },
  beforeRouteLeave: function(){
    // document.getElementsByTagName('body')[0].className = ''
  },
  deactivated: function(){
    // document.getElementsByTagName('body')[0].className = ''
  },
  mounted() {
    console.log(this.$route);
    var params = window.location.href.split('?')
    if(params.length > 1){
      this.navigateTo('/index/?' + params[1])
    }else{
      this.navigateTo('/index')
    }
  },
  methods: {
    cancelmuted(){
      this.muted = this.myVideo.volume
      this.myVideo.volume = !this.myVideo.volume ? 0.5 : 0.5;
    },
    tiaoguo(){
      this.stopplay()
    },
    play(){
      console.log('play')
      var localDate = localStorage.getItem('sliderDateMobile4');
      if(localDate === null || (localDate !== this.timeFormat(null, 'yymmdd'))){
        this.video = true
      }else{
        this.video = false
        localStorage.setItem('sliderDateMobile4', this.timeFormat(null, 'yymmdd'))
        this.navigateTo('/index')
      }
      localStorage.setItem('sliderDateMobile4', this.timeFormat(null, 'yymmdd'))
      this.video = true;
      if(this.video) {
        const _t = this;
        this.$nextTick(() => {
          this.myVideo = new HlsJsPlayer({
            id:'welcomeVideo',
            url: 'https://virtualobs.ciif-expo.com/video/pc0920/b57d3feea0e3448e95d91e7fd00dff46/index.m3u8',
            autoplay: true,
            muted: false,
            width: '100%',
            height: '100%',
          })
          this.myVideo.once('ended',function(){
            _t.navigateTo('/index')
            this.myVideo.destroy(true)
          })
          // this.myVideo = videojs('welcomeVideo', {
          //   loop: false,
          //   controls: false,
          //   preload: 'auto',
          //   autoplay: true
          // }, function onPlayerReady() {
          //   this.on("play", function () {
          //     _t.playing = true
          //   });
          //   this.on("playing", function () {
          //     _t.playing = true
          //   });
          //   this.on("ended", function () {
          //     _t.navigateTo('/index')
          //   });
          // })
          setTimeout(() => {
            this.myVideo.play();
          },300)
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.slider-index-pc {
  width: 100%;
  height: 100%;
  position: relative;
  background: #000;
  .bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url("../../../assets/images/pc_slider_bg.png");
    background-size: cover;
    z-index: -1;
  }
  .container{
    .logo{
      width: 208px;
      height: 40px;
      background: url("../../../assets/images/pc_slider_logo.png");
      background-size: 100%;
      margin-top: 8px;
    }
  }
  #welcomeVideo{
    width: 100%;
    height: 100%;
  }
  .menu{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 88px;
    z-index: 2;
    .menu-item{
      margin: 0 16px;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 158px;
      height: 48px;
      border-radius: 4px;
      filter: blur(0px);
      position: relative;
      cursor: pointer;
      opacity: 1;
      img{
        width: 22px;
        height: 22px;
        margin-right: 4px;
      }
    }
    .menu-item-0{
      background: linear-gradient(48deg, rgba(252, 213, 0, 0.6) 0%, rgba(243, 147, 15, 0.4) 100%);
      &:before{
        content: ' ';
        width: 130px;
        height: 20px;
        background: linear-gradient(48deg, rgba(252, 213, 0, 0.6) 0%, rgba(243, 147, 15, 0.4) 100%);
        border-radius: 4px;
        filter: blur(10px);
        position: absolute;
        bottom: -10px;
      }
      &:hover{
        background: linear-gradient(48deg, rgba(252, 213, 0, 0.9) 0%, rgba(243, 147, 15, 0.8) 100%);
        opacity: 1;
        &:before{
          content: ' ';
          width: 130px;
          height: 20px;
          background: linear-gradient(48deg, rgba(252, 213, 0, 0.8) 0%, rgba(243, 147, 15, 0.6) 100%);
          opacity: 0.8;
          filter: blur(10px);
          position: absolute;
          bottom: -10px;
        }
      }
    }
    .menu-item-1{
      background: linear-gradient(48deg, rgba(243, 142, 15, 0.6) 0%, rgba(235, 80, 29, 0.4) 100%);
      &:before{
        content: ' ';
        width: 130px;
        height: 20px;
        background: linear-gradient(48deg, rgba(243, 142, 15, 0.6) 0%, rgba(235, 80, 29, 0.4) 100%);        border-radius: 4px;
        opacity: 0.8;
        filter: blur(10px);
        position: absolute;
        bottom: -10px;
      }
      &:hover{
        background: linear-gradient(48deg, rgba(243, 142, 15, 0.8) 0%, rgba(235, 80, 29, 0.6) 100%);
        opacity: 1;
        &:before{
          content: ' ';
          width: 130px;
          height: 20px;
          background: linear-gradient(48deg, rgba(243, 142, 15, 0.8) 0%, rgba(235, 80, 29, 0.6) 100%);
          opacity: 0.8;
          filter: blur(10px);
          position: absolute;
          bottom: -10px;
        }
      }
    }
    .menu-item-2{
      background: linear-gradient(41deg, rgba(233, 74, 29, 0.6) 0%, rgba(231, 38, 24, 0.4) 100%);
      &:before{
        content: ' ';
        width: 130px;
        height: 20px;
        background: linear-gradient(41deg, rgba(233, 74, 29, 0.6) 0%, rgba(231, 38, 24, 0.4) 100%);
        opacity: 0.8;
        filter: blur(10px);
        position: absolute;
        bottom: -10px;
      }
      &:hover{
        background: linear-gradient(41deg, rgba(233, 74, 29, 0.8) 0%, rgba(231, 38, 24, 0.6) 100%);
        opacity: 1;
        &:before{
          content: ' ';
          width: 130px;
          height: 20px;
          background: linear-gradient(41deg, rgba(233, 74, 29, 0.8) 0%, rgba(231, 38, 24, 0.6) 100%);
          opacity: 0.8;
          filter: blur(10px);
          position: absolute;
          bottom: -10px;
        }
      }
    }
    .menu-item-3{
      background: linear-gradient(41deg, rgba(231, 34, 24, 0.6) 0%, rgba(229, 2, 19, 0.4) 100%);
      &:before{
        content: ' ';
        width: 130px;
        height: 20px;
        background: linear-gradient(41deg, rgba(231, 34, 24, 0.6) 0%, rgba(229, 2, 19, 0.4) 100%);
        opacity: 0.8;
        filter: blur(10px);
        position: absolute;
        bottom: -10px;
      }
      &:hover{
        background: linear-gradient(41deg, rgba(231, 34, 24, 0.8) 0%, rgba(229, 2, 19, 0.6) 100%);
        opacity: 1;
        &:before{
          content: ' ';
          width: 130px;
          height: 20px;
          background: linear-gradient(41deg, rgba(231, 34, 24, 0.8) 0%, rgba(229, 2, 19, 0.6) 100%);
          opacity: 0.8;
          filter: blur(10px);
          position: absolute;
          bottom: -10px;
        }
      }
    }
  }
  .tiaoguo{
    position: absolute;
    right: 20px;
    top: 24px;
    font-size: 14px;
    color: #fff;
    z-index: 999;
    cursor: pointer;
    height: 34px;
    width: 88px;
    line-height: 34px;
    text-align: center;
    background: #000000;
    border-radius: 19px;
    background: url('../../../assets/images/tiaoguo.png');
    background-size: 100% 100%;
    &:hover{
      background: url('../../../assets/images/tiaoguo_hover.png');
      background-size: 100% 100%;
    }
  }
  .tiaoguo1{
    position: absolute;
    right: 120px;
    height: 48px;
    width: 48px;
    line-height: 48px;
    text-align: center;
    top: 20px;
    font-size: 18px;
    color: #fff;
    border-radius: 10px;
    z-index: 999;
    cursor: pointer;
    img{
      margin-top: 12px;
      vertical-align: center;
    }
  }
  .play-btn{
    width: 80px;
    height: 80px;
    background: url("../../../assets/images/play-btn.png");
    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    z-index: 999;
  }
  .music{
    width: 44px;
    height: 44px;
    background: url("../../../assets/images/music_kaiqi.png");
    background-size: 100% 100%;
    &.guanbi{
      background: url("../../../assets/images/music_guanbi.png");
      background-size: 100% 100%;
    }
    &:hover{
      background: url("../../../assets/images/music_hover.png");
      background-size: 100% 100%;
    }
  }
}
</style>
