<template>
  <div
    class="supply-demand-category-item-mobile"
    :class="on ? `on` : ``"
    :style="{color: (on ? `#EA4C1E` : '#858585')}"
    @click="$emit('click')"
  >
    {{ $t(info.title) }}
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'SupplyDemandCategoryItemMobile',
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    on: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.supply-demand-category-item-mobile {
  margin-right: 48px;
  font-size: 28px;
  font-weight: 500;
  color: #858585;
  line-height: 32px;
  &.on{
    font-size: 32px;
    font-weight: 500;
    color: #EA4C1E;
  }
}
</style>
