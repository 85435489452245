<template>
  <div class="search-list-mobile">
    <template v-for="(res, index) in list">
      <search-list-item-mobile
        :key="index"
        :info="res"
        :type="type"
      />
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import SearchListItemMobile from "./searchListItem";

export default {
  name: 'SearchListMobile',
  components: {SearchListItemMobile},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.search-list-mobile {
  height: 100%;
  overflow-y: scroll;
}
</style>
