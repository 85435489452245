<template>
  <div class="about-index-mobile">
    <top-nav-mobile
      :backurl="backurl"
      :is-back="true"
    >
      <span class="nav-title">{{ $t('10010') }}</span>
    </top-nav-mobile>
    <template v-for="(res, index) in options.info.json_text.templateSort">
      <about-banner-mobile
        v-if="res==='0' && options.info.json_text.pic_status"
        :key="index"
        :options="options"
      />
      <about-text-mobile
        v-if="res==='1' && options.info.json_text.text_status"
        :key="index"
        :info="options.info"
      />
      <about-contract-mobile
        v-if="res==='2' && options.info.json_text.contact_status"
        :key="index"
        :info="options.info"
      />
      <about-link-mobile
        v-if="res==='3' && options.info.json_text.ad_status"
        :key="index"
        :info="options.info"
      />
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import LangBtnMobile from "../../../components/Mobile/common/LangBtn";
import AboutBannerMobile from "../../../components/Mobile/About/AboutBanner";
import AboutTextMobile from "../../../components/Mobile/About/AboutText";
import AboutContractMobile from "../../../components/Mobile/About/AboutContract";
import AboutLinkMobile from "../../../components/Mobile/About/AboutLink";
import BackBtnMobile from "../../../components/Mobile/common/BackBtn";

export default {
  name: 'AboutIndexMobile',
  components: {
    AboutLinkMobile,
    AboutContractMobile,
    AboutTextMobile,
    AboutBannerMobile,
    TopNavMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  data(){
    return {
      backurl: ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    this.backurl = this.$route.meta.fromUrl
  },
  mounted() {
    this.backurl = this.$route.meta.fromUrl
  }
}
</script>

<style scoped lang="scss">
.about-index-mobile {
  .pic{
    width:100%;
    height: 280px;
  }
}
</style>
