<template>
  <div
    class="search-view-pc"
  >
    <div
      v-if="showBack"
      class="back-btn-view"
    >
      <back-btn-pc />
    </div>
    <form
      class="search-form"
      :class="{focus: focus}"
      :style="{width: showBack ? `1060px` : `100%`, background: bgColor }"
    >
      <div
        class="search-form-group"
      >
        <div
          v-if="false"
          class="search-icon"
          :class="bgColor === '#fff' ? 'bai' : ''"
        ></div>
        <el-select
          v-if="showSelect"
          v-model="type"
          popper-class="search-select"
          @change="changeType"
        >
          <el-option
            :label="$t('10002')"
            :value="0"
          />
          <el-option
            :label="$t('24136')"
            :value="1"
          />
        </el-select>
        <button
          type="button"
          @click="search"
        >
          {{ $t('50025') }}
        </button>
        <div
          class="search-input"
          :style="{marginLeft: showSelect ? `120px` : `0`}"
        >
          <input
            v-model="key"
            name="keywords"
            :placeholder="$t('20011')"
            @blur="dialaingout()"
            @change="changeInput"
            @focus="dialaing()"
          />
          <span
            v-if="key"
            class="close"
            @click="clear"
          ></span>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import {mapState} from "vuex";
import BackBtnPc from "@/components/Pc/common/BackBtn";

export default {
  name: 'SearchViewPc',
  components: {BackBtnPc},
  props: {
    placeholder: {
      type: String,
      default: () => '请输入搜索关键词'
    },
    search:{
      type: Function,
      default: () => {}
    },
    changeKey:{
      type: Function,
      default: () => {}
    },
    changeType:{
      type: Function,
      default: () => {}
    },
    showBack:{
      type: Boolean,
      default: () => true
    },
    bgColor: {
      type: String,
      default: () => 'rgba(250, 250, 250, 0.1000)'
    },
    showSelect: {
      type: Boolean,
      default: () => false
    }
  },
  data(){
    return {
      key: '',
      type: 0,
      focus: false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    changeInput(){
      this.changeKey(this.key)
    },
    clear(){
      this.key = ''
      this.changeInput()
    },
    dialaing(){
      this.focus = true
    },
    dialaingout(){
      this.focus = false
    }
  }
}
</script>

<style scoped lang="scss">

.search-view-pc{
  .back-btn-view{
    display: inline-block;
    float: left;
    margin-right: 20px
  }

  .search-form{
    height: 42px;
    border: 1px solid rgba(255,255,255,0);
    background: #f7f7f7;
    border-right: 0;
    display: inline-block;
    width: 1060px;
    margin-bottom: 16px;
    &.focus{
      box-shadow: 0px 2px 10px 0px rgba(255,255,255,0.2);
      border: 1px solid rgba(255,255,255,0.5);
      border-right: 0;
      height: 42px;
      backdrop-filter: blur(1px);
    }
    .search-form-group{
      width: 100%;
      .search-icon{
        width: 20px;
        height: 20px;
        background: url("../../../../assets/images/search_left_icon_normal.png") no-repeat;
        background-size: 100% 100%;
        margin: 10px 10px 10px 16px;
        display: inline-block;
        float: left;
        &.bai{
          background: url("../../../../assets/images/search_left_icon.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .search-input{
        height: 40px;
        line-height: 40px;
        margin-left: 46px;
        margin-right: 148px;
        position: relative;
        input{
          width: 100%;
          height: 40px;
          line-height: 40px;
          background: rgba(0,0,0,0);
          border: 0;
          color: rgba(255,255,255,0.8);
          padding-left: 10px;
        }
        .close{
          width: 16px;
          height: 16px;
          background: url("../../../../assets/images/anhei/pc/search_pc_close.png") no-repeat;
          background-size: 100% 100%;
          background-position: center;
          position: absolute;
          top: 12px;
          right: 12px;
          bottom: 12px;
          cursor: pointer;
        }
      }
      button {
        float: right;
        width: 148px;
        height: 42px;
        background: #EA4C1E;
        border: 0;
        color: #fff;
        font-size: 14px;
        position: relative;
        top: -1px;
        &:hover, &:focus{
          opacity: 0.8;
        }
      }
    }
  }

  &:after{
    clear: both;
    display: block;
    width: 0;
    height: 0;
    content: ' '
  }
}
</style>
