<template>
  <div
    class="btn-loukong-pc"
    :class="on ? 'on' : ''"
  >
    {{ text }}
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'BtnLoukongPc',
  props: {
    text: {
      type: String,
      default: () => ''
    },
    on: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.btn-loukong-pc{
  width: 88px;
  height: 32px;
  border: 1px solid #fbdbd2;
  border-radius: 32px;
  line-height: 32px;
  text-align: center;
  color: #EA4C1E;
  cursor: pointer;
  &:hover, &.on{
    background: #fbdbd2;
  }
}
</style>
