<template>
  <div class="supply-product-mobile">
    <div
      v-if="false"
      class="bg"
    />
    <div
      class="bg2"
    />
    <top-nav-mobile :is-back="true">
      <span class="nav-title">供需匹配</span>
    </top-nav-mobile>
    <div
      class="container z"
    >
      <template v-for="(res, index) in options.list">
        <div
          :key="index"
          class="item"
        >
          <supply-demmand-item-supply-mobile
            :info="res"
            @click="showSupplyModalInfo(res)"
          />
        </div>
      </template>
    </div>
    <supply-demand-supply-modal-mobile
      v-if="supplyShow"
      :hide-supply-modal="hideSupplyModal"
      :info="supplyModalInfo"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import SupplyDemandSupplyModalMobile from "../../../components/Mobile/SupplyDemand/SupplyDemandSupplyModal";
import SupplyDemmandItemSupplyMobile from "../../../components/Mobile/SupplyDemand/SupplyDemandItem/component/supply";

export default {
  name: 'SupplyProductMobile',
  components: {
    SupplyDemmandItemSupplyMobile,
    SupplyDemandSupplyModalMobile,
    TopNavMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    return {
      supplyShow: false,
      supplyModalInfo: {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    showSupplyModalInfo(info){
        this.supplyModalInfo = info
        this.supplyShow = true
    },
    hideSupplyModal(){
      this.supplyShow = false
    },
    getDetail(id, callback){
      const params = {
        id: id
      }
      this.$u.api.supplydemands.getDetail(params).then(res => {
        if(res.status === 1){
          callback(res.data)
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.supply-product-mobile {
  .container{
    &.z{
      position: relative;
      z-index: 1;
      margin-top: 20px;
      padding-bottom: 170px;
    }
    .item{
      width: 678px;
      background: rgba(255,255,255,0.1);
      border-radius: 12px;
      padding: 28px;
      margin-bottom: 20px;
    }
  }
  .filter{
    position: relative;
    z-index: 99;
  }
  .bg{
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../../../assets/images/mobile/gongxu_bg.png");
    background-size: 100% auto;
    background-position: center bottom;
    background-repeat: no-repeat;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
  .bg2{
    position: absolute;
    width: 100%;
    background: url("../../../assets/images/mobile/gongxu_bg.png") no-repeat;
    background-size: 100% auto;
    background-position: center bottom;
    left: 0;
    bottom: 0;
    z-index: 2;
    pointer-events: none;
    height: 456px;
  }
  .category{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .gongxu-bottom-view{
    text-align: center;
    height: 52px;
    position: relative;
    bottom: 20px;
    left: 0;
    width: 100%;
    .gongxu-more{
      padding-top: 10px;
      .gongxu-more-btn{
        text-align: center;
        display: inline-block;
        cursor: pointer;
        .text{
          text-align: center;
        }
        .pic{
          display: inline-block;
          text-align: center;
          width: 26px;
          height: 26px;
        }
      }
    }
  }
}
</style>
