<template>
  <div class="activity-info-default">
    <activity-info-pc
      v-if="browser===1"
      :options="options"
    />
    <activity-info-mobile
      v-if="browser===2"
      :options="options"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import ActivityInfoPc from "@/views/Pc/Activity/info";
import ActivityInfoMobile from "../../Mobile/Activity/info";

export default {
  name: 'ActivityInfoDefault',
  components: {ActivityInfoMobile, ActivityInfoPc},
  data() {
    return {
      options: {
        id: 0,
        info: {}
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
  },
  mounted() {
    this.options.id = this.$route.query.id
    this.options.share = this.$route.query.share || 0
    this.defaultOptions = this.deepClone(this.options)
    this.init()
  },
  methods: {
    init() {
      this.$u.api.activitys.getDetail({id: this.options.id, share: this.options.share}).then((res) =>{
        this.isFirst = false
        if(res.status === 1){
          this.options.info = res.data
          this.setShare(res)
        }
      })
    },
    setShare(res){
      this.getWxConfig(this.$t2(this.lang, res.data.title, res.data.en_title), this.$t2(this.lang, res.data.content, res.data.en_content), res.data.pic)
    },
  }

}
</script>

<style scoped></style>
