<template>
  <div
    class="list-box2-item-pc"
    @click="$emit('click', info)"
  >
    <div class="pic">
      <img :src="imageurl" />
    </div>
    <div class="info">
      <div class="title line-2">
        {{ title }}
      </div>
      <div class="time">
        <icon-time-line
          color="rgba(255, 255, 255, 0.6000)"
          icon="icon_clock_hei.png"
          :text="timeFormat(time, 'yyyy/mm/dd hh:MM')"
        />
      </div>
      <div class="summary line-2">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import IconTimeLine from "@/components/Pc/common/IconTimeLine";

export default {
  name: 'ListBox2ItemPc',
  components: {IconTimeLine},
  props: {
    imageurl: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: () => ''
    },
    info: {
      type: Object,
      default: () => {}
    },
    description: {
      type: String,
      default: () => ''
    },
    time: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.list-box2-item-pc{
  display: inline-block;
  margin-right: 20px;
  margin-bottom:20px;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  width: 550px;
  &:hover{
    .pic{
      img{
        transform: scale(1.1);
      }
    }
  }
  .pic{
    width: 210px;
    height: 140px;
    overflow: hidden;
    float: left;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.4s ease-in-out;
    }
  }
  .info{
    margin-left: 228px;
    .title{
      margin-top: 12px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      line-height: 20px;
      height: 40px;
    }
    .time{
      margin-top: 10px;
    }
    .summary{
      margin-top: 8px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.6000);
      line-height: 20px;
    }
  }
}
</style>
