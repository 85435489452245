<template>
  <div class="news-index-default">
    <news-index-pc
      v-if="browser===1"
      :change-page="changePage"
      :change-typeid="changeTypeid"
      :options="options"
    />
    <news-index-mobile
      v-if="browser===2"
      :change-page="changePage"
      :change-typeid="changeTypeid"
      :options="options"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import NewsIndexPc from "@/views/Pc/News";
import NewsIndexMobile from "../../Mobile/News";

export default {
  name: 'NewsIndexDefault',
  components: {NewsIndexMobile, NewsIndexPc},
  data() {
    return {
      typeId: 31,
      options: {
        loading: true,
        swiper: [],
        childType: [],
        childTypeId: 0,
        hezuo: [],
        data: {
          list: [],
          page: 1,
          pagesize: 10,
          total: 0
        }
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        if(this.$route.meta.fromUrl === '/news') {
          this.init()
        }
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    this.init()
    this.setShare()
  },
  methods: {
    init() {
      this.getSwiper()
      this.getAds()
      this.getCategory()
    },
    getAds(){
      this.$u.api.commons.getNewAds({typeId: 3}).then(res => {
        if(res.status === 1){
          this.options.hezuo = res.data
        }
      })
    },
    getSwiper(){
      this.$u.api.newss.getRecom().then(res => {
        if(res.status === 1){
          let arr = []
          res.data.map(function (item, index){
            if(index < 3){
              arr.push(item)
            }
          })
          this.options.swiper = arr
        }
      })
    },
    getCategory(){
      this.$u.api.commons.getChildArchivesType({typeId: this.typeId}).then(res => {
        if(res.status === 1){
          this.options.childType = res.data
          this.options.childTypeId = res.data[0].id
          this.getList()
        }
      })
    },
    getList(){
      this.options.loading = true
      const params = {
        typeid: this.options.childTypeId,
        pagesize: this.options.data.pagesize,
        page: this.options.data.page
      }
      this.$u.api.newss.getLists(params).then(res => {
        this.options.loading = false
        if(res.status === 1){
          const {data: {lists,  totalNum}} = res
          this.options.data.list = lists
          this.options.data.total = totalNum
        }
      })
    },
    changeTypeid(v){
      this.options.childTypeId = v
      this.options.data.page = 1
      this.getList()
    },
    changePage(v){
      this.options.data.page = v;
      this.getList()
    },
    setShare(){
      this.getWxConfig(this.$t('10006') + this.bankuai(), this.$t('10006') + this.bankuai())
    }
  }
}
</script>

<style scoped></style>
