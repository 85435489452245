<template>
  <div
    class="activity-tabs-list-item-mobile"
    :class="on ? `on` : ``"
    @click="$emit('click', $event)"
  >
    {{ $t2(lang, info.name, info.ename) }}
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'ActivityTabsListItemMobile',
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    on: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.activity-tabs-list-item-mobile {
  height: 48px;
  line-height: 48px;
  display: inline-block;
  margin-right: 20px;
  padding: 0 20px;
  font-size: 28px;
  font-weight: 400;
  color: #fff;
  border-radius: 4px;
  overflow: hidden;
  background: rgba(255,255,255,0.1);
  &.on {
    background: linear-gradient(223deg, #FF4F1A, #FF9B61);
    color: #fff;
  }
}
</style>
