<template>
  <div class="user-schedule-mobile">
    <top-nav-mobile
      backurl="/user"
      :is-back="true"
      :search="false"
    >
      <span class="nav-title">{{ $t('30003') }}</span>
    </top-nav-mobile>
    <div class="container">
      <div class="types-view">
        <user-types-mobile
          :change-type="changeType"
          :options="options"
        />
      </div>
      <div class="schedule-list">
        <bbs-list-mobile
          v-if="options.type === 'bbs'"
          :change-page="changePage"
          :data="options.data"
          from="user"
          :refresh="refresh"
          type="schedule"
        />
        <activity-list-mobile
          v-if="options.type === 'activity'"
          :change-page="changePage"
          :data="options.data"
          from="user"
          :refresh="refresh"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import UserTypesMobile from "../../../components/Mobile/common/UserTypes";
import BbsListMobile from "../../../components/Mobile/Bbs/BbsList";
import ActivityListMobile from "../../../components/Mobile/Activity/ActivityList";

export default {
  name: 'UserScheduleMobile',
  components: {ActivityListMobile, BbsListMobile, UserTypesMobile, TopNavMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeType: {
      type: Function,
      default: () => () => {}
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.user-schedule-mobile {
  .types-view{
    display: flex;
    justify-content: center;
  }
  .schedule-list{
    margin-top: 32px;
  }
}
</style>
