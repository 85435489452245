<template>
  <div class="activity-footer-mobile">
    <div class="left">
      <icon-text-column-mobile
        color="#c2c2c2"
        icon="share.png"
        style="margin-left: 20px"
        :text="$t('50016')"
        type="share"
        width="36px"
      />
    </div>
    <activity-info-btn-mobile
      :info="info"
      :people="info.people"
      :status="info.is_status"
      @click="yuyue"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import IconTextColumnMobile from "../../common/IconTextColumn";
import ActivityInfoBtnMobile from "../ActivityInfoBtn";

export default {
  name: 'ActivityFooterMobile',
  components: {ActivityInfoBtnMobile, IconTextColumnMobile},
  props: {
    showReserveModal: {
      type: Function,
      default: () => () => {}
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  statusTest() {
    switch(this.info.is_status){
      case -1:
        if(this.info.live_id > 0){
          return '观看直播'
        }else{
          return '无需预约'
        }
      case 0:
        return '可预约'
      case 1:
        return '已预约'
      case 2:
        if(this.info.live_id > 0){
          return '观看直播'
        }else{
          return '预约结束'
        }
      case 3:
        return '即将开始'
      case 4:
        return '已约满'
    }
    return ''
  },
  statusClass() {
    switch(this.info.is_status){
      case -1:
        if(this.info.live_id > 0){
          return 'in'
        }else {
          return 'default'
        }
      case 0:
        return 'on'
      case 1:
      case 3:
        return 'in'
      case 2:
        if(this.info.live_id > 0){
          return 'in'
        }else{
          return 'default'
        }
      case 4:
        return 'default'
    }
    return 'default';
  }
  },
  methods:{
    yuyue(){
      if(this.info.is_status === 0){
        this.showReserveModal(this.info)
      }
      if((this.info.is_status === 2 || this.info.is_status === -1) && this.info.live_id > 0){
        this.navigateTo('/Live?typeCurrent=1&liveid=' + this.info.live_id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.activity-footer-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 36px;
  background: #000;
  .left{
    display: flex;
    justify-content: space-around;
    align-items: center;
    .item{
      margin: 0 20px;
    }
  }
}
</style>
