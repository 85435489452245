<template>
  <div
    class="tags-item-mobile"
    :class="{dark:dark}"
    :style="{height: height, lineHeight: height, padding: padding, background: bg}"
  >
    {{ $t2(lang, (info.name || info.typelistname), (info.ename || info.typelistname_en)) }}
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'TagsItemMobile',
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    dark: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: () => '32px'
    },
    bg: {
      type: String,
      default: () => ''
    },
    padding: {
      type: String,
      default: () => '0 10px'
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.tags-item-mobile {
  height: 32px;
  line-height: 32px;
  background: rgba(0,0,0,0.3);
  text-align: center;
  padding: 0 10px;
  display: inline-block;
  font-size: 20px;
  font-weight: 400;
  color: #000;
  margin-right: 10px;
  border-radius: 4px;
  &.dark{
    background: rgba(0,0,0,0.3);
    color: #FF754D;
  }
}
</style>
