<template>
  <div class="bbs-index-mobile">
    <top-nav-mobile>
      <span class="nav-title">{{ $t('10003') }}</span>
    </top-nav-mobile>
    <top-nav-mobile
      :btns="false"
      :fixed="false"
    >
      <bbs-filter-mobile
        :change-child-type-id="changeChildTypeId"
        :change-key="changeKey"
        :change-type-id="changeTypeId"
        :changeYear="changeYear"
        :options="options"
        :search="search"
        :years="years"
      />
    </top-nav-mobile>
    <div class="container">
      <bbs-date-list-mobile
        v-if="false"
        :change-date="changeDate"
        class="bbs-date"
        :options="options"
      />
      <bbs-list-mobile
        :change-page="changePage"
        :data="options.data"
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import BbsFilterMobile from "../../../components/Mobile/Bbs/BbsFilter";
import TopNavMobile from "../../../components/Mobile/common/TopNav";
import LangBtnMobile from "../../../components/Mobile/common/LangBtn";
import BbsDateListMobile from "../../../components/Mobile/Bbs/BbsDateList";
import BbsListMobile from "../../../components/Mobile/Bbs/BbsList";
import SearchBtnMobile from "../../../components/Mobile/common/SearchBtn";

export default {
  name: 'BbsIndexMobile',
  components: {BbsListMobile, BbsDateListMobile,  TopNavMobile, BbsFilterMobile},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeChildTypeId: {
      type: Function,
      default: () => {return () => {}}
    },
    changeTypeId: {
      type: Function,
      default: () => {return () => {}}
    },
    changeYear: {
      type: Function,
      default: () => {}
    },
    changePage: {
      type: Function,
      default: () => {return () => {}}
    },
    changeDate: {
      type: Function,
      default: () => {return () => {}}
    },
    changeKey: {
      type: Function,
      default: () => () => {}
    },
    search: {
      type: Function,
      default: () => () => {}
    },
    years: {
      type: Array,
      default: () => [
        {
          name: '全部',
          ename: '全部',
          value: ''
        },
        {
          name: '2023',
          ename: '2023',
          value: '2023'
        },
        // {
        //   name: '2024',
        //   ename: '2024'
        // },
        // {
        //   name: '2025',
        //   ename: '2025'
        // },
      ]
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.bbs-index-mobile {
  .bbs-date{
    margin-top: 32px;
    margin-bottom: 32px;
  }
}
</style>
