<template>
  <div class="user-schedule-pc user">
    <div class="container">
      <user-left-menu-pc />
      <div class="user-right">
        <div class="collect-type">
          <template v-for="(res, index) in options.typeList">
            <template v-if="lang !== 'en' || (lang ==='en' && !res.enHide)">
              <btn-big
                :key="index"
                class="item"
                :label="$t(res.title)"
                :on="options.type === res.type"
                @click="changeType(res.type)"
              />
            </template>
          </template>
        </div>
        <div class="collect-list">
          <bbs-list-pc
            v-if="options.type === 'bbs'"
            :change-page="changePage"
            :data="options.data"
            from="schedule"
            :refresh="refresh"
          />
          <user-schedule-activity-list-pc
            v-if="options.type === 'activity'"
            :change-page="changePage"
            :data="options.data"
            from="schedule"
            :refresh="refresh"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import UserLeftMenuPc from "../../../components/Pc/User/UserLeftMenu";
import BtnBig from "../../../components/Pc/common/BtnBig";
import BbsListPc from "../../../components/Pc/Bbs/BbsList";
import UserScheduleActivityListPc from "../../../components/Pc/User/UserScheduleActivityList";

export default {
  name: 'UserSchedulePc',
  components: {UserScheduleActivityListPc,BbsListPc, BtnBig, UserLeftMenuPc},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeType: {
      type: Function,
      default: () => () => {}
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.user-schedule-pc {
  .collect-type{
    display: flex;
    justify-content: center;
    align-items: center;
    .item{
      margin: 0 8px;
    }
  }
  .collect-list{
    margin-top: 20px;
  }
}
</style>
