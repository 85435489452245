<template>
  <div class="post-supply-pc">
    <div class="container">
      <div
        class="main-box"
        style="margin-bottom: 0;"
      >
        <div class="steps">
          <el-steps
            :active="step > 2 ? 2 : step"
            align-center
            space="400px"
          >
            <el-step :title="$t('50015')" />
            <el-step
              :status="step >= 2 ? 'finish' : 'wait'"
              :title="$t('24102')"
            />
            <el-step
              status="wait"
              :title="$t('24103')"
            />
          </el-steps>
        </div>
      </div>
      <split-line-pc
        bg-color="#f0f0f0"
        height="1px"
        margin-bottom="0"
        margin-top="0"
      />
      <div
        v-if="options.loaded"
        class="main-box"
      >
        <div
          v-if="step===2"
          class="setp2"
        >
          {{ $t('30017') }}
        </div>
        <div
          v-if="step===3"
          class="setp2"
        >
          <el-result
            icon="error"
            :title="$t('30018')"
          >
            <template slot="extra">
              <el-button
                size="mini"
                type="default"
                @click="back"
              >
                {{ $t('50020') }}
              </el-button>
            </template>
            <template slot="subTitle">
              {{ tips }}<span
                :class="`click` + options.info.is_reason"
                @click="click"
              >{{ tips2 }}。</span>
            </template>
          </el-result>
        </div>
        <div
          v-if="step===1"
          class="form"
        >
          <el-form
            ref="form"
            label-width="80px"
            :model="form"
            :rules="rules"
            size="medium"
          >
            <el-form-item :label="$t('50015')" />
            <el-form-item :label="$t('24105')">
              <div class="post_type">
                {{ $t('24002') }}
              </div>
            </el-form-item>
            <el-form-item
              :label="$t('24106')"
              prop="title"
            >
              <el-input
                v-model="form.title"
                :placeholder="$t('24119')"
              />
            </el-form-item>
            <el-form-item
              :label="$t('20001')"
              prop="typeid"
            >
              <el-select
                v-model="form.typeid"
                multiple
                :multiple-limit="3"
                :placeholder="$t('24120')"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in options.typeList"
                  :key="item.id"
                  :label="$t2(lang, item.name, item.ename)"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('24107')"
              :placeholder="$t('24121')"
              prop="content"
            >
              <el-input
                v-model="form.content"
                type="textarea"
              />
            </el-form-item>
            <el-form-item
              :label="$t('24117')"
            >
              <el-upload
                :action="uploadUrl"
                auto-upload
                :before-upload="beforeAvatarUpload"
                class="avatar-uploader"
                :on-success="handleAvatarSuccess"
                :show-file-list="false"
              >
                <img
                  v-if="imageUrl"
                  class="avatar"
                  :src="imageUrl"
                >
                <i
                  v-else
                  class="el-icon-plus uploader-icon"
                ></i>
              </el-upload>
            </el-form-item>
            <el-form-item :label="$t('24108')">
              <span class="tips">({{ $t('24118') }})</span>
            </el-form-item>
            <el-form-item
              :label="$t('24109')"
              prop="contact.name"
            >
              <el-input
                v-model="form.contact.name"
                :placeholder="$t('24122')"
              />
            </el-form-item>
            <el-form-item
              :label="$t('50027')"
              prop="contact.email"
            >
              <el-input
                v-model="form.contact.email"
                :placeholder="$t('24123')"
              />
            </el-form-item>
            <el-form-item
              :label="$t('24111')"
              prop="contact.company"
            >
              <el-input
                v-model="form.contact.company"
                :placeholder="$t('24124')"
              />
            </el-form-item>
            <el-form-item label="">
              <btn-custom-pc
                class="btn"
                :label="$t('50020')"
              />
              <btn-custom-pc
                background="linear-gradient(223deg, rgba(255, 26, 44, 0.9), rgba(255, 155, 97, 0.8))"
                class="btn"
                color="#fff"
                :label="$t('24112')"
                @click="submit"
              />
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import Setting from "@/config/setting.config";
import SplitLinePc from "../../../components/Pc/common/SplitLine";
import BtnCustomPc from "../../../components/Pc/common/BtnCustom";

export default {
  name: 'PostSupplyPc',
  components: {BtnCustomPc, SplitLinePc},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  data(){
    return {
      step: 0,
      form: {
        title: '',
        contact: {}
      },
      uploadUrl: Setting.appDomain.api + Setting.uploadUrl,
      imageUrl: '',
      rules: {
        title: [
          { required: true, message: '请输入需求名称', trigger: 'blur' },
        ],
        typeid: [
          { required: true, message: '请选择需求分类', trigger: ['blur', 'change'] },
        ],
        content: [
          { required: true, message: '请输入描述信息', trigger: 'blur' },
        ],
        'contact.name': [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
        ],
        'contact.email': [
          { required: true, message: '请输入常用邮箱', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        'contact.company': [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
        ],
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    tips(){
      switch(this.options.info.is_reason){
        case '1':
          return this.$t('24129')
        case '3':
          return this.$t('24131')
      }
      return this.$t('24130')
    },
    tips2(){
      switch(this.options.info.is_reason){
        case '1':
          return this.$t('30014')
        case '3':
          return this.$t('30016')
      }
      return ''
    }
  },
  watch: {
    options:{
      deep:true,
      handler(newVal, oldVal){
        console.log('numbers改变了')
        this.initForm()
      }
    }
  },
  mounted() {
    this.initForm()
  },
  methods: {
    back(){
      this.$router.back()
    },
    click(){
      if(parseInt(this.options.info.is_reason) === 1){
        this.form = {
          language: this.lang,
          contact: {}
        }
        this.imageUrl = ''
        this.step = 1;
      }
      if(parseInt(this.options.info.is_reason) === 3){
        // this.options.info = {}
        this.step = 1;
      }
    },
    initForm(){
      if(this.options.id){
        const {contact, content, content_en, id, is_status, language, logo, reason, title, title_en, typeid, logo_url} = this.options.info;
        if(!contact){
          return ;
        }
        const contact_json = JSON.parse(this.htmlspecialchars_decode(contact))
        this.imageUrl = logo_url
        this.form = {
          id: id,
          logo: logo,
          language: language,
          typeid: typeid.split(','),
          contact: {
            name: contact_json.name,
            email: contact_json.email,
            company: contact_json.company,
          }
        }
        if(language === 'zh'){
          this.form.title = title
          this.form.content = content
        }else{
          this.form.title = title_en
          this.form.content = content_en
        }
        if(parseInt(is_status) === 2){
          this.step = 3
        }
        if(parseInt(is_status) === 1){
          this.step = 2
        }
      }else{
        if(this.options.loaded){
          this.step = 1;
          this.form.language = this.lang
        }
      }
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.form.logo = res.data.pic
    },
    beforeAvatarUpload(file) {
      return true;
      // const isJPG = file.type === 'image/jpeg';
      // const isLt2M = file.size / 1024 / 1024 < 2;
      //
      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!');
      // }
      return isJPG && isLt2M;
    },
    submit(){
      const _this = this
      let form = {}
      if(this.lang === 'zh'){
        form = {
          id: this.form.id,
          title: this.form.title,
          logo: this.form.logo,
          content: this.form.content,
          contact: JSON.stringify(this.form.contact),
          typeid: this.form.typeid.join(','),
          language: this.form.language
        }
      }else{
        form = {
          id: this.form.id,
          title_en: this.form.title,
          logo: this.form.logo,
          content_en: this.form.content,
          contact: JSON.stringify(this.form.contact),
          typeid: this.form.typeid.join(','),
          language: this.form.language
        }
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$u.api.users.actSaveSupply(form).then(res => {
            if (res.status === 1) {
              this.step = 2;
            } else {
              this.$message.error(res.msg)
            }
          })
        }else{
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.post-supply-pc {
  .steps{

  }
  .setp2{
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .form{
    width: 468px;
    margin: 0 auto;
    .post_type{
      height: 36px;
      line-height: 36px;
      border: 1px solid #EA4C1E;
      color: #EA4C1E;
      text-align: center;
      padding: 0 20px;
      display: inline-block;
    }
    .avatar-uploader{
      width: 60px;
      height: 60px;
      line-height: 60px;
      text-align: center;
      border: 1px #E0E0E0 dashed;
      color: #fff!important;
    }
    .tips{
      font-size: 12px;
      font-weight: 400;
      color: rgba(255,255,255,0.8);
      line-height: 16px;
    }
    .btn{
      margin-right: 20px;
    }
  }
  .click1, .click3{
    color: #0032AE;
    cursor: pointer;
  }
}
</style>
