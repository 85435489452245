<template>
  <div
    class="icon-text-vr-mobile"
    :style="{marginRight: marginRight, marginLeft: marginLeft}"
  >
    <div
      class="icon"
      :style="{width: showText ? '32px' : '48px', height: showText ? '32px' : '48px'}"
    ></div>
    <div
      v-if="showText"
      class="label"
    >
      {{ $t('10012') }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextVrMobile',
  props: {
    showText: {
      type: Boolean,
      default: () => true
    },
    marginRight: {
      type: String,
      default: () => '0'
    },
    marginLeft: {
      type: String,
      default: () => '0'
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }
}
</script>

<style scoped lang="scss">
.icon-text-vr-mobile{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .icon{
    width: 32px;
    height: 32px;
    background: url("../../../../assets/images/mobile/vr.png") no-repeat;
    background-size: 100% 100%;

    display: block;
  }
  .label{
    display: block;
    height: 14px;
    text-align: center;
    color: #EA4C1E;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    white-space: nowrap;
    margin-left: -2px;
  }
}
</style>
