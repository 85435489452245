<template>
  <div
    class="back-btn-mobile"
    @click="goBack"
  >
    <img :src="backicon || require(`../../../../assets/images/mobile/back.png`)" />
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'BackBtnMobile',
  props: {
    url: {
      type: String,
      default: () => ''
    },
    backicon: {
      type: String,
      default: () => ''
    },
    backurl: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods: {
    goBack(){
      if(this.url !== '' && !localStorage.getItem('backUrl')){
        window.location.back();
      }else{
        if (window.history.length > 2 && navigator.userAgent.indexOf('Mobile') == -1) {
          this.$store.commit('setIsPageBack', true);
          this.$router.back()
        }else{
          if(this.backurl == 'back'){
            window.history.back()
          }else{
            this.navigateTo(this.backurl)
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.back-btn-mobile {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 48px;
    height: 48px;
  }
}
</style>
