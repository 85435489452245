<template>
  <div
    class="bbs-date-item-mobile"
    :class="on ? `on` : ``"
    @click="$emit('click')"
  >
    {{ $t2(lang, info.zh, info.en) }}
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'BbsDateItemMobile',
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    on: {
      type: Boolean,
      default: () => false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.bbs-date-item-mobile {
  display: inline-block;
  margin-right: 20px;
  line-height: 56px;
  height: 56px;
  background: rgba(255,255,255,0.1);
  padding: 0 20px;
  font-size: 28px;
  font-weight: 400;
  color: #fff;
  border-radius: 4px;
  overflow: hidden;
  &.on{
    background: linear-gradient(223deg, #FF4F1A, #FF9B61);
    color: #fff;
  }
}
</style>
