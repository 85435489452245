<template>
  <div class="user-supply-pc user">
    <div class="container">
      <user-left-menu-pc />
      <div class="user-right">
        <div class="collect-type">
          <template v-for="(res, index) in options.typeList">
            <btn-big
              :key="index"
              class="item"
              :label="$t(res.title)"
              :on="options.type === res.type"
              @click="changeType(res.type)"
            />
          </template>
        </div>
        <div class="collect-list">
          <user-supply-my-list-pc
            v-if="options.type === 'my'"
            :change-page="changePage"
            :hide-demand-contact-modal="hideDemandContactModal"
            :hide-demand-modal="hideDemandModal"
            :options="options"
            :refresh="refresh"
            :show-demand-contact-modal-info="showDemandContactModalInfo"
            :show-demand-modal-info="showDemandModalInfo"
            :show-supply-product-lists="showSupplyProductLists"
          />
          <user-supply-follow-list-pc
            v-if="options.type === 'follow'"
            :change-page="changePage"
            :options="options"
            :refresh="refresh"
            :show-supply-modal="showSupplyModal"
          />
          <user-supply-card-list-pc
            v-if="options.type === 'card'"
            :hide-demand-contact-modal="hideDemandContactModal"
            :hide-demand-modal="hideDemandModal"
            :options="options"
            :refresh="refresh"
            :show-demand-contact-modal-info="showDemandContactModalInfo"
            :show-demand-modal-info="showDemandModalInfo"
          />
        </div>
        <page-common-pc
          :change-page="changePage"
          :data="options.data"
        />
      </div>
    </div>
    <supply-demand-demand-modal-pc
      v-if="demandShow"
      :hide-demand-modal="hideDemandModal"
      :info="demandModalInfo"
      :show-demand-contact-modal-info="showDemandContactModalInfo"
      :user="true"
    />
    <supply-demand-demand-contact-modal-pc
      v-if="demandContactShow"
      :del-item="delItem"
      :hide-demand-contact-modal="hideDemandContactModal"
      :info="demandContactModalInfo"
      :user="true"
    />
    <supply-demand-supply-modal-pc
      v-if="supplyShow"
      :hide-supply-modal="hideSupplyModal"
      :info="supplyModalInfo"
    />
    <user-supply-product-list-modal
      v-if="options.userSupplyProductListModalShow"
      :hide-supply-product-lists="hideSupplyProductLists"
      :options="options"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import UserLeftMenuPc from "../../../components/Pc/User/UserLeftMenu";
import BtnBig from "../../../components/Pc/common/BtnBig";
import UserSupplyFollowListPc from "../../../components/Pc/User/UserSupplyFollowList";
import UserSupplyCardListPc from "../../../components/Pc/User/UserSupplyCardList";
import UserSupplyMyListPc from "../../../components/Pc/User/UserSupplyMyList";
import SupplyDemandDemandModalPc from "../../../components/Pc/SupplyDemand/SupplyDemandDemandModal";
import SupplyDemandDemandContactModalPc from "../../../components/Pc/SupplyDemand/SupplyDemandContactModal";
import SupplyDemandSupplyModalPc from "../../../components/Pc/SupplyDemand/SupplyDemandSupplyModal";
import UserSupplyProductListModal from "../../../components/Pc/User/UserSupplyProductListModal";
import PageCommonPc from "../../../components/Pc/common/PageCommon";

export default {
  name: 'UserSupplyPc',
  components: {
    PageCommonPc,
    UserSupplyProductListModal,
    SupplyDemandSupplyModalPc,
    SupplyDemandDemandContactModalPc,
    SupplyDemandDemandModalPc,
    UserSupplyMyListPc, UserSupplyCardListPc, UserSupplyFollowListPc, BtnBig, UserLeftMenuPc},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    changeType: {
      type: Function,
      default: () => () => {}
    },
    changePage: {
      type: Function,
      default: () => () => {}
    },
    showSupplyProductLists: {
      type: Function,
      default: () => () => {}
    },
    hideSupplyProductLists: {
      type: Function,
      default: () => () => {}
    },
    delItem: {
      type: Function,
      default: () => () => {}
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      demandModalInfo: {},
      demandContactModalInfo: {},
      demandShow: false,
      demandContactShow: false,
      supplyShow: false,
      supplyModalInfo: {},
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  methods:{
    showSupplyModal(info){
      this.supplyShow = true
      this.supplyModalInfo = info
    },
    hideSupplyModal(){
      this.supplyShow = false
    },
    showDemandModalInfo(info){
      this.demandShow = true
      this.demandModalInfo = info
    },
    showDemandContactModalInfo(info){
      this.demandShow = false
      this.demandContactShow = true
      this.demandContactModalInfo = JSON.parse(this.htmlspecialchars_decode(info.contact))
      this.demandContactModalInfo.id = info.id
    },
    hideDemandContactModal(){
      this.demandContactShow = false
    },
    hideDemandModal(){
      this.demandShow = false
    },
  }
}
</script>

<style scoped lang="scss">
.user-supply-pc {
  .collect-type{
    display: flex;
    justify-content: center;
    align-items: center;
    .item{
      margin: 0 8px;
    }
  }
  .collect-list{
    margin-top: 20px;
  }
}
</style>
