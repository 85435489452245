<template>
  <div class="user-collect-default">
    <user-collect-pc
      v-if="browser===1"
      :change-page="changePage"
      :change-type="changeType"
      :options="options"
    />
    <user-collect-mobile
      v-if="browser===2"
      :change-page="changePage"
      :change-type="changeType"
      :options="options"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import UserCollectPc from "../../Pc/User/collect";
import UserCollectMobile from "../../Mobile/User/collect";

export default {
  name: 'UserCollectDefault',
  components: {UserCollectMobile, UserCollectPc},
  data() {
    return {
      options: {
        type: 'bbs',
        typeList: [
          {
            type: 'bbs',
            // title: '论坛',
            title: '10003',
          },
          {
            type: 'awards',
            title: '10005',
            // title: '评奖',
          },
          {
            type: 'news',
            title: '10006',
            // title: '评奖',
          },
          {
            type: 'case',
            title: '10008',
            // title: '案例',
            enHide: true
          },
          {
            type: 'exhibition',
            title: '50042',
            // title: '展商',
          },
          // {
          //   type: 'exhibition_card',
          //   title: '30009',
          //   // title: '展商名片',
          // },
          {
            type: 'exhibition_video',
            title: '30010',
            // title: '展商视频',
          },
          {
            type: 'product',
            title: '10026',
            // title: '展商视频',
          }
        ],
        data: {
          list: [],
          page: 1,
          pagesize: 6,
          total: 0,
          nowType: 0
        },
        loading: false
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    this.init()
    this.setShare()
  },
  methods: {
    init() {
      this.getList();
    },
    changeType(type){
      this.options.data.list = [];
      this.options.data.page = 1;
      this.options.data.total = 0;
      this.options.type = type
      this.getList()
    },
    changePage(v){
      this.options.data.page = v;
      this.getList()
    },
    getList(){
      let type = 1
      switch(this.options.type){
        case 'bbs':
          type = 1;
          break;
        case 'awards':
          type = 2;
          break;
        case 'case':
          type = 3;
          break;
        case 'exhibition':
          type = 4;
          break;
        case 'exhibition_card':
          type = 5;
          break;
        case 'exhibition_video':
          type = 6;
          break;
        case 'news':
          type = 7;
          break;
        case 'product':
          type = 8;
          break;
      }
      const params = {
        t: type,
        page: this.options.data.page,
        pagesize: this.options.data.pagesize
      }
      this.options.loading = true
      this.$u.api.users.getMyCang(params).then(res => {
        this.options.loading = false
        if(res.status === 1){
          const {data: {lists, totalNum}} = res
          lists.map(function(item, k){
            lists[k].is_cang = 1;
          })
          this.options.data.list = lists
          this.options.data.total = totalNum
        }
      })
    },
    setShare(){
      this.getWxConfig('', '')
    }
  }
}
</script>

<style scoped></style>
