<template>
  <div
    class="rank-item-pc"
    :class="`rank-item-`+rank"
    @click="$emit('click')"
  >
    <div
      class="rank-num"
      :class="`rank-num-`+rank"
      :style="{color: color[rank]}"
    >
      {{ rank }}
    </div>
    <div class="right">
      <div
        v-if="rank<=3"
        class="icon"
      >
      </div>
      <div class="num">
        {{ info[field['num']] }}
      </div>
    </div>
    <div
      class="title"
      :class="`title-`+rank"
    >
      <span class="line-1">{{ $t2(lang, info[field['title']], info[field['en_title']]) }}</span>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'RankItemPc',
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    rank: {
      type: Number,
      default: () => 1
    },
    field: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return {
      color: {
        1: '#E50113',
        2: '#EA4C1E',
        3: '#F7AB00',
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.rank-item-pc{
  margin-left: 4px;
  line-height: 40px;
  height: 40px;
  overflow: hidden;
  padding: 0 20px 5px 12px;
  &:hover{
    box-shadow: 0 2px 8px 0 rgba(174, 106, 84, 0.15);
  }
  &.rank-item-1{
    background: linear-gradient(270deg, rgba(255,0,0,0) 0%, rgba(255,0,0,0.15) 100%);
  }
  &.rank-item-2{
    background: linear-gradient(270deg, rgba(255,176,0,0) 0%, rgba(255,156,0,0.11) 100%);
  }
  &.rank-item-3{
    background: linear-gradient(270deg, rgba(11,21,255,0) 0%, rgba(51,123,234,0.07) 100%);
  }
  .rank-num{
    display: inline-block;
    float: left;
    font-size: 16px;
    font-family: Helvetica;
    color: rgba(255,255,255,0.8);
    width: 30px;
    text-align: center;
    &.rank-num-1{
      background: url("../../../../assets/images/rank_1.png");
      background-size: 22px 18px;
      background-position: 5px 8px;
      background-repeat: no-repeat;
      font-size: 0
    }
    &.rank-num-2{
      background: url("../../../../assets/images/rank_2.png");
      background-size: 22px 18px;
      background-position: 5px 8px;
      background-repeat: no-repeat;
      font-size: 0
    }
    &.rank-num-3{
      background: url("../../../../assets/images/rank_3.png");
      background-size: 22px 18px;
      background-position: 5px 8px;
      background-repeat: no-repeat;
      font-size: 0
    }
  }
  .title{
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
    color: rgba(255,255,255,0.8);
    margin-left: 30px;
    margin-right: 70px;
    white-space: nowrap;
    width: 340px;
    vertical-align: middle;
    &.title-1,&.title-2,&.title-3{
      font-weight: bold;
      color: rgba(255,255,255,0.8);
    }
    span{
      width: 100%;
      display: inline-block;
      white-space: nowrap;
    }
  }
  .right{
    float: right;
    text-align: right;
    .icon{
      display: inline-block;
      width: 14px;
      height: 14px;
      margin-right: 4px;
      background: url("../../../../assets/images/rank_hot.png");
      background-size: 14px 14px;
      background-position: center 2px;
      background-repeat: no-repeat;
    }
    .num{
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      color: #B3B3B3;
    }
  }
}
</style>
