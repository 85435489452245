<template>
  <div class="awards-info-default">
    <awards-info-pc
      v-if="browser === 1"
      :options="options"
      :set-collect="setCollect"
      :set-zan="setZan"
    />
    <awards-info-mobile
      v-if="browser === 2"
      :options="options"
      :set-collect="setCollect"
      :set-zan="setZan"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import AwardsInfoPc from "@/views/Pc/Awards/info";
import AwardsInfoMobile from "../../Mobile/Awards/info";

export default {
  name: 'AwardsInfoDefault',
  components: {AwardsInfoMobile, AwardsInfoPc},
  data() {
    return {
      options: {
        id: 0,
        info: {}
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
  },
  mounted() {
    this.options.id = this.$route.query.id
    this.options.share = this.$route.query.share || 0
    this.defaultOptions = this.deepClone(this.options)
    this.init()
  },
  methods: {
    init() {
      this.$u.api.awardss.getDetail({id: this.options.id, share: this.options.share}).then(res => {
        this.isFirst = false
        if(res.status === 1){
          this.options.info = res.data
          this.setShare(res)
        }
      })
    },
    setShare(res){
      this.getWxConfig(this.$t2(this.lang, res.data.title, res.data.title_en), this.$t2(this.lang, res.data.content, res.data.content_en), res.data.pic)
    },
    setCollect(v) {
      this.options.info.is_cang = v
    },
    setZan(v,val) {
      this.options.info.is_zan = v
      this.options.info.zan = val
    }
  }

}
</script>

<style scoped></style>
