<template>
  <div
    class="user-collect-exhibition-card-list-modal-mobile"
    @click.stop="hideModal"
  >
    <div class="container info">
      <div class="top">
        <div class="pic radius">
          <pic-mobile :src="info.logoimageurl" />
        </div>
        <div class="company-info">
          <div
            class="title"
            @click="navigateTo('/exhibition/info?id='+info.id)"
          >
            {{ $t2(lang, info.companyname, info.companynameen) }}
          </div>
          <div class="line1"></div>
        </div>
      </div>
      <div class="bottom">
        <icon-text-location-mobile
          class="item"
          color="#fff"
          from="collect"
          :label="info.boothno"
        />
        <a
          :href="`http://api.map.baidu.com/geocoder?address=`+info.address+`&output=html&src=webapp.baidu.openAPIdemo`"
          target="_blank"
        >
          <icon-text-address-mobile
            class="item"
            color="#fff"
            from="collect"
            :label="$t2(lang, info.address, info.address_en)"
          />
        </a>
        <icon-text-tel-mobile
          class="item"
          color="#fff"
          from="collect"
          :label="info.phone"
          @click="window.location.href = 'tel://'+info.phone"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "../../common/Pic";
import IconTextLocationMobile from "../../common/IconText/location";
import IconTextAddressMobile from "../../common/IconText/address";
import IconTextTelMobile from "../../common/IconText/tel";

export default {
  name: 'UserCollectExhibitionCardListModalMobile',
  components: {IconTextTelMobile, IconTextAddressMobile, IconTextLocationMobile, PicMobile},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    hideModal: {
      type: Function,
      default: () => () =>{}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.user-collect-exhibition-card-list-modal-mobile {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .info{
    width: 100%;
    background: #F7F8FA;
    box-shadow: 0 5px 20px 0 rgba(30, 115, 234, 0.1);
    border-radius: 12px;
    .top{
      padding: 40px;
      display: flex;
      .pic{
        width: 160px;
        height: 160px;
        margin-right: 24px;
      }
      .title{
        height: 104px;
        font-size: 32px;
        font-weight: 500;
        color: #000000;
        line-height: 52px;
      }
      .line1{
        width: 48px;
        height: 4px;
        background: linear-gradient(223deg, #FF1A2C, #FF9B61 100%);
        border-radius: 2px;
        opacity: 0.6;
        display: inline-block;
        margin-top: 29px;
      }
    }
    .bottom{
      height: 148px;
      background: linear-gradient(223deg, #FC501E, #FA8958 100%);
      border-radius: 0 0 12px 12px;
      padding: 22px 43px;
      .item{
        margin-bottom: 12px;
      }
    }
  }
}
</style>
