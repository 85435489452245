<template>
  <div
    class="acitivity-item-pc"
    @click="navigateTo('/activity/info?id='+info.id)"
  >
    <div class="pic">
      <pic-pc :src="info.pic" />
    </div>
    <div class="info">
      <div class="title line-1">
        <div v-html="title"></div>
      </div>
      <div class="tags">
        <template v-for="(res, index) in info.catename">
          <tag-item
            :key="index"
            class="item"
            :dark="true"
            :label="$t2(lang, res.name, res.ename)"
          />
        </template>
      </div>
      <div class="summary line-2">
        <icon-text-time
          color="#999"
          :label="timeFormat(info.done_start_time, 'mm/dd hh:MM') + ` - ` + timeFormat(info.done_end_time, 'mm/dd hh:MM')"
        />
        <a
          class="item"
          :href="`http://api.map.baidu.com/geocoder?address=`+($t2(lang,
                                                                   info.cn_address,
                                                                   info.en_address))+`&output=html&src=webapp.baidu.openAPIdemo`"
          target="_blank"
          @click.stop=""
        >
          <icon-text-location
            color="#999"
            :label="$t2(lang, info.cn_address, info.en_address)"
          />
        </a>
      </div>
      <div class="btn-view">
        <activity-btn-pc
          :info="info"
          :people="info.people"
          :status="info.is_status"
          @click="yuyue"
        />
        <div
          v-if="parseInt(info.is_status) === 0"
          class="people"
        >
          剩余{{ info.people }}人
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicPc from "@/components/Pc/common/Pic";
import TagItem from "@/components/Pc/common/TagItem";
import ActivityBtnPc from "@/components/Pc/Activity/ActivityBtn";
import IconTextTime from "../../common/IconText/time";
import IconTextLocation from "../../common/IconText/location";
import IconTextDel from "../../common/IconText/del";

export default {
  name: 'ActivityItemPc',
  components: { IconTextLocation,  IconTextTime, ActivityBtnPc, TagItem, PicPc},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    showReserveModal: {
      type: Function,
      default: () => () => {}
    },
    keyword: {
      type: String,
      default: () => ''
    },
    from: {
      type: String,
      default: () => ''
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    title(){
      return this.replaceSearch(this.lang, this.keyword, this.info.title, this.info.en_title)
    },
  },
  methods: {
    yuyue(){
      if(this.info.is_status === 0){
        this.showReserveModal(this.info)
      }
      if((this.info.is_status === 2 || this.info.is_status === -1) && this.info.live_id > 0){
        this.navigateTo('/Live?typeCurrent=1&liveid=' + this.info.live_id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.acitivity-item-pc {
  background: rgba(255,255,255,0.1);
  vertical-align: top;
  display: inline-block;
  width: 360px;
  height: 442px;
  margin-right: 20px;
  margin-bottom: 20px;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  &:nth-child(3n){
    margin-right: 0;
  }
  &:hover{
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
    .pic{
      div{
        transition: all 0.4s ease-in-out;
        transform: scale(1.1);
      }
    }
  }
  .pic{
    width: 360px;
    height: 240px;
    overflow: hidden;
  }
  .info{
    padding: 12px 20px 12px;
    .title{
      font-size: 20px;
      font-weight: 600;
      color: #fff;
      line-height: 24px;
    }
    .tags{
      margin-top: 10px;
    }
    .summary{
      height: 40px;
      line-height: 20px;
      font-weight: 500;
      color: #999999;
      margin-top: 8px;
      .item{
        margin-top: 10px;
      }
    }
    .people{
      font-size: 12px;
      font-weight: 400;
      color: #B3B3B3;
      line-height: 12px;
      margin-top: 8px;
      text-align: center;
    }
    .location-line{
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
