<template>
  <div
    v-if="list.length > 0"
    class="news-swiper-pc"
  >
    <div class="left">
      <div
        class="currentImg"
        :style="{backgroundImage: 'url('+list[swiperIndex].pic+')'}"
        @click="navigateTo('/news/info?id='+list[swiperIndex].id)"
      >
        <div class="title">
          {{ $t2(lang, list[swiperIndex].title, list[swiperIndex].en_title) }}
        </div>
      </div>
    </div>
    <div
      class="right"
      @mouseenter="stopAutoSwiper"
      @mouseleave="startAutoSwiper"
    >
      <ul class="swiper-right-list">
        <template
          v-for="(res, index) in list"
        >
          <li
            v-if="index < 3"
            :key="index"
            @click="navigateTo('/news/info?id='+res.id)"
          >
            <div
              class="img"
            >
              <pic-pc :src="res.pic" />
            </div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicPc from "@/components/Pc/common/Pic";

export default {
  name: 'NewsSwiperPc',
  components: {PicPc},
  props: {
    list: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data(){
    return {
      swiperIndex: 0,
      timer: null,
    }
  },
  computed:  {
    ...mapState(['lang'])
  },
  mounted() {
    this.init()
  },
  methods:{
    init(){
      this.startAutoSwiper();
    },
    startAutoSwiper(){
      this.timer && clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.swiperIndex = this.swiperIndex + 1 > 2 ? 0 : this.swiperIndex + 1;
      }, 4000)
    },
    stopAutoSwiper(){
      this.timer && clearInterval(this.timer)
    },
    qiehuan(index){
      this.startAutoSwiper()
      this.swiperIndex=index
    }
  }
}
</script>

<style scoped lang="scss">
.news-swiper-pc{
  height: 560px;
  overflow: hidden;
  .left{
    width: 840px;
    height: 560px;
    float: left;
    .currentImg{
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      background-position: center center;
      cursor: pointer;
      .title{
        position: absolute;
        bottom: 0;
        left: 0;
        height: 64px;
        background: rgba(0,0,0,0.3);
        padding-left: 20px;
        color: #fff;
        line-height: 64px;
        width: 100%;
        margin: 0;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
  .right{
    float: right;
    ul.swiper-right-list{
      li{
        margin-bottom: 10px;
        div{
          width: 270px;
          height: 180px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
