<template>
  <div class="supply-demand-demand-modal-pc">
    <div class="cell">
      <div class="box">
        <div class="close-btn">
          <btn-close-pc
            size="24"
            @click="hideDemandModal"
          />
        </div>
        <div class="title-line clearfix">
          <div class="title">
            <div class="text">
              {{ $t2(lang, info.title, info.title_en) }}
            </div>
          </div>
          <div class="tags-line">
            <div class="tags">
              <template v-for="(res, index) in info.catename">
                <tag-item
                  :key="index"
                  class="item"
                  :dark="true"
                  :label="$t2(lang, res.name, res.ename)"
                />
              </template>
            </div>
          </div>
          <div
            v-if="!user && info.is_follow === 0"
            class="guanzhu-btn"
            @click="contactShow=true"
          >
            获取联系方式 >
          </div>
        </div>
        <split-line-pc
          bg-color="rgba(255,255,255,0.1)"
          height="1px"
          margin-top="0"
        />
        <div class="info-main">
          <div
            v-show="contactShow || user"
            class="contact-main"
          >
            <div class="cell-list">
              <div class="label">
                姓名：
              </div>
              <div class="text">
                {{ contactInfo.name }}
              </div>
            </div>
            <div class="cell-list">
              <div class="label">
                邮箱：
              </div>
              <div class="text">
                {{ contactInfo.email }}
              </div>
            </div>
            <div class="cell-list">
              <div class="label">
                公司：
              </div>
              <div class="text">
                {{ contactInfo.company }}
              </div>
            </div>
            <div
              v-if="!user"
              class="btns"
            >
              <btn-custom-pc
                :background="parseInt(info.is_follow) === 0 ? `linear-gradient(223deg, rgba(255, 26, 44, 0.9), rgba(255, 155, 97, 0.8))` : `#eee`"
                class="btn"
                :class="parseInt(info.is_follow) === 1 ? `on` : ``"
                :color="parseInt(info.is_follow) === 0 ? `#fff` : `#aaa`"
                :label="parseInt(info.is_follow) === 0 ? `添加到我的供需` : `已添加`"
                @click="guanzhu"
              />
            </div>
          </div>
          <div class="info-pic">
            <img :src="info.logo_url || info.logo" />
          </div>
          <text-content-pc
            class="content"
            :content="$t2(lang, info.content, info.content_en)"
            margin-bottom="0"
            title=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TextContentPc from "@/components/Pc/common/TextContent";
import BtnClosePc from "@/components/Pc/common/BtnClosePc";
import BtnCustomPc from "@/components/Pc/common/BtnCustom";
import SplitLinePc from "@/components/Pc/common/SplitLine";
import TagItem from "../../common/TagItem";

export default {
  name: 'SupplyDemandDemandModalPc',
  components: {TagItem, SplitLinePc, BtnClosePc,  TextContentPc, BtnCustomPc},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    hideDemandModal: {
      type: Function,
      default: () => () => {}
    },
    showDemandContactModalInfo: {
      type: Function,
      default: () => () => {}
    },
    user: {
      type: Boolean,
      default: () => false
    }
  },
  data(){
    return {
      contactShow: false
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    contactInfo() {
      return JSON.parse(this.htmlspecialchars_decode(this.info.contact))
    }
  },
  mounted() {
    if(this.info.is_follow){
      this.contactShow = true
    }
  },
  methods: {
    guanzhu() {
      const params = {
        sid: this.info.id,
      }
      this.$u.api.supplydemands.actfollow(params).then(res => {
        if(res.status === 1){
          this.info.is_follow = res.data.is_follow
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.supply-demand-demand-modal-pc{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  background: rgba(0,0,0,0.6);
  display: table;
  .info-pic{
    margin-top: 24px;
  }
  .cell{
    display: table-cell;
    vertical-align: middle;
    .box{
      position: relative;
      width: 880px;
      background: #262626;
      margin: 0 auto;
      padding-bottom: 24px;
      .close-btn{
        position: absolute;
        right: -24px;
        top: -24px;
      }
      .title-line{
        padding: 24px 32px 16px;
        .title{
          display: inline-block;
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          line-height: 24px;
          .text{
            display: inline-block;
            vertical-align: middle;
          }
        }
        .tags-line{
          margin-top: 12px;
          .tags{
            float: left;
            .item{
              margin-right: 4px;
            }
          }
        }
        .guanzhu-btn{
          float: right;
          font-size: 14px;
          font-weight: 500;
          color: rgba(178, 200, 255, 0.6000);
          line-height: 20px;
          cursor: pointer;
        }
      }
      .info-main{
        padding: 0 32px 0;
        max-height: 427px;
        overflow-y: auto;
        .contact-main{
          background: rgba(255,255,255,0.1);
          margin-top: 10px;
          padding: 10px;
          position: relative;
          .cell-list{
            display: table-row;
            height: 36px;
            line-height: 36px;
            font-size: 16px;
            font-weight: 500;
            .label{
              display: table-cell;
              color: rgba(255,255,255,0.8);
              width: 50px;
            }
            .text{
              display: table-cell;
              color: rgba(255,255,255,0.8);
            }
          }
          .btns{
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: auto;
          }
        }
        .pic{
          display: inline-block;
          width: 100%;
          height: 360px;
          vertical-align: middle;
          margin-top: 12px;
        }
        .company-line{
          height: 28px;
          line-height: 28px;
          margin-top: 12px;
          .company{
            float: left;
            .pic{
              display: inline-block;
              width: 28px;
              height: 28px;
              border-radius: 28px;
              overflow: hidden;
              vertical-align: middle;
            }
            .name{
              margin-left: 5px;
              display: inline-block;
              vertical-align: middle;
            }
          }
          .info{
            float: right;
          }
        }
      }
    }
  }
}
</style>
