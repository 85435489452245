<template>
  <div
    class="btn-custom-pc"
    :style="{background: background, color: color}"
    @click="$emit('click')"
  >
    {{ label }}
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'BtnCustomPc',
  props:{
    label: {
      type:String,
      default: () => ''
    },
    background: {
      type: String,
      default: () => '#F7F7F7'
    },
    color: {
      type: String,
      default: () => '#7f7f7f'
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.btn-custom-pc{
  cursor: pointer;
  display: inline-block;
  line-height: 32px;
  height: 32px;
  padding: 0 32px;
  color: #fff;
  vertical-align: top;
  &:hover{
    opacity: 0.8;
  }
}
</style>
