<template>
  <div
    class="demand-demand-item-supply-pc-supply-pc"
    :class="from"
    @click="$emit('click')"
  >
    <div class="title">
      <span class="text line-1">{{ info.title }}</span>
      <span class="tips">采购需求</span>
    </div>
    <div class="summary line-1">
      <span
        class=" line-1"
        v-html="$t2(lang, info.content, info.content_en)"
      ></span>
    </div>
    <div class="tags-line clearfix">
      <div class="tags">
        <template v-for="(res, index) in info.catename">
          <tag-item
            :key="index"
            class="item"
            :dark="true"
            :label="res.name"
          />
        </template>
      </div>
      <div
        v-if="from!=='user'"
        class="info"
        @click.stop="showDemandContactModalInfo(info)"
      >
        获取联系方式 >
      </div>
      <div
        v-else
        class="right"
      >
        <icon-text-supply-del
          :id="info.id"
          class="item"
          label=""
          :refresh="refresh"
          :shoucang="parseInt(info.is_cang) === 1"
          :type="4"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import TagItem from "@/components/Pc/common/TagItem";
import IconTextSupplyDel from "../../../common/IconText/supplydel";

export default {
  name: 'SupplyDemmandItemDemandPc',
  components: {IconTextSupplyDel, TagItem},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    from: {
      type: String,
      default: () => ''
    },
    showDemandContactModalInfo: {
      type: Function,
      default: () => () => {}
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.demand-demand-item-supply-pc-supply-pc{
  overflow: hidden;
  cursor: pointer;
  &.user{
    .title{
      .text{
        width: 235px;
      }
    }
    .tags-line{
      margin-top: 10px;
    }
  }
  .title{
    .text{
      width: 390px;
      line-height: 28px;
      display: inline-block;
      margin-right: 60px;
      font-size: 18px;
      font-weight: 600;
      color: #fff;
    }
    .tips{
      height: 28px;
      width: 72px;
      float: right;
      line-height: 28px;
      display: inline-block;
      background: linear-gradient(223deg, rgba(0, 92, 179, .2), rgba(69, 151, 229, .2));
      color: #6591FF;
      text-align: center;
    }
  }
  .summary{
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255,255,255,0.6);
    line-height: 18px;
    width: 420px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tags-line{
    height: 28px;
    line-height: 28px;
    margin-top: 24px;
    .right{
      float: right;
    }
    .tags{
      float: left;
      .item{
        margin-right: 4px;
      }
    }
    .info{
      float: right;
      color: #808080;
    }
  }
}
</style>
