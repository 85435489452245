<template>
  <div class="supply-demand-item-mobile">
    <supply-demmand-item-supply-mobile
      v-if="parseInt(info.is_type) === 0 || parseInt(info.is_type) === 2"
      :from="from"
      :info="info"
      :refresh="refresh"
      :set-collect="setCollect"
      @click="showSupplyModalInfo(info)"
    />
    <supply-demmand-item-demand-mobile
      v-if="parseInt(info.is_type) === 1"
      :from="from"
      :info="info"
      :refresh="refresh"
      :set-collect="setCollect"
      :show-demand-contact-modal-info="showDemandContactModalInfo"
      @click="showDemandModalInfo(info)"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import SupplyDemmandItemSupplyMobile from "./component/supply";
import SupplyDemmandItemDemandMobile from "./component/demand";

export default {
  name: 'SupplyDemandItemMobile',
  components: {
    SupplyDemmandItemDemandMobile,
    SupplyDemmandItemSupplyMobile},
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    refresh: {
      type: Function,
      default: () => () => {}
    },
    showSupplyModalInfo: {
      type: Function,
      default: () => () => {}
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    showDemandModalInfo: {
      type: Function,
      default: () => () => {}
    },
    showDemandContactModalInfo: {
      type: Function,
      default: () => () => {}
    },
    from: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.supply-demand-item-mobile{
  margin-bottom: 20px;
  width: 100%;
  height: 240px;
  overflow: hidden;
  background: rgba(255,255,255,0.1);
  border-radius: 12px;
  padding: 24px;
}
</style>
