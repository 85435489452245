<template>
  <!--  <img-->
  <!--    v-lazy="src"-->
  <!--  />-->
  <div
    v-lazy:background-image="src"
    class="pic-bg"
    data-error="../../../../../assets/images/newlogo/logo_chang1.png"
    :style="{borderRadius: borderRadius + `px`}"
  ></div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'PicPc',
  props:{
    src: {
      type: String,
      default: ''
    },
    borderRadius: {
      type: [String, Number],
      default: 0
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.pic-bg{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-color: rgba(255,255,255,0.1);
  background-repeat: no-repeat;
}
</style>
