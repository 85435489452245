<template>
  <div
    class="case-list-item-mobile"
    @click="navigateTo('/case/info?id='+info.id)"
  >
    <div class="pic">
      <pic-mobile :src="info.pic" />
    </div>
    <div class="info">
      <div
        class="title line-2"
        v-html="title"
      >
      </div>
      <div class="tags line-1">
        <div class="tags-view">
          <tags-item-mobile
            :dark="true"
            :info="info.catename && (info.catename[0] || info.catename)"
          />
        </div>
        <icon-text-collect-mobile
          :id="info.id"
          :bbs="true"
          class="item"
          color="#c2c2c2"
          font-size="20px"
          label=""
          :set-collect="setCollect"
          :shoucang="parseInt(info.is_cang) === 1"
          :type="5"
          width="32px"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "../../common/Pic";
import TagsItemMobile from "../../common/TagsItem";
import IconTextCollectMobile from "../../common/IconText/collect";

export default {
  name: 'CaseListItemMobile',
  components: {IconTextCollectMobile, TagsItemMobile, PicMobile},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    keyword: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
    title(){
      return this.replaceSearch(this.lang, this.keyword, this.info.title, this.info.en_title)
    },
  },
  methods: {
    setCollect(v){
      this.info.is_cang = v
    }
  }
}
</script>

<style scoped lang="scss">
.case-list-item-mobile {
  width: 330px;
  margin-bottom: 16px;
  background: rgba(255,255,255,0.1);
  border-radius: 12px;
  overflow: hidden;

  .pic{
    width: 330px;
    height: 220px;
  }
  .info{
    padding: 16px 16px 36px;
    .title{
      height: 80px;
      font-size: 28px;
      font-weight: 500;
      color: #fff;
      line-height: 40px;
      text-align: left;
    }
    .tags{
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      .tags-view{
        max-width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .summary{
      margin-top: 12px;
      height: 64px;
      font-size: 22px;
      font-weight: 500;
      color: rgba(255,255,255,0.8);
      line-height: 32px;
    }
  }
}
</style>
