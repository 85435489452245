<template>
  <div
    class="search-view-mobile"
    :class="className"
    :style="{height: height + 'px'}"
  >
    <div class="left">
      <div class="icon">
        <img
          v-if="className===''"
          src="../../../../assets/images/mobile/search_mobile.png"
        />
        <img
          v-else
          src="../../../../assets/images/mobile/search-index-icon.png"
        />
      </div>
      <div class="input">
        <mt-field
          v-if="!tabStatus"
          v-model="keyword"
          :placeholder="$t('20011')"
          :style="{fontSize: '28px'}"
        />
        <div
          v-else
          class="keyword-input"
        >
          <div
            class="keyword-view"
            @click="clearinput"
          >
            <div class="span">
              {{ keyword }}
            </div>
          </div>
          <div
            class="mint-field-clear"
            @click="clearinput"
          >
            <i class="mintui mintui-field-error"></i>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!searchView"
      class="btn"
      :style="{height: height + 'px', lineHeight: height + 'px'}"
      @click="searchAction(keyword)"
    >
      {{ $t('50025') }}
    </div>
    <div
      v-else
      class="btn cancel"
      @click="close"
    >
      {{ $t('50020') }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'SearchViewMobile',
  props: {
    options: {
      type: Object,
      default: () => {
      }
    },
    search: {
      type: Function,
      default: () => () => {}
    },
    closeSearch: {
      type: Function,
      default: () => () => {}
    },
    searchView: {
      type: Boolean,
      default: () => false
    },
    tab: {
      type: Boolean,
      default: () => false
    },
    className: {
      type: String,
      default: () => ''
    },
    height: {
      type: [String, Number],
      default: () => 68
    }
  },
  data() {
    return {
      keyword: '',
      tabStatus: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  mounted() {
      this.keyword = this.$route.query.keyword || this.options.data.key
      if(this.tab){
        this.tabStatus = true
      }
  },
  methods: {
    close(){
      this.closeSearch()
    },
    clearinput(){
      this.tabStatus = false
      this.keyword = ''
      this.search(this.keyword)
    },
    searchAction(keyword){
      if(keyword !== '' && this.tab){
        this.tabStatus = true
      }
      this.search(keyword)
    }
  }
}
</script>

<style scoped lang="scss">
.search-view-mobile {
  background: #f5f5f5;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  &.search-index{
    background: #fff;
  }
  .left{
    display: flex;
    align-items: center;
    flex: 1;

    .icon{
      width: 40px;
      height: 40px;
      margin-left: 18px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .input{
      flex: 1;
      font-size: 28px;
      font-weight: 400;
      color: #CCCCCC;
      line-height: 40px;
      margin-left: 20px;
      input{
        width: 100%;
        height: 100%;
      }
      .keyword-input{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .mint-field-clear{
          margin-right: 10px;
        }
        .keyword-view{
          width: calc(100vw - 300px);
          overflow: hidden;
          text-overflow: ellipsis;
          .span{
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 10px;
            border-radius: 50px;
            line-height: 1.6;
            font-size: 24px;
            color: #333;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            white-space: nowrap;
            .clear-btn{
              font-size: 26px;
              display: inline-block;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .btn{
    width: 148px;
    height: 68px;
    background: #EA4C1E;
    font-size: 28px;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    line-height: 68px;
    border-radius: 0 12px 12px 0;
    &.cancel{
      color: #999999;
      background: none;
    }
  }
}
</style>
