<template>
  <div
    class="user-collect-exhibition-card-list-item-pc"
    @click="navigateTo('/exhibition/info?id='+info.id)"
  >
    <div
      class="delbtn"
    >
      <icon-text-card-del
        :id="info.id"
        class="item"
        label=""
        :refresh="refresh"
        :shoucang="parseInt(info.is_cang) === 1"
        :type="4"
      />
    </div>
    <div class="info">
      <div class="pic">
        <pic-pc :src="info.logoimageurl" />
      </div>
      <div class="title">
        {{ $t2(lang, info.companyname, info.companynameen) }}
      </div>
    </div>
    <div class="other">
      <icon-text-location
        class="item line-1"
        color="#fff"
        icon="location_white.png"
        :label="$t2(lang, info.address, info.address_en)"
      />
      <icon-text-line
        class="lianxi-item"
        color="#fff"
        icon="address_white.png"
        :text="info.boothno"
      />
      <icon-text-line
        class="lianxi-item"
        color="#fff"
        icon="tel_white.png"
        :text="info.phone"
      />
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicPc from "../../common/Pic";
import IconTextLocation from "../../common/IconText/location";
import IconTextLine from "../../common/IconTextLine";
import IconTextCardDel from "../../common/IconText/carddel";

export default {
  name: 'UserCollectExhibitionCardListItemPc',
  components: {IconTextCardDel, IconTextLine, IconTextLocation, PicPc},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    refresh: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.user-collect-exhibition-card-list-item-pc {
  width: 400px;
  margin-right: 42px;
  background: rgba(255,255,255,0.1);
  margin-bottom: 32px;
  box-shadow: 0 3px 11px 0 rgba(30, 115, 234, 0.1);
  position: relative;
  &:nth-child(2n){
    margin-right: 0;
  }
  .delbtn{
    position: absolute;
    right: 20px;
    bottom: 100px;
    z-index: 2;
  }
  .info{
    padding: 22px;
    display: flex;
    .pic{
      width: 94px;
      height: 94px;
      margin-right: 15px;
    }
    .title{
      flex: 1;
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      line-height: 28px;
      position: relative;
      &:after{
        position: absolute;
        bottom: 10px;
        left: 0;
        content: '';
        display: inline-block;
        width: 28px;
        height: 2px;
        background: linear-gradient(223deg, #FF1A2C 0%, #FF9B61 100%);
        border-radius: 1px;
        opacity: 0.6;
      }
    }
  }
  .other{
    padding: 16px 20px;
    height: 86px;
    background: linear-gradient(223deg, #FC501E, #FA8958);
    color: #fff;
  }
}
</style>
