<template>
  <div
    class="activity-btn-mobile"
  >
    <a
      v-if="user.tokens && !user.qrcode"
      class="btn"
      :class="statusClass"
      :href="regurl"
      target="_blank"
      @click.stop=""
    >{{ statusTest }}</a>
    <div
      v-else
      class="btn"
      :class="statusClass"
      @click.stop="from !== 'user' && $emit('click')"
    >
      {{ statusTest }}
    </div>
    <div
      v-if="parseInt(status) === 0"
      class="people"
    >
      剩余{{ people }}人
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  //预约状态，0可预约，1已预约，2预约结束，3即将开始,4已约满
  name: 'ActivityBtnMobile',
  props: {
    status: {
      type: Number,
      default: 0
    },
    info: {
      type: Object,
      default:() => {}
    },
    people: {
      type: [Number, String],
      default: () => ''
    },
    from: {
      type: [Number, String],
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'user']),
    statusTest() {
      if(this.from==='user'){
        return '已预约'
      }
      switch(this.status){
        case -1:
          if(this.info.live_id > 0){
            return '观看直播'
          }else{
            return '无需预约'
          }
        case 0:
          return '可预约'
        case 1:
          if(this.info.live_id > 0){
            return '观看直播'
          }else{
            return '已预约'
          }
        case 2:
          if(this.info.live_id > 0){
            return '观看直播'
          }else{
            return '预约结束'
          }
        case 3:
          return '即将开始'
        case 4:
          return '已约满'
      }
      return ''
    },
    statusClass() {
      if(this.from==='user'){
        return 'in'
      }
      switch(this.status){
        case -1:
          if(this.info.live_id > 0){
            return 'in'
          }else {
            return 'default'
          }
        case 0:
          return 'on'
        case 1:
        case 3:
          return 'in'
        case 2:
          if(this.info.live_id > 0){
            return 'in'
          }else{
            return 'default'
          }
        case 4:
          return 'default'
      }
      return 'default';
    },
    regurl(){
      return 'https://online.ciif-expo.cn/RegMethod.html?pcurl='+location.href+'&phone='+this.user.mobile
    }
  },
}
</script>

<style scoped lang="scss">
.activity-btn-mobile {
  .btn{
    width: 100%;
    height: 56px;
    line-height: 56px;
    text-align: center;
    margin-top: 16px;
    font-size: 24px;
    border-radius: 8px;
    overflow: hidden;
    display: inline-block;
    &.on{
      background: linear-gradient(223deg, #FC501E, #FA8958);
      color: #fff;
    }
    &.in{
      background: linear-gradient(223deg, #faeee9, #fae9e4);
      color: #EA4C1E;
    }
    &.default{
      background: rgba(204, 204, 204, 0.4);
      color: #989898;
    }
  }
  .people{
    margin-top: 12px;
    height: 20px;
    font-size: 20px;
    font-weight: 400;
    color: #B3B3B3;
    line-height: 20px;
    text-align: center;
  }
}
</style>
