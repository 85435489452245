<template>
  <div class="exhibition-list-default">
    <exhibition-list-pc
      v-if="browser===1"
      :change-child-type-id="changeChildTypeId"
      :change-key="changeKey"
      :change-page="changePage"
      :change-type="changeType"
      :change-type-id="changeTypeId"
      :options="options"
      :search="search"
    />
    <exhibition-list-index-mobile
      v-if="browser===2"
      :change-child-type-id="changeChildTypeId"
      :change-key="changeKey"
      :change-page="changePage"
      :change-type="changeType"
      :change-type-id="changeTypeId"
      :options="options"
      :search="search"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import ExhibitionListPc from "@/views/Pc/Exhibition/list";
import ExhibitionListMobile from "@/views/Mobile/Exhibition/list";
import ExhibitionListIndexMobile from "@/views/Mobile/Exhibition/list";

export default {
  name: 'ExhibitionListDefault',
  components: {ExhibitionListIndexMobile, ExhibitionListPc},
  data() {
    return {
      options: {
        typeList: [],
        childTypeList: [],
        typeId: 0,
        childTypeId: 0,
        loading: false,
        data: {
          list: [],
          key: '',
          isType: 0,
          nowType: 0,
          page: 1,
          pagesize: this.browser === 1 ? 10 : 10,
          total: 0
        },
        ads: []
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if(this.$route.meta.needRefresh){
      if(!this.isFirst){
        this.options = this.defaultOptions;
        this.options.typeId = this.$route.query.typeid
        if(this.$route.meta.fromUrl === '/exhibition'){
          this.init()
        }
      }
    }
    this.scrollAuto()
    this.setShare()
  },
  mounted() {
    this.options.typeId = this.$route.query.typeid
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    this.init()
    this.setShare()
  },
  methods: {
    init() {
      if(this.browser === 1){
        this.options.data.pagesize = 10
      }else{
        this.options.data.pagesize = 10
      }
      this.getTypeList()
    },
    getTypeList(){
      this.$u.api.exhibitions.getParentTypes().then((res) => {
        if (res.status === 1) {
          res.data.unshift({
            id: 0,
            typename: '全部',
            typename_en: 'All'
          })
          this.options.typeList = res.data
          this.getChildTypeList();
        }
      })
    },
    getChildTypeList(change=false){
      const params = {
        typeid: this.options.typeId
      }
      this.$u.api.exhibitions.getChildTypes(params).then((res) => {
        if (res.status === 1) {
          res.data.unshift({
            id: 0,
            typelistname: '全部',
            typelistname_en: 'All'
          })
          this.options.childTypeList = res.data
          this.options.childTypeId = 0
          this.options.data.page = 1
          if(!change || navigator.userAgent.indexOf('Mobile') === -1){
            this.getList()
            this.getAds(this.options.typeId)
          }
          this.setShare();
        }
      })
    },
    getList(){
      const params = {
        typesid: this.options.typeId,
        typelistid: this.options.childTypeId,
        page: this.options.data.page,
        key: this.options.data.key,
        pagesize: this.options.data.pagesize
      }
      this.options.loading = true
      if(this.options.data.isType===0){
        this.$u.api.exhibitions.getLists(params).then(res => {
          this.options.loading = false
          if(res.status === 1){
            this.options.data.nowType = this.options.data.isType
            this.options.data.list = res.data.lists
            this.options.data.total = res.data.totalNum;
            this.goTop()
          }
        })
      }else{
        this.$u.api.exhibitions.getProductLists(params).then(res => {
          this.options.loading = false
          if(res.status === 1){
            this.options.data.nowType = this.options.data.isType
            this.options.data.list = res.data.lists
            this.options.data.total = res.data.totalNum;
            this.goTop()
          }
        })
      }
    },
    getAds(typelistid){
      this.$u.api.commons.getZhanAds({typelistid: typelistid}).then(res => {
        if(res.status === 1){
          this.options.ads = []
          this.$nextTick(() => {
            this.options.ads = res.data
          })
        }
      })
    },
    changeTypeId (id) {
      this.options.typeId = id
      this.options.data.page = 1
      this.options.childTypeId = 0
      this.$router.push({
        query:  {'typeid': id}
      })
      this.getChildTypeList(true)
      this.setShare();
    },
    changeChildTypeId (id) {
      this.options.data.page = 1
      this.options.childTypeId = id
      this.getList()
    },
    changePage (page) {
      this.options.data.page = page
      this.getList()
    },
    changeKey (val) {
      this.options.data.key = val
    },
    changeType (val) {
      this.options.data.page = 1
      this.options.data.isType = val
    },
    search () {
      this.getList()
    },
    setShare(){
      console.log('this.options.typeList', this.options.typeId, this.options.typeList.filter(r => r.id == this.options.typeId))
      let title = '数字工博'
      let img = ''
      let now = this.options.typeList.filter(r => r.id == this.options.typeId);
      if(now.length > 0){
        title = now[0]['typename']
        img = now[0]['smallimg']
      }
      this.getWxConfig(title, '2000+线上展商，2300+新产品新技术。', img)
    }
  }
}
</script>

<style scoped></style>
