<template>
  <div class="supply-demand-category-mobile">
    <template v-for="(res, index) in category">
      <supply-demand-category-item-mobile
        :key="index"
        :info="res"
        :on="categoryCurrent === index"
        @click="changeCategoryCurrent(index)"
      />
    </template>
  </div>
</template>

<script>

import {mapState} from "vuex";
import SupplyDemandCategoryItemMobile from "./item";

export default {
  name: 'SupplyDemandCategoryMobile',
  components: {SupplyDemandCategoryItemMobile},
  props: {
    category: {
      type: Array,
      default: () => []
    },
    categoryCurrent: {
      type: Number,
      default: () => 0
    },
    changeCategoryCurrent: {
      type: Function,
      default: () => () => {}
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.supply-demand-category-mobile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
