<template>
  <div class="icon-text-time">
    <div class="icon"></div>
    <div class="label">
      {{ label }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'IconTextTime',
  props: {
    label: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  }

}
</script>

<style scoped lang="scss">
.icon-text-time{
  height: 20px;
  .icon{
    width: 14px;
    height: 14px;
    background: url("../../../../assets/images/icon_clock_hei.png") no-repeat;
    background-size: 100% 100%;
    float: left;
    margin-top: 3px;
  }
  .label{
    height: 20px;
    color: #999999;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-left: 20px;
  }
}
</style>
