<template>
  <div
    class="awards-category-item-mobile"
    @click="navigateTo('/awards/list?typeid='+info.id)"
  >
    <div class="pic radius">
      <pic-mobile :src="info.pic" />
    </div>
    <div class="title line-1">
      {{ $t2(lang, info.name, info.ename) }}
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import PicMobile from "../../common/Pic";

export default {
  name: 'AwardsCategoryItemMobile',
  components: {PicMobile},
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.awards-category-item-mobile {
  width: 309px;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 20px;
  display: inline-block;
  margin-right: 20px;
  &:nth-child(2n){
    margin-right: 0;
  }
  .pic{
    width: 309px;
    height: 309px;
  }
  .title{
    font-size: 28px;
    font-weight: 600;
    color: #fff;
    line-height: 28px;
    padding: 16px 0;
  }
}
</style>
