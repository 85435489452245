<template>
  <div class="news-hezuo-mobile">
    <div
      v-if="hezuo.length > 0"
      class="links"
    >
      <h3>合作媒体<img src="../../../../assets/images/icon_friend.png" /></h3>
      <swiper
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="(res, index) in hezuo"
          :key="index"
          class="swiper-item-pic"
        >
          <div class="swiper-item-pic-div">
            <img :src="res.pic" />
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-page">
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import '@/assets/swiper.css'
export default {
  name: 'NewsHezuoMobile',
  components: {  Swiper, SwiperSlide},
  directives: {
    swiper: directive
  },
  props: {
    hezuo: {
      type: Array,
      default: () => []
    }
  },
  data(){
    return {
      swiperOptions: {
        slidesPerView: 3,
        slidesPerColumn: 2,
        slidesPerColumnFill: 'row',
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        slidesPerGroup: 2,
      },
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
}
</script>

<style scoped lang="scss">
.news-hezuo-mobile {
  margin: 0 30px;
  border-radius: 12px;
  .links{
    width: 100%;
    h3{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 32px;
      margin-bottom: 28px;
      color: #fff;
      img{
        width: 32px;
        height: 32px;
        margin-left: 6px;
      }
    }
    .swiper-item-pic{
      display: flex;
      justify-content: center;
      align-items: center;
      .swiper-item-pic-div{
        width: 216px;
        border: 1px solid #EFEFEF;
        background: #fff;
        padding: 0 5px;
        margin-bottom: 15px;
        border-radius: 4px;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
