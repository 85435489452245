<template>
  <div class="activity-index-default">
    <activity-index-pc
      v-if="browser===1"
      :change-page="changePage"
      :change-type-id="changeTypeId"
      :options="options"
    />
    <activity-index-mobile
      v-if="browser===2"
      :change-page="changePage"
      :change-type-id="changeTypeId"
      :options="options"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import ActivityIndexPc from "@/views/Pc/Activity";
import ActivityIndexMobile from "../../Mobile/Activity";

export default {
  name: 'ActivityIndexDefault',
  components: {ActivityIndexMobile, ActivityIndexPc},
  data() {
    return {
      options: {
        loading: true,
        typeId: 32,
        childType: [],
        childTypeId: 0,
        data: {
          list: [],
          page: 1,
          pagesize: 9,
          total: 0
        }
      },
      defaultOptions: {},
      isFirst: false,
    }
  },
  computed: {
    ...mapState(['browser', 'lang']),
  },
  activated() {
    if (this.$route.meta.needRefresh) {
      if (!this.isFirst) {
        this.options = this.defaultOptions;
        this.init()
      }
    }
    this.scrollAuto()
  },
  mounted() {
    this.isFirst = false
    this.defaultOptions = this.deepClone(this.options)
    this.init()
  },
  methods: {
    init() {
      this.getChildType()
      this.setShare()
    },
    getChildType(){
      this.$u.api.commons.getChildArchivesType({typeId: this.options.typeId}).then(res => {
        if(res.status === 1){
          res.data.unshift({id: 0, name: '全部', ename: 'All'})
          this.options.childType = res.data
          this.options.childTypeId = res.data[0].id
          this.getList()
        }
      })
    },
    getList(){
      this.options.loading = true;
      const params = {
        typeid: this.options.childTypeId,
        page: this.options.data.page,
        pagesize: this.options.data.pagesize,
      }
      this.$u.api.activitys.getLists(params).then(res => {
        this.options.loading = false
        if(res.status === 1){
          const {data: {lists, totalNum}} = res
          this.options.data.list = lists
          this.options.data.total = totalNum
        }
      })
    },
    changeTypeId(v){
      this.options.childTypeId = v;
      this.options.data.page = 1;
      this.getList()
    },
    changePage(v){
      this.options.data.page = v;
      this.getList()
    },
    setShare(){
      this.getWxConfig(this.$t('10007') + this.bankuai(), this.$t('10007') + this.bankuai())
    }
  }

}
</script>

<style scoped></style>
