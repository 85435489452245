<template>
  <div class="user-left-menu-pc">
    <div class="username">
      {{ user.name }}
    </div>
    <ul>
      <template v-for="(res, index) in userMenu">
        <li
          :key="index"
          :class="nowPath===res.link ? `on` : ``"
          @click="navigateTo(res.link)"
        >
          <div
            class="pic"
            :class="`pic_` + (index + 1)"
          ></div>
          {{ $t(res.title) }}
        </li>
      </template>
      <li
        class="logout"
        @click="logout"
      >
        <div
          class="pic pic_5"
        ></div>
        {{ $t('30005') }}
      </li>
    </ul>
  </div>
</template>

<script>

import {mapState} from "vuex";

export default {
  name: 'UserLeftMenuPc',
  components: {},
  props: {
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'user', 'userMenu']),
    nowPath: function(){
      if(this.$route.path.split('/')[2]){
        return '/' + this.$route.path.split('/')[1] + '/' + this.$route.path.split('/')[2]
      }else{
        return '/' + this.$route.path.split('/')[1]
      }
    }
  },
  methods: {
    logout(){
      this.$confirm('是否要退出当前账号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass: 'pc-message'
      }).then(() => {
        this.$store.commit('setUser', {})
        this.navigateTo('/')
      })
    }
  }
}
</script>

<style scoped lang="scss">
.user-left-menu-pc {
  width: 260px;
  background: rgba(255,255,255,0.05);
  margin-right: 16px;
  backdrop-filter: blur(2px);
  .username{
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    padding-left: 28px;
    border-bottom: 1px solid #ebebeb;
  }
  ul{
    li{
      height: 50px;
      line-height: 50px;
      padding-left: 28px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      color: rgba(255, 255, 255, 0.8);
      &.logout{
        border-top: 1px solid rgba(255, 255, 255, 0.1000);
      }
      .pic{
        width: 16px;
        height: 16px;
        margin-right: 8px;
        &.pic_1{
          background: url("../../../../assets/images/user_icon_1.png") no-repeat;
          background-size: 100% 100%;
        }
        &.pic_2{
          background: url("../../../../assets/images/user_icon_2.png") no-repeat;
          background-size: 100% 100%;
        }
        &.pic_3{
          background: url("../../../../assets/images/user_icon_3.png") no-repeat;
          background-size: 100% 100%;
        }
        &.pic_4{
          background: url("../../../../assets/images/user_icon_4.png") no-repeat;
          background-size: 100% 100%;
        }
        &.pic_5{
          background: url("../../../../assets/images/user_icon_5.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      &:hover, &.on{
        color: #EA4C1E;
        .pic{
          width: 16px;
          height: 16px;
          margin-right: 8px;
          &.pic_1{
            background: url("../../../../assets/images/user_icon_1_on.png") no-repeat;
            background-size: 100% 100%;
          }
          &.pic_2{
            background: url("../../../../assets/images/user_icon_2_on.png") no-repeat;
            background-size: 100% 100%;
          }
          &.pic_3{
            background: url("../../../../assets/images/user_icon_3_on.png") no-repeat;
            background-size: 100% 100%;
          }
          &.pic_4{
            background: url("../../../../assets/images/user_icon_4_on.png") no-repeat;
            background-size: 100% 100%;
          }
          &.pic_5{
            background: url("../../../../assets/images/user_icon_5_on.png") no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
</style>
