<template>
  <div class="exhibition-info-pc-theme-1">
    <div
      class="top-banner"
      :style="{background: 'url('+options.info.template_banner_pc+')', backgroundSize: '100%'}"
    >
      <div class="container">
        <div class="lianxi-info">
          <icon-text-line-exhibition
            class="lianxi-item "
            class-name="position"
            color="#fff"
            :hover="false"
            v-if="false"
            :text="options.info.boothno"
          />
          <a
            :href="`http://api.map.baidu.com/geocoder?address=`+options.info.address+`&output=html&src=webapp.baidu.openAPIdemo`"
            target="_blank"
          >
            <icon-text-line-exhibition
              class="lianxi-item address"
              class-name="address"
              color="#fff"
              :text="$t2(lang, options.info.address, options.info.address_en)"
            />
          </a>
          <icon-text-line-exhibition
            class="lianxi-item tel"
            class-name="tel"
            color="#fff"
            :hover="false"
            :text="options.info.phone"
          />
        </div>
        <div class="btn-view">
          <icon-text-collect-exhibition-theme1
            :id="options.info.id"
            class="btn-item"
            :label="$t('50024')"
            :set-collect="setCollect"
            :shoucang="parseInt(options.info.is_cang) === 1"
            :type="1"
          />
          <icon-text-zan-exhibition-theme1
            :id="options.info.id"
            class="btn-item"
            :label="options.info.zan"
            :set-zan="setZan"
            :type="1"
            :zan="parseInt(options.info.is_zan) === 1"
          />
          <icon-text-share-exhibition-theme1
            class="btn-item"
            :label="$t('50016')"
          />
          <icon-text-jiaohuan-exhibition-theme1
            class="btn-item"
            :jiaohuan="parseInt(options.info.is_card) === 1"
            :label="!options.info.is_card ? $t('20102') : $t('20115')"
            @click="jiaohuanmingpian"
          />
        </div>
      </div>
    </div>
    <div
      v-if="options.info.videoList && options.info.videoList.length > 0"
      class="video-list"
      @click="stop"
    >
      <swiper
        ref="mySwiper"
        :options="swiperOptionsTop"
      >
        <swiper-slide
          v-for="(res, index) in options.info.videoList"
          :key="index"
        >
          <div
            v-if="!playing"
            class="play-btn"
            @click.stop="play()"
          ></div>
          <video
            :ref="'player_'+index"
            :poster="res.imageurl"
            :src="res.videourl"
          ></video>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination"></div>
    </div>
    <div class="main-box-div">
      <div class="main-content">
        <div class="container">
          <div class="title">
            {{ $t2(lang, options.info.companyname, options.info.companynameen) }}
          </div>
          <div class="title_en">
            {{ $t2(lang,options.info.companynameen, options.info.companyname) }}
          </div>
          <div
            class="exhibition-content"
            v-html="$t2(lang, options.info.content, options.info.content_en)"
          >
          </div>
        </div>
      </div>
      <div class="list-div">
        <news-list-exhibition-thumb1-pc
          v-if="options.info.informationList.length > 0"
          :list="options.info.informationList"
        />
        <product-list-exhibition-thumb1-pc
          v-if="options.info.productList.length > 0"
          :list="options.info.productList"
          :options="options"
        />
        <case-list-exhibition-thumb1-pc
          v-if="options.info.caselist.length > 0"
          :list="options.info.caselist"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import '@/assets/swiper.css'
import {mapState} from "vuex";
import IconTextLineExhibition from "../../../../components/Pc/common/IconTextLineExhibition";
import IconTextCollectExhibitionTheme1 from "../../../../components/Pc/Exhibition/Theme1/collect";
import IconTextZanExhibitionTheme1 from "../../../../components/Pc/Exhibition/Theme1/zan";
import IconTextShareExhibitionTheme1 from "../../../../components/Pc/Exhibition/Theme1/share";
import IconTextJiaohuanExhibitionTheme1 from "../../../../components/Pc/Exhibition/Theme1/jiaohuan";
import NewsListExhibitionThumb1Pc from "../../../../components/Pc/Exhibition/Theme1/NewsList/NewsListExhibitionThumb1";
import ProductListExhibitionThumb1Pc
  from "../../../../components/Pc/Exhibition/Theme1/ProductList/ProductListExhibitionThumb1";
import CaseListExhibitionThumb1Pc from "../../../../components/Pc/Exhibition/Theme1/CaseList/CaseListExhibitionThumb1";

export default {
  name: 'ExhibitionInfoPcTheme1',
  components: {
    CaseListExhibitionThumb1Pc,
    ProductListExhibitionThumb1Pc,
    NewsListExhibitionThumb1Pc,
    Swiper,
    SwiperSlide,
    IconTextJiaohuanExhibitionTheme1,
    IconTextShareExhibitionTheme1,
    IconTextZanExhibitionTheme1, IconTextCollectExhibitionTheme1, IconTextLineExhibition},
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    setCollect: {
      type: Function,
      default: () => () => {}
    },
    setZan: {
      type: Function,
      default: () => () => {}
    },
    jiaohuanmingpian: {
      type:Function,
      default: () => () => {}
    }
  },
  data(){
    return {
      videoCurrent: 0,
      playing: false,
      swiperOptionsTop: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    }
  },
  computed: {
    ...mapState(['browser', 'lang', 'windowHeight', 'windowWidth']),
    swiper() {
      return this.$refs.mySwiper ? this.$refs.mySwiper.$swiper : null
    }
  },
  watch: {
    options: {
      deep: true,
      handler: function(newVal, oldVal) {
        if(newVal.info.videoList){
          const _t = this;
          _t.$nextTick(() => {
            _t.swiperOptionsTop = {
              direction: 'vertical',
              height: this.windowHeight - 100,
              width: this.windowWidth,
              autoplay: false,
              pagination: {
                el: ".swiper-pagination",
              },
            }
            _t.$nextTick(() => {
              _t.swiper.on('slideChangeTransitionEnd', function () {
                _t.stop()
                _t.videoCurrent = this.realIndex
              })
            })
          })
        }
      }
    }
  },
  beforeCreate: function () {
    document.getElementsByTagName('body')[0].className = 'index-donghua'
  },
  beforeRouteEnter: function(){
    document.getElementsByTagName('body')[0].className = 'index-donghua'
  },
  activated: function(){
    document.getElementsByTagName('body')[0].className = 'index-donghua'
  },
  beforeDestroy: function(){
    if (this.vantaEffect) {
      this.vantaEffect.destroy()
    }
    document.getElementsByTagName('body')[0].className = ''
  },
  beforeRouteLeave: function(){
    document.getElementsByTagName('body')[0].className = ''
  },
  deactivated: function(){
    document.getElementsByTagName('body')[0].className = ''
  },
  mounted() {
  },
  methods: {
    showPlayer(videoUrl) {
      this.$bus.$emit('showPlayer', videoUrl)
    },
    stop(){
      this.playing = false;
      this.$refs['player_'+this.videoCurrent][0].pause()
    },
    play(){
      const _this = this;
      this.duration = this.$refs['player_'+this.videoCurrent][0].duration
      this.$refs['player_'+this.videoCurrent][0].play()
      this.$refs['player_'+this.videoCurrent][0].onended = () => {
        this.swiper.slideTo(this.videoCurrent + 1, 1000)
      };
      this.$refs['player_'+this.videoCurrent][0].ontimeupdate = () => {
        _this.currentTime = _this.$refs['player_'+_this.videoCurrent][0].currentTime
        _this.rangeValue = (_this.currentTime * 100 /  _this.duration)
      };
      // this.$refs['player_'+this.videoCurrent][0].
      this.playing = true;
    },
  }

}
</script>

<style scoped lang="scss">
.exhibition-info-pc-theme-1{
  .top-banner{
    height: calc(100vh);
    width: 100%;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position:top center;
    .container{
      height: 100%;
      position: relative;
      .lianxi-info{
        position: absolute;
        left: 0;
        bottom: 40px;
        .lianxi-item{
          margin-top: 12px;
        }
      }
      .btn-view{
        position: absolute;
        right: 0;
        bottom: 40px;
        .btn-item{
          margin-left: 40px;
        }
      }
    }
  }
  .video-list{
    height: calc(100vh);
    width: 100%;
    background: rgba(0,0,0,0.6);
    video{
      width: 100%;
      height: calc(100vh);
      object-fit: cover;
    }
    .play-btn{
      width: 240px;
      height: 240px;
      background: url("../../../../assets/images/exhibition_theme_1/play_btn@2x.png");
      background-size: 100% 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -120px;
      margin-top: -120px;
      z-index: 2;
    }
  }
  .main-box-div{
    background: url("../../../../assets/images/exhibition_theme_1/bg.png");
    background-size: 100%;
    background-position: top center;
    padding-bottom: 50px;
    .main-content{
      background: #fff;
      border-bottom-left-radius: 28px;
      border-bottom-right-radius: 28px;
      .container{
        padding: 40px;
        .title{
          font-size: 32px;
          font-weight: 500;
          color: #1A1A1A;
          line-height: 84px;
          text-align: center!important;
        }
        .title_en{
          font-size: 20px;
          font-weight: 400;
          color: #333333;
          line-height: 54px;
          text-align: center!important;
        }
        .exhibition-content{
          margin-top: 28px;
          font-size: 24px;
          font-weight: 400;
          color: #717071;
          line-height: 34px;
          text-indent: 2rem;
        }
      }
    }
    .list-div{
      margin-top: 49px;
    }
  }
}
</style>
