<template>
  <div
    v-if="showLang"
    class="lang-btn-mobile"
    @click="changeLang"
  >
    <img
      v-if="lang === 'zh'"
      src="../../../../assets/images/mobile/en.png"
    />
    <img
      v-if="lang === 'en'"
      src="../../../../assets/images/mobile/zh.png"
    />
  </div>
</template>

<script>

import {mapState} from "vuex";
import loadLanguageAsync from "@/common/i18n/i18n";
import locale from 'element-ui/lib/locale'
import en from 'element-ui/lib/locale/lang/en'
import zh from 'element-ui/lib/locale/lang/zh-CN'

export default {
  name: 'LangBtnMobile',
  props: {

  },
  computed: {
    ...mapState(['browser', 'lang', 'hideEnMenu']),
    nowPath: function(){
      return this.$route.path.split('/')[1]
    },
    showLang(){
      if(this.hideEnMenu){
        if(this.hideEnMenu.indexOf('/'+this.nowPath.toLowerCase()) > -1){
          return false
        }else{
          return true
        }
      }
      return true
    }
  },
  methods:{
    changeLang() {
      locale.use(this.lang === 'en' ? 'zh' : 'en')
      loadLanguageAsync(this.lang === 'en' ? 'zh' : 'en')
    }
  }
}
</script>

<style scoped lang="scss">
.lang-btn-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 48px;
    height: 48px;
  }
}
</style>
